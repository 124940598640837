import * as React from "react"
import * as styles from "./styles.scss"
import Button from "components/Button"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { IconProp } from "@fortawesome/fontawesome-svg-core"
import * as cx from "classnames"
import OptionDropdown from "components/OptionDropdown"

class DropdownButton extends React.PureComponent<DropdownButtonProps> {
  renderToggler = () => {
    const {
      label,
      leftIcon,
      rightIcon,
      color,
      variant,
      togglerClassName,
      disabled,
      sizeValue,
      handleButtonClick,
      isDiv
    } = this.props
    return (
      <Button
        className={cx(styles.dropdownButtonToggler, togglerClassName)}
        variant={variant}
        color={color}
        isDiv={isDiv ?? true}
        disabled={disabled}
        onClick={handleButtonClick}
        onBlur={this.props.onBlur}
        
      >
        <div>
          {leftIcon && (
            <FontAwesomeIcon className={styles.leftIcon} icon={leftIcon} />
          )}
          <span>{label} {sizeValue && <span style={{ fontWeight: "normal" }}>{sizeValue}</span>}</span>
          {rightIcon && (
            <FontAwesomeIcon className={styles.rightIcon} icon={rightIcon} />
          )}
        </div>
      </Button>
    )
  }
  render() {
    const {
      options,
      dropdownClassName,
      contentClassName,
      direction,
      onOptionClick,
      onOptionHover,
      currentSelectedOptionId
    } = this.props

    return (
      <OptionDropdown
        options={options}
        onOptionHover={onOptionHover}
        onChange={onOptionClick}
        contentClassName={contentClassName}
        className={dropdownClassName}
        direction={direction}
        renderToggler={this.renderToggler}
        currentSelectedOptionId={currentSelectedOptionId}
      />
    )
  }
}

interface DropdownButtonProps {
  label: string
  leftIcon?: IconProp
  rightIcon?: IconProp
  options: DropdownOption[]
  variant?: ButtonVariants
  color?: ButtonColors
  togglerClassName?: string
  direction?: DropdownDirections
  dropdownClassName?: string
  contentClassName?: string
  centerText?: boolean
  disabled?: boolean
  onOptionClick: (option: DropdownOption) => void
  onOptionHover?: (id?: string) => void
  sizeValue?: any
  handleButtonClick?: () => void
  currentSelectedOptionId?: string
  isDiv?: boolean
  onBlur?: () => void
}

export interface DropdownOption {
  value: any
  label: any
}

export default DropdownButton
