import React from 'react';
import * as styles from './styles.scss';
import { useQuery } from 'react-apollo-hooks';
import GET_NET_NOTICE from 'graphql/queries/GetNetNotice';
import { GetNetNotice, GetNetNoticeVariables } from '../../../../../schema';
import cx from 'classnames'
import Spinner from 'components/Spinner';
import Button from 'components/Button';
import ErrorMessage from 'components/ErrorMessage';
import Modal from 'components/Modal';
import { useTranslation } from "react-i18next"


const NetNoticePreview: React.FC<{
    isNetNoticeVisible: boolean
    orderId: string
    updateButtonText: string
    isFEUser: boolean
    handleNetNoticeClose: () => void
}> = (props) => {
    const iframeRef = React.useRef<HTMLIFrameElement>(null)
    const [isNoticeFullWidth, setNoticeFullWidth] = React.useState<boolean>(false)
    let htmlDoc = ''

    const { data, loading, error, refetch } = useQuery<GetNetNotice, GetNetNoticeVariables>(GET_NET_NOTICE, {
        variables: {
            id: props.orderId!,
            isNetNoticeVisible: props.isNetNoticeVisible
        }
    })
    const { t } = useTranslation("editOrder")

    React.useEffect(() => {
        refetch()
    },[props.isNetNoticeVisible])


    if (!props.isNetNoticeVisible) {
        return null
    }

    if (error) {
        return <ErrorMessage message={error.message} />
    }

    if (data?.orders &&
        data.orders[0].mediaOrder &&
        data.orders[0].mediaOrder.preview.material &&
        data.orders[0].mediaOrder.preview.material.basePage &&
        data.orders[0].mediaOrder.preview.material.basePage.netnotice) {
        htmlDoc = `${data.orders[0].mediaOrder.preview.material.basePage.netnotice.html}`
    }

    const toggleNetNoticeWidth = () => {
        setNoticeFullWidth(!isNoticeFullWidth)
    }

    return (
        <Modal
            isOpen={props.isNetNoticeVisible}
            onRequestClose={props.handleNetNoticeClose}
            large={true}
            isLoading={loading}
        >
            <div className={cx(styles.container)}>
                {data && (
                    <React.Fragment>
                       <Button variant={props.isFEUser! ? "feView" : "secondary"} onClick={toggleNetNoticeWidth} isWorking={loading}>{t("netNoticeWidthToggleButton")}</Button> 
                        <div className={styles.iframeContainer} >
                            <iframe
                                ref={iframeRef}
                                className={styles.iframe}
                                srcDoc={htmlDoc}
                                frameBorder="0"
                                style={{width: isNoticeFullWidth ? "600px" : "300px"}}
                            />
                        </div>
                    </React.Fragment>
                )}

            </div>
        </Modal>

    )
};

export default NetNoticePreview;