import * as React from "react"
import * as styles from "./styles.scss"
import { Form } from "react-final-form"
import FieldWrapper, { FieldLabel } from "components/FieldWrapper"
import TextInput from "components/TextInput"
import DateField from "components/DateField"
import DropdownInput from "components/DropdownInput"
import { useTranslation } from "react-i18next"
import { MutationFn } from "react-apollo"
import { omitTypename, omitProps } from "utils"
import * as cx from "classnames"
import { GetCaseFolders_findCaseFoldersByCurrentCustomer_caseFolders } from "schema"
import { DateTime } from "luxon"
import { AppContext } from "App"
import { ErrorText } from "components/ErrorMessage"
import SubmitButton from "pages/AgencySettings/SubmitButton"
import { useParams, useHistory } from "react-router-dom"
// import { useApolloClient, useMutation } from "react-apollo-hooks"
// import CREATE_CASE_FOLDER from "graphql/mutations/createCaseFolder"
// import GET_DASHBOARD_CASE_FOLDERS from "graphql/queries/getDashboardCaseFolders"
// import { DataProxy } from "apollo-cache"
// import CaseFolderHeaderRow from "pages/NewCaseFolderPage/CaseFolderHeaderRow"
// import DateAndTimeField from "components/DateAndTimeField"
import TextArea from "components/TextArea"
import { RcHourField } from "components/TimeField"
// import * as dayjs from "dayjs"
import moment from "moment"
// import { unusedFragMessage } from "graphql/validation/rules/NoUnusedFragments"
// import { fromPairs } from "lodash"
// import CaseFolderHeaderRow from "./../CaseFolderHeaderRow"
import Button from "components/Button"

const validate = (values: any) => {
  let errors: any = {}
  if (!values.personFirstname) errors.personFirstname = "firstNameMissing"
  if (!values.personLastname) errors.personLastname = "lastNameMissing"
  if (!values.personBorn) errors.personBorn = "personBornMissing"
  if (!values.personDied) errors.personDied = "personDiedMissing"
  // if(values.personFuneral > new Date()) errors.personFuneral ="futureDateError"
  if (values.personBorn > new Date()) errors.personBorn = "futureDateError"
  if (values.personDied > new Date()) errors.personDied = "futureDateError"
  if (moment.utc(values.personBorn)  > moment.utc(values.personDied))
    errors.personDied = "personDiedLaterThanBornError"
  return errors
}

const CaseFolderForm: React.FC<CaseFolderFormProps> = (props) => {
  const columnCount = props.columnCount ?? props.columnCount
  // const isCreateCase = props.queryValue?.action != "new_case_folder"
  const { t } = useTranslation(["dashboard", "dateFormats"])
  const [error, setError] = React.useState<string | undefined>()
  const [loading, setLoading] = React.useState<boolean>(false)
  const params: any = useParams()
  const history = useHistory()

  const formatDate = (date: Date | string | null) => {
    if (!date) return

    const format = t("dateFormats:date")

    if (typeof date === "string")
      // return DateTime.fromString(date, format).toFormat(format)
      return DateTime.fromJSDate(new Date(date)).toFormat(format)

    return DateTime.fromJSDate(date).toFormat(format)
  }

  // const checkMemoryRoom = (): boolean | any => {
  //   //if params object is empty we are creating new casefolder
  //   //otherwise we are in casefolder at memoryRoom
  //   if (Object.keys(params).length === 0) return false
  //   return true
  // }



  const onSubmitForm = (values: any) => {
    // const { onToggleLoadingOn, onToggleLoadingOff } = props
    const errors = validate(values)

    if (Object.keys(errors).length) {
      // if (onToggleLoadingOff) onToggleLoadingOff()
      return errors
    }

    setLoading(false)
    setError("")

    const d1 = moment(values.personFuneral)
    let _personFuneral: undefined | string
    const time = moment(values.personFuneralTime)
    
    if (d1.isValid() === true) {
      if (time.isValid() === true) {
        d1.set("hour", time.get("hour"))
        d1.set("minute", time.get("minute"))
        d1.set("second", 0)
      }

      _personFuneral = d1.format("YYYY-MM-DDTHH:mm:ssZ")
    } else {
      _personFuneral = undefined
    }

    let caseFolder: any = {
      propertiesFuneralStandard: {
        ...omitProps(values, ["caseWorker", "personFuneralTime"]),
        personBorn: formatDate(values.personBorn),
        personDied: formatDate(values.personDied),
        personFuneral: _personFuneral,
        memoryCeremonyLastBookingDay: formatDate(
          values.memoryCeremonyLastBookingDay
        ),
      },
      customerId: values.caseWorker.value,
    }

    if (props.selectedCaseFolder) {
      caseFolder = {
        ...caseFolder,
        id: props.selectedCaseFolder.id,
      }
    }

    // if (onToggleLoadingOn){
    //   onToggleLoadingOn()
    // }
    setLoading(true)
    setError("")

    return props
      .onSubmit({
        variables: {
          input: {
            caseFolder: omitTypename(caseFolder),
          },
        },
      })
      .then((result) => {
        if (result) {
          if (
            result.data &&
            result.data.createCaseFolder &&
            result.data.createCaseFolder.isError !== undefined &&
            result.data.createCaseFolder.isError !== null &&
            result.data.createCaseFolder.isError === false
          ) {
            setLoading(false)
            if (props.onComplete) {
              props.onComplete(result)
              if(props.isMediaUser)  props.openProductsModal!()
             
            }
          } else if (
            result.data &&
            result.data.updateCaseFolder &&
            result.data.updateCaseFolder.isError !== undefined &&
            result.data.updateCaseFolder.isError !== null &&
            result.data.updateCaseFolder.isError === false
          ) {
            setLoading(false)
            if (props.onComplete) {
              props.onComplete(result)
            }
          } else {
            setLoading(false)
            setError(result.data?.createCaseFolder?.errorReason ?? "error")
          }
        }
      })
      .catch((err) => {
        setLoading(false)
        setError(err.toString())
      })
  }

  const personFuneral =
    props?.selectedCaseFolder?.propertiesFuneralStandard?.personFuneral ??
    undefined

  let personFuneralTime: any

  if (personFuneral) {
    personFuneralTime = moment(personFuneral)
      // .set("minute", 0)
      .set("second", 0)
      .format("YYYY-MM-DD HH:mm:ss")
  }


  return (
    <div
      className={cx(styles.mainWrapper, {
        [styles.inactive]: props.isActive === false,
      })}
      id={"casefolderForm"}
    >
      <AppContext.Consumer>
        {({ currentUserSession }) => (
          <Form
            onSubmit={onSubmitForm}
            initialValues={
              props.selectedCaseFolder
                ? {
                    ...props.selectedCaseFolder.propertiesFuneralStandard,
                    caseWorker: {
                      value: props.selectedCaseFolder.customer.id,
                      label: props.selectedCaseFolder.customer.name,
                    },
                    personFuneralTime,
                  }
                : {
                    caseWorker: {
                      value: currentUserSession.customer!.id,
                      label: currentUserSession.customer!.name,
                    },
                  }
            }
            render={({ handleSubmit }) => (
              <form className={styles.container} onSubmit={handleSubmit}>
                {(props.isCreateMode ||
                  history.location.pathname === "/newCaseFolder") && (
                  <div className={styles.headerContainer}>
                    <div className={styles.title}>
                      <h3>{t("newCaseFolder:newCase")}</h3>
                    </div>
                  </div>
                )}

                {props.renderHeaderRow ? (
                  <div>{props.renderHeaderRow()}</div>
                ) : null}
                <div
                  className={cx(styles.inputs, {
                    [styles.isEditCaseFolder]: columnCount === 2,
                    [styles.treeColumns]: columnCount === 3,
                  })}
                >
                  <FieldWrapper
                    component={TextInput}
                    label={`${t("firstName")} *`}
                    name="personFirstname"
                    isAutoComplete={false}
                  />
                  <FieldWrapper
                    component={TextInput}
                    label={`${t("lastName")} *`}
                    name="personLastname"
                    isAutoComplete={false}
                  />
                  <FieldWrapper
                    component={TextInput}
                    label={t("birthName")}
                    name="personBornLastname"
                    isAutoComplete={false}
                  />
                  <FieldWrapper
                    component={DateField}
                    label={`${t("birthDay")} *`}
                    name="personBorn"
                    className="personBorn"
                    id={"birth-date"}
                  />
                  <FieldWrapper
                    component={DateField}
                    label={`${t("dayOfDeath")} *`}
                    name="personDied"
                    className="personDied"
                    id={"death-date"}
                  />
                  <FieldWrapper
                    component={TextInput}
                    label={t("hometown")}
                    name="personCityLive"
                    isAutoComplete={false}
                  />

                  <div className={styles.twoColumnsWrapper}>
                    <FieldWrapper
                      component={DateField}
                      label={t("funeralDate")}
                      name="personFuneral"
                    />
                    <FieldWrapper
                      component={RcHourField}
                      className={styles.rcTime}
                      renderLabel={() => (
                        <FieldLabel
                          text={t("personFuneralTime")}
                          className={styles.labelOneLine}
                        />
                      )}
                      name="personFuneralTime"
                    />
                  </div>

                  <FieldWrapper
                    component={DropdownInput}
                    dropdownOptions={currentUserSession.customer!.colleagues.map(
                      (colleague) => { 
                      return{
                        value: colleague!.id!,
                        label: `${colleague?.office.name}/ ${colleague!.firstName} ${colleague!.lastName}`,
                      }}
                      
                    )}
                    label={t("caseWorker")}
                    name="caseWorker"
                  />
                  {params?.inEditMode && (
                    <FieldWrapper
                      component={DateField}
                      label={t("memoryCeremonyLastBookingDay")}
                      name="memoryCeremonyLastBookingDay"
                    />
                  )}

                  <FieldWrapper
                    component={TextInput}
                    label={t("caseNumber")}
                    name="externalId"
                    className={styles.caseNumber}
                    isAutoComplete={false}
                  />

                  {/* {params?.inEditMode && (
                    <FieldWrapper
                      component={TextArea}
                      label={t("dashboard:personFuneralInfoText")}
                      name="personFuneralInfoText"
                    />
                  )} */}

                  <div
                    className={cx(styles.buttonContainer, {
                      [styles.inEditMode]: params?.inEditMode,
                    })}
                  >
                    <Button
                      onClick={props.exitCreateCaseFolder}
                      color="secondary"
                    >
                      {t("dashboard:cancel")}
                    </Button>
                    <SubmitButton
                      isWorking={loading}
                      id={"CaseFormFolderSubmit"}
                    />
                  </div>

                  {error !== undefined && error.length > 0 ? (
                    <ErrorText>{error}</ErrorText>
                  ) : null}
                </div>
              </form>
            )}
          />
        )}
      </AppContext.Consumer>
    </div>
  )
}

interface CaseFolderFormProps {
  queryValue?: { action: string }
  isCreateMode?: boolean
  exitCreateCaseFolder?: () => void
  isActive: boolean
  onComplete?: (args?: any) => void
  onSubmit: MutationFn
  afterSubmit?: (args?: any) => void
  columnCount?: 2 | 3 | 4
  renderHeaderRow?: any
  selectedCaseFolder?: GetCaseFolders_findCaseFoldersByCurrentCustomer_caseFolders
  isMediaUser?: boolean
  openProductsModal?: () => void
  renderBottomRightSubmitButton?:
    | React.ComponentClass
    | React.StatelessComponent
  // onToggleLoadingOff?: () => void
  // onToggleLoadingOn?: () => void
}

export default CaseFolderForm
// export default withTranslation(["dashboard", "dateFormats"])(CaseFolderForm)

// class CaseFolderForm extends React.PureComponent<
//   CaseFolderFormProps & WithTranslation
// > {
//   static defaultProps: any = { columnCount: 2 }

//   formatDate = (date: Date | string | null) => {
//     if (!date) return

//     const format = this.props.t("dateFormats:date")

//     if (typeof date === "string")
//       // return DateTime.fromString(date, format).toFormat(format)
//       return DateTime.fromJSDate(new Date(date)).toFormat(format)

//     return DateTime.fromJSDate(date).toFormat(format)
//   }

//   handleSubmit = (values: any) => {
//     const { onToggleLoadingOn, onToggleLoadingOff } = this.props

//     const errors = validate(values)

//     if (Object.keys(errors).length) {
//       if (onToggleLoadingOff) onToggleLoadingOff()
//       return errors
//     }

//     let caseFolder: any = {
//       propertiesFuneralStandard: {
//         ...omitProps(values, ["caseWorker"]),
//         personBorn: this.formatDate(values.personBorn),
//         personDied: this.formatDate(values.personDied),
//         personFuneral: this.formatDate(values.personFuneral),
//         memoryCeremonyLastBookingDay: this.formatDate(
//           values.memoryCeremonyLastBookingDay
//         ),
//       },
//       customerId: values.caseWorker.value,
//     }

//     if (this.props.selectedCaseFolder) {
//       caseFolder = {
//         ...caseFolder,
//         id: this.props.selectedCaseFolder.id,
//       }
//     }

//     if (onToggleLoadingOn) onToggleLoadingOn()

//     return this.props
//       .onSubmit({
//         variables: {
//           input: {
//             caseFolder: omitTypename(caseFolder),
//           },
//         },
//       })
//       .then((result) => {
//         if(result){
//           result.data?.createCaseFolder.isError
//         }
//         if (this.props.afterSubmit) {
//           this.props.afterSubmit(result)
//         }
//       })
//       .catch((err) => {})
//   }

//   render() {
//     const {
//       selectedCaseFolder,
//       renderHeaderRow: HeaderRow,
//       renderBottomRightSubmitButton: BottomRightSubmitButton,
//       columnCount,
//       t,
//     } = this.props

//     return (
//       <AppContext.Consumer>
//         {({ currentUserSession }) => (
//           <Form
//             onSubmit={this.handleSubmit}
//             initialValues={
//               selectedCaseFolder
//                 ? {
//                     ...selectedCaseFolder!.propertiesFuneralStandard,
//                     caseWorker: {
//                       value: selectedCaseFolder.customer.id,
//                       label: selectedCaseFolder.customer.name,
//                     },
//                   }
//                 : {
//                     caseWorker: {
//                       value: currentUserSession.customer!.id,
//                       label: currentUserSession.customer!.name,
//                     },
//                   }
//             }
//             render={({ handleSubmit }) => (
//               <form className={styles.container} onSubmit={handleSubmit}>
//                 <HeaderRow />
//                 <div
//                   className={cx(styles.inputs, {
//                     [styles.isEditCaseFolder]: columnCount === 2,
//                   })}
//                 >
//                   <FieldWrapper
//                     component={TextInput}
//                     label={t("firstName")}
//                     name="personFirstname"
//                   />
//                   <FieldWrapper
//                     component={TextInput}
//                     label={t("lastName")}
//                     name="personLastname"
//                   />
//                   <FieldWrapper
//                     component={DateField}
//                     label={t("birthDay")}
//                     name="personBorn"
//                     className="personBorn"
//                   />
//                   <FieldWrapper
//                     component={DateField}
//                     label={t("dayOfDeath")}
//                     name="personDied"
//                     className="personDied"
//                   />
//                   <FieldWrapper
//                     component={DateField}
//                     label={t("funeralDate")}
//                     name="personFuneral"
//                   />
//                   <FieldWrapper
//                     component={TextInput}
//                     label={t("hometown")}
//                     name="personCityLive"
//                   />
//                   <FieldWrapper
//                     component={TextInput}
//                     label={t("birthName")}
//                     name="personBornLastname"
//                   />
//                   <FieldWrapper
//                     component={DropdownInput}
//                     dropdownOptions={currentUserSession.customer!.colleagues.map(
//                       (colleague) => ({
//                         value: colleague!.id!,
//                         label: `${colleague!.firstName} ${colleague!.lastName}`,
//                       })
//                     )}
//                     label={t("caseWorker")}
//                     name="caseWorker"
//                   />
//                   <FieldWrapper
//                     component={DateField}
//                     label={t("memoryCeremonyLastBookingDay")}
//                     name="memoryCeremonyLastBookingDay"
//                   />
//                   <FieldWrapper
//                     component={TextInput}
//                     label={t("caseNumber")}
//                     name="externalId"
//                   />
//                 </div>
//                 {BottomRightSubmitButton && (
//                   <div className={styles.buttonContainer}>
//                     <BottomRightSubmitButton />
//                   </div>
//                 )}
//               </form>
//             )}
//           />
//         )}
//       </AppContext.Consumer>
//     )
//   }
// }
