import * as React from "react"
import "react-datepicker/dist/react-datepicker-cssmodules.css"
import * as styles from "./styles.scss"
import DatePicker, { ReactDatePickerProps, registerLocale } from "react-datepicker"
import { withTranslation, WithTranslation } from "react-i18next"
import { FieldRenderProps } from "react-final-form"
import * as cx from "classnames"
import CalendarIcon from "./CalendarIcon"
import styled from "styled-components"
import nb from "date-fns/locale/nb";


class Calendar extends React.PureComponent<
  CalendarProps & WithTranslation & ReactDatePickerProps
> {
  datePicker: any = {}
  state = {

  }

  componentDidUpdate() {
    if (
      this.props.fieldRenderProps &&
      this.props.fieldRenderProps.input.value === "" &&
      this.datePicker.onClearClick
    ) {
      this.datePicker.onClearClick("")
    }

  }

  

  render() {
    const {
      showTimeSelect,
      inline,
      selectedDate,
      highlightDates,
      includeDates,
      onChange,
      disabled,
      className,
      t,
      ...rest
    } = this.props

    if(t("common:locale") === "nb") {
      registerLocale(t("common:locale"), nb);
    }

 
    return (
      <DivContainer>
        <div
          className={cx(styles.container, className, {
            [styles.inline]: inline,
            "feView": this.props.isFEUser
          })}
          data-testid={"calender-input"}
        >
          {React.createElement(DatePicker, {
            ref: (datePicker: any) => (this.datePicker = datePicker),
            disabledKeyboardNavigation: true,
            inline,
            // dateFormat: t("date"),
            locale: t("common:locale"),
            selected: selectedDate,
            placeholderText: t("datePlaceholder"),
            calendarClassName: cx(styles.dateField, {
              [styles.inline]: inline,
            }),
            className: cx(styles.dateFieldInput, {
              [styles.disabled]: disabled,
              withTime: showTimeSelect === true,
            }),
            highlightDates,
            includeDates,
            onChange,
            disabled,
            timeFormat:
              showTimeSelect && showTimeSelect === true ? "HH:mm" : undefined,
            timeIntervals: 15,
            showTimeSelect,
            // showTimeSelect && showTimeInput === true ? showTimeInput : undefined,
            timeInputLabel: "Time:",
            dateFormat: showTimeSelect ? t("dateAndTime") : t("date"),
            autoComplete: "off",
            ...rest,
          })}
          {!inline && <CalendarIcon disabled={disabled} />}
        </div>
      </DivContainer>
    )
  }
}

interface CalendarProps {
  includeDates?: Date[]
  inline?: boolean
  fieldRenderProps?: FieldRenderProps<any, any>
  selectedDate?: Date | null
  highlight?: Date[] | any
  disabled?: boolean
  className?: string
  showTimeSelect?: boolean
  onChange: (date: Date) => void
  isWeekSelected?: boolean
  selectsRange?: boolean
  isFEUser?: boolean
}

export default withTranslation(["dateFormats", "common"])(Calendar)

const DivContainer = styled.div`
  .react-datepicker__day--highlighted-BLUE {
    background-color: blue !important;
    color: blue !important;
  }
`
