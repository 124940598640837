/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: AddOrRemoveTagsFromSymbols
// ====================================================

export interface AddOrRemoveTagsFromSymbols_selectGalleryImageTagOnImageItems_images_tagGroups {
  tags: string[];
}

export interface AddOrRemoveTagsFromSymbols_selectGalleryImageTagOnImageItems_images {
  /**
   * Id of the galleryimage
   */
  id: string;
  /**
   * Tag groups
   */
  tagGroups: AddOrRemoveTagsFromSymbols_selectGalleryImageTagOnImageItems_images_tagGroups[];
}

export interface AddOrRemoveTagsFromSymbols_selectGalleryImageTagOnImageItems {
  /**
   * Reasons
   */
  errorReason: string | null;
  images: AddOrRemoveTagsFromSymbols_selectGalleryImageTagOnImageItems_images[];
}

export interface AddOrRemoveTagsFromSymbols {
  /**
   * Set/Remove gallery image tags on an image
   */
  selectGalleryImageTagOnImageItems: AddOrRemoveTagsFromSymbols_selectGalleryImageTagOnImageItems;
}

export interface AddOrRemoveTagsFromSymbolsVariables {
  input: MutationSelectGalleryImageTagOnImageItemsInput;
  filterTagGroupId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CopyOrderFuneralInfoToMemoryRoom
// ====================================================

export interface CopyOrderFuneralInfoToMemoryRoom {
  /**
   * Extract the funeral info text and pass it to memoryroom
   */
  copyOrderFuneralInfoToMemoryRoom: string;
}

export interface CopyOrderFuneralInfoToMemoryRoomVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateCaseFolder
// ====================================================

export interface CreateCaseFolder_createCaseFolder_caseFolder_customer {
  /**
   * The id of customer
   */
  id: string;
  /**
   * The name of customer
   */
  name: string;
}

export interface CreateCaseFolder_createCaseFolder_caseFolder_propertiesFuneralStandard {
  /**
   * Id from a another businesssystem (option)
   */
  externalId: string;
  /**
   * Born date for the person in the case
   */
  personBorn: any | null;
  /**
   * Died date for the person in the case
   */
  personDied: any | null;
  /**
   * Funeral date for the person in the case
   */
  personFuneral: any | null;
  /**
   * Lastname when the person in the case was born
   */
  personBornLastname: string | null;
  /**
   * First name of the person in the case
   */
  personFirstname: string;
  /**
   * Last name of the person in the case
   */
  personLastname: string;
  /**
   * Where did the person live
   */
  personCityLive: string;
  /**
   * Informationtext about the funeral
   */
  personFuneralInfoText: string;
  /**
   * Last day for booking the cermony for memory
   */
  memoryCeremonyLastBookingDay: any | null;
}

export interface CreateCaseFolder_createCaseFolder_caseFolder_summary {
  /**
   * Count number of orders)
   */
  countOrders: number;
  /**
   * Most critical Status color in the orders
   */
  mostCriticalColor: StatusColors;
  /**
   * Most critical Status in the orders
   */
  mostCriticalStatus: OrderStatus;
}

export interface CreateCaseFolder_createCaseFolder_caseFolder_productPackages_productPackage {
  /**
   * Id of the ProductPackage
   */
  id: string;
  /**
   * Name of the ProductPackage
   */
  name: string;
}

export interface CreateCaseFolder_createCaseFolder_caseFolder_productPackages_summary {
  /**
   * Most critical Status color in the orders
   */
  mostCriticalColor: StatusColors;
  /**
   * Most critical Status in the orders
   */
  mostCriticalStatus: OrderStatus;
  /**
   * Flag when shows warning about the bookingstoptime
   */
  isMostCriticalTimeBookingWarning: boolean;
}

export interface CreateCaseFolder_createCaseFolder_caseFolder_productPackages_documents_documentFormat_documentType {
  friendlyName: string;
  /**
   * Id of the documenttype
   */
  id: string;
  /**
   * The name of the documentType
   */
  name: string;
}

export interface CreateCaseFolder_createCaseFolder_caseFolder_productPackages_documents_documentFormat {
  /**
   * Id of the documentformat
   */
  id: string;
  documentType: CreateCaseFolder_createCaseFolder_caseFolder_productPackages_documents_documentFormat_documentType;
}

export interface CreateCaseFolder_createCaseFolder_caseFolder_productPackages_documents_orders_document {
  /**
   * Id of the document
   */
  id: string | null;
}

export interface CreateCaseFolder_createCaseFolder_caseFolder_productPackages_documents_orders_alerting {
  text: string | null;
  smallText: string | null;
}

export interface CreateCaseFolder_createCaseFolder_caseFolder_productPackages_documents_orders_mediaOrder_media {
  /**
   * The id of the media
   */
  id: string | null;
  /**
   * The friendlyName of the media
   */
  friendlyName: string;
}

export interface CreateCaseFolder_createCaseFolder_caseFolder_productPackages_documents_orders_mediaOrder_documentFormat {
  /**
   * Id of the documentformat
   */
  id: string;
  mediaAdaptedName: string;
}

export interface CreateCaseFolder_createCaseFolder_caseFolder_productPackages_documents_orders_mediaOrder_publishTimes {
  /**
   * Date when the order will be published
   */
  time: any;
}

export interface CreateCaseFolder_createCaseFolder_caseFolder_productPackages_documents_orders_mediaOrder_status {
  /**
   * ProductStatus enum for the status
   */
  status: OrderStatus;
  /**
   * Color for vieweing the customer
   */
  color: StatusColors;
}

export interface CreateCaseFolder_createCaseFolder_caseFolder_productPackages_documents_orders_mediaOrder {
  /**
   * Flag when shows warning about the bookingstoptime
   */
  isCriticalTimeBookingWarning: boolean;
  /**
   * Locked
   */
  isLocked: boolean;
  /**
   * media of the order
   */
  media: CreateCaseFolder_createCaseFolder_caseFolder_productPackages_documents_orders_mediaOrder_media;
  /**
   * documentformat of the order
   */
  documentFormat: CreateCaseFolder_createCaseFolder_caseFolder_productPackages_documents_orders_mediaOrder_documentFormat;
  /**
   * times when the order will be published
   */
  publishTimes: (CreateCaseFolder_createCaseFolder_caseFolder_productPackages_documents_orders_mediaOrder_publishTimes | null)[];
  /**
   * Status of the order
   */
  status: CreateCaseFolder_createCaseFolder_caseFolder_productPackages_documents_orders_mediaOrder_status;
}

export interface CreateCaseFolder_createCaseFolder_caseFolder_productPackages_documents_orders {
  /**
   * The id of the mediaorder
   */
  id: string | null;
  /**
   * The Id shown for customer
   */
  customerFriendlyId: string;
  /**
   * document of the order
   */
  document: CreateCaseFolder_createCaseFolder_caseFolder_productPackages_documents_orders_document;
  alerting: CreateCaseFolder_createCaseFolder_caseFolder_productPackages_documents_orders_alerting;
  /**
   * mediaOrder properties if that kind of order
   */
  mediaOrder: CreateCaseFolder_createCaseFolder_caseFolder_productPackages_documents_orders_mediaOrder | null;
}

export interface CreateCaseFolder_createCaseFolder_caseFolder_productPackages_documents {
  /**
   * Id of the document
   */
  id: string | null;
  /**
   * InternalId of the document
   */
  internalId: number;
  /**
   * format of the document
   */
  documentFormat: CreateCaseFolder_createCaseFolder_caseFolder_productPackages_documents_documentFormat;
  orders: CreateCaseFolder_createCaseFolder_caseFolder_productPackages_documents_orders[];
}

export interface CreateCaseFolder_createCaseFolder_caseFolder_productPackages {
  /**
   * Product Package
   */
  productPackage: CreateCaseFolder_createCaseFolder_caseFolder_productPackages_productPackage;
  /**
   * ordersummary of product package
   */
  summary: CreateCaseFolder_createCaseFolder_caseFolder_productPackages_summary;
  /**
   * orders of the product package
   */
  documents: (CreateCaseFolder_createCaseFolder_caseFolder_productPackages_documents | null)[];
}

export interface CreateCaseFolder_createCaseFolder_caseFolder {
  /**
   * The id of the caseFolder
   */
  id: string | null;
  /**
   * The internal id of the caseFolder
   */
  internalId: number;
  /**
   * The name of the caseFolder
   */
  name: string;
  customer: CreateCaseFolder_createCaseFolder_caseFolder_customer;
  propertiesFuneralStandard: CreateCaseFolder_createCaseFolder_caseFolder_propertiesFuneralStandard;
  summary: CreateCaseFolder_createCaseFolder_caseFolder_summary;
  /**
   * ProductPackages of the caseFolder
   */
  productPackages: (CreateCaseFolder_createCaseFolder_caseFolder_productPackages | null)[];
}

export interface CreateCaseFolder_createCaseFolder {
  /**
   * Mutation is not successful
   */
  isError: boolean;
  /**
   * Reasons
   */
  errorReason: string | null;
  caseFolder: CreateCaseFolder_createCaseFolder_caseFolder;
}

export interface CreateCaseFolder {
  /**
   * Create caseFolder
   */
  createCaseFolder: CreateCaseFolder_createCaseFolder;
}

export interface CreateCaseFolderVariables {
  input: MutationCreateCaseFolderInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateCustomerUser
// ====================================================

export interface CreateCustomerUser_createCustomerUser_customer_inheritSettings {
  /**
   * The emailaddress used when sending info to customer
   */
  contactPhone: string | null;
}

export interface CreateCustomerUser_createCustomerUser_customer_office {
  /**
   * The id of office
   */
  id: string;
  /**
   * The name of office
   */
  name: string;
}

export interface CreateCustomerUser_createCustomerUser_customer_primaryUser {
  /**
   * The id of user
   */
  id: string | null;
  /**
   * The username of user
   */
  username: string;
  /**
   * The account is enabled or not
   */
  enabled: boolean;
}

export interface CreateCustomerUser_createCustomerUser_customer {
  /**
   * The id of customer
   */
  id: string;
  /**
   * The name of customer
   */
  name: string;
  /**
   * The firstname of customer
   */
  firstName: string;
  /**
   * The lastname of customer
   */
  lastName: string;
  inheritSettings: CreateCustomerUser_createCustomerUser_customer_inheritSettings;
  office: CreateCustomerUser_createCustomerUser_customer_office;
  /**
   * The primary user for the customer
   */
  primaryUser: CreateCustomerUser_createCustomerUser_customer_primaryUser | null;
}

export interface CreateCustomerUser_createCustomerUser {
  customer: CreateCustomerUser_createCustomerUser_customer | null;
  errorCode: CustomerInputErrors | null;
  /**
   * Reasons
   */
  errorReason: string | null;
  /**
   * Stack
   */
  errorStack: string | null;
  /**
   * Mutation is not successful
   */
  isError: boolean;
}

export interface CreateCustomerUser {
  /**
   * Create a user and customer in the system
   */
  createCustomerUser: CreateCustomerUser_createCustomerUser;
}

export interface CreateCustomerUserVariables {
  input: MutationCRUDCustomerUserInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateImageTag
// ====================================================

export interface CreateImageTag_createGalleryImageTags {
  /**
   * Reasons
   */
  errorReason: string | null;
  /**
   * tags
   */
  tags: string[];
}

export interface CreateImageTag {
  /**
   * Create gallery image tags
   */
  createGalleryImageTags: CreateImageTag_createGalleryImageTags;
}

export interface CreateImageTagVariables {
  input: MutationCreateGalleryImageTagsInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateOrder
// ====================================================

export interface CreateOrder_initiateCaseFolderOrders_caseFolder_editTabs_orders_client_route_editPageRoute {
  clientApp: ApplicationClientApps;
  absUrl: string;
  relUrl: string;
  taps5IframeAbsUrl: string | null;
  taps5RelUrl: string;
}

export interface CreateOrder_initiateCaseFolderOrders_caseFolder_editTabs_orders_client_route {
  editPageRoute: CreateOrder_initiateCaseFolderOrders_caseFolder_editTabs_orders_client_route_editPageRoute;
}

export interface CreateOrder_initiateCaseFolderOrders_caseFolder_editTabs_orders_client {
  route: CreateOrder_initiateCaseFolderOrders_caseFolder_editTabs_orders_client_route;
}

export interface CreateOrder_initiateCaseFolderOrders_caseFolder_editTabs_orders_alerting {
  smallText: string | null;
  text: string | null;
  type: OrderAlertingType;
}

export interface CreateOrder_initiateCaseFolderOrders_caseFolder_editTabs_orders_mediaOrder_edit {
  /**
   * Kind of editor using for the order
   */
  editModelType: EditModelTypes;
}

export interface CreateOrder_initiateCaseFolderOrders_caseFolder_editTabs_orders_mediaOrder_documentFormat_documentType {
  /**
   * Id of the documenttype
   */
  id: string;
  /**
   * The name of the documentType
   */
  name: string;
}

export interface CreateOrder_initiateCaseFolderOrders_caseFolder_editTabs_orders_mediaOrder_documentFormat {
  /**
   * Id of the documentformat
   */
  id: string;
  mediaAdaptedName: string;
  documentType: CreateOrder_initiateCaseFolderOrders_caseFolder_editTabs_orders_mediaOrder_documentFormat_documentType;
}

export interface CreateOrder_initiateCaseFolderOrders_caseFolder_editTabs_orders_mediaOrder_media {
  /**
   * The id of the media
   */
  id: string | null;
  /**
   * The friendlyName of the media
   */
  friendlyName: string;
}

export interface CreateOrder_initiateCaseFolderOrders_caseFolder_editTabs_orders_mediaOrder_status {
  /**
   * Color for vieweing the customer
   */
  color: StatusColors;
  /**
   * ProductStatus enum for the status
   */
  status: OrderStatus;
}

export interface CreateOrder_initiateCaseFolderOrders_caseFolder_editTabs_orders_mediaOrder {
  /**
   * Locked
   */
  isLocked: boolean;
  /**
   * information for the editors
   */
  edit: CreateOrder_initiateCaseFolderOrders_caseFolder_editTabs_orders_mediaOrder_edit;
  /**
   * documentformat of the order
   */
  documentFormat: CreateOrder_initiateCaseFolderOrders_caseFolder_editTabs_orders_mediaOrder_documentFormat;
  /**
   * media of the order
   */
  media: CreateOrder_initiateCaseFolderOrders_caseFolder_editTabs_orders_mediaOrder_media;
  /**
   * Status of the order
   */
  status: CreateOrder_initiateCaseFolderOrders_caseFolder_editTabs_orders_mediaOrder_status;
}

export interface CreateOrder_initiateCaseFolderOrders_caseFolder_editTabs_orders_document {
  /**
   * Id of the document
   */
  id: string | null;
}

export interface CreateOrder_initiateCaseFolderOrders_caseFolder_editTabs_orders {
  /**
   * The id of the mediaorder
   */
  id: string | null;
  /**
   * Order is deleted
   */
  isDeleted: boolean;
  /**
   * The Id shown for customer
   */
  customerFriendlyId: string;
  client: CreateOrder_initiateCaseFolderOrders_caseFolder_editTabs_orders_client | null;
  alerting: CreateOrder_initiateCaseFolderOrders_caseFolder_editTabs_orders_alerting;
  /**
   * mediaOrder properties if that kind of order
   */
  mediaOrder: CreateOrder_initiateCaseFolderOrders_caseFolder_editTabs_orders_mediaOrder | null;
  /**
   * document of the order
   */
  document: CreateOrder_initiateCaseFolderOrders_caseFolder_editTabs_orders_document;
}

export interface CreateOrder_initiateCaseFolderOrders_caseFolder_editTabs {
  id: string;
  name: string;
  isSubTabs: boolean;
  orders: CreateOrder_initiateCaseFolderOrders_caseFolder_editTabs_orders[];
}

export interface CreateOrder_initiateCaseFolderOrders_caseFolder_customer {
  /**
   * The id of customer
   */
  id: string;
  /**
   * The name of customer
   */
  name: string;
}

export interface CreateOrder_initiateCaseFolderOrders_caseFolder_propertiesFuneralStandard {
  /**
   * Id from a another businesssystem (option)
   */
  externalId: string;
  /**
   * Born date for the person in the case
   */
  personBorn: any | null;
  /**
   * Died date for the person in the case
   */
  personDied: any | null;
  /**
   * Funeral date for the person in the case
   */
  personFuneral: any | null;
  /**
   * Lastname when the person in the case was born
   */
  personBornLastname: string | null;
  /**
   * First name of the person in the case
   */
  personFirstname: string;
  /**
   * Last name of the person in the case
   */
  personLastname: string;
  /**
   * Where did the person live
   */
  personCityLive: string;
  /**
   * Informationtext about the funeral
   */
  personFuneralInfoText: string;
  /**
   * Last day for booking the cermony for memory
   */
  memoryCeremonyLastBookingDay: any | null;
}

export interface CreateOrder_initiateCaseFolderOrders_caseFolder_summary {
  /**
   * Count number of orders)
   */
  countOrders: number;
  /**
   * Most critical Status color in the orders
   */
  mostCriticalColor: StatusColors;
  /**
   * Most critical Status in the orders
   */
  mostCriticalStatus: OrderStatus;
}

export interface CreateOrder_initiateCaseFolderOrders_caseFolder_productPackages_productPackage {
  /**
   * Id of the ProductPackage
   */
  id: string;
  /**
   * Name of the ProductPackage
   */
  name: string;
}

export interface CreateOrder_initiateCaseFolderOrders_caseFolder_productPackages_summary {
  /**
   * Most critical Status color in the orders
   */
  mostCriticalColor: StatusColors;
  /**
   * Most critical Status in the orders
   */
  mostCriticalStatus: OrderStatus;
  /**
   * Flag when shows warning about the bookingstoptime
   */
  isMostCriticalTimeBookingWarning: boolean;
}

export interface CreateOrder_initiateCaseFolderOrders_caseFolder_productPackages_documents_documentFormat_documentType {
  friendlyName: string;
  /**
   * Id of the documenttype
   */
  id: string;
  /**
   * The name of the documentType
   */
  name: string;
}

export interface CreateOrder_initiateCaseFolderOrders_caseFolder_productPackages_documents_documentFormat {
  /**
   * Id of the documentformat
   */
  id: string;
  documentType: CreateOrder_initiateCaseFolderOrders_caseFolder_productPackages_documents_documentFormat_documentType;
}

export interface CreateOrder_initiateCaseFolderOrders_caseFolder_productPackages_documents_orders_document {
  /**
   * Id of the document
   */
  id: string | null;
}

export interface CreateOrder_initiateCaseFolderOrders_caseFolder_productPackages_documents_orders_alerting {
  text: string | null;
  smallText: string | null;
}

export interface CreateOrder_initiateCaseFolderOrders_caseFolder_productPackages_documents_orders_mediaOrder_media {
  /**
   * The id of the media
   */
  id: string | null;
  /**
   * The friendlyName of the media
   */
  friendlyName: string;
}

export interface CreateOrder_initiateCaseFolderOrders_caseFolder_productPackages_documents_orders_mediaOrder_documentFormat {
  /**
   * Id of the documentformat
   */
  id: string;
  mediaAdaptedName: string;
}

export interface CreateOrder_initiateCaseFolderOrders_caseFolder_productPackages_documents_orders_mediaOrder_publishTimes {
  /**
   * Date when the order will be published
   */
  time: any;
}

export interface CreateOrder_initiateCaseFolderOrders_caseFolder_productPackages_documents_orders_mediaOrder_status {
  /**
   * ProductStatus enum for the status
   */
  status: OrderStatus;
  /**
   * Color for vieweing the customer
   */
  color: StatusColors;
}

export interface CreateOrder_initiateCaseFolderOrders_caseFolder_productPackages_documents_orders_mediaOrder {
  /**
   * Flag when shows warning about the bookingstoptime
   */
  isCriticalTimeBookingWarning: boolean;
  /**
   * Locked
   */
  isLocked: boolean;
  /**
   * media of the order
   */
  media: CreateOrder_initiateCaseFolderOrders_caseFolder_productPackages_documents_orders_mediaOrder_media;
  /**
   * documentformat of the order
   */
  documentFormat: CreateOrder_initiateCaseFolderOrders_caseFolder_productPackages_documents_orders_mediaOrder_documentFormat;
  /**
   * times when the order will be published
   */
  publishTimes: (CreateOrder_initiateCaseFolderOrders_caseFolder_productPackages_documents_orders_mediaOrder_publishTimes | null)[];
  /**
   * Status of the order
   */
  status: CreateOrder_initiateCaseFolderOrders_caseFolder_productPackages_documents_orders_mediaOrder_status;
}

export interface CreateOrder_initiateCaseFolderOrders_caseFolder_productPackages_documents_orders {
  /**
   * The id of the mediaorder
   */
  id: string | null;
  /**
   * The Id shown for customer
   */
  customerFriendlyId: string;
  /**
   * document of the order
   */
  document: CreateOrder_initiateCaseFolderOrders_caseFolder_productPackages_documents_orders_document;
  alerting: CreateOrder_initiateCaseFolderOrders_caseFolder_productPackages_documents_orders_alerting;
  /**
   * mediaOrder properties if that kind of order
   */
  mediaOrder: CreateOrder_initiateCaseFolderOrders_caseFolder_productPackages_documents_orders_mediaOrder | null;
}

export interface CreateOrder_initiateCaseFolderOrders_caseFolder_productPackages_documents {
  /**
   * Id of the document
   */
  id: string | null;
  /**
   * InternalId of the document
   */
  internalId: number;
  /**
   * format of the document
   */
  documentFormat: CreateOrder_initiateCaseFolderOrders_caseFolder_productPackages_documents_documentFormat;
  orders: CreateOrder_initiateCaseFolderOrders_caseFolder_productPackages_documents_orders[];
}

export interface CreateOrder_initiateCaseFolderOrders_caseFolder_productPackages {
  /**
   * Product Package
   */
  productPackage: CreateOrder_initiateCaseFolderOrders_caseFolder_productPackages_productPackage;
  /**
   * ordersummary of product package
   */
  summary: CreateOrder_initiateCaseFolderOrders_caseFolder_productPackages_summary;
  /**
   * orders of the product package
   */
  documents: (CreateOrder_initiateCaseFolderOrders_caseFolder_productPackages_documents | null)[];
}

export interface CreateOrder_initiateCaseFolderOrders_caseFolder {
  /**
   * The id of the caseFolder
   */
  id: string | null;
  editTabs: CreateOrder_initiateCaseFolderOrders_caseFolder_editTabs[];
  /**
   * The internal id of the caseFolder
   */
  internalId: number;
  /**
   * The name of the caseFolder
   */
  name: string;
  customer: CreateOrder_initiateCaseFolderOrders_caseFolder_customer;
  propertiesFuneralStandard: CreateOrder_initiateCaseFolderOrders_caseFolder_propertiesFuneralStandard;
  summary: CreateOrder_initiateCaseFolderOrders_caseFolder_summary;
  /**
   * ProductPackages of the caseFolder
   */
  productPackages: (CreateOrder_initiateCaseFolderOrders_caseFolder_productPackages | null)[];
}

export interface CreateOrder_initiateCaseFolderOrders_orders_document {
  /**
   * Id of the document
   */
  id: string | null;
}

export interface CreateOrder_initiateCaseFolderOrders_orders_client {
  mainSystem: SystemFlags;
  isTaps5: boolean;
}

export interface CreateOrder_initiateCaseFolderOrders_orders_alerting {
  smallText: string | null;
  text: string | null;
  type: OrderAlertingType;
}

export interface CreateOrder_initiateCaseFolderOrders_orders_mediaOrder_publishTimes {
  /**
   * Date when the order will be published
   */
  time: any;
}

export interface CreateOrder_initiateCaseFolderOrders_orders_mediaOrder_documentFormat_documentFormatCollection {
  id: string;
  /**
   * The name of the collection
   */
  name: string;
}

export interface CreateOrder_initiateCaseFolderOrders_orders_mediaOrder_documentFormat_documentType {
  /**
   * Id of the documenttype
   */
  id: string;
  /**
   * The name of the documentType
   */
  name: string;
}

export interface CreateOrder_initiateCaseFolderOrders_orders_mediaOrder_documentFormat {
  /**
   * Id of the documentformat
   */
  id: string;
  documentFormatCollection: CreateOrder_initiateCaseFolderOrders_orders_mediaOrder_documentFormat_documentFormatCollection;
  mediaAdaptedName: string;
  documentType: CreateOrder_initiateCaseFolderOrders_orders_mediaOrder_documentFormat_documentType;
}

export interface CreateOrder_initiateCaseFolderOrders_orders_mediaOrder_publishConversation {
  /**
   * Validation error texts
   */
  validateErrorTexts: string[];
  /**
   * Message to media
   */
  messageFromMedia: string | null;
  /**
   * Code to verify late publish
   */
  codeLate: string;
  /**
   * Code to media. Used when the ordering is late. Passed bookingstop. This must be verfied before it can be published
   */
  isCodeLate: boolean;
  /**
   * Message to media allowed
   */
  isMessageToMedia: boolean;
  /**
   * Media-System Ordernumber has to be set
   */
  isMediaSystemOrderNumber: boolean;
  /**
   * EndCustomer subribenumber on media can be set
   */
  isMediaCustomerSubriberNumber: boolean;
  /**
   * Status for publish
   */
  publishReadyStatus: MediaOrderPublishhReadyStatus;
}

export interface CreateOrder_initiateCaseFolderOrders_orders_mediaOrder_publishChannels {
  /**
   * Publish in familjesidan
   */
  inFamiljesidan: boolean;
  /**
   * Publish in minnesrummet
   */
  inMinnesrummet: boolean;
  /**
   * Publish in agency homepage
   */
  inAgency: boolean;
}

export interface CreateOrder_initiateCaseFolderOrders_orders_mediaOrder_edit_userInterface {
  editStatusColor: EditUIEditStatusColor;
  editStatusText: string | null;
  publishButtonText: string | null;
  editStatusIcon: EditUIEditStatusIcon;
  isUnPublishButton: boolean;
  unPublishButtonText: string | null;
  isPublishButton: boolean;
  isLockButton: boolean;
  lockButtonText: string | null;
  isUnlockButton: boolean;
  unlockButtonText: string | null;
  isReactivateButton: boolean;
  reactivateButtonText: string | null;
  isSetExhibitOrder: boolean;
  setExhibitOrderText: string | null;
  isUpdateCustomerDocumentContentInitiator: boolean;
  updateCustomerDocumentContentInitiatorText: string | null;
  /**
   * Size limit
   */
  orderEntityNameSingular: string | null;
  /**
   * Size limit
   */
  orderEntityNamePlural: string | null;
  /**
   * Size limit
   */
  orderEntityNameSingularDetermined: string | null;
  /**
   * Size limit
   */
  orderEntityNamePluralDetermined: string | null;
  isCopyFuneralInfoToMemoryRoom: boolean;
  copyFuneralInfoToMemoryRoomText: string | null;
  isNetnoticeEnabled: boolean;
  isNetnoticeSupported: boolean;
}

export interface CreateOrder_initiateCaseFolderOrders_orders_mediaOrder_edit_resources {
  /**
   * Get style css for the specialCharacters
   */
  styleCssSpecialCharacters: string;
}

export interface CreateOrder_initiateCaseFolderOrders_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_validateErrors {
  error: EditModelAdvertAreaContentValidateErrors;
  validationText: string | null;
}

export interface CreateOrder_initiateCaseFolderOrders_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_common_marginLeftRightOffset {
  /**
   * Type
   */
  type: RelativeTypes;
  /**
   * Value
   */
  value: number;
}

export interface CreateOrder_initiateCaseFolderOrders_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_common_margin {
  /**
   * Type of unit
   */
  type: UnitTypes;
  /**
   * Left
   */
  left: number | null;
  /**
   * Right
   */
  right: number | null;
  /**
   * Top
   */
  top: number | null;
  /**
   * Bottom
   */
  bottom: number | null;
}

export interface CreateOrder_initiateCaseFolderOrders_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_common_size {
  /**
   * Type
   */
  type: UnitTypes;
  /**
   * Min Width
   */
  minWidth: number | null;
  /**
   * Min Height
   */
  minHeight: number | null;
  /**
   * Max Width
   */
  maxWidth: number | null;
  /**
   * Max Height
   */
  maxHeight: number | null;
  /**
   * Fix Width
   */
  fixedWidth: number | null;
  /**
   * Fix Height
   */
  fixedHeight: number | null;
}

export interface CreateOrder_initiateCaseFolderOrders_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_common {
  /**
   * Alternative BlockStyle
   */
  blockStyleAreaNameId: string | null;
  marginLeftRightOffset: CreateOrder_initiateCaseFolderOrders_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_common_marginLeftRightOffset | null;
  margin: CreateOrder_initiateCaseFolderOrders_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_common_margin | null;
  size: CreateOrder_initiateCaseFolderOrders_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_common_size | null;
}

export interface CreateOrder_initiateCaseFolderOrders_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_image {
  /**
   * Repository of image
   */
  type: EditModelAdvertAreaContentRepository | null;
  /**
   * id for the image
   */
  imageId: string | null;
  /**
   * public id for the image
   */
  imagePublicId: string | null;
  /**
   * public id for the image
   */
  imageThumbnaill100x80Url: string | null;
  /**
   * Height for the image in mm
   */
  heightMM: number | null;
  /**
   * DefaultHeight for the image in mm
   */
  defaultHeightMM: number | null;
  /**
   * IsSizeFree
   */
  isSizeFree: boolean | null;
  /**
   * Upload image content
   */
  uploadData: string | null;
  /**
   * Upload filename
   */
  uploadFilename: string | null;
  /**
   * Bitmap resolution in dpi
   */
  resolution: number | null;
  /**
   * Upload prefered HeightMM of uploaded image
   */
  uploadPreferedHeightMM: number | null;
  /**
   * Repository of image
   */
  repository: EditModelAdvertAreaContentRepository | null;
  /**
   * Upload source image content
   */
  uploadDataSource: string | null;
  /**
   * Comment about the upload
   */
  uploadComment: string | null;
}

export interface CreateOrder_initiateCaseFolderOrders_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_text_textScaleWidthOffset {
  /**
   * Type
   */
  type: RelativeTypes;
  /**
   * Value
   */
  value: number;
}

export interface CreateOrder_initiateCaseFolderOrders_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_text_fontSize {
  /**
   * Type of unit
   */
  type: UnitTypes;
  /**
   * Value
   */
  value: number;
}

export interface CreateOrder_initiateCaseFolderOrders_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_text_lineHeight {
  /**
   * Type of unit
   */
  type: UnitTypes;
  /**
   * Value
   */
  value: number;
}

export interface CreateOrder_initiateCaseFolderOrders_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_text_lineHeightEmpty {
  /**
   * Type of unit
   */
  type: UnitTypes;
  /**
   * Value
   */
  value: number;
}

export interface CreateOrder_initiateCaseFolderOrders_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_text_letterSpacing {
  /**
   * Type of unit
   */
  type: UnitTypes;
  /**
   * Value
   */
  value: number;
}

export interface CreateOrder_initiateCaseFolderOrders_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_text_textScaleWidth {
  /**
   * Type
   */
  type: RelativeTypes;
  /**
   * Value
   */
  value: number;
}

export interface CreateOrder_initiateCaseFolderOrders_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_text {
  /**
   * Font style
   */
  fontStyle: FontStyles | null;
  /**
   * Textscale width offset
   */
  textScaleWidthOffset: CreateOrder_initiateCaseFolderOrders_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_text_textScaleWidthOffset | null;
  /**
   * Font weight
   */
  fontWeight: FontWeights | null;
  /**
   * Text alignment
   */
  textAlignment: TextAlignments | null;
  /**
   * Text formats in xml
   */
  xmlText: string | null;
  /**
   * Is htmleditor readonly
   */
  isXmlTextReadonly: boolean | null;
  /**
   * Fontfamily of the text
   */
  fontFamily: string | null;
  /**
   * Font
   */
  fontSize: CreateOrder_initiateCaseFolderOrders_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_text_fontSize | null;
  /**
   * Line-height
   */
  lineHeight: CreateOrder_initiateCaseFolderOrders_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_text_lineHeight | null;
  /**
   * Line-height for empty rows
   */
  lineHeightEmpty: CreateOrder_initiateCaseFolderOrders_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_text_lineHeightEmpty | null;
  /**
   * Letter-Spacing
   */
  letterSpacing: CreateOrder_initiateCaseFolderOrders_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_text_letterSpacing | null;
  /**
   * Textscale width
   */
  textScaleWidth: CreateOrder_initiateCaseFolderOrders_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_text_textScaleWidth | null;
  /**
   * Text do not wrap when text reach its width
   */
  textNoWrap: boolean | null;
}

export interface CreateOrder_initiateCaseFolderOrders_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_separator {
  /**
   * Type of image
   */
  type: EditModelAdvertAreaContentItemSeparatorTypes;
  imageId: string | null;
  /**
   * Repository of image
   */
  repository: EditModelAdvertAreaContentRepository | null;
  /**
   * Width for the image in mm
   */
  widthMM: number | null;
  /**
   * Height for the image in mm
   */
  heightMM: number | null;
}

export interface CreateOrder_initiateCaseFolderOrders_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_fields {
  /**
   * Name
   */
  name: string;
  /**
   * Header
   */
  header: string | null;
  /**
   * Type
   */
  type: EditModelAdvertAreaContentItemFieldItemTypes | null;
  /**
   * Editor
   */
  editor: EditModelAdvertAreaContentItemFieldItemEditors | null;
  /**
   * Value
   */
  value: string | null;
  __typename: "EditModelAdvertAreaContentItemFieldItem";
}

export interface CreateOrder_initiateCaseFolderOrders_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item {
  /**
   * Common
   */
  common: CreateOrder_initiateCaseFolderOrders_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_common | null;
  /**
   * Value is only for demo purpose
   */
  isDemoValue: boolean | null;
  /**
   * ImageContent
   */
  image: CreateOrder_initiateCaseFolderOrders_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_image | null;
  /**
   * TextContent
   */
  text: CreateOrder_initiateCaseFolderOrders_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_text | null;
  /**
   * HorizontalLineContent
   */
  separator: CreateOrder_initiateCaseFolderOrders_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_separator | null;
  /**
   * Fields
   */
  fields: (CreateOrder_initiateCaseFolderOrders_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_fields | null)[] | null;
}

export interface CreateOrder_initiateCaseFolderOrders_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns {
  item: CreateOrder_initiateCaseFolderOrders_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item;
}

export interface CreateOrder_initiateCaseFolderOrders_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows {
  columns: CreateOrder_initiateCaseFolderOrders_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns[];
}

export interface CreateOrder_initiateCaseFolderOrders_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_value {
  /**
   * Id for the content-value
   */
  id: string | null;
  /**
   * Is content visible
   */
  visible: boolean;
  /**
   * Is it content
   */
  hasContent: boolean;
  /**
   * Is editor visible
   */
  isEditorVisible: boolean;
  /**
   * Id to the template
   */
  templateAreaId: string | null;
  /**
   * Rows
   */
  rows: CreateOrder_initiateCaseFolderOrders_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows[];
}

export interface CreateOrder_initiateCaseFolderOrders_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_access {
  add: boolean;
  modify: boolean;
  statusAdd: EditModelAdvertAreaContentEditorAccessStatuses;
}

export interface CreateOrder_initiateCaseFolderOrders_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_common_allowedMarginLeftRightOffsetInterval {
  /**
   * Type of unit
   */
  type: RelativeTypes;
  /**
   * Max-Value
   */
  maxValue: number;
  /**
   * Min-Value
   */
  minValue: number;
  /**
   * Interval-Value
   */
  intervalValue: number;
}

export interface CreateOrder_initiateCaseFolderOrders_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_common_blockStyles {
  /**
   * Id
   */
  id: string;
  /**
   * Name
   */
  name: string;
}

export interface CreateOrder_initiateCaseFolderOrders_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_common {
  /**
   * Margin can adjust margins
   */
  isEnabledAdjustMargins: boolean;
  /**
   * If UpdateOrdersContent is avaiable
   */
  isEnabledUpdateOrdersContent: boolean;
  /**
   * If it is possible to add columns
   */
  isEnabledMultiColumns: boolean;
  /**
   * Enable margin setting
   */
  isMarginLeftRightOffset: boolean;
  /**
   * Allowed margin settings
   */
  allowedMarginLeftRightOffsetInterval: CreateOrder_initiateCaseFolderOrders_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_common_allowedMarginLeftRightOffsetInterval | null;
  /**
   * Block styles
   */
  blockStyles: CreateOrder_initiateCaseFolderOrders_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_common_blockStyles[];
}

export interface CreateOrder_initiateCaseFolderOrders_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_text_allowedFontSizes {
  /**
   * Values
   */
  values: number[];
}

export interface CreateOrder_initiateCaseFolderOrders_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_text_allowedLineHeights {
  /**
   * Values
   */
  values: number[];
}

export interface CreateOrder_initiateCaseFolderOrders_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_text_allowedFontSizeInterval {
  /**
   * Type of unit
   */
  type: UnitTypes;
  /**
   * Interval-Value
   */
  intervalValue: number;
}

export interface CreateOrder_initiateCaseFolderOrders_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_text_allowedLineHeightInterval {
  /**
   * Type of unit
   */
  type: UnitTypes;
  /**
   * Interval-Value
   */
  intervalValue: number;
}

export interface CreateOrder_initiateCaseFolderOrders_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_text_allowedTextScaleWidthOffsetInterval {
  /**
   * Interval-Value
   */
  intervalValue: number;
  /**
   * Min-Value
   */
  minValue: number;
  /**
   * Max-Value
   */
  maxValue: number;
}

export interface CreateOrder_initiateCaseFolderOrders_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_text_allowedCharacterGalleries_items {
  /**
   * Id
   */
  id: string;
  /**
   * Name
   */
  name: string;
  /**
   * Title
   */
  title: string;
  /**
   * Preview
   */
  preview: string | null;
  /**
   * Html
   */
  html: string;
}

export interface CreateOrder_initiateCaseFolderOrders_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_text_allowedCharacterGalleries {
  /**
   * Id
   */
  id: string;
  isAdvancedUser: boolean;
  /**
   * Items
   */
  items: CreateOrder_initiateCaseFolderOrders_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_text_allowedCharacterGalleries_items[];
}

export interface CreateOrder_initiateCaseFolderOrders_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_text_allowedParagraphClasses {
  /**
   * Id
   */
  id: string;
  /**
   * Name
   */
  name: string;
}

export interface CreateOrder_initiateCaseFolderOrders_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_text {
  /**
   * Default textalignment from template
   */
  defaultTextAdjustment: TextAlignments;
  /**
   * Allowed font sizes
   */
  allowedFontSizes: CreateOrder_initiateCaseFolderOrders_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_text_allowedFontSizes;
  /**
   * Allowed font styles
   */
  allowedFontStyles: FontStyles[];
  /**
   * Allowed font weights
   */
  allowedFontWeights: FontWeights[];
  /**
   * Allowed line height sizes
   */
  allowedLineHeights: CreateOrder_initiateCaseFolderOrders_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_text_allowedLineHeights;
  /**
   * Allowed font families
   */
  allowedFontFamilies: string[];
  /**
   * Allowed text adjustments
   */
  allowedTextAdjustments: TextAlignments[];
  /**
   * Allowed font size interval
   */
  allowedFontSizeInterval: CreateOrder_initiateCaseFolderOrders_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_text_allowedFontSizeInterval;
  /**
   * Allowed line height size interval
   */
  allowedLineHeightInterval: CreateOrder_initiateCaseFolderOrders_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_text_allowedLineHeightInterval;
  /**
   * Enable text scale width offset
   */
  isTextScaleWidthOffset: boolean;
  /**
   * Allowed TextScale width offset interval
   */
  allowedTextScaleWidthOffsetInterval: CreateOrder_initiateCaseFolderOrders_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_text_allowedTextScaleWidthOffsetInterval | null;
  /**
   * Allowed special characters galleries
   */
  allowedCharacterGalleries: CreateOrder_initiateCaseFolderOrders_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_text_allowedCharacterGalleries[];
  /**
   * Allowed number of paragraphClass
   */
  allowedParagraphClasses: CreateOrder_initiateCaseFolderOrders_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_text_allowedParagraphClasses[];
  /**
   * Enable to change font size
   */
  isEnabledFontSize: boolean;
  /**
   * Enable to change font style
   */
  isEnabledFontStyle: boolean;
  /**
   * Enable to change line height
   */
  isEnabledLineHeight: boolean;
  /**
   * Enable to change font family
   */
  isEnabledFontFamily: boolean;
  /**
   * Enable to change font weights
   */
  isEnabledFontWeight: boolean;
  /**
   * Enable to change letter spacing
   */
  isEnabledLetterSpacing: boolean;
  /**
   * Enable to advanced settings for the admin/support
   */
  isEnabledAdvancedSettings: boolean;
  /**
   * Enable to change letter spacing
   */
  isEnabledTextScaleWidthTolerance: boolean;
  /**
   * Enable gallery option
   */
  isEnabledGallery: boolean;
}

export interface CreateOrder_initiateCaseFolderOrders_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_image_adjustment_brightness {
  /**
   * Interval-Value
   */
  intervalValue: number;
  /**
   * Max-Value
   */
  maxValue: number;
  /**
   * Min-Value
   */
  minValue: number;
  /**
   * Type of unit
   */
  type: RelativeTypes;
}

export interface CreateOrder_initiateCaseFolderOrders_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_image_adjustment_contrast {
  /**
   * Interval-Value
   */
  intervalValue: number;
  /**
   * Max-Value
   */
  maxValue: number;
  /**
   * Min-Value
   */
  minValue: number;
  /**
   * Type of unit
   */
  type: RelativeTypes;
}

export interface CreateOrder_initiateCaseFolderOrders_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_image_adjustment_rotate {
  /**
   * Interval-Value
   */
  intervalValue: number;
  /**
   * Max-Value
   */
  maxValue: number;
  /**
   * Min-Value
   */
  minValue: number;
  /**
   * Type of unit
   */
  type: RelativeTypes;
}

export interface CreateOrder_initiateCaseFolderOrders_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_image_adjustment {
  brightness: CreateOrder_initiateCaseFolderOrders_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_image_adjustment_brightness;
  contrast: CreateOrder_initiateCaseFolderOrders_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_image_adjustment_contrast;
  rotate: CreateOrder_initiateCaseFolderOrders_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_image_adjustment_rotate;
}

export interface CreateOrder_initiateCaseFolderOrders_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_image_mask {
  imageUrl: string | null;
}

export interface CreateOrder_initiateCaseFolderOrders_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_image {
  /**
   * Min image height
   */
  minImageHeight: number;
  /**
   * Max image height
   */
  maxImageHeight: number;
  /**
   * Filter by gallery image category
   */
  filterGalleryImageAttributeInternalIds: number[];
  /**
   * Filter by not gallery image category
   */
  filterGalleryImageAttributeNotInternalIds: number[];
  /**
   * Filter by not gallery image category
   */
  galleryImageOption: string;
  /**
   * Filter by not gallery image category
   */
  documentFormatId: string;
  /**
   * Is the user enabled to switch to free size
   */
  isEnabledFreeSize: boolean;
  /**
   * Is the user enabled to upload images
   */
  isEnabledUpload: boolean;
  /**
   * Gallery is enabled
   */
  isEnabledGallery: boolean;
  /**
   * Resize is enabled
   */
  isEnabledResize: boolean;
  adjustment: CreateOrder_initiateCaseFolderOrders_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_image_adjustment | null;
  mask: CreateOrder_initiateCaseFolderOrders_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_image_mask | null;
}

export interface CreateOrder_initiateCaseFolderOrders_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_help {
  html: string | null;
  hoverHtml: string | null;
  supportHtml: string | null;
}

export interface CreateOrder_initiateCaseFolderOrders_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor {
  /**
   * Type of editor (Image, Text, Separator)
   */
  type: EditModelAdvertAreaContentEditorTypes;
  /**
   * SubType of editor
   */
  behaviour: EditModelAdvertAreaContentEditorBehaviours;
  /**
   * Access properties
   */
  access: CreateOrder_initiateCaseFolderOrders_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_access;
  /**
   * Common parameters
   */
  common: CreateOrder_initiateCaseFolderOrders_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_common;
  /**
   * Text parameters
   */
  text: CreateOrder_initiateCaseFolderOrders_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_text | null;
  /**
   * Image parameters
   */
  image: CreateOrder_initiateCaseFolderOrders_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_image | null;
  /**
   * Help properties
   */
  help: CreateOrder_initiateCaseFolderOrders_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_help | null;
}

export interface CreateOrder_initiateCaseFolderOrders_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content {
  /**
   * Validate errors
   */
  validateErrors: CreateOrder_initiateCaseFolderOrders_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_validateErrors[];
  value: CreateOrder_initiateCaseFolderOrders_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_value;
  editor: CreateOrder_initiateCaseFolderOrders_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor | null;
}

export interface CreateOrder_initiateCaseFolderOrders_orders_mediaOrder_edit_editModelAdvert_basePage_areas_style_margin {
  /**
   * Top
   */
  top: number | null;
  /**
   * Left
   */
  left: number | null;
  /**
   * Right
   */
  right: number | null;
  /**
   * Bottom
   */
  bottom: number | null;
}

export interface CreateOrder_initiateCaseFolderOrders_orders_mediaOrder_edit_editModelAdvert_basePage_areas_style_content_size {
  /**
   * Type
   */
  type: UnitTypes;
  /**
   * Min Width
   */
  minWidth: number | null;
  /**
   * Min Height
   */
  minHeight: number | null;
  /**
   * Max Width
   */
  maxWidth: number | null;
  /**
   * Max Height
   */
  maxHeight: number | null;
  /**
   * Fix Width
   */
  fixedWidth: number | null;
  /**
   * Fix Height
   */
  fixedHeight: number | null;
}

export interface CreateOrder_initiateCaseFolderOrders_orders_mediaOrder_edit_editModelAdvert_basePage_areas_style_content {
  /**
   * Size limit
   */
  size: CreateOrder_initiateCaseFolderOrders_orders_mediaOrder_edit_editModelAdvert_basePage_areas_style_content_size | null;
}

export interface CreateOrder_initiateCaseFolderOrders_orders_mediaOrder_edit_editModelAdvert_basePage_areas_style_text_fontSize {
  /**
   * Type of unit
   */
  type: UnitTypes;
  /**
   * Value
   */
  value: number;
}

export interface CreateOrder_initiateCaseFolderOrders_orders_mediaOrder_edit_editModelAdvert_basePage_areas_style_text_lineHeight {
  /**
   * Type of unit
   */
  type: UnitTypes;
  /**
   * Value
   */
  value: number;
}

export interface CreateOrder_initiateCaseFolderOrders_orders_mediaOrder_edit_editModelAdvert_basePage_areas_style_text_lineHeightEmpty {
  /**
   * Type of unit
   */
  type: UnitTypes;
  /**
   * Value
   */
  value: number;
}

export interface CreateOrder_initiateCaseFolderOrders_orders_mediaOrder_edit_editModelAdvert_basePage_areas_style_text_letterSpacing {
  /**
   * Type of unit
   */
  type: UnitTypes;
  /**
   * Value
   */
  value: number;
}

export interface CreateOrder_initiateCaseFolderOrders_orders_mediaOrder_edit_editModelAdvert_basePage_areas_style_text_textScaleWidthOffset {
  /**
   * Type
   */
  type: RelativeTypes;
  /**
   * Value
   */
  value: number;
}

export interface CreateOrder_initiateCaseFolderOrders_orders_mediaOrder_edit_editModelAdvert_basePage_areas_style_text_textScaleWidth {
  /**
   * Type
   */
  type: RelativeTypes;
  /**
   * Value
   */
  value: number;
}

export interface CreateOrder_initiateCaseFolderOrders_orders_mediaOrder_edit_editModelAdvert_basePage_areas_style_text {
  fontFamily: string;
  /**
   * Font-size
   */
  fontSize: CreateOrder_initiateCaseFolderOrders_orders_mediaOrder_edit_editModelAdvert_basePage_areas_style_text_fontSize | null;
  /**
   * Line height
   */
  lineHeight: CreateOrder_initiateCaseFolderOrders_orders_mediaOrder_edit_editModelAdvert_basePage_areas_style_text_lineHeight | null;
  /**
   * Line height for empty rows
   */
  lineHeightEmpty: CreateOrder_initiateCaseFolderOrders_orders_mediaOrder_edit_editModelAdvert_basePage_areas_style_text_lineHeightEmpty | null;
  /**
   * Letter spacing
   */
  letterSpacing: CreateOrder_initiateCaseFolderOrders_orders_mediaOrder_edit_editModelAdvert_basePage_areas_style_text_letterSpacing | null;
  textScaleWidthOffset: CreateOrder_initiateCaseFolderOrders_orders_mediaOrder_edit_editModelAdvert_basePage_areas_style_text_textScaleWidthOffset;
  textScaleWidth: CreateOrder_initiateCaseFolderOrders_orders_mediaOrder_edit_editModelAdvert_basePage_areas_style_text_textScaleWidth;
  fontStyle: FontStyles | null;
  fontWeight: FontWeights | null;
}

export interface CreateOrder_initiateCaseFolderOrders_orders_mediaOrder_edit_editModelAdvert_basePage_areas_style {
  /**
   * margins
   */
  margin: CreateOrder_initiateCaseFolderOrders_orders_mediaOrder_edit_editModelAdvert_basePage_areas_style_margin | null;
  content: CreateOrder_initiateCaseFolderOrders_orders_mediaOrder_edit_editModelAdvert_basePage_areas_style_content;
  /**
   * Text settings
   */
  text: CreateOrder_initiateCaseFolderOrders_orders_mediaOrder_edit_editModelAdvert_basePage_areas_style_text;
}

export interface CreateOrder_initiateCaseFolderOrders_orders_mediaOrder_edit_editModelAdvert_basePage_areas_rectangle {
  /**
   * X coordinate
   */
  x: number;
  /**
   * Y coordinate
   */
  y: number;
  /**
   * Width
   */
  w: number;
  /**
   * Width
   */
  h: number;
}

export interface CreateOrder_initiateCaseFolderOrders_orders_mediaOrder_edit_editModelAdvert_basePage_areas {
  /**
   * Id of the blockeditor
   */
  id: string;
  /**
   * Parameters of the blockeditor
   */
  header: string;
  content: CreateOrder_initiateCaseFolderOrders_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content;
  style: CreateOrder_initiateCaseFolderOrders_orders_mediaOrder_edit_editModelAdvert_basePage_areas_style;
  rectangle: CreateOrder_initiateCaseFolderOrders_orders_mediaOrder_edit_editModelAdvert_basePage_areas_rectangle | null;
}

export interface CreateOrder_initiateCaseFolderOrders_orders_mediaOrder_edit_editModelAdvert_basePage {
  areas: (CreateOrder_initiateCaseFolderOrders_orders_mediaOrder_edit_editModelAdvert_basePage_areas | null)[] | null;
}

export interface CreateOrder_initiateCaseFolderOrders_orders_mediaOrder_edit_editModelAdvert {
  /**
   * If UpdateOrdersContent is avaiable
   */
  isEnabledUpdateOrdersContent: boolean;
  basePage: CreateOrder_initiateCaseFolderOrders_orders_mediaOrder_edit_editModelAdvert_basePage;
}

export interface CreateOrder_initiateCaseFolderOrders_orders_mediaOrder_edit_history {
  /**
   * How many steps it is possible to go back in history
   */
  countStepBack: number;
}

export interface CreateOrder_initiateCaseFolderOrders_orders_mediaOrder_edit {
  /**
   * Texts for editing the order
   */
  userInterface: CreateOrder_initiateCaseFolderOrders_orders_mediaOrder_edit_userInterface;
  resources: CreateOrder_initiateCaseFolderOrders_orders_mediaOrder_edit_resources | null;
  editModelAdvert: CreateOrder_initiateCaseFolderOrders_orders_mediaOrder_edit_editModelAdvert | null;
  history: CreateOrder_initiateCaseFolderOrders_orders_mediaOrder_edit_history;
}

export interface CreateOrder_initiateCaseFolderOrders_orders_mediaOrder_preview_material_basePage_size {
  /**
   * Width
   */
  width: number;
  /**
   * Height
   */
  height: number;
}

export interface CreateOrder_initiateCaseFolderOrders_orders_mediaOrder_preview_material_basePage {
  size: CreateOrder_initiateCaseFolderOrders_orders_mediaOrder_preview_material_basePage_size | null;
}

export interface CreateOrder_initiateCaseFolderOrders_orders_mediaOrder_preview_material_pages_png {
  /**
   * Url till pngfile
   */
  url: string;
}

export interface CreateOrder_initiateCaseFolderOrders_orders_mediaOrder_preview_material_pages {
  /**
   * Png material
   */
  png: CreateOrder_initiateCaseFolderOrders_orders_mediaOrder_preview_material_pages_png;
}

export interface CreateOrder_initiateCaseFolderOrders_orders_mediaOrder_preview_material {
  /**
   * base page
   */
  basePage: CreateOrder_initiateCaseFolderOrders_orders_mediaOrder_preview_material_basePage;
  /**
   * pages
   */
  pages: (CreateOrder_initiateCaseFolderOrders_orders_mediaOrder_preview_material_pages | null)[];
}

export interface CreateOrder_initiateCaseFolderOrders_orders_mediaOrder_preview_price_customer {
  /**
   * How much the product is pricing for the scope in the contract
   */
  priceContract: any | null;
  /**
   * How much the product is pricing for the order if it has been override the contract
   */
  priceContractOverride: any | null;
}

export interface CreateOrder_initiateCaseFolderOrders_orders_mediaOrder_preview_price_endCustomer {
  /**
   * Whats pay for the order and rounded
   */
  pricePayRounded: any | null;
}

export interface CreateOrder_initiateCaseFolderOrders_orders_mediaOrder_preview_price {
  /**
   * Currency
   */
  currency: string;
  /**
   * Customer view of the price
   */
  customer: CreateOrder_initiateCaseFolderOrders_orders_mediaOrder_preview_price_customer;
  /**
   * EndCustomer view of the price
   */
  endCustomer: CreateOrder_initiateCaseFolderOrders_orders_mediaOrder_preview_price_endCustomer;
}

export interface CreateOrder_initiateCaseFolderOrders_orders_mediaOrder_preview {
  /**
   * material
   */
  material: CreateOrder_initiateCaseFolderOrders_orders_mediaOrder_preview_material;
  /**
   * price of the order
   */
  price: CreateOrder_initiateCaseFolderOrders_orders_mediaOrder_preview_price | null;
}

export interface CreateOrder_initiateCaseFolderOrders_orders_mediaOrder_media {
  /**
   * The id of the media
   */
  id: string | null;
  /**
   * The friendlyName of the media
   */
  friendlyName: string;
}

export interface CreateOrder_initiateCaseFolderOrders_orders_mediaOrder_status {
  /**
   * Color for vieweing the customer
   */
  color: StatusColors;
  /**
   * ProductStatus enum for the status
   */
  status: OrderStatus;
}

export interface CreateOrder_initiateCaseFolderOrders_orders_mediaOrder {
  /**
   * Time when last sending the booking to media
   */
  timeBookingStop: any | null;
  /**
   * Locked
   */
  isLocked: boolean;
  /**
   * times when the order will be published
   */
  publishTimes: (CreateOrder_initiateCaseFolderOrders_orders_mediaOrder_publishTimes | null)[];
  /**
   * documentformat of the order
   */
  documentFormat: CreateOrder_initiateCaseFolderOrders_orders_mediaOrder_documentFormat;
  /**
   * Publish conversation settings for the media
   */
  publishConversation: CreateOrder_initiateCaseFolderOrders_orders_mediaOrder_publishConversation;
  /**
   * Parallell publish channels
   */
  publishChannels: CreateOrder_initiateCaseFolderOrders_orders_mediaOrder_publishChannels;
  /**
   * information for the editors
   */
  edit: CreateOrder_initiateCaseFolderOrders_orders_mediaOrder_edit;
  /**
   * Preview of the order
   */
  preview: CreateOrder_initiateCaseFolderOrders_orders_mediaOrder_preview;
  /**
   * media of the order
   */
  media: CreateOrder_initiateCaseFolderOrders_orders_mediaOrder_media;
  /**
   * Status of the order
   */
  status: CreateOrder_initiateCaseFolderOrders_orders_mediaOrder_status;
}

export interface CreateOrder_initiateCaseFolderOrders_orders {
  /**
   * The id of the mediaorder
   */
  id: string | null;
  /**
   * The Id shown for customer
   */
  customerFriendlyId: string;
  /**
   * Order is deleted
   */
  isDeleted: boolean;
  /**
   * document of the order
   */
  document: CreateOrder_initiateCaseFolderOrders_orders_document;
  client: CreateOrder_initiateCaseFolderOrders_orders_client | null;
  alerting: CreateOrder_initiateCaseFolderOrders_orders_alerting;
  /**
   * mediaOrder properties if that kind of order
   */
  mediaOrder: CreateOrder_initiateCaseFolderOrders_orders_mediaOrder | null;
}

export interface CreateOrder_initiateCaseFolderOrders {
  caseFolder: CreateOrder_initiateCaseFolderOrders_caseFolder;
  orders: CreateOrder_initiateCaseFolderOrders_orders[];
}

export interface CreateOrder {
  /**
   * Create order from inititors or initiatorpackages
   */
  initiateCaseFolderOrders: CreateOrder_initiateCaseFolderOrders;
}

export interface CreateOrderVariables {
  input: MutationInitiateCaseFolderOrdersInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteCaseFolder
// ====================================================

export interface DeleteCaseFolder_deleteCaseFolder {
  /**
   * Mutation is not successful
   */
  isError: boolean;
  /**
   * Reasons
   */
  errorReason: string | null;
}

export interface DeleteCaseFolder {
  /**
   * Delete casefolder
   */
  deleteCaseFolder: DeleteCaseFolder_deleteCaseFolder;
}

export interface DeleteCaseFolderVariables {
  input: MutationDeleteCaseFolderInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteGalleryTextItem
// ====================================================

export interface DeleteGalleryTextItem {
  /**
   * Save a text
   */
  deleteGalleryTextItem: boolean;
}

export interface DeleteGalleryTextItemVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteOrder
// ====================================================

export interface DeleteOrder_deleteOrder_orders {
  /**
   * The id of the mediaorder
   */
  id: string | null;
  /**
   * Order is deleted
   */
  isDeleted: boolean;
}

export interface DeleteOrder_deleteOrder {
  /**
   * Mutation is not successful
   */
  isError: boolean;
  /**
   * Reasons
   */
  errorReason: string | null;
  orders: DeleteOrder_deleteOrder_orders[];
}

export interface DeleteOrder {
  /**
   * Delete an order
   */
  deleteOrder: DeleteOrder_deleteOrder;
}

export interface DeleteOrderVariables {
  input: MutationDeleteOrderInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeletePackage
// ====================================================

export interface DeletePackage_deleteOrderInitiatorPackages {
  /**
   * Mutation is not successful
   */
  isError: boolean;
  /**
   * Reasons
   */
  errorReason: string | null;
}

export interface DeletePackage {
  /**
   * Update order-initiator packages
   */
  deleteOrderInitiatorPackages: DeletePackage_deleteOrderInitiatorPackages;
}

export interface DeletePackageVariables {
  input: MutationDeleteOrderInitiatorPackagesInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteTag
// ====================================================

export interface DeleteTag_deleteGalleryImageTagGroupTagsOnAllImageItems {
  /**
   * Reasons
   */
  errorReason: string | null;
}

export interface DeleteTag {
  /**
   * Remove gallery image tags from all images
   */
  deleteGalleryImageTagGroupTagsOnAllImageItems: DeleteTag_deleteGalleryImageTagGroupTagsOnAllImageItems;
}

export interface DeleteTagVariables {
  input: MutationDeleteGalleryImageTagGroupTagsOnAllImageItemsInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: MutationDeleteUser
// ====================================================

export interface MutationDeleteUser_deleteUser {
  /**
   * Mutation is not successful
   */
  isError: boolean;
}

export interface MutationDeleteUser {
  /**
   * Deactivate a user from system
   */
  deleteUser: MutationDeleteUser_deleteUser;
}

export interface MutationDeleteUserVariables {
  input: MutationDeleteUserInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: GetProofMemorizCandlesThemes
// ====================================================

export interface GetProofMemorizCandlesThemes_getProofMemorizCandlesThemes {
  id: string;
  name: string;
  imageUrl: string | null;
  description: string | null;
}

export interface GetProofMemorizCandlesThemes {
  /**
   * Get themes for the proof
   */
  getProofMemorizCandlesThemes: GetProofMemorizCandlesThemes_getProofMemorizCandlesThemes[];
}

export interface GetProofMemorizCandlesThemesVariables {
  orderID: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: GetProofMemorizCandlesUrl
// ====================================================

export interface GetProofMemorizCandlesUrl {
  /**
   * Create proof for memoriz candles
   */
  getProofMemorizCandlesUrl: string;
}

export interface GetProofMemorizCandlesUrlVariables {
  orderID: string;
  theme: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: InvokeAction
// ====================================================

export interface InvokeAction_invokeAction_result {
  /**
   * Response action
   */
  responseAction: ResponseActions | null;
  /**
   * Response text
   */
  text: string;
  /**
   * Action successful
   */
  success: boolean;
}

export interface InvokeAction_invokeAction {
  /**
   * Reasons
   */
  errorReason: string | null;
  result: InvokeAction_invokeAction_result;
}

export interface InvokeAction {
  /**
   * Invoke an action
   */
  invokeAction: InvokeAction_invokeAction;
}

export interface InvokeActionVariables {
  input: MutationInvokeActionInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: Login
// ====================================================

export interface Login_loginByCredential_user_customers {
  /**
   * The id of customer
   */
  id: string;
}

export interface Login_loginByCredential_user {
  customers: (Login_loginByCredential_user_customers | null)[] | null;
}

export interface Login_loginByCredential {
  id: string;
  isAuthenticated: boolean;
  /**
   * The authorization-token for putting in the Authorization header when calling for the api
   */
  authorizationToken: string;
  user: Login_loginByCredential_user | null;
}

export interface Login {
  /**
   * Login an user by credentials
   */
  loginByCredential: Login_loginByCredential;
}

export interface LoginVariables {
  userCredentialInput: UserCredentialInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: LoginAsCustomer
// ====================================================

export interface LoginAsCustomer_loginByCredential {
  /**
   * The authorization-token for putting in the Authorization header when calling for the api
   */
  authorizationToken: string;
}

export interface LoginAsCustomer {
  /**
   * Login an user by credentials
   */
  loginByCredential: LoginAsCustomer_loginByCredential;
}

export interface LoginAsCustomerVariables {
  credential: UserCredentialInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: MediateMaterial
// ====================================================

export interface MediateMaterial_messagingOrdersMaterials_material_proof {
  /**
   * Url to the material
   */
  url: string | null;
}

export interface MediateMaterial_messagingOrdersMaterials_material_galleryImages {
  /**
   * Url to the material
   */
  url: string;
}

export interface MediateMaterial_messagingOrdersMaterials_material_orders_previewPng {
  /**
   * Url to the material
   */
  url: string;
}

export interface MediateMaterial_messagingOrdersMaterials_material_orders {
  previewPng: MediateMaterial_messagingOrdersMaterials_material_orders_previewPng | null;
}

export interface MediateMaterial_messagingOrdersMaterials_material {
  /**
   * Proof
   */
  proof: MediateMaterial_messagingOrdersMaterials_material_proof;
  galleryImages: MediateMaterial_messagingOrdersMaterials_material_galleryImages[];
  /**
   * Material per Order
   */
  orders: MediateMaterial_messagingOrdersMaterials_material_orders[];
}

export interface MediateMaterial_messagingOrdersMaterials {
  /**
   * Mutation is not successful
   */
  isError: boolean;
  /**
   * Reasons
   */
  errorReason: string | null;
  material: MediateMaterial_messagingOrdersMaterials_material;
}

export interface MediateMaterial {
  /**
   * Email endcustomer with materials
   */
  messagingOrdersMaterials: MediateMaterial_messagingOrdersMaterials;
}

export interface MediateMaterialVariables {
  input: MutationMessagingOrdersMaterialsInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: MessagingOrderInfo
// ====================================================

export interface MessagingOrderInfo_messagingOrderInfo {
  /**
   * Mutation is not successful
   */
  isError: boolean;
  /**
   * Reasons
   */
  errorReason: string | null;
  /**
   * Stack
   */
  errorStack: string | null;
}

export interface MessagingOrderInfo {
  /**
   * Email endcustomer with order information
   */
  messagingOrderInfo: MessagingOrderInfo_messagingOrderInfo;
}

export interface MessagingOrderInfoVariables {
  input: OrderCollectionMessagingInfoInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: PrintGalleryImages
// ====================================================

export interface PrintGalleryImages_printGalleryImagesByTags {
  publicUrl: string;
}

export interface PrintGalleryImages {
  /**
   * print images filter by tags
   */
  printGalleryImagesByTags: PrintGalleryImages_printGalleryImagesByTags;
}

export interface PrintGalleryImagesVariables {
  input: GalleryImageAvailableImagesInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: PublishOrderLiveStream
// ====================================================

export interface PublishOrderLiveStream_publishOrders_resultOrders_order_mediaOrder_edit_userInterface {
  editStatusColor: EditUIEditStatusColor;
  editStatusText: string | null;
  publishButtonText: string | null;
  editStatusIcon: EditUIEditStatusIcon;
  isUnPublishButton: boolean;
  unPublishButtonText: string | null;
  isPublishButton: boolean;
  isLockButton: boolean;
  lockButtonText: string | null;
  isUnlockButton: boolean;
  unlockButtonText: string | null;
  isReactivateButton: boolean;
  reactivateButtonText: string | null;
  isSetExhibitOrder: boolean;
  setExhibitOrderText: string | null;
  isUpdateCustomerDocumentContentInitiator: boolean;
  updateCustomerDocumentContentInitiatorText: string | null;
  /**
   * Size limit
   */
  orderEntityNameSingular: string | null;
  /**
   * Size limit
   */
  orderEntityNamePlural: string | null;
  /**
   * Size limit
   */
  orderEntityNameSingularDetermined: string | null;
  /**
   * Size limit
   */
  orderEntityNamePluralDetermined: string | null;
  isCopyFuneralInfoToMemoryRoom: boolean;
  copyFuneralInfoToMemoryRoomText: string | null;
  isNetnoticeEnabled: boolean;
  isNetnoticeSupported: boolean;
}

export interface PublishOrderLiveStream_publishOrders_resultOrders_order_mediaOrder_edit_editModelLiveStream_advanced {
  playerUrl: string | null;
  ingestionKey: string | null;
  ingestionUrl: string | null;
  streamName: string | null;
  RecorderLarix500: string | null;
  RecorderLarix1000: string | null;
  RecorderLarix1500: string | null;
  RecorderLarix2000: string | null;
  GoCoderIOS: string | null;
  GoCoderAndriod: string | null;
  qrRecorderLarix500: string | null;
  qrRecorderLarix1000: string | null;
  qrRecorderLarix1500: string | null;
  qrRecorderLarix2000: string | null;
  qrGoCoderIOS: string | null;
  qrGoCoderAndriod: string | null;
  GoCoderAndroid: string | null;
  __typename: "EditModelLiveStreamAdvanced";
}

export interface PublishOrderLiveStream_publishOrders_resultOrders_order_mediaOrder_edit_editModelLiveStream {
  isStored: boolean;
  isActivated: boolean;
  timeLive: any | null;
  waitImageUrl: string | null;
  ceremonyAgendaUrl: string | null;
  advanced: PublishOrderLiveStream_publishOrders_resultOrders_order_mediaOrder_edit_editModelLiveStream_advanced;
  __typename: "EditModelLiveStream";
}

export interface PublishOrderLiveStream_publishOrders_resultOrders_order_mediaOrder_edit {
  /**
   * Texts for editing the order
   */
  userInterface: PublishOrderLiveStream_publishOrders_resultOrders_order_mediaOrder_edit_userInterface;
  editModelLiveStream: PublishOrderLiveStream_publishOrders_resultOrders_order_mediaOrder_edit_editModelLiveStream | null;
}

export interface PublishOrderLiveStream_publishOrders_resultOrders_order_mediaOrder_status {
  /**
   * Color for vieweing the customer
   */
  color: StatusColors;
  /**
   * ProductStatus enum for the status
   */
  status: OrderStatus;
}

export interface PublishOrderLiveStream_publishOrders_resultOrders_order_mediaOrder_publishConversation {
  /**
   * Order is alread
   */
  isBookingSent: boolean;
}

export interface PublishOrderLiveStream_publishOrders_resultOrders_order_mediaOrder {
  /**
   * information for the editors
   */
  edit: PublishOrderLiveStream_publishOrders_resultOrders_order_mediaOrder_edit;
  /**
   * Locked
   */
  isLocked: boolean;
  /**
   * Status of the order
   */
  status: PublishOrderLiveStream_publishOrders_resultOrders_order_mediaOrder_status;
  /**
   * Publish conversation settings for the media
   */
  publishConversation: PublishOrderLiveStream_publishOrders_resultOrders_order_mediaOrder_publishConversation;
}

export interface PublishOrderLiveStream_publishOrders_resultOrders_order {
  /**
   * The id of the mediaorder
   */
  id: string | null;
  /**
   * mediaOrder properties if that kind of order
   */
  mediaOrder: PublishOrderLiveStream_publishOrders_resultOrders_order_mediaOrder | null;
}

export interface PublishOrderLiveStream_publishOrders_resultOrders {
  success: boolean;
  resultIcon: MessageBoxIcons;
  resultText: string | null;
  order: PublishOrderLiveStream_publishOrders_resultOrders_order;
}

export interface PublishOrderLiveStream_publishOrders {
  /**
   * Mutation is not successful
   */
  isError: boolean;
  isAddedMemoryRoom: boolean;
  isPublishMemoryRoom: boolean;
  isPublishMemoryRoomError: boolean;
  publishMemoryRoomErrorText: string | null;
  resultText: string | null;
  resultIcon: MessageBoxIcons;
  /**
   * Reasons
   */
  errorReason: string | null;
  resultOrders: PublishOrderLiveStream_publishOrders_resultOrders[];
}

export interface PublishOrderLiveStream {
  /**
   * Publish/Send orders
   */
  publishOrders: PublishOrderLiveStream_publishOrders;
}

export interface PublishOrderLiveStreamVariables {
  input: MutationPublishOrdersInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: PublishOrders
// ====================================================

export interface PublishOrders_publishOrders_resultOrders_order_mediaOrder_edit_userInterface {
  editStatusIcon: EditUIEditStatusIcon;
  editStatusText: string | null;
  publishButtonText: string | null;
}

export interface PublishOrders_publishOrders_resultOrders_order_mediaOrder_edit {
  /**
   * Texts for editing the order
   */
  userInterface: PublishOrders_publishOrders_resultOrders_order_mediaOrder_edit_userInterface;
}

export interface PublishOrders_publishOrders_resultOrders_order_mediaOrder_status {
  /**
   * Color for vieweing the customer
   */
  color: StatusColors;
  /**
   * ProductStatus enum for the status
   */
  status: OrderStatus;
}

export interface PublishOrders_publishOrders_resultOrders_order_mediaOrder_publishConversation {
  /**
   * Order is alread
   */
  isBookingSent: boolean;
}

export interface PublishOrders_publishOrders_resultOrders_order_mediaOrder {
  /**
   * information for the editors
   */
  edit: PublishOrders_publishOrders_resultOrders_order_mediaOrder_edit;
  /**
   * Locked
   */
  isLocked: boolean;
  /**
   * Status of the order
   */
  status: PublishOrders_publishOrders_resultOrders_order_mediaOrder_status;
  /**
   * Publish conversation settings for the media
   */
  publishConversation: PublishOrders_publishOrders_resultOrders_order_mediaOrder_publishConversation;
}

export interface PublishOrders_publishOrders_resultOrders_order {
  /**
   * The id of the mediaorder
   */
  id: string | null;
  /**
   * mediaOrder properties if that kind of order
   */
  mediaOrder: PublishOrders_publishOrders_resultOrders_order_mediaOrder | null;
}

export interface PublishOrders_publishOrders_resultOrders {
  success: boolean;
  resultIcon: MessageBoxIcons;
  resultText: string | null;
  order: PublishOrders_publishOrders_resultOrders_order;
}

export interface PublishOrders_publishOrders {
  /**
   * Mutation is not successful
   */
  isError: boolean;
  isAddedMemoryRoom: boolean;
  isPublishMemoryRoom: boolean;
  isPublishMemoryRoomError: boolean;
  publishMemoryRoomErrorText: string | null;
  resultText: string | null;
  resultIcon: MessageBoxIcons;
  /**
   * Reasons
   */
  errorReason: string | null;
  resultOrders: PublishOrders_publishOrders_resultOrders[];
}

export interface PublishOrders {
  /**
   * Publish/Send orders
   */
  publishOrders: PublishOrders_publishOrders;
}

export interface PublishOrdersVariables {
  input: MutationPublishOrdersInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ReActivateUnPublishOrders
// ====================================================

export interface ReActivateUnPublishOrders_reactivateUnPublishOrders_orders_mediaOrder_edit_userInterface {
  editStatusText: string | null;
  editStatusIcon: EditUIEditStatusIcon;
  editStatusColor: EditUIEditStatusColor;
  publishButtonText: string | null;
  isLockButton: boolean;
  lockButtonText: string | null;
  isUnlockButton: boolean;
  unlockButtonText: string | null;
  isReactivateButton: boolean;
  reactivateButtonText: string | null;
}

export interface ReActivateUnPublishOrders_reactivateUnPublishOrders_orders_mediaOrder_edit {
  /**
   * Texts for editing the order
   */
  userInterface: ReActivateUnPublishOrders_reactivateUnPublishOrders_orders_mediaOrder_edit_userInterface;
}

export interface ReActivateUnPublishOrders_reactivateUnPublishOrders_orders_mediaOrder_status {
  /**
   * Color for vieweing the customer
   */
  color: StatusColors;
  /**
   * ProductStatus enum for the status
   */
  status: OrderStatus;
}

export interface ReActivateUnPublishOrders_reactivateUnPublishOrders_orders_mediaOrder_publishConversation {
  /**
   * Order is alread
   */
  isBookingSent: boolean;
}

export interface ReActivateUnPublishOrders_reactivateUnPublishOrders_orders_mediaOrder {
  /**
   * Locked
   */
  isLocked: boolean;
  /**
   * information for the editors
   */
  edit: ReActivateUnPublishOrders_reactivateUnPublishOrders_orders_mediaOrder_edit;
  /**
   * Status of the order
   */
  status: ReActivateUnPublishOrders_reactivateUnPublishOrders_orders_mediaOrder_status;
  /**
   * Publish conversation settings for the media
   */
  publishConversation: ReActivateUnPublishOrders_reactivateUnPublishOrders_orders_mediaOrder_publishConversation;
}

export interface ReActivateUnPublishOrders_reactivateUnPublishOrders_orders {
  /**
   * The id of the mediaorder
   */
  id: string | null;
  /**
   * mediaOrder properties if that kind of order
   */
  mediaOrder: ReActivateUnPublishOrders_reactivateUnPublishOrders_orders_mediaOrder | null;
}

export interface ReActivateUnPublishOrders_reactivateUnPublishOrders {
  /**
   * Mutation is not successful
   */
  isError: boolean;
  /**
   * Reasons
   */
  errorReason: string | null;
  orders: ReActivateUnPublishOrders_reactivateUnPublishOrders_orders[];
}

export interface ReActivateUnPublishOrders {
  /**
   * Reactivate UnPublish orders
   */
  reactivateUnPublishOrders: ReActivateUnPublishOrders_reactivateUnPublishOrders;
}

export interface ReActivateUnPublishOrdersVariables {
  input: MutationReactivateUnPublishOrdersInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SaveGalleryTextItem
// ====================================================

export interface SaveGalleryTextItem {
  /**
   * Save a text
   */
  saveGalleryTextItem: boolean;
}

export interface SaveGalleryTextItemVariables {
  input: SaveGalleryTextItemInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SelectOrderInitiatorView
// ====================================================

export interface SelectOrderInitiatorView_selectOrderInitiatorView_orderInitiatorId {
  /**
   * Id to media
   */
  mediaId: string | null;
  /**
   * Id to documentFormatInitiatorId
   */
  documentFormatInitiatorId: string | null;
  /**
   * Id to custmoerDocumentFormatInitiatorId
   */
  customerDocumentFormatInitiatorId: string | null;
}

export interface SelectOrderInitiatorView_selectOrderInitiatorView_columns {
  name: string;
}

export interface SelectOrderInitiatorView_selectOrderInitiatorView_subViews_orderInitiatorId {
  /**
   * Id to media
   */
  mediaId: string | null;
  /**
   * Id to documentFormatInitiatorId
   */
  documentFormatInitiatorId: string | null;
  /**
   * Id to custmoerDocumentFormatInitiatorId
   */
  customerDocumentFormatInitiatorId: string | null;
}

export interface SelectOrderInitiatorView_selectOrderInitiatorView_subViews_columns {
  name: string;
}

export interface SelectOrderInitiatorView_selectOrderInitiatorView_subViews_options_orderInitiatorId {
  /**
   * Id to media
   */
  mediaId: string | null;
  /**
   * Id to documentFormatInitiatorId
   */
  documentFormatInitiatorId: string | null;
}

export interface SelectOrderInitiatorView_selectOrderInitiatorView_subViews_options_columns {
  text: string;
}

export interface SelectOrderInitiatorView_selectOrderInitiatorView_subViews_options {
  selector: string;
  name: string | null;
  imageUrl: string | null;
  isSelected: boolean;
  isEnabled: boolean;
  hoverText: string | null;
  orderInitiatorId: SelectOrderInitiatorView_selectOrderInitiatorView_subViews_options_orderInitiatorId | null;
  columns: SelectOrderInitiatorView_selectOrderInitiatorView_subViews_options_columns[];
}

export interface SelectOrderInitiatorView_selectOrderInitiatorView_subViews {
  orderInitiatorId: SelectOrderInitiatorView_selectOrderInitiatorView_subViews_orderInitiatorId | null;
  isFilterEnabled: boolean;
  selectType: OrderInitiatorSelectTypes;
  layout: OrderInitiatorSelectLayout;
  view: OrderInitiatorSelectViews;
  header: string;
  isNavigateToEdit: boolean;
  navigateRoute: string | null;
  jsInvokeStatement: string | null;
  columns: SelectOrderInitiatorView_selectOrderInitiatorView_subViews_columns[];
  options: SelectOrderInitiatorView_selectOrderInitiatorView_subViews_options[];
}

export interface SelectOrderInitiatorView_selectOrderInitiatorView_options_orderInitiatorId {
  /**
   * Id to media
   */
  mediaId: string | null;
  /**
   * Id to documentFormatInitiatorId
   */
  documentFormatInitiatorId: string | null;
}

export interface SelectOrderInitiatorView_selectOrderInitiatorView_options_columns {
  text: string;
}

export interface SelectOrderInitiatorView_selectOrderInitiatorView_options_subView_orderInitiatorId {
  /**
   * Id to media
   */
  mediaId: string | null;
  /**
   * Id to documentFormatInitiatorId
   */
  documentFormatInitiatorId: string | null;
  /**
   * Id to custmoerDocumentFormatInitiatorId
   */
  customerDocumentFormatInitiatorId: string | null;
}

export interface SelectOrderInitiatorView_selectOrderInitiatorView_options_subView_columns {
  name: string;
}

export interface SelectOrderInitiatorView_selectOrderInitiatorView_options_subView_options_orderInitiatorId {
  /**
   * Id to media
   */
  mediaId: string | null;
  /**
   * Id to documentFormatInitiatorId
   */
  documentFormatInitiatorId: string | null;
}

export interface SelectOrderInitiatorView_selectOrderInitiatorView_options_subView_options_columns {
  text: string;
}

export interface SelectOrderInitiatorView_selectOrderInitiatorView_options_subView_options {
  selector: string;
  name: string | null;
  imageUrl: string | null;
  isSelected: boolean;
  isEnabled: boolean;
  hoverText: string | null;
  orderInitiatorId: SelectOrderInitiatorView_selectOrderInitiatorView_options_subView_options_orderInitiatorId | null;
  columns: SelectOrderInitiatorView_selectOrderInitiatorView_options_subView_options_columns[];
}

export interface SelectOrderInitiatorView_selectOrderInitiatorView_options_subView {
  orderInitiatorId: SelectOrderInitiatorView_selectOrderInitiatorView_options_subView_orderInitiatorId | null;
  isFilterEnabled: boolean;
  selectType: OrderInitiatorSelectTypes;
  layout: OrderInitiatorSelectLayout;
  view: OrderInitiatorSelectViews;
  header: string;
  isNavigateToEdit: boolean;
  navigateRoute: string | null;
  jsInvokeStatement: string | null;
  columns: SelectOrderInitiatorView_selectOrderInitiatorView_options_subView_columns[];
  options: SelectOrderInitiatorView_selectOrderInitiatorView_options_subView_options[];
}

export interface SelectOrderInitiatorView_selectOrderInitiatorView_options {
  selector: string;
  name: string | null;
  imageUrl: string | null;
  isSelected: boolean;
  isEnabled: boolean;
  hoverText: string | null;
  orderInitiatorId: SelectOrderInitiatorView_selectOrderInitiatorView_options_orderInitiatorId | null;
  columns: SelectOrderInitiatorView_selectOrderInitiatorView_options_columns[];
  subView: SelectOrderInitiatorView_selectOrderInitiatorView_options_subView;
}

export interface SelectOrderInitiatorView_selectOrderInitiatorView_order_client_route_editPageRoute {
  absUrl: string;
  relUrl: string;
  taps5IframeAbsUrl: string | null;
  taps5RelUrl: string;
}

export interface SelectOrderInitiatorView_selectOrderInitiatorView_order_client_route {
  editPageRoute: SelectOrderInitiatorView_selectOrderInitiatorView_order_client_route_editPageRoute;
  editPageUrl: string | null;
}

export interface SelectOrderInitiatorView_selectOrderInitiatorView_order_client {
  route: SelectOrderInitiatorView_selectOrderInitiatorView_order_client_route;
}

export interface SelectOrderInitiatorView_selectOrderInitiatorView_order {
  /**
   * The id of the mediaorder
   */
  id: string | null;
  client: SelectOrderInitiatorView_selectOrderInitiatorView_order_client | null;
}

export interface SelectOrderInitiatorView_selectOrderInitiatorView {
  orderInitiatorId: SelectOrderInitiatorView_selectOrderInitiatorView_orderInitiatorId | null;
  isFilterEnabled: boolean;
  selectType: OrderInitiatorSelectTypes;
  layout: OrderInitiatorSelectLayout;
  view: OrderInitiatorSelectViews;
  header: string;
  isNavigateToEdit: boolean;
  navigateRoute: string | null;
  jsInvokeStatement: string | null;
  columns: SelectOrderInitiatorView_selectOrderInitiatorView_columns[];
  previousSelector: string;
  subViews: SelectOrderInitiatorView_selectOrderInitiatorView_subViews[];
  options: SelectOrderInitiatorView_selectOrderInitiatorView_options[];
  order: SelectOrderInitiatorView_selectOrderInitiatorView_order | null;
}

export interface SelectOrderInitiatorView {
  /**
   * Get order initiator select view (obsolete. Use page instead)
   */
  selectOrderInitiatorView: SelectOrderInitiatorView_selectOrderInitiatorView;
}

export interface SelectOrderInitiatorViewVariables {
  selector: string;
  caseFolderId: string;
  createOrder?: boolean | null;
  documentId?: string | null;
  orderId?: string | null;
  fromOrderId?: string | null;
  documentTypeId?: string | null;
  mediaId?: string | null;
  route?: OrderInitiatorSelectRoutes | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SelectPackage
// ====================================================

export interface SelectPackage_initiateCaseFolderOrders_caseFolder_editTabs_orders_client_route_editPageRoute {
  clientApp: ApplicationClientApps;
  absUrl: string;
  relUrl: string;
  taps5IframeAbsUrl: string | null;
  taps5RelUrl: string;
}

export interface SelectPackage_initiateCaseFolderOrders_caseFolder_editTabs_orders_client_route {
  editPageRoute: SelectPackage_initiateCaseFolderOrders_caseFolder_editTabs_orders_client_route_editPageRoute;
}

export interface SelectPackage_initiateCaseFolderOrders_caseFolder_editTabs_orders_client {
  route: SelectPackage_initiateCaseFolderOrders_caseFolder_editTabs_orders_client_route;
}

export interface SelectPackage_initiateCaseFolderOrders_caseFolder_editTabs_orders_alerting {
  smallText: string | null;
  text: string | null;
  type: OrderAlertingType;
}

export interface SelectPackage_initiateCaseFolderOrders_caseFolder_editTabs_orders_mediaOrder_edit {
  /**
   * Kind of editor using for the order
   */
  editModelType: EditModelTypes;
}

export interface SelectPackage_initiateCaseFolderOrders_caseFolder_editTabs_orders_mediaOrder_documentFormat_documentType {
  /**
   * Id of the documenttype
   */
  id: string;
  /**
   * The name of the documentType
   */
  name: string;
}

export interface SelectPackage_initiateCaseFolderOrders_caseFolder_editTabs_orders_mediaOrder_documentFormat {
  /**
   * Id of the documentformat
   */
  id: string;
  mediaAdaptedName: string;
  documentType: SelectPackage_initiateCaseFolderOrders_caseFolder_editTabs_orders_mediaOrder_documentFormat_documentType;
}

export interface SelectPackage_initiateCaseFolderOrders_caseFolder_editTabs_orders_mediaOrder_media {
  /**
   * The id of the media
   */
  id: string | null;
  /**
   * The friendlyName of the media
   */
  friendlyName: string;
}

export interface SelectPackage_initiateCaseFolderOrders_caseFolder_editTabs_orders_mediaOrder_status {
  /**
   * Color for vieweing the customer
   */
  color: StatusColors;
  /**
   * ProductStatus enum for the status
   */
  status: OrderStatus;
}

export interface SelectPackage_initiateCaseFolderOrders_caseFolder_editTabs_orders_mediaOrder {
  /**
   * Locked
   */
  isLocked: boolean;
  /**
   * information for the editors
   */
  edit: SelectPackage_initiateCaseFolderOrders_caseFolder_editTabs_orders_mediaOrder_edit;
  /**
   * documentformat of the order
   */
  documentFormat: SelectPackage_initiateCaseFolderOrders_caseFolder_editTabs_orders_mediaOrder_documentFormat;
  /**
   * media of the order
   */
  media: SelectPackage_initiateCaseFolderOrders_caseFolder_editTabs_orders_mediaOrder_media;
  /**
   * Status of the order
   */
  status: SelectPackage_initiateCaseFolderOrders_caseFolder_editTabs_orders_mediaOrder_status;
}

export interface SelectPackage_initiateCaseFolderOrders_caseFolder_editTabs_orders_document {
  /**
   * Id of the document
   */
  id: string | null;
}

export interface SelectPackage_initiateCaseFolderOrders_caseFolder_editTabs_orders {
  /**
   * The id of the mediaorder
   */
  id: string | null;
  /**
   * Order is deleted
   */
  isDeleted: boolean;
  /**
   * The Id shown for customer
   */
  customerFriendlyId: string;
  client: SelectPackage_initiateCaseFolderOrders_caseFolder_editTabs_orders_client | null;
  alerting: SelectPackage_initiateCaseFolderOrders_caseFolder_editTabs_orders_alerting;
  /**
   * mediaOrder properties if that kind of order
   */
  mediaOrder: SelectPackage_initiateCaseFolderOrders_caseFolder_editTabs_orders_mediaOrder | null;
  /**
   * document of the order
   */
  document: SelectPackage_initiateCaseFolderOrders_caseFolder_editTabs_orders_document;
}

export interface SelectPackage_initiateCaseFolderOrders_caseFolder_editTabs {
  id: string;
  name: string;
  isSubTabs: boolean;
  orders: SelectPackage_initiateCaseFolderOrders_caseFolder_editTabs_orders[];
}

export interface SelectPackage_initiateCaseFolderOrders_caseFolder {
  /**
   * The id of the caseFolder
   */
  id: string | null;
  editTabs: SelectPackage_initiateCaseFolderOrders_caseFolder_editTabs[];
}

export interface SelectPackage_initiateCaseFolderOrders {
  /**
   * Mutation is not successful
   */
  isError: boolean;
  /**
   * Reasons
   */
  errorReason: string | null;
  caseFolder: SelectPackage_initiateCaseFolderOrders_caseFolder;
}

export interface SelectPackage {
  /**
   * Create order from inititors or initiatorpackages
   */
  initiateCaseFolderOrders: SelectPackage_initiateCaseFolderOrders;
}

export interface SelectPackageVariables {
  input: MutationInitiateCaseFolderOrdersInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SetExhibitOrder
// ====================================================

export interface SetExhibitOrder {
  /**
   * Set what order should it exhibit with
   */
  setExhibitOrder: string;
}

export interface SetExhibitOrderVariables {
  caseFolderId: string;
  orderId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: TouchCaseFolder
// ====================================================

export interface TouchCaseFolder_touchCaseFolder {
  /**
   * Mutation is not successful
   */
  isError: boolean;
}

export interface TouchCaseFolder {
  /**
   * Touch the casefolder
   */
  touchCaseFolder: TouchCaseFolder_touchCaseFolder;
}

export interface TouchCaseFolderVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UndoChange
// ====================================================

export interface UndoChange_updateOrdersContentFromHistory_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_common_marginLeftRightOffset {
  /**
   * Type
   */
  type: RelativeTypes;
  /**
   * Value
   */
  value: number;
}

export interface UndoChange_updateOrdersContentFromHistory_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_common_margin {
  /**
   * Type of unit
   */
  type: UnitTypes;
  /**
   * Left
   */
  left: number | null;
  /**
   * Right
   */
  right: number | null;
  /**
   * Top
   */
  top: number | null;
  /**
   * Bottom
   */
  bottom: number | null;
}

export interface UndoChange_updateOrdersContentFromHistory_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_common_size {
  /**
   * Type
   */
  type: UnitTypes;
  /**
   * Min Width
   */
  minWidth: number | null;
  /**
   * Min Height
   */
  minHeight: number | null;
  /**
   * Max Width
   */
  maxWidth: number | null;
  /**
   * Max Height
   */
  maxHeight: number | null;
  /**
   * Fix Width
   */
  fixedWidth: number | null;
  /**
   * Fix Height
   */
  fixedHeight: number | null;
}

export interface UndoChange_updateOrdersContentFromHistory_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_common {
  /**
   * Alternative BlockStyle
   */
  blockStyleAreaNameId: string | null;
  marginLeftRightOffset: UndoChange_updateOrdersContentFromHistory_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_common_marginLeftRightOffset | null;
  margin: UndoChange_updateOrdersContentFromHistory_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_common_margin | null;
  size: UndoChange_updateOrdersContentFromHistory_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_common_size | null;
}

export interface UndoChange_updateOrdersContentFromHistory_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_image {
  /**
   * Repository of image
   */
  type: EditModelAdvertAreaContentRepository | null;
  /**
   * id for the image
   */
  imageId: string | null;
  /**
   * public id for the image
   */
  imagePublicId: string | null;
  /**
   * public id for the image
   */
  imageThumbnaill100x80Url: string | null;
  /**
   * Height for the image in mm
   */
  heightMM: number | null;
  /**
   * DefaultHeight for the image in mm
   */
  defaultHeightMM: number | null;
  /**
   * IsSizeFree
   */
  isSizeFree: boolean | null;
  /**
   * Upload image content
   */
  uploadData: string | null;
  /**
   * Upload filename
   */
  uploadFilename: string | null;
  /**
   * Bitmap resolution in dpi
   */
  resolution: number | null;
  /**
   * Upload prefered HeightMM of uploaded image
   */
  uploadPreferedHeightMM: number | null;
  /**
   * Repository of image
   */
  repository: EditModelAdvertAreaContentRepository | null;
  /**
   * Upload source image content
   */
  uploadDataSource: string | null;
  /**
   * Comment about the upload
   */
  uploadComment: string | null;
}

export interface UndoChange_updateOrdersContentFromHistory_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_text_textScaleWidthOffset {
  /**
   * Type
   */
  type: RelativeTypes;
  /**
   * Value
   */
  value: number;
}

export interface UndoChange_updateOrdersContentFromHistory_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_text_fontSize {
  /**
   * Type of unit
   */
  type: UnitTypes;
  /**
   * Value
   */
  value: number;
}

export interface UndoChange_updateOrdersContentFromHistory_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_text_lineHeight {
  /**
   * Type of unit
   */
  type: UnitTypes;
  /**
   * Value
   */
  value: number;
}

export interface UndoChange_updateOrdersContentFromHistory_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_text_lineHeightEmpty {
  /**
   * Type of unit
   */
  type: UnitTypes;
  /**
   * Value
   */
  value: number;
}

export interface UndoChange_updateOrdersContentFromHistory_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_text_letterSpacing {
  /**
   * Type of unit
   */
  type: UnitTypes;
  /**
   * Value
   */
  value: number;
}

export interface UndoChange_updateOrdersContentFromHistory_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_text_textScaleWidth {
  /**
   * Type
   */
  type: RelativeTypes;
  /**
   * Value
   */
  value: number;
}

export interface UndoChange_updateOrdersContentFromHistory_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_text {
  /**
   * Font style
   */
  fontStyle: FontStyles | null;
  /**
   * Textscale width offset
   */
  textScaleWidthOffset: UndoChange_updateOrdersContentFromHistory_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_text_textScaleWidthOffset | null;
  /**
   * Font weight
   */
  fontWeight: FontWeights | null;
  /**
   * Text alignment
   */
  textAlignment: TextAlignments | null;
  /**
   * Text formats in xml
   */
  xmlText: string | null;
  /**
   * Is htmleditor readonly
   */
  isXmlTextReadonly: boolean | null;
  /**
   * Fontfamily of the text
   */
  fontFamily: string | null;
  /**
   * Font
   */
  fontSize: UndoChange_updateOrdersContentFromHistory_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_text_fontSize | null;
  /**
   * Line-height
   */
  lineHeight: UndoChange_updateOrdersContentFromHistory_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_text_lineHeight | null;
  /**
   * Line-height for empty rows
   */
  lineHeightEmpty: UndoChange_updateOrdersContentFromHistory_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_text_lineHeightEmpty | null;
  /**
   * Letter-Spacing
   */
  letterSpacing: UndoChange_updateOrdersContentFromHistory_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_text_letterSpacing | null;
  /**
   * Textscale width
   */
  textScaleWidth: UndoChange_updateOrdersContentFromHistory_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_text_textScaleWidth | null;
  /**
   * Text do not wrap when text reach its width
   */
  textNoWrap: boolean | null;
}

export interface UndoChange_updateOrdersContentFromHistory_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_separator {
  /**
   * Type of image
   */
  type: EditModelAdvertAreaContentItemSeparatorTypes;
  imageId: string | null;
  /**
   * Repository of image
   */
  repository: EditModelAdvertAreaContentRepository | null;
  /**
   * Width for the image in mm
   */
  widthMM: number | null;
  /**
   * Height for the image in mm
   */
  heightMM: number | null;
}

export interface UndoChange_updateOrdersContentFromHistory_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_fields {
  /**
   * Name
   */
  name: string;
  /**
   * Header
   */
  header: string | null;
  /**
   * Type
   */
  type: EditModelAdvertAreaContentItemFieldItemTypes | null;
  /**
   * Editor
   */
  editor: EditModelAdvertAreaContentItemFieldItemEditors | null;
  /**
   * Value
   */
  value: string | null;
  __typename: "EditModelAdvertAreaContentItemFieldItem";
}

export interface UndoChange_updateOrdersContentFromHistory_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item {
  /**
   * Common
   */
  common: UndoChange_updateOrdersContentFromHistory_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_common | null;
  /**
   * Value is only for demo purpose
   */
  isDemoValue: boolean | null;
  /**
   * ImageContent
   */
  image: UndoChange_updateOrdersContentFromHistory_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_image | null;
  /**
   * TextContent
   */
  text: UndoChange_updateOrdersContentFromHistory_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_text | null;
  /**
   * HorizontalLineContent
   */
  separator: UndoChange_updateOrdersContentFromHistory_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_separator | null;
  /**
   * Fields
   */
  fields: (UndoChange_updateOrdersContentFromHistory_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_fields | null)[] | null;
}

export interface UndoChange_updateOrdersContentFromHistory_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns {
  item: UndoChange_updateOrdersContentFromHistory_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item;
}

export interface UndoChange_updateOrdersContentFromHistory_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows {
  columns: UndoChange_updateOrdersContentFromHistory_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns[];
}

export interface UndoChange_updateOrdersContentFromHistory_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_value {
  /**
   * Id for the content-value
   */
  id: string | null;
  /**
   * Is content visible
   */
  visible: boolean;
  /**
   * Is it content
   */
  hasContent: boolean;
  /**
   * Is editor visible
   */
  isEditorVisible: boolean;
  /**
   * Id to the template
   */
  templateAreaId: string | null;
  /**
   * Rows
   */
  rows: UndoChange_updateOrdersContentFromHistory_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows[];
}

export interface UndoChange_updateOrdersContentFromHistory_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content {
  value: UndoChange_updateOrdersContentFromHistory_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_value;
}

export interface UndoChange_updateOrdersContentFromHistory_orders_mediaOrder_edit_editModelAdvert_basePage_areas_rectangle {
  /**
   * X coordinate
   */
  x: number;
  /**
   * Y coordinate
   */
  y: number;
  /**
   * Width
   */
  w: number;
  /**
   * Width
   */
  h: number;
}

export interface UndoChange_updateOrdersContentFromHistory_orders_mediaOrder_edit_editModelAdvert_basePage_areas {
  /**
   * Id of the blockeditor
   */
  id: string;
  content: UndoChange_updateOrdersContentFromHistory_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content;
  rectangle: UndoChange_updateOrdersContentFromHistory_orders_mediaOrder_edit_editModelAdvert_basePage_areas_rectangle | null;
}

export interface UndoChange_updateOrdersContentFromHistory_orders_mediaOrder_edit_editModelAdvert_basePage {
  areas: (UndoChange_updateOrdersContentFromHistory_orders_mediaOrder_edit_editModelAdvert_basePage_areas | null)[] | null;
}

export interface UndoChange_updateOrdersContentFromHistory_orders_mediaOrder_edit_editModelAdvert {
  basePage: UndoChange_updateOrdersContentFromHistory_orders_mediaOrder_edit_editModelAdvert_basePage;
}

export interface UndoChange_updateOrdersContentFromHistory_orders_mediaOrder_edit_history {
  /**
   * How many steps it is possible to go back in history
   */
  countStepBack: number;
}

export interface UndoChange_updateOrdersContentFromHistory_orders_mediaOrder_edit {
  editModelAdvert: UndoChange_updateOrdersContentFromHistory_orders_mediaOrder_edit_editModelAdvert | null;
  history: UndoChange_updateOrdersContentFromHistory_orders_mediaOrder_edit_history;
}

export interface UndoChange_updateOrdersContentFromHistory_orders_mediaOrder_preview_material_basePage_size {
  /**
   * Width
   */
  width: number;
  /**
   * Height
   */
  height: number;
}

export interface UndoChange_updateOrdersContentFromHistory_orders_mediaOrder_preview_material_basePage {
  size: UndoChange_updateOrdersContentFromHistory_orders_mediaOrder_preview_material_basePage_size | null;
}

export interface UndoChange_updateOrdersContentFromHistory_orders_mediaOrder_preview_material_pages_png {
  /**
   * Url till pngfile
   */
  url: string;
}

export interface UndoChange_updateOrdersContentFromHistory_orders_mediaOrder_preview_material_pages {
  /**
   * Png material
   */
  png: UndoChange_updateOrdersContentFromHistory_orders_mediaOrder_preview_material_pages_png;
}

export interface UndoChange_updateOrdersContentFromHistory_orders_mediaOrder_preview_material {
  /**
   * base page
   */
  basePage: UndoChange_updateOrdersContentFromHistory_orders_mediaOrder_preview_material_basePage;
  /**
   * pages
   */
  pages: (UndoChange_updateOrdersContentFromHistory_orders_mediaOrder_preview_material_pages | null)[];
}

export interface UndoChange_updateOrdersContentFromHistory_orders_mediaOrder_preview {
  /**
   * material
   */
  material: UndoChange_updateOrdersContentFromHistory_orders_mediaOrder_preview_material;
}

export interface UndoChange_updateOrdersContentFromHistory_orders_mediaOrder {
  /**
   * information for the editors
   */
  edit: UndoChange_updateOrdersContentFromHistory_orders_mediaOrder_edit;
  /**
   * Preview of the order
   */
  preview: UndoChange_updateOrdersContentFromHistory_orders_mediaOrder_preview;
}

export interface UndoChange_updateOrdersContentFromHistory_orders {
  /**
   * The id of the mediaorder
   */
  id: string | null;
  /**
   * mediaOrder properties if that kind of order
   */
  mediaOrder: UndoChange_updateOrdersContentFromHistory_orders_mediaOrder | null;
}

export interface UndoChange_updateOrdersContentFromHistory {
  orders: UndoChange_updateOrdersContentFromHistory_orders[];
}

export interface UndoChange {
  /**
   * Update contents in all orders belongengs to the same document from the history
   */
  updateOrdersContentFromHistory: UndoChange_updateOrdersContentFromHistory;
}

export interface UndoChangeVariables {
  input: MutatonUpdateOrdersContentFromHistoryInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UnPublishOrders
// ====================================================

export interface UnPublishOrders_unPublishOrders_orders_mediaOrder_status {
  /**
   * Color for vieweing the customer
   */
  color: StatusColors;
  /**
   * ProductStatus enum for the status
   */
  status: OrderStatus;
}

export interface UnPublishOrders_unPublishOrders_orders_mediaOrder_publishConversation {
  /**
   * Order is alread
   */
  isBookingSent: boolean;
}

export interface UnPublishOrders_unPublishOrders_orders_mediaOrder {
  /**
   * Locked
   */
  isLocked: boolean;
  /**
   * Status of the order
   */
  status: UnPublishOrders_unPublishOrders_orders_mediaOrder_status;
  /**
   * Publish conversation settings for the media
   */
  publishConversation: UnPublishOrders_unPublishOrders_orders_mediaOrder_publishConversation;
}

export interface UnPublishOrders_unPublishOrders_orders {
  /**
   * The id of the mediaorder
   */
  id: string | null;
  /**
   * mediaOrder properties if that kind of order
   */
  mediaOrder: UnPublishOrders_unPublishOrders_orders_mediaOrder | null;
}

export interface UnPublishOrders_unPublishOrders {
  /**
   * Mutation is not successful
   */
  isError: boolean;
  /**
   * Reasons
   */
  errorReason: string | null;
  orders: UnPublishOrders_unPublishOrders_orders[];
}

export interface UnPublishOrders {
  /**
   * UnPublish orders
   */
  unPublishOrders: UnPublishOrders_unPublishOrders;
}

export interface UnPublishOrdersVariables {
  input: MutationUnPublishOrdersInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateAgencySettings
// ====================================================

export interface UpdateAgencySettings_updateCurrentCustomerSettings_customer_settings {
  /**
   * The emailaddress used when sending info to office
   */
  emailOffice: string | null;
  /**
   * The emailaddress used when sending info to customer
   */
  contactPhone: string | null;
  /**
   * The name of the local memory name
   */
  agencyHomepageName: string | null;
  /**
   * The emailaddress used when sending info to customer
   */
  emailCustomer: string | null;
  /**
   * When order is disapproved or approved a receipt is sending to this address
   */
  emailOrderReceipt: string | null;
  /**
   * The emailaddress send to Memoriz
   */
  emailOrderMemoriz: string | null;
  /**
   * Post city
   */
  postAddressCity: string | null;
  /**
   * Post postcode
   */
  postAddressPostcode: string | null;
  /**
   * Post address
   */
  postAddressStreet: string | null;
  orderPriceEndCustomerRule: CustomerSettingOrderPriceEndCustomerRules | null;
  /**
   * On the proof, we shows suggestion of an thanks advert
   */
  isDefaultProofSuggestionThanksAdvert: boolean | null;
  /**
   * On the proof, the casefee will be add on the other others
   */
  isDefaultProofCaseFeeAddOnOrders: boolean | null;
  /**
   * On the proof, the casefee will be hided (not visible)
   */
  isDefaultProofCaseFeeHide: boolean | null;
  /**
   * On the proof, the casefee will be add on the first order
   */
  isDefaultProofCaseFeeAddOnFirstOrder: boolean | null;
  /**
   * Default parallel publish in familjesidan for printadverts
   */
  isDefaultParallelPublishInFamiljesidan: boolean | null;
  /**
   * Default parallel publish in minnesrummet for printadverts
   */
  isDefaultParallelPublishInMinnesrummet: boolean | null;
  /**
   * Default parallel publish in the agency home page for printadverts
   */
  isDefaultParallelPublishInAgencyHomepage: boolean | null;
  /**
   * Default enable MyPage service
   */
  isDefaultMyPageEnabled: boolean | null;
  /**
   * External memoryroom is exposed
   */
  isMemoryRoomUrlEnabled: boolean | null;
  /**
   * Favorite medias
   */
  favoriteMedias: (string | null)[] | null;
  /**
   * Is allowed to change addon
   */
  isAllowUpdateAddOn: boolean | null;
}

export interface UpdateAgencySettings_updateCurrentCustomerSettings_customer_inheritSettings {
  /**
   * The emailaddress used when sending info to office
   */
  emailOffice: string | null;
  /**
   * The emailaddress used when sending info to customer
   */
  contactPhone: string | null;
  /**
   * The name of the local memory name
   */
  agencyHomepageName: string | null;
  /**
   * The emailaddress used when sending info to customer
   */
  emailCustomer: string | null;
  /**
   * When order is disapproved or approved a receipt is sending to this address
   */
  emailOrderReceipt: string | null;
  /**
   * The emailaddress send to Memoriz
   */
  emailOrderMemoriz: string | null;
  /**
   * Post city
   */
  postAddressCity: string | null;
  /**
   * Post postcode
   */
  postAddressPostcode: string | null;
  /**
   * Post address
   */
  postAddressStreet: string | null;
  orderPriceEndCustomerRule: CustomerSettingOrderPriceEndCustomerRules | null;
  /**
   * On the proof, we shows suggestion of an thanks advert
   */
  isDefaultProofSuggestionThanksAdvert: boolean | null;
  /**
   * On the proof, the casefee will be add on the other others
   */
  isDefaultProofCaseFeeAddOnOrders: boolean | null;
  /**
   * On the proof, the casefee will be hided (not visible)
   */
  isDefaultProofCaseFeeHide: boolean | null;
  /**
   * On the proof, the casefee will be add on the first order
   */
  isDefaultProofCaseFeeAddOnFirstOrder: boolean | null;
  /**
   * Default parallel publish in familjesidan for printadverts
   */
  isDefaultParallelPublishInFamiljesidan: boolean | null;
  /**
   * Default parallel publish in minnesrummet for printadverts
   */
  isDefaultParallelPublishInMinnesrummet: boolean | null;
  /**
   * Default parallel publish in the agency home page for printadverts
   */
  isDefaultParallelPublishInAgencyHomepage: boolean | null;
  /**
   * Default enable MyPage service
   */
  isDefaultMyPageEnabled: boolean | null;
  /**
   * External memoryroom is exposed
   */
  isMemoryRoomUrlEnabled: boolean | null;
  /**
   * Favorite medias
   */
  favoriteMedias: (string | null)[] | null;
  /**
   * Is allowed to change addon
   */
  isAllowUpdateAddOn: boolean | null;
}

export interface UpdateAgencySettings_updateCurrentCustomerSettings_customer_office_settings {
  /**
   * The emailaddress used when sending info to office
   */
  emailOffice: string | null;
  /**
   * The emailaddress used when sending info to customer
   */
  contactPhone: string | null;
  /**
   * The name of the local memory name
   */
  agencyHomepageName: string | null;
  /**
   * The emailaddress used when sending info to customer
   */
  emailCustomer: string | null;
  /**
   * When order is disapproved or approved a receipt is sending to this address
   */
  emailOrderReceipt: string | null;
  /**
   * The emailaddress send to Memoriz
   */
  emailOrderMemoriz: string | null;
  /**
   * Post city
   */
  postAddressCity: string | null;
  /**
   * Post postcode
   */
  postAddressPostcode: string | null;
  /**
   * Post address
   */
  postAddressStreet: string | null;
  orderPriceEndCustomerRule: CustomerSettingOrderPriceEndCustomerRules | null;
  /**
   * On the proof, we shows suggestion of an thanks advert
   */
  isDefaultProofSuggestionThanksAdvert: boolean | null;
  /**
   * On the proof, the casefee will be add on the other others
   */
  isDefaultProofCaseFeeAddOnOrders: boolean | null;
  /**
   * On the proof, the casefee will be hided (not visible)
   */
  isDefaultProofCaseFeeHide: boolean | null;
  /**
   * On the proof, the casefee will be add on the first order
   */
  isDefaultProofCaseFeeAddOnFirstOrder: boolean | null;
  /**
   * Default parallel publish in familjesidan for printadverts
   */
  isDefaultParallelPublishInFamiljesidan: boolean | null;
  /**
   * Default parallel publish in minnesrummet for printadverts
   */
  isDefaultParallelPublishInMinnesrummet: boolean | null;
  /**
   * Default parallel publish in the agency home page for printadverts
   */
  isDefaultParallelPublishInAgencyHomepage: boolean | null;
  /**
   * Default enable MyPage service
   */
  isDefaultMyPageEnabled: boolean | null;
  /**
   * External memoryroom is exposed
   */
  isMemoryRoomUrlEnabled: boolean | null;
  /**
   * Favorite medias
   */
  favoriteMedias: (string | null)[] | null;
  /**
   * Is allowed to change addon
   */
  isAllowUpdateAddOn: boolean | null;
}

export interface UpdateAgencySettings_updateCurrentCustomerSettings_customer_office_inheritSettings {
  /**
   * The emailaddress used when sending info to office
   */
  emailOffice: string | null;
  /**
   * The emailaddress used when sending info to customer
   */
  contactPhone: string | null;
  /**
   * The name of the local memory name
   */
  agencyHomepageName: string | null;
  /**
   * The emailaddress used when sending info to customer
   */
  emailCustomer: string | null;
  /**
   * When order is disapproved or approved a receipt is sending to this address
   */
  emailOrderReceipt: string | null;
  /**
   * The emailaddress send to Memoriz
   */
  emailOrderMemoriz: string | null;
  /**
   * Post city
   */
  postAddressCity: string | null;
  /**
   * Post postcode
   */
  postAddressPostcode: string | null;
  /**
   * Post address
   */
  postAddressStreet: string | null;
  orderPriceEndCustomerRule: CustomerSettingOrderPriceEndCustomerRules | null;
  /**
   * On the proof, we shows suggestion of an thanks advert
   */
  isDefaultProofSuggestionThanksAdvert: boolean | null;
  /**
   * On the proof, the casefee will be add on the other others
   */
  isDefaultProofCaseFeeAddOnOrders: boolean | null;
  /**
   * On the proof, the casefee will be hided (not visible)
   */
  isDefaultProofCaseFeeHide: boolean | null;
  /**
   * On the proof, the casefee will be add on the first order
   */
  isDefaultProofCaseFeeAddOnFirstOrder: boolean | null;
  /**
   * Default parallel publish in familjesidan for printadverts
   */
  isDefaultParallelPublishInFamiljesidan: boolean | null;
  /**
   * Default parallel publish in minnesrummet for printadverts
   */
  isDefaultParallelPublishInMinnesrummet: boolean | null;
  /**
   * Default parallel publish in the agency home page for printadverts
   */
  isDefaultParallelPublishInAgencyHomepage: boolean | null;
  /**
   * Default enable MyPage service
   */
  isDefaultMyPageEnabled: boolean | null;
  /**
   * External memoryroom is exposed
   */
  isMemoryRoomUrlEnabled: boolean | null;
  /**
   * Favorite medias
   */
  favoriteMedias: (string | null)[] | null;
  /**
   * Is allowed to change addon
   */
  isAllowUpdateAddOn: boolean | null;
}

export interface UpdateAgencySettings_updateCurrentCustomerSettings_customer_office_company_settings {
  /**
   * The emailaddress using for invoicing
   */
  emailInvoicing: string | null;
}

export interface UpdateAgencySettings_updateCurrentCustomerSettings_customer_office_company_inheritSettings {
  /**
   * The emailaddress using for invoicing
   */
  emailInvoicing: string | null;
}

export interface UpdateAgencySettings_updateCurrentCustomerSettings_customer_office_company {
  /**
   * The id of company
   */
  id: string;
  settings: UpdateAgencySettings_updateCurrentCustomerSettings_customer_office_company_settings;
  inheritSettings: UpdateAgencySettings_updateCurrentCustomerSettings_customer_office_company_inheritSettings;
}

export interface UpdateAgencySettings_updateCurrentCustomerSettings_customer_office {
  /**
   * The id of office
   */
  id: string;
  settings: UpdateAgencySettings_updateCurrentCustomerSettings_customer_office_settings;
  inheritSettings: UpdateAgencySettings_updateCurrentCustomerSettings_customer_office_inheritSettings;
  company: UpdateAgencySettings_updateCurrentCustomerSettings_customer_office_company;
}

export interface UpdateAgencySettings_updateCurrentCustomerSettings_customer {
  /**
   * The id of customer
   */
  id: string;
  settings: UpdateAgencySettings_updateCurrentCustomerSettings_customer_settings;
  inheritSettings: UpdateAgencySettings_updateCurrentCustomerSettings_customer_inheritSettings;
  office: UpdateAgencySettings_updateCurrentCustomerSettings_customer_office;
}

export interface UpdateAgencySettings_updateCurrentCustomerSettings {
  customer: UpdateAgencySettings_updateCurrentCustomerSettings_customer;
  /**
   * Mutation is not successful
   */
  isError: boolean;
}

export interface UpdateAgencySettings {
  /**
   * Change setting on customer/office/company
   */
  updateCurrentCustomerSettings: UpdateAgencySettings_updateCurrentCustomerSettings;
}

export interface UpdateAgencySettingsVariables {
  input: MutationUpdateCurrentCustomerSettingsInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateAllOrders
// ====================================================

export interface UpdateAllOrders_updateOrdersContentFromOrder_orders_document {
  /**
   * Id of the document
   */
  id: string | null;
}

export interface UpdateAllOrders_updateOrdersContentFromOrder_orders_client {
  mainSystem: SystemFlags;
  isTaps5: boolean;
}

export interface UpdateAllOrders_updateOrdersContentFromOrder_orders_alerting {
  smallText: string | null;
  text: string | null;
  type: OrderAlertingType;
}

export interface UpdateAllOrders_updateOrdersContentFromOrder_orders_mediaOrder_publishTimes {
  /**
   * Date when the order will be published
   */
  time: any;
}

export interface UpdateAllOrders_updateOrdersContentFromOrder_orders_mediaOrder_documentFormat_documentFormatCollection {
  id: string;
  /**
   * The name of the collection
   */
  name: string;
}

export interface UpdateAllOrders_updateOrdersContentFromOrder_orders_mediaOrder_documentFormat_documentType {
  /**
   * Id of the documenttype
   */
  id: string;
  /**
   * The name of the documentType
   */
  name: string;
}

export interface UpdateAllOrders_updateOrdersContentFromOrder_orders_mediaOrder_documentFormat {
  /**
   * Id of the documentformat
   */
  id: string;
  documentFormatCollection: UpdateAllOrders_updateOrdersContentFromOrder_orders_mediaOrder_documentFormat_documentFormatCollection;
  mediaAdaptedName: string;
  documentType: UpdateAllOrders_updateOrdersContentFromOrder_orders_mediaOrder_documentFormat_documentType;
}

export interface UpdateAllOrders_updateOrdersContentFromOrder_orders_mediaOrder_publishConversation {
  /**
   * Validation error texts
   */
  validateErrorTexts: string[];
  /**
   * Message to media
   */
  messageFromMedia: string | null;
  /**
   * Code to verify late publish
   */
  codeLate: string;
  /**
   * Code to media. Used when the ordering is late. Passed bookingstop. This must be verfied before it can be published
   */
  isCodeLate: boolean;
  /**
   * Message to media allowed
   */
  isMessageToMedia: boolean;
  /**
   * Media-System Ordernumber has to be set
   */
  isMediaSystemOrderNumber: boolean;
  /**
   * EndCustomer subribenumber on media can be set
   */
  isMediaCustomerSubriberNumber: boolean;
  /**
   * Status for publish
   */
  publishReadyStatus: MediaOrderPublishhReadyStatus;
}

export interface UpdateAllOrders_updateOrdersContentFromOrder_orders_mediaOrder_publishChannels {
  /**
   * Publish in familjesidan
   */
  inFamiljesidan: boolean;
  /**
   * Publish in minnesrummet
   */
  inMinnesrummet: boolean;
  /**
   * Publish in agency homepage
   */
  inAgency: boolean;
}

export interface UpdateAllOrders_updateOrdersContentFromOrder_orders_mediaOrder_edit_userInterface {
  editStatusColor: EditUIEditStatusColor;
  editStatusText: string | null;
  publishButtonText: string | null;
  editStatusIcon: EditUIEditStatusIcon;
  isUnPublishButton: boolean;
  unPublishButtonText: string | null;
  isPublishButton: boolean;
  isLockButton: boolean;
  lockButtonText: string | null;
  isUnlockButton: boolean;
  unlockButtonText: string | null;
  isReactivateButton: boolean;
  reactivateButtonText: string | null;
  isSetExhibitOrder: boolean;
  setExhibitOrderText: string | null;
  isUpdateCustomerDocumentContentInitiator: boolean;
  updateCustomerDocumentContentInitiatorText: string | null;
  /**
   * Size limit
   */
  orderEntityNameSingular: string | null;
  /**
   * Size limit
   */
  orderEntityNamePlural: string | null;
  /**
   * Size limit
   */
  orderEntityNameSingularDetermined: string | null;
  /**
   * Size limit
   */
  orderEntityNamePluralDetermined: string | null;
  isCopyFuneralInfoToMemoryRoom: boolean;
  copyFuneralInfoToMemoryRoomText: string | null;
  isNetnoticeEnabled: boolean;
  isNetnoticeSupported: boolean;
}

export interface UpdateAllOrders_updateOrdersContentFromOrder_orders_mediaOrder_edit_resources {
  /**
   * Get style css for the specialCharacters
   */
  styleCssSpecialCharacters: string;
}

export interface UpdateAllOrders_updateOrdersContentFromOrder_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_validateErrors {
  error: EditModelAdvertAreaContentValidateErrors;
  validationText: string | null;
}

export interface UpdateAllOrders_updateOrdersContentFromOrder_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_common_marginLeftRightOffset {
  /**
   * Type
   */
  type: RelativeTypes;
  /**
   * Value
   */
  value: number;
}

export interface UpdateAllOrders_updateOrdersContentFromOrder_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_common_margin {
  /**
   * Type of unit
   */
  type: UnitTypes;
  /**
   * Left
   */
  left: number | null;
  /**
   * Right
   */
  right: number | null;
  /**
   * Top
   */
  top: number | null;
  /**
   * Bottom
   */
  bottom: number | null;
}

export interface UpdateAllOrders_updateOrdersContentFromOrder_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_common_size {
  /**
   * Type
   */
  type: UnitTypes;
  /**
   * Min Width
   */
  minWidth: number | null;
  /**
   * Min Height
   */
  minHeight: number | null;
  /**
   * Max Width
   */
  maxWidth: number | null;
  /**
   * Max Height
   */
  maxHeight: number | null;
  /**
   * Fix Width
   */
  fixedWidth: number | null;
  /**
   * Fix Height
   */
  fixedHeight: number | null;
}

export interface UpdateAllOrders_updateOrdersContentFromOrder_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_common {
  /**
   * Alternative BlockStyle
   */
  blockStyleAreaNameId: string | null;
  marginLeftRightOffset: UpdateAllOrders_updateOrdersContentFromOrder_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_common_marginLeftRightOffset | null;
  margin: UpdateAllOrders_updateOrdersContentFromOrder_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_common_margin | null;
  size: UpdateAllOrders_updateOrdersContentFromOrder_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_common_size | null;
}

export interface UpdateAllOrders_updateOrdersContentFromOrder_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_image {
  /**
   * Repository of image
   */
  type: EditModelAdvertAreaContentRepository | null;
  /**
   * id for the image
   */
  imageId: string | null;
  /**
   * public id for the image
   */
  imagePublicId: string | null;
  /**
   * public id for the image
   */
  imageThumbnaill100x80Url: string | null;
  /**
   * Height for the image in mm
   */
  heightMM: number | null;
  /**
   * DefaultHeight for the image in mm
   */
  defaultHeightMM: number | null;
  /**
   * IsSizeFree
   */
  isSizeFree: boolean | null;
  /**
   * Upload image content
   */
  uploadData: string | null;
  /**
   * Upload filename
   */
  uploadFilename: string | null;
  /**
   * Bitmap resolution in dpi
   */
  resolution: number | null;
  /**
   * Upload prefered HeightMM of uploaded image
   */
  uploadPreferedHeightMM: number | null;
  /**
   * Repository of image
   */
  repository: EditModelAdvertAreaContentRepository | null;
  /**
   * Upload source image content
   */
  uploadDataSource: string | null;
  /**
   * Comment about the upload
   */
  uploadComment: string | null;
}

export interface UpdateAllOrders_updateOrdersContentFromOrder_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_text_textScaleWidthOffset {
  /**
   * Type
   */
  type: RelativeTypes;
  /**
   * Value
   */
  value: number;
}

export interface UpdateAllOrders_updateOrdersContentFromOrder_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_text_fontSize {
  /**
   * Type of unit
   */
  type: UnitTypes;
  /**
   * Value
   */
  value: number;
}

export interface UpdateAllOrders_updateOrdersContentFromOrder_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_text_lineHeight {
  /**
   * Type of unit
   */
  type: UnitTypes;
  /**
   * Value
   */
  value: number;
}

export interface UpdateAllOrders_updateOrdersContentFromOrder_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_text_lineHeightEmpty {
  /**
   * Type of unit
   */
  type: UnitTypes;
  /**
   * Value
   */
  value: number;
}

export interface UpdateAllOrders_updateOrdersContentFromOrder_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_text_letterSpacing {
  /**
   * Type of unit
   */
  type: UnitTypes;
  /**
   * Value
   */
  value: number;
}

export interface UpdateAllOrders_updateOrdersContentFromOrder_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_text_textScaleWidth {
  /**
   * Type
   */
  type: RelativeTypes;
  /**
   * Value
   */
  value: number;
}

export interface UpdateAllOrders_updateOrdersContentFromOrder_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_text {
  /**
   * Font style
   */
  fontStyle: FontStyles | null;
  /**
   * Textscale width offset
   */
  textScaleWidthOffset: UpdateAllOrders_updateOrdersContentFromOrder_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_text_textScaleWidthOffset | null;
  /**
   * Font weight
   */
  fontWeight: FontWeights | null;
  /**
   * Text alignment
   */
  textAlignment: TextAlignments | null;
  /**
   * Text formats in xml
   */
  xmlText: string | null;
  /**
   * Is htmleditor readonly
   */
  isXmlTextReadonly: boolean | null;
  /**
   * Fontfamily of the text
   */
  fontFamily: string | null;
  /**
   * Font
   */
  fontSize: UpdateAllOrders_updateOrdersContentFromOrder_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_text_fontSize | null;
  /**
   * Line-height
   */
  lineHeight: UpdateAllOrders_updateOrdersContentFromOrder_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_text_lineHeight | null;
  /**
   * Line-height for empty rows
   */
  lineHeightEmpty: UpdateAllOrders_updateOrdersContentFromOrder_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_text_lineHeightEmpty | null;
  /**
   * Letter-Spacing
   */
  letterSpacing: UpdateAllOrders_updateOrdersContentFromOrder_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_text_letterSpacing | null;
  /**
   * Textscale width
   */
  textScaleWidth: UpdateAllOrders_updateOrdersContentFromOrder_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_text_textScaleWidth | null;
  /**
   * Text do not wrap when text reach its width
   */
  textNoWrap: boolean | null;
}

export interface UpdateAllOrders_updateOrdersContentFromOrder_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_separator {
  /**
   * Type of image
   */
  type: EditModelAdvertAreaContentItemSeparatorTypes;
  imageId: string | null;
  /**
   * Repository of image
   */
  repository: EditModelAdvertAreaContentRepository | null;
  /**
   * Width for the image in mm
   */
  widthMM: number | null;
  /**
   * Height for the image in mm
   */
  heightMM: number | null;
}

export interface UpdateAllOrders_updateOrdersContentFromOrder_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_fields {
  /**
   * Name
   */
  name: string;
  /**
   * Header
   */
  header: string | null;
  /**
   * Type
   */
  type: EditModelAdvertAreaContentItemFieldItemTypes | null;
  /**
   * Editor
   */
  editor: EditModelAdvertAreaContentItemFieldItemEditors | null;
  /**
   * Value
   */
  value: string | null;
  __typename: "EditModelAdvertAreaContentItemFieldItem";
}

export interface UpdateAllOrders_updateOrdersContentFromOrder_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item {
  /**
   * Common
   */
  common: UpdateAllOrders_updateOrdersContentFromOrder_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_common | null;
  /**
   * Value is only for demo purpose
   */
  isDemoValue: boolean | null;
  /**
   * ImageContent
   */
  image: UpdateAllOrders_updateOrdersContentFromOrder_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_image | null;
  /**
   * TextContent
   */
  text: UpdateAllOrders_updateOrdersContentFromOrder_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_text | null;
  /**
   * HorizontalLineContent
   */
  separator: UpdateAllOrders_updateOrdersContentFromOrder_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_separator | null;
  /**
   * Fields
   */
  fields: (UpdateAllOrders_updateOrdersContentFromOrder_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_fields | null)[] | null;
}

export interface UpdateAllOrders_updateOrdersContentFromOrder_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns {
  item: UpdateAllOrders_updateOrdersContentFromOrder_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item;
}

export interface UpdateAllOrders_updateOrdersContentFromOrder_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows {
  columns: UpdateAllOrders_updateOrdersContentFromOrder_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns[];
}

export interface UpdateAllOrders_updateOrdersContentFromOrder_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_value {
  /**
   * Id for the content-value
   */
  id: string | null;
  /**
   * Is content visible
   */
  visible: boolean;
  /**
   * Is it content
   */
  hasContent: boolean;
  /**
   * Is editor visible
   */
  isEditorVisible: boolean;
  /**
   * Id to the template
   */
  templateAreaId: string | null;
  /**
   * Rows
   */
  rows: UpdateAllOrders_updateOrdersContentFromOrder_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows[];
}

export interface UpdateAllOrders_updateOrdersContentFromOrder_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_access {
  add: boolean;
  modify: boolean;
  statusAdd: EditModelAdvertAreaContentEditorAccessStatuses;
}

export interface UpdateAllOrders_updateOrdersContentFromOrder_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_common_allowedMarginLeftRightOffsetInterval {
  /**
   * Type of unit
   */
  type: RelativeTypes;
  /**
   * Max-Value
   */
  maxValue: number;
  /**
   * Min-Value
   */
  minValue: number;
  /**
   * Interval-Value
   */
  intervalValue: number;
}

export interface UpdateAllOrders_updateOrdersContentFromOrder_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_common_blockStyles {
  /**
   * Id
   */
  id: string;
  /**
   * Name
   */
  name: string;
}

export interface UpdateAllOrders_updateOrdersContentFromOrder_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_common {
  /**
   * Margin can adjust margins
   */
  isEnabledAdjustMargins: boolean;
  /**
   * If UpdateOrdersContent is avaiable
   */
  isEnabledUpdateOrdersContent: boolean;
  /**
   * If it is possible to add columns
   */
  isEnabledMultiColumns: boolean;
  /**
   * Enable margin setting
   */
  isMarginLeftRightOffset: boolean;
  /**
   * Allowed margin settings
   */
  allowedMarginLeftRightOffsetInterval: UpdateAllOrders_updateOrdersContentFromOrder_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_common_allowedMarginLeftRightOffsetInterval | null;
  /**
   * Block styles
   */
  blockStyles: UpdateAllOrders_updateOrdersContentFromOrder_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_common_blockStyles[];
}

export interface UpdateAllOrders_updateOrdersContentFromOrder_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_text_allowedFontSizes {
  /**
   * Values
   */
  values: number[];
}

export interface UpdateAllOrders_updateOrdersContentFromOrder_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_text_allowedLineHeights {
  /**
   * Values
   */
  values: number[];
}

export interface UpdateAllOrders_updateOrdersContentFromOrder_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_text_allowedFontSizeInterval {
  /**
   * Type of unit
   */
  type: UnitTypes;
  /**
   * Interval-Value
   */
  intervalValue: number;
}

export interface UpdateAllOrders_updateOrdersContentFromOrder_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_text_allowedLineHeightInterval {
  /**
   * Type of unit
   */
  type: UnitTypes;
  /**
   * Interval-Value
   */
  intervalValue: number;
}

export interface UpdateAllOrders_updateOrdersContentFromOrder_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_text_allowedTextScaleWidthOffsetInterval {
  /**
   * Interval-Value
   */
  intervalValue: number;
  /**
   * Min-Value
   */
  minValue: number;
  /**
   * Max-Value
   */
  maxValue: number;
}

export interface UpdateAllOrders_updateOrdersContentFromOrder_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_text_allowedCharacterGalleries_items {
  /**
   * Id
   */
  id: string;
  /**
   * Name
   */
  name: string;
  /**
   * Title
   */
  title: string;
  /**
   * Preview
   */
  preview: string | null;
  /**
   * Html
   */
  html: string;
}

export interface UpdateAllOrders_updateOrdersContentFromOrder_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_text_allowedCharacterGalleries {
  /**
   * Id
   */
  id: string;
  isAdvancedUser: boolean;
  /**
   * Items
   */
  items: UpdateAllOrders_updateOrdersContentFromOrder_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_text_allowedCharacterGalleries_items[];
}

export interface UpdateAllOrders_updateOrdersContentFromOrder_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_text_allowedParagraphClasses {
  /**
   * Id
   */
  id: string;
  /**
   * Name
   */
  name: string;
}

export interface UpdateAllOrders_updateOrdersContentFromOrder_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_text {
  /**
   * Default textalignment from template
   */
  defaultTextAdjustment: TextAlignments;
  /**
   * Allowed font sizes
   */
  allowedFontSizes: UpdateAllOrders_updateOrdersContentFromOrder_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_text_allowedFontSizes;
  /**
   * Allowed font styles
   */
  allowedFontStyles: FontStyles[];
  /**
   * Allowed font weights
   */
  allowedFontWeights: FontWeights[];
  /**
   * Allowed line height sizes
   */
  allowedLineHeights: UpdateAllOrders_updateOrdersContentFromOrder_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_text_allowedLineHeights;
  /**
   * Allowed font families
   */
  allowedFontFamilies: string[];
  /**
   * Allowed text adjustments
   */
  allowedTextAdjustments: TextAlignments[];
  /**
   * Allowed font size interval
   */
  allowedFontSizeInterval: UpdateAllOrders_updateOrdersContentFromOrder_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_text_allowedFontSizeInterval;
  /**
   * Allowed line height size interval
   */
  allowedLineHeightInterval: UpdateAllOrders_updateOrdersContentFromOrder_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_text_allowedLineHeightInterval;
  /**
   * Enable text scale width offset
   */
  isTextScaleWidthOffset: boolean;
  /**
   * Allowed TextScale width offset interval
   */
  allowedTextScaleWidthOffsetInterval: UpdateAllOrders_updateOrdersContentFromOrder_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_text_allowedTextScaleWidthOffsetInterval | null;
  /**
   * Allowed special characters galleries
   */
  allowedCharacterGalleries: UpdateAllOrders_updateOrdersContentFromOrder_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_text_allowedCharacterGalleries[];
  /**
   * Allowed number of paragraphClass
   */
  allowedParagraphClasses: UpdateAllOrders_updateOrdersContentFromOrder_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_text_allowedParagraphClasses[];
  /**
   * Enable to change font size
   */
  isEnabledFontSize: boolean;
  /**
   * Enable to change font style
   */
  isEnabledFontStyle: boolean;
  /**
   * Enable to change line height
   */
  isEnabledLineHeight: boolean;
  /**
   * Enable to change font family
   */
  isEnabledFontFamily: boolean;
  /**
   * Enable to change font weights
   */
  isEnabledFontWeight: boolean;
  /**
   * Enable to change letter spacing
   */
  isEnabledLetterSpacing: boolean;
  /**
   * Enable to advanced settings for the admin/support
   */
  isEnabledAdvancedSettings: boolean;
  /**
   * Enable to change letter spacing
   */
  isEnabledTextScaleWidthTolerance: boolean;
  /**
   * Enable gallery option
   */
  isEnabledGallery: boolean;
}

export interface UpdateAllOrders_updateOrdersContentFromOrder_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_image_adjustment_brightness {
  /**
   * Interval-Value
   */
  intervalValue: number;
  /**
   * Max-Value
   */
  maxValue: number;
  /**
   * Min-Value
   */
  minValue: number;
  /**
   * Type of unit
   */
  type: RelativeTypes;
}

export interface UpdateAllOrders_updateOrdersContentFromOrder_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_image_adjustment_contrast {
  /**
   * Interval-Value
   */
  intervalValue: number;
  /**
   * Max-Value
   */
  maxValue: number;
  /**
   * Min-Value
   */
  minValue: number;
  /**
   * Type of unit
   */
  type: RelativeTypes;
}

export interface UpdateAllOrders_updateOrdersContentFromOrder_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_image_adjustment_rotate {
  /**
   * Interval-Value
   */
  intervalValue: number;
  /**
   * Max-Value
   */
  maxValue: number;
  /**
   * Min-Value
   */
  minValue: number;
  /**
   * Type of unit
   */
  type: RelativeTypes;
}

export interface UpdateAllOrders_updateOrdersContentFromOrder_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_image_adjustment {
  brightness: UpdateAllOrders_updateOrdersContentFromOrder_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_image_adjustment_brightness;
  contrast: UpdateAllOrders_updateOrdersContentFromOrder_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_image_adjustment_contrast;
  rotate: UpdateAllOrders_updateOrdersContentFromOrder_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_image_adjustment_rotate;
}

export interface UpdateAllOrders_updateOrdersContentFromOrder_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_image_mask {
  imageUrl: string | null;
}

export interface UpdateAllOrders_updateOrdersContentFromOrder_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_image {
  /**
   * Min image height
   */
  minImageHeight: number;
  /**
   * Max image height
   */
  maxImageHeight: number;
  /**
   * Filter by gallery image category
   */
  filterGalleryImageAttributeInternalIds: number[];
  /**
   * Filter by not gallery image category
   */
  filterGalleryImageAttributeNotInternalIds: number[];
  /**
   * Filter by not gallery image category
   */
  galleryImageOption: string;
  /**
   * Filter by not gallery image category
   */
  documentFormatId: string;
  /**
   * Is the user enabled to switch to free size
   */
  isEnabledFreeSize: boolean;
  /**
   * Is the user enabled to upload images
   */
  isEnabledUpload: boolean;
  /**
   * Gallery is enabled
   */
  isEnabledGallery: boolean;
  /**
   * Resize is enabled
   */
  isEnabledResize: boolean;
  adjustment: UpdateAllOrders_updateOrdersContentFromOrder_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_image_adjustment | null;
  mask: UpdateAllOrders_updateOrdersContentFromOrder_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_image_mask | null;
}

export interface UpdateAllOrders_updateOrdersContentFromOrder_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_help {
  html: string | null;
  hoverHtml: string | null;
  supportHtml: string | null;
}

export interface UpdateAllOrders_updateOrdersContentFromOrder_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor {
  /**
   * Type of editor (Image, Text, Separator)
   */
  type: EditModelAdvertAreaContentEditorTypes;
  /**
   * SubType of editor
   */
  behaviour: EditModelAdvertAreaContentEditorBehaviours;
  /**
   * Access properties
   */
  access: UpdateAllOrders_updateOrdersContentFromOrder_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_access;
  /**
   * Common parameters
   */
  common: UpdateAllOrders_updateOrdersContentFromOrder_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_common;
  /**
   * Text parameters
   */
  text: UpdateAllOrders_updateOrdersContentFromOrder_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_text | null;
  /**
   * Image parameters
   */
  image: UpdateAllOrders_updateOrdersContentFromOrder_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_image | null;
  /**
   * Help properties
   */
  help: UpdateAllOrders_updateOrdersContentFromOrder_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_help | null;
}

export interface UpdateAllOrders_updateOrdersContentFromOrder_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content {
  /**
   * Validate errors
   */
  validateErrors: UpdateAllOrders_updateOrdersContentFromOrder_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_validateErrors[];
  value: UpdateAllOrders_updateOrdersContentFromOrder_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_value;
  editor: UpdateAllOrders_updateOrdersContentFromOrder_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor | null;
}

export interface UpdateAllOrders_updateOrdersContentFromOrder_orders_mediaOrder_edit_editModelAdvert_basePage_areas_style_margin {
  /**
   * Top
   */
  top: number | null;
  /**
   * Left
   */
  left: number | null;
  /**
   * Right
   */
  right: number | null;
  /**
   * Bottom
   */
  bottom: number | null;
}

export interface UpdateAllOrders_updateOrdersContentFromOrder_orders_mediaOrder_edit_editModelAdvert_basePage_areas_style_content_size {
  /**
   * Type
   */
  type: UnitTypes;
  /**
   * Min Width
   */
  minWidth: number | null;
  /**
   * Min Height
   */
  minHeight: number | null;
  /**
   * Max Width
   */
  maxWidth: number | null;
  /**
   * Max Height
   */
  maxHeight: number | null;
  /**
   * Fix Width
   */
  fixedWidth: number | null;
  /**
   * Fix Height
   */
  fixedHeight: number | null;
}

export interface UpdateAllOrders_updateOrdersContentFromOrder_orders_mediaOrder_edit_editModelAdvert_basePage_areas_style_content {
  /**
   * Size limit
   */
  size: UpdateAllOrders_updateOrdersContentFromOrder_orders_mediaOrder_edit_editModelAdvert_basePage_areas_style_content_size | null;
}

export interface UpdateAllOrders_updateOrdersContentFromOrder_orders_mediaOrder_edit_editModelAdvert_basePage_areas_style_text_fontSize {
  /**
   * Type of unit
   */
  type: UnitTypes;
  /**
   * Value
   */
  value: number;
}

export interface UpdateAllOrders_updateOrdersContentFromOrder_orders_mediaOrder_edit_editModelAdvert_basePage_areas_style_text_lineHeight {
  /**
   * Type of unit
   */
  type: UnitTypes;
  /**
   * Value
   */
  value: number;
}

export interface UpdateAllOrders_updateOrdersContentFromOrder_orders_mediaOrder_edit_editModelAdvert_basePage_areas_style_text_lineHeightEmpty {
  /**
   * Type of unit
   */
  type: UnitTypes;
  /**
   * Value
   */
  value: number;
}

export interface UpdateAllOrders_updateOrdersContentFromOrder_orders_mediaOrder_edit_editModelAdvert_basePage_areas_style_text_letterSpacing {
  /**
   * Type of unit
   */
  type: UnitTypes;
  /**
   * Value
   */
  value: number;
}

export interface UpdateAllOrders_updateOrdersContentFromOrder_orders_mediaOrder_edit_editModelAdvert_basePage_areas_style_text_textScaleWidthOffset {
  /**
   * Type
   */
  type: RelativeTypes;
  /**
   * Value
   */
  value: number;
}

export interface UpdateAllOrders_updateOrdersContentFromOrder_orders_mediaOrder_edit_editModelAdvert_basePage_areas_style_text_textScaleWidth {
  /**
   * Type
   */
  type: RelativeTypes;
  /**
   * Value
   */
  value: number;
}

export interface UpdateAllOrders_updateOrdersContentFromOrder_orders_mediaOrder_edit_editModelAdvert_basePage_areas_style_text {
  fontFamily: string;
  /**
   * Font-size
   */
  fontSize: UpdateAllOrders_updateOrdersContentFromOrder_orders_mediaOrder_edit_editModelAdvert_basePage_areas_style_text_fontSize | null;
  /**
   * Line height
   */
  lineHeight: UpdateAllOrders_updateOrdersContentFromOrder_orders_mediaOrder_edit_editModelAdvert_basePage_areas_style_text_lineHeight | null;
  /**
   * Line height for empty rows
   */
  lineHeightEmpty: UpdateAllOrders_updateOrdersContentFromOrder_orders_mediaOrder_edit_editModelAdvert_basePage_areas_style_text_lineHeightEmpty | null;
  /**
   * Letter spacing
   */
  letterSpacing: UpdateAllOrders_updateOrdersContentFromOrder_orders_mediaOrder_edit_editModelAdvert_basePage_areas_style_text_letterSpacing | null;
  textScaleWidthOffset: UpdateAllOrders_updateOrdersContentFromOrder_orders_mediaOrder_edit_editModelAdvert_basePage_areas_style_text_textScaleWidthOffset;
  textScaleWidth: UpdateAllOrders_updateOrdersContentFromOrder_orders_mediaOrder_edit_editModelAdvert_basePage_areas_style_text_textScaleWidth;
  fontStyle: FontStyles | null;
  fontWeight: FontWeights | null;
}

export interface UpdateAllOrders_updateOrdersContentFromOrder_orders_mediaOrder_edit_editModelAdvert_basePage_areas_style {
  /**
   * margins
   */
  margin: UpdateAllOrders_updateOrdersContentFromOrder_orders_mediaOrder_edit_editModelAdvert_basePage_areas_style_margin | null;
  content: UpdateAllOrders_updateOrdersContentFromOrder_orders_mediaOrder_edit_editModelAdvert_basePage_areas_style_content;
  /**
   * Text settings
   */
  text: UpdateAllOrders_updateOrdersContentFromOrder_orders_mediaOrder_edit_editModelAdvert_basePage_areas_style_text;
}

export interface UpdateAllOrders_updateOrdersContentFromOrder_orders_mediaOrder_edit_editModelAdvert_basePage_areas_rectangle {
  /**
   * X coordinate
   */
  x: number;
  /**
   * Y coordinate
   */
  y: number;
  /**
   * Width
   */
  w: number;
  /**
   * Width
   */
  h: number;
}

export interface UpdateAllOrders_updateOrdersContentFromOrder_orders_mediaOrder_edit_editModelAdvert_basePage_areas {
  /**
   * Id of the blockeditor
   */
  id: string;
  /**
   * Parameters of the blockeditor
   */
  header: string;
  content: UpdateAllOrders_updateOrdersContentFromOrder_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content;
  style: UpdateAllOrders_updateOrdersContentFromOrder_orders_mediaOrder_edit_editModelAdvert_basePage_areas_style;
  rectangle: UpdateAllOrders_updateOrdersContentFromOrder_orders_mediaOrder_edit_editModelAdvert_basePage_areas_rectangle | null;
}

export interface UpdateAllOrders_updateOrdersContentFromOrder_orders_mediaOrder_edit_editModelAdvert_basePage {
  areas: (UpdateAllOrders_updateOrdersContentFromOrder_orders_mediaOrder_edit_editModelAdvert_basePage_areas | null)[] | null;
}

export interface UpdateAllOrders_updateOrdersContentFromOrder_orders_mediaOrder_edit_editModelAdvert {
  /**
   * If UpdateOrdersContent is avaiable
   */
  isEnabledUpdateOrdersContent: boolean;
  basePage: UpdateAllOrders_updateOrdersContentFromOrder_orders_mediaOrder_edit_editModelAdvert_basePage;
}

export interface UpdateAllOrders_updateOrdersContentFromOrder_orders_mediaOrder_edit_history {
  /**
   * How many steps it is possible to go back in history
   */
  countStepBack: number;
}

export interface UpdateAllOrders_updateOrdersContentFromOrder_orders_mediaOrder_edit {
  /**
   * Texts for editing the order
   */
  userInterface: UpdateAllOrders_updateOrdersContentFromOrder_orders_mediaOrder_edit_userInterface;
  resources: UpdateAllOrders_updateOrdersContentFromOrder_orders_mediaOrder_edit_resources | null;
  editModelAdvert: UpdateAllOrders_updateOrdersContentFromOrder_orders_mediaOrder_edit_editModelAdvert | null;
  history: UpdateAllOrders_updateOrdersContentFromOrder_orders_mediaOrder_edit_history;
}

export interface UpdateAllOrders_updateOrdersContentFromOrder_orders_mediaOrder_preview_material_basePage_size {
  /**
   * Width
   */
  width: number;
  /**
   * Height
   */
  height: number;
}

export interface UpdateAllOrders_updateOrdersContentFromOrder_orders_mediaOrder_preview_material_basePage {
  size: UpdateAllOrders_updateOrdersContentFromOrder_orders_mediaOrder_preview_material_basePage_size | null;
}

export interface UpdateAllOrders_updateOrdersContentFromOrder_orders_mediaOrder_preview_material_pages_png {
  /**
   * Url till pngfile
   */
  url: string;
}

export interface UpdateAllOrders_updateOrdersContentFromOrder_orders_mediaOrder_preview_material_pages {
  /**
   * Png material
   */
  png: UpdateAllOrders_updateOrdersContentFromOrder_orders_mediaOrder_preview_material_pages_png;
}

export interface UpdateAllOrders_updateOrdersContentFromOrder_orders_mediaOrder_preview_material {
  /**
   * base page
   */
  basePage: UpdateAllOrders_updateOrdersContentFromOrder_orders_mediaOrder_preview_material_basePage;
  /**
   * pages
   */
  pages: (UpdateAllOrders_updateOrdersContentFromOrder_orders_mediaOrder_preview_material_pages | null)[];
}

export interface UpdateAllOrders_updateOrdersContentFromOrder_orders_mediaOrder_preview_price_customer {
  /**
   * How much the product is pricing for the scope in the contract
   */
  priceContract: any | null;
  /**
   * How much the product is pricing for the order if it has been override the contract
   */
  priceContractOverride: any | null;
}

export interface UpdateAllOrders_updateOrdersContentFromOrder_orders_mediaOrder_preview_price_endCustomer {
  /**
   * Whats pay for the order and rounded
   */
  pricePayRounded: any | null;
}

export interface UpdateAllOrders_updateOrdersContentFromOrder_orders_mediaOrder_preview_price {
  /**
   * Currency
   */
  currency: string;
  /**
   * Customer view of the price
   */
  customer: UpdateAllOrders_updateOrdersContentFromOrder_orders_mediaOrder_preview_price_customer;
  /**
   * EndCustomer view of the price
   */
  endCustomer: UpdateAllOrders_updateOrdersContentFromOrder_orders_mediaOrder_preview_price_endCustomer;
}

export interface UpdateAllOrders_updateOrdersContentFromOrder_orders_mediaOrder_preview {
  /**
   * material
   */
  material: UpdateAllOrders_updateOrdersContentFromOrder_orders_mediaOrder_preview_material;
  /**
   * price of the order
   */
  price: UpdateAllOrders_updateOrdersContentFromOrder_orders_mediaOrder_preview_price | null;
}

export interface UpdateAllOrders_updateOrdersContentFromOrder_orders_mediaOrder_media {
  /**
   * The id of the media
   */
  id: string | null;
  /**
   * The friendlyName of the media
   */
  friendlyName: string;
}

export interface UpdateAllOrders_updateOrdersContentFromOrder_orders_mediaOrder_status {
  /**
   * Color for vieweing the customer
   */
  color: StatusColors;
  /**
   * ProductStatus enum for the status
   */
  status: OrderStatus;
}

export interface UpdateAllOrders_updateOrdersContentFromOrder_orders_mediaOrder {
  /**
   * Time when last sending the booking to media
   */
  timeBookingStop: any | null;
  /**
   * Locked
   */
  isLocked: boolean;
  /**
   * times when the order will be published
   */
  publishTimes: (UpdateAllOrders_updateOrdersContentFromOrder_orders_mediaOrder_publishTimes | null)[];
  /**
   * documentformat of the order
   */
  documentFormat: UpdateAllOrders_updateOrdersContentFromOrder_orders_mediaOrder_documentFormat;
  /**
   * Publish conversation settings for the media
   */
  publishConversation: UpdateAllOrders_updateOrdersContentFromOrder_orders_mediaOrder_publishConversation;
  /**
   * Parallell publish channels
   */
  publishChannels: UpdateAllOrders_updateOrdersContentFromOrder_orders_mediaOrder_publishChannels;
  /**
   * information for the editors
   */
  edit: UpdateAllOrders_updateOrdersContentFromOrder_orders_mediaOrder_edit;
  /**
   * Preview of the order
   */
  preview: UpdateAllOrders_updateOrdersContentFromOrder_orders_mediaOrder_preview;
  /**
   * media of the order
   */
  media: UpdateAllOrders_updateOrdersContentFromOrder_orders_mediaOrder_media;
  /**
   * Status of the order
   */
  status: UpdateAllOrders_updateOrdersContentFromOrder_orders_mediaOrder_status;
}

export interface UpdateAllOrders_updateOrdersContentFromOrder_orders {
  /**
   * The id of the mediaorder
   */
  id: string | null;
  /**
   * The Id shown for customer
   */
  customerFriendlyId: string;
  /**
   * Order is deleted
   */
  isDeleted: boolean;
  /**
   * document of the order
   */
  document: UpdateAllOrders_updateOrdersContentFromOrder_orders_document;
  client: UpdateAllOrders_updateOrdersContentFromOrder_orders_client | null;
  alerting: UpdateAllOrders_updateOrdersContentFromOrder_orders_alerting;
  /**
   * mediaOrder properties if that kind of order
   */
  mediaOrder: UpdateAllOrders_updateOrdersContentFromOrder_orders_mediaOrder | null;
}

export interface UpdateAllOrders_updateOrdersContentFromOrder {
  orders: UpdateAllOrders_updateOrdersContentFromOrder_orders[];
}

export interface UpdateAllOrders {
  /**
   * Update contents in all orders belongengs to the same document from an order
   */
  updateOrdersContentFromOrder: UpdateAllOrders_updateOrdersContentFromOrder;
}

export interface UpdateAllOrdersVariables {
  input: MutationUpdateOrdersContentFromOrderInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateAllOrdersByField
// ====================================================

export interface UpdateAllOrdersByField_updateOrdersContent_orders_document {
  /**
   * Id of the document
   */
  id: string | null;
}

export interface UpdateAllOrdersByField_updateOrdersContent_orders_client {
  mainSystem: SystemFlags;
  isTaps5: boolean;
}

export interface UpdateAllOrdersByField_updateOrdersContent_orders_alerting {
  smallText: string | null;
  text: string | null;
  type: OrderAlertingType;
}

export interface UpdateAllOrdersByField_updateOrdersContent_orders_mediaOrder_publishTimes {
  /**
   * Date when the order will be published
   */
  time: any;
}

export interface UpdateAllOrdersByField_updateOrdersContent_orders_mediaOrder_documentFormat_documentFormatCollection {
  id: string;
  /**
   * The name of the collection
   */
  name: string;
}

export interface UpdateAllOrdersByField_updateOrdersContent_orders_mediaOrder_documentFormat_documentType {
  /**
   * Id of the documenttype
   */
  id: string;
  /**
   * The name of the documentType
   */
  name: string;
}

export interface UpdateAllOrdersByField_updateOrdersContent_orders_mediaOrder_documentFormat {
  /**
   * Id of the documentformat
   */
  id: string;
  documentFormatCollection: UpdateAllOrdersByField_updateOrdersContent_orders_mediaOrder_documentFormat_documentFormatCollection;
  mediaAdaptedName: string;
  documentType: UpdateAllOrdersByField_updateOrdersContent_orders_mediaOrder_documentFormat_documentType;
}

export interface UpdateAllOrdersByField_updateOrdersContent_orders_mediaOrder_publishConversation {
  /**
   * Validation error texts
   */
  validateErrorTexts: string[];
  /**
   * Message to media
   */
  messageFromMedia: string | null;
  /**
   * Code to verify late publish
   */
  codeLate: string;
  /**
   * Code to media. Used when the ordering is late. Passed bookingstop. This must be verfied before it can be published
   */
  isCodeLate: boolean;
  /**
   * Message to media allowed
   */
  isMessageToMedia: boolean;
  /**
   * Media-System Ordernumber has to be set
   */
  isMediaSystemOrderNumber: boolean;
  /**
   * EndCustomer subribenumber on media can be set
   */
  isMediaCustomerSubriberNumber: boolean;
  /**
   * Status for publish
   */
  publishReadyStatus: MediaOrderPublishhReadyStatus;
}

export interface UpdateAllOrdersByField_updateOrdersContent_orders_mediaOrder_publishChannels {
  /**
   * Publish in familjesidan
   */
  inFamiljesidan: boolean;
  /**
   * Publish in minnesrummet
   */
  inMinnesrummet: boolean;
  /**
   * Publish in agency homepage
   */
  inAgency: boolean;
}

export interface UpdateAllOrdersByField_updateOrdersContent_orders_mediaOrder_edit_userInterface {
  editStatusColor: EditUIEditStatusColor;
  editStatusText: string | null;
  publishButtonText: string | null;
  editStatusIcon: EditUIEditStatusIcon;
  isUnPublishButton: boolean;
  unPublishButtonText: string | null;
  isPublishButton: boolean;
  isLockButton: boolean;
  lockButtonText: string | null;
  isUnlockButton: boolean;
  unlockButtonText: string | null;
  isReactivateButton: boolean;
  reactivateButtonText: string | null;
  isSetExhibitOrder: boolean;
  setExhibitOrderText: string | null;
  isUpdateCustomerDocumentContentInitiator: boolean;
  updateCustomerDocumentContentInitiatorText: string | null;
  /**
   * Size limit
   */
  orderEntityNameSingular: string | null;
  /**
   * Size limit
   */
  orderEntityNamePlural: string | null;
  /**
   * Size limit
   */
  orderEntityNameSingularDetermined: string | null;
  /**
   * Size limit
   */
  orderEntityNamePluralDetermined: string | null;
  isCopyFuneralInfoToMemoryRoom: boolean;
  copyFuneralInfoToMemoryRoomText: string | null;
  isNetnoticeEnabled: boolean;
  isNetnoticeSupported: boolean;
}

export interface UpdateAllOrdersByField_updateOrdersContent_orders_mediaOrder_edit_resources {
  /**
   * Get style css for the specialCharacters
   */
  styleCssSpecialCharacters: string;
}

export interface UpdateAllOrdersByField_updateOrdersContent_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_validateErrors {
  error: EditModelAdvertAreaContentValidateErrors;
  validationText: string | null;
}

export interface UpdateAllOrdersByField_updateOrdersContent_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_common_marginLeftRightOffset {
  /**
   * Type
   */
  type: RelativeTypes;
  /**
   * Value
   */
  value: number;
}

export interface UpdateAllOrdersByField_updateOrdersContent_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_common_margin {
  /**
   * Type of unit
   */
  type: UnitTypes;
  /**
   * Left
   */
  left: number | null;
  /**
   * Right
   */
  right: number | null;
  /**
   * Top
   */
  top: number | null;
  /**
   * Bottom
   */
  bottom: number | null;
}

export interface UpdateAllOrdersByField_updateOrdersContent_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_common_size {
  /**
   * Type
   */
  type: UnitTypes;
  /**
   * Min Width
   */
  minWidth: number | null;
  /**
   * Min Height
   */
  minHeight: number | null;
  /**
   * Max Width
   */
  maxWidth: number | null;
  /**
   * Max Height
   */
  maxHeight: number | null;
  /**
   * Fix Width
   */
  fixedWidth: number | null;
  /**
   * Fix Height
   */
  fixedHeight: number | null;
}

export interface UpdateAllOrdersByField_updateOrdersContent_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_common {
  /**
   * Alternative BlockStyle
   */
  blockStyleAreaNameId: string | null;
  marginLeftRightOffset: UpdateAllOrdersByField_updateOrdersContent_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_common_marginLeftRightOffset | null;
  margin: UpdateAllOrdersByField_updateOrdersContent_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_common_margin | null;
  size: UpdateAllOrdersByField_updateOrdersContent_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_common_size | null;
}

export interface UpdateAllOrdersByField_updateOrdersContent_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_image {
  /**
   * Repository of image
   */
  type: EditModelAdvertAreaContentRepository | null;
  /**
   * id for the image
   */
  imageId: string | null;
  /**
   * public id for the image
   */
  imagePublicId: string | null;
  /**
   * public id for the image
   */
  imageThumbnaill100x80Url: string | null;
  /**
   * Height for the image in mm
   */
  heightMM: number | null;
  /**
   * DefaultHeight for the image in mm
   */
  defaultHeightMM: number | null;
  /**
   * IsSizeFree
   */
  isSizeFree: boolean | null;
  /**
   * Upload image content
   */
  uploadData: string | null;
  /**
   * Upload filename
   */
  uploadFilename: string | null;
  /**
   * Bitmap resolution in dpi
   */
  resolution: number | null;
  /**
   * Upload prefered HeightMM of uploaded image
   */
  uploadPreferedHeightMM: number | null;
  /**
   * Repository of image
   */
  repository: EditModelAdvertAreaContentRepository | null;
  /**
   * Upload source image content
   */
  uploadDataSource: string | null;
  /**
   * Comment about the upload
   */
  uploadComment: string | null;
}

export interface UpdateAllOrdersByField_updateOrdersContent_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_text_textScaleWidthOffset {
  /**
   * Type
   */
  type: RelativeTypes;
  /**
   * Value
   */
  value: number;
}

export interface UpdateAllOrdersByField_updateOrdersContent_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_text_fontSize {
  /**
   * Type of unit
   */
  type: UnitTypes;
  /**
   * Value
   */
  value: number;
}

export interface UpdateAllOrdersByField_updateOrdersContent_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_text_lineHeight {
  /**
   * Type of unit
   */
  type: UnitTypes;
  /**
   * Value
   */
  value: number;
}

export interface UpdateAllOrdersByField_updateOrdersContent_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_text_lineHeightEmpty {
  /**
   * Type of unit
   */
  type: UnitTypes;
  /**
   * Value
   */
  value: number;
}

export interface UpdateAllOrdersByField_updateOrdersContent_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_text_letterSpacing {
  /**
   * Type of unit
   */
  type: UnitTypes;
  /**
   * Value
   */
  value: number;
}

export interface UpdateAllOrdersByField_updateOrdersContent_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_text_textScaleWidth {
  /**
   * Type
   */
  type: RelativeTypes;
  /**
   * Value
   */
  value: number;
}

export interface UpdateAllOrdersByField_updateOrdersContent_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_text {
  /**
   * Font style
   */
  fontStyle: FontStyles | null;
  /**
   * Textscale width offset
   */
  textScaleWidthOffset: UpdateAllOrdersByField_updateOrdersContent_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_text_textScaleWidthOffset | null;
  /**
   * Font weight
   */
  fontWeight: FontWeights | null;
  /**
   * Text alignment
   */
  textAlignment: TextAlignments | null;
  /**
   * Text formats in xml
   */
  xmlText: string | null;
  /**
   * Is htmleditor readonly
   */
  isXmlTextReadonly: boolean | null;
  /**
   * Fontfamily of the text
   */
  fontFamily: string | null;
  /**
   * Font
   */
  fontSize: UpdateAllOrdersByField_updateOrdersContent_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_text_fontSize | null;
  /**
   * Line-height
   */
  lineHeight: UpdateAllOrdersByField_updateOrdersContent_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_text_lineHeight | null;
  /**
   * Line-height for empty rows
   */
  lineHeightEmpty: UpdateAllOrdersByField_updateOrdersContent_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_text_lineHeightEmpty | null;
  /**
   * Letter-Spacing
   */
  letterSpacing: UpdateAllOrdersByField_updateOrdersContent_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_text_letterSpacing | null;
  /**
   * Textscale width
   */
  textScaleWidth: UpdateAllOrdersByField_updateOrdersContent_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_text_textScaleWidth | null;
  /**
   * Text do not wrap when text reach its width
   */
  textNoWrap: boolean | null;
}

export interface UpdateAllOrdersByField_updateOrdersContent_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_separator {
  /**
   * Type of image
   */
  type: EditModelAdvertAreaContentItemSeparatorTypes;
  imageId: string | null;
  /**
   * Repository of image
   */
  repository: EditModelAdvertAreaContentRepository | null;
  /**
   * Width for the image in mm
   */
  widthMM: number | null;
  /**
   * Height for the image in mm
   */
  heightMM: number | null;
}

export interface UpdateAllOrdersByField_updateOrdersContent_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_fields {
  /**
   * Name
   */
  name: string;
  /**
   * Header
   */
  header: string | null;
  /**
   * Type
   */
  type: EditModelAdvertAreaContentItemFieldItemTypes | null;
  /**
   * Editor
   */
  editor: EditModelAdvertAreaContentItemFieldItemEditors | null;
  /**
   * Value
   */
  value: string | null;
  __typename: "EditModelAdvertAreaContentItemFieldItem";
}

export interface UpdateAllOrdersByField_updateOrdersContent_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item {
  /**
   * Common
   */
  common: UpdateAllOrdersByField_updateOrdersContent_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_common | null;
  /**
   * Value is only for demo purpose
   */
  isDemoValue: boolean | null;
  /**
   * ImageContent
   */
  image: UpdateAllOrdersByField_updateOrdersContent_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_image | null;
  /**
   * TextContent
   */
  text: UpdateAllOrdersByField_updateOrdersContent_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_text | null;
  /**
   * HorizontalLineContent
   */
  separator: UpdateAllOrdersByField_updateOrdersContent_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_separator | null;
  /**
   * Fields
   */
  fields: (UpdateAllOrdersByField_updateOrdersContent_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_fields | null)[] | null;
}

export interface UpdateAllOrdersByField_updateOrdersContent_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns {
  item: UpdateAllOrdersByField_updateOrdersContent_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item;
}

export interface UpdateAllOrdersByField_updateOrdersContent_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows {
  columns: UpdateAllOrdersByField_updateOrdersContent_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns[];
}

export interface UpdateAllOrdersByField_updateOrdersContent_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_value {
  /**
   * Id for the content-value
   */
  id: string | null;
  /**
   * Is content visible
   */
  visible: boolean;
  /**
   * Is it content
   */
  hasContent: boolean;
  /**
   * Is editor visible
   */
  isEditorVisible: boolean;
  /**
   * Id to the template
   */
  templateAreaId: string | null;
  /**
   * Rows
   */
  rows: UpdateAllOrdersByField_updateOrdersContent_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows[];
}

export interface UpdateAllOrdersByField_updateOrdersContent_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_access {
  add: boolean;
  modify: boolean;
  statusAdd: EditModelAdvertAreaContentEditorAccessStatuses;
}

export interface UpdateAllOrdersByField_updateOrdersContent_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_common_allowedMarginLeftRightOffsetInterval {
  /**
   * Type of unit
   */
  type: RelativeTypes;
  /**
   * Max-Value
   */
  maxValue: number;
  /**
   * Min-Value
   */
  minValue: number;
  /**
   * Interval-Value
   */
  intervalValue: number;
}

export interface UpdateAllOrdersByField_updateOrdersContent_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_common_blockStyles {
  /**
   * Id
   */
  id: string;
  /**
   * Name
   */
  name: string;
}

export interface UpdateAllOrdersByField_updateOrdersContent_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_common {
  /**
   * Margin can adjust margins
   */
  isEnabledAdjustMargins: boolean;
  /**
   * If UpdateOrdersContent is avaiable
   */
  isEnabledUpdateOrdersContent: boolean;
  /**
   * If it is possible to add columns
   */
  isEnabledMultiColumns: boolean;
  /**
   * Enable margin setting
   */
  isMarginLeftRightOffset: boolean;
  /**
   * Allowed margin settings
   */
  allowedMarginLeftRightOffsetInterval: UpdateAllOrdersByField_updateOrdersContent_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_common_allowedMarginLeftRightOffsetInterval | null;
  /**
   * Block styles
   */
  blockStyles: UpdateAllOrdersByField_updateOrdersContent_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_common_blockStyles[];
}

export interface UpdateAllOrdersByField_updateOrdersContent_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_text_allowedFontSizes {
  /**
   * Values
   */
  values: number[];
}

export interface UpdateAllOrdersByField_updateOrdersContent_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_text_allowedLineHeights {
  /**
   * Values
   */
  values: number[];
}

export interface UpdateAllOrdersByField_updateOrdersContent_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_text_allowedFontSizeInterval {
  /**
   * Type of unit
   */
  type: UnitTypes;
  /**
   * Interval-Value
   */
  intervalValue: number;
}

export interface UpdateAllOrdersByField_updateOrdersContent_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_text_allowedLineHeightInterval {
  /**
   * Type of unit
   */
  type: UnitTypes;
  /**
   * Interval-Value
   */
  intervalValue: number;
}

export interface UpdateAllOrdersByField_updateOrdersContent_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_text_allowedTextScaleWidthOffsetInterval {
  /**
   * Interval-Value
   */
  intervalValue: number;
  /**
   * Min-Value
   */
  minValue: number;
  /**
   * Max-Value
   */
  maxValue: number;
}

export interface UpdateAllOrdersByField_updateOrdersContent_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_text_allowedCharacterGalleries_items {
  /**
   * Id
   */
  id: string;
  /**
   * Name
   */
  name: string;
  /**
   * Title
   */
  title: string;
  /**
   * Preview
   */
  preview: string | null;
  /**
   * Html
   */
  html: string;
}

export interface UpdateAllOrdersByField_updateOrdersContent_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_text_allowedCharacterGalleries {
  /**
   * Id
   */
  id: string;
  isAdvancedUser: boolean;
  /**
   * Items
   */
  items: UpdateAllOrdersByField_updateOrdersContent_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_text_allowedCharacterGalleries_items[];
}

export interface UpdateAllOrdersByField_updateOrdersContent_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_text_allowedParagraphClasses {
  /**
   * Id
   */
  id: string;
  /**
   * Name
   */
  name: string;
}

export interface UpdateAllOrdersByField_updateOrdersContent_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_text {
  /**
   * Default textalignment from template
   */
  defaultTextAdjustment: TextAlignments;
  /**
   * Allowed font sizes
   */
  allowedFontSizes: UpdateAllOrdersByField_updateOrdersContent_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_text_allowedFontSizes;
  /**
   * Allowed font styles
   */
  allowedFontStyles: FontStyles[];
  /**
   * Allowed font weights
   */
  allowedFontWeights: FontWeights[];
  /**
   * Allowed line height sizes
   */
  allowedLineHeights: UpdateAllOrdersByField_updateOrdersContent_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_text_allowedLineHeights;
  /**
   * Allowed font families
   */
  allowedFontFamilies: string[];
  /**
   * Allowed text adjustments
   */
  allowedTextAdjustments: TextAlignments[];
  /**
   * Allowed font size interval
   */
  allowedFontSizeInterval: UpdateAllOrdersByField_updateOrdersContent_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_text_allowedFontSizeInterval;
  /**
   * Allowed line height size interval
   */
  allowedLineHeightInterval: UpdateAllOrdersByField_updateOrdersContent_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_text_allowedLineHeightInterval;
  /**
   * Enable text scale width offset
   */
  isTextScaleWidthOffset: boolean;
  /**
   * Allowed TextScale width offset interval
   */
  allowedTextScaleWidthOffsetInterval: UpdateAllOrdersByField_updateOrdersContent_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_text_allowedTextScaleWidthOffsetInterval | null;
  /**
   * Allowed special characters galleries
   */
  allowedCharacterGalleries: UpdateAllOrdersByField_updateOrdersContent_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_text_allowedCharacterGalleries[];
  /**
   * Allowed number of paragraphClass
   */
  allowedParagraphClasses: UpdateAllOrdersByField_updateOrdersContent_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_text_allowedParagraphClasses[];
  /**
   * Enable to change font size
   */
  isEnabledFontSize: boolean;
  /**
   * Enable to change font style
   */
  isEnabledFontStyle: boolean;
  /**
   * Enable to change line height
   */
  isEnabledLineHeight: boolean;
  /**
   * Enable to change font family
   */
  isEnabledFontFamily: boolean;
  /**
   * Enable to change font weights
   */
  isEnabledFontWeight: boolean;
  /**
   * Enable to change letter spacing
   */
  isEnabledLetterSpacing: boolean;
  /**
   * Enable to advanced settings for the admin/support
   */
  isEnabledAdvancedSettings: boolean;
  /**
   * Enable to change letter spacing
   */
  isEnabledTextScaleWidthTolerance: boolean;
  /**
   * Enable gallery option
   */
  isEnabledGallery: boolean;
}

export interface UpdateAllOrdersByField_updateOrdersContent_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_image_adjustment_brightness {
  /**
   * Interval-Value
   */
  intervalValue: number;
  /**
   * Max-Value
   */
  maxValue: number;
  /**
   * Min-Value
   */
  minValue: number;
  /**
   * Type of unit
   */
  type: RelativeTypes;
}

export interface UpdateAllOrdersByField_updateOrdersContent_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_image_adjustment_contrast {
  /**
   * Interval-Value
   */
  intervalValue: number;
  /**
   * Max-Value
   */
  maxValue: number;
  /**
   * Min-Value
   */
  minValue: number;
  /**
   * Type of unit
   */
  type: RelativeTypes;
}

export interface UpdateAllOrdersByField_updateOrdersContent_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_image_adjustment_rotate {
  /**
   * Interval-Value
   */
  intervalValue: number;
  /**
   * Max-Value
   */
  maxValue: number;
  /**
   * Min-Value
   */
  minValue: number;
  /**
   * Type of unit
   */
  type: RelativeTypes;
}

export interface UpdateAllOrdersByField_updateOrdersContent_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_image_adjustment {
  brightness: UpdateAllOrdersByField_updateOrdersContent_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_image_adjustment_brightness;
  contrast: UpdateAllOrdersByField_updateOrdersContent_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_image_adjustment_contrast;
  rotate: UpdateAllOrdersByField_updateOrdersContent_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_image_adjustment_rotate;
}

export interface UpdateAllOrdersByField_updateOrdersContent_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_image_mask {
  imageUrl: string | null;
}

export interface UpdateAllOrdersByField_updateOrdersContent_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_image {
  /**
   * Min image height
   */
  minImageHeight: number;
  /**
   * Max image height
   */
  maxImageHeight: number;
  /**
   * Filter by gallery image category
   */
  filterGalleryImageAttributeInternalIds: number[];
  /**
   * Filter by not gallery image category
   */
  filterGalleryImageAttributeNotInternalIds: number[];
  /**
   * Filter by not gallery image category
   */
  galleryImageOption: string;
  /**
   * Filter by not gallery image category
   */
  documentFormatId: string;
  /**
   * Is the user enabled to switch to free size
   */
  isEnabledFreeSize: boolean;
  /**
   * Is the user enabled to upload images
   */
  isEnabledUpload: boolean;
  /**
   * Gallery is enabled
   */
  isEnabledGallery: boolean;
  /**
   * Resize is enabled
   */
  isEnabledResize: boolean;
  adjustment: UpdateAllOrdersByField_updateOrdersContent_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_image_adjustment | null;
  mask: UpdateAllOrdersByField_updateOrdersContent_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_image_mask | null;
}

export interface UpdateAllOrdersByField_updateOrdersContent_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_help {
  html: string | null;
  hoverHtml: string | null;
  supportHtml: string | null;
}

export interface UpdateAllOrdersByField_updateOrdersContent_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor {
  /**
   * Type of editor (Image, Text, Separator)
   */
  type: EditModelAdvertAreaContentEditorTypes;
  /**
   * SubType of editor
   */
  behaviour: EditModelAdvertAreaContentEditorBehaviours;
  /**
   * Access properties
   */
  access: UpdateAllOrdersByField_updateOrdersContent_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_access;
  /**
   * Common parameters
   */
  common: UpdateAllOrdersByField_updateOrdersContent_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_common;
  /**
   * Text parameters
   */
  text: UpdateAllOrdersByField_updateOrdersContent_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_text | null;
  /**
   * Image parameters
   */
  image: UpdateAllOrdersByField_updateOrdersContent_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_image | null;
  /**
   * Help properties
   */
  help: UpdateAllOrdersByField_updateOrdersContent_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_help | null;
}

export interface UpdateAllOrdersByField_updateOrdersContent_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content {
  /**
   * Validate errors
   */
  validateErrors: UpdateAllOrdersByField_updateOrdersContent_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_validateErrors[];
  value: UpdateAllOrdersByField_updateOrdersContent_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_value;
  editor: UpdateAllOrdersByField_updateOrdersContent_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor | null;
}

export interface UpdateAllOrdersByField_updateOrdersContent_orders_mediaOrder_edit_editModelAdvert_basePage_areas_style_margin {
  /**
   * Top
   */
  top: number | null;
  /**
   * Left
   */
  left: number | null;
  /**
   * Right
   */
  right: number | null;
  /**
   * Bottom
   */
  bottom: number | null;
}

export interface UpdateAllOrdersByField_updateOrdersContent_orders_mediaOrder_edit_editModelAdvert_basePage_areas_style_content_size {
  /**
   * Type
   */
  type: UnitTypes;
  /**
   * Min Width
   */
  minWidth: number | null;
  /**
   * Min Height
   */
  minHeight: number | null;
  /**
   * Max Width
   */
  maxWidth: number | null;
  /**
   * Max Height
   */
  maxHeight: number | null;
  /**
   * Fix Width
   */
  fixedWidth: number | null;
  /**
   * Fix Height
   */
  fixedHeight: number | null;
}

export interface UpdateAllOrdersByField_updateOrdersContent_orders_mediaOrder_edit_editModelAdvert_basePage_areas_style_content {
  /**
   * Size limit
   */
  size: UpdateAllOrdersByField_updateOrdersContent_orders_mediaOrder_edit_editModelAdvert_basePage_areas_style_content_size | null;
}

export interface UpdateAllOrdersByField_updateOrdersContent_orders_mediaOrder_edit_editModelAdvert_basePage_areas_style_text_fontSize {
  /**
   * Type of unit
   */
  type: UnitTypes;
  /**
   * Value
   */
  value: number;
}

export interface UpdateAllOrdersByField_updateOrdersContent_orders_mediaOrder_edit_editModelAdvert_basePage_areas_style_text_lineHeight {
  /**
   * Type of unit
   */
  type: UnitTypes;
  /**
   * Value
   */
  value: number;
}

export interface UpdateAllOrdersByField_updateOrdersContent_orders_mediaOrder_edit_editModelAdvert_basePage_areas_style_text_lineHeightEmpty {
  /**
   * Type of unit
   */
  type: UnitTypes;
  /**
   * Value
   */
  value: number;
}

export interface UpdateAllOrdersByField_updateOrdersContent_orders_mediaOrder_edit_editModelAdvert_basePage_areas_style_text_letterSpacing {
  /**
   * Type of unit
   */
  type: UnitTypes;
  /**
   * Value
   */
  value: number;
}

export interface UpdateAllOrdersByField_updateOrdersContent_orders_mediaOrder_edit_editModelAdvert_basePage_areas_style_text_textScaleWidthOffset {
  /**
   * Type
   */
  type: RelativeTypes;
  /**
   * Value
   */
  value: number;
}

export interface UpdateAllOrdersByField_updateOrdersContent_orders_mediaOrder_edit_editModelAdvert_basePage_areas_style_text_textScaleWidth {
  /**
   * Type
   */
  type: RelativeTypes;
  /**
   * Value
   */
  value: number;
}

export interface UpdateAllOrdersByField_updateOrdersContent_orders_mediaOrder_edit_editModelAdvert_basePage_areas_style_text {
  fontFamily: string;
  /**
   * Font-size
   */
  fontSize: UpdateAllOrdersByField_updateOrdersContent_orders_mediaOrder_edit_editModelAdvert_basePage_areas_style_text_fontSize | null;
  /**
   * Line height
   */
  lineHeight: UpdateAllOrdersByField_updateOrdersContent_orders_mediaOrder_edit_editModelAdvert_basePage_areas_style_text_lineHeight | null;
  /**
   * Line height for empty rows
   */
  lineHeightEmpty: UpdateAllOrdersByField_updateOrdersContent_orders_mediaOrder_edit_editModelAdvert_basePage_areas_style_text_lineHeightEmpty | null;
  /**
   * Letter spacing
   */
  letterSpacing: UpdateAllOrdersByField_updateOrdersContent_orders_mediaOrder_edit_editModelAdvert_basePage_areas_style_text_letterSpacing | null;
  textScaleWidthOffset: UpdateAllOrdersByField_updateOrdersContent_orders_mediaOrder_edit_editModelAdvert_basePage_areas_style_text_textScaleWidthOffset;
  textScaleWidth: UpdateAllOrdersByField_updateOrdersContent_orders_mediaOrder_edit_editModelAdvert_basePage_areas_style_text_textScaleWidth;
  fontStyle: FontStyles | null;
  fontWeight: FontWeights | null;
}

export interface UpdateAllOrdersByField_updateOrdersContent_orders_mediaOrder_edit_editModelAdvert_basePage_areas_style {
  /**
   * margins
   */
  margin: UpdateAllOrdersByField_updateOrdersContent_orders_mediaOrder_edit_editModelAdvert_basePage_areas_style_margin | null;
  content: UpdateAllOrdersByField_updateOrdersContent_orders_mediaOrder_edit_editModelAdvert_basePage_areas_style_content;
  /**
   * Text settings
   */
  text: UpdateAllOrdersByField_updateOrdersContent_orders_mediaOrder_edit_editModelAdvert_basePage_areas_style_text;
}

export interface UpdateAllOrdersByField_updateOrdersContent_orders_mediaOrder_edit_editModelAdvert_basePage_areas_rectangle {
  /**
   * X coordinate
   */
  x: number;
  /**
   * Y coordinate
   */
  y: number;
  /**
   * Width
   */
  w: number;
  /**
   * Width
   */
  h: number;
}

export interface UpdateAllOrdersByField_updateOrdersContent_orders_mediaOrder_edit_editModelAdvert_basePage_areas {
  /**
   * Id of the blockeditor
   */
  id: string;
  /**
   * Parameters of the blockeditor
   */
  header: string;
  content: UpdateAllOrdersByField_updateOrdersContent_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content;
  style: UpdateAllOrdersByField_updateOrdersContent_orders_mediaOrder_edit_editModelAdvert_basePage_areas_style;
  rectangle: UpdateAllOrdersByField_updateOrdersContent_orders_mediaOrder_edit_editModelAdvert_basePage_areas_rectangle | null;
}

export interface UpdateAllOrdersByField_updateOrdersContent_orders_mediaOrder_edit_editModelAdvert_basePage {
  areas: (UpdateAllOrdersByField_updateOrdersContent_orders_mediaOrder_edit_editModelAdvert_basePage_areas | null)[] | null;
}

export interface UpdateAllOrdersByField_updateOrdersContent_orders_mediaOrder_edit_editModelAdvert {
  /**
   * If UpdateOrdersContent is avaiable
   */
  isEnabledUpdateOrdersContent: boolean;
  basePage: UpdateAllOrdersByField_updateOrdersContent_orders_mediaOrder_edit_editModelAdvert_basePage;
}

export interface UpdateAllOrdersByField_updateOrdersContent_orders_mediaOrder_edit_history {
  /**
   * How many steps it is possible to go back in history
   */
  countStepBack: number;
}

export interface UpdateAllOrdersByField_updateOrdersContent_orders_mediaOrder_edit {
  /**
   * Texts for editing the order
   */
  userInterface: UpdateAllOrdersByField_updateOrdersContent_orders_mediaOrder_edit_userInterface;
  resources: UpdateAllOrdersByField_updateOrdersContent_orders_mediaOrder_edit_resources | null;
  editModelAdvert: UpdateAllOrdersByField_updateOrdersContent_orders_mediaOrder_edit_editModelAdvert | null;
  history: UpdateAllOrdersByField_updateOrdersContent_orders_mediaOrder_edit_history;
}

export interface UpdateAllOrdersByField_updateOrdersContent_orders_mediaOrder_preview_material_basePage_size {
  /**
   * Width
   */
  width: number;
  /**
   * Height
   */
  height: number;
}

export interface UpdateAllOrdersByField_updateOrdersContent_orders_mediaOrder_preview_material_basePage {
  size: UpdateAllOrdersByField_updateOrdersContent_orders_mediaOrder_preview_material_basePage_size | null;
}

export interface UpdateAllOrdersByField_updateOrdersContent_orders_mediaOrder_preview_material_pages_png {
  /**
   * Url till pngfile
   */
  url: string;
}

export interface UpdateAllOrdersByField_updateOrdersContent_orders_mediaOrder_preview_material_pages {
  /**
   * Png material
   */
  png: UpdateAllOrdersByField_updateOrdersContent_orders_mediaOrder_preview_material_pages_png;
}

export interface UpdateAllOrdersByField_updateOrdersContent_orders_mediaOrder_preview_material {
  /**
   * base page
   */
  basePage: UpdateAllOrdersByField_updateOrdersContent_orders_mediaOrder_preview_material_basePage;
  /**
   * pages
   */
  pages: (UpdateAllOrdersByField_updateOrdersContent_orders_mediaOrder_preview_material_pages | null)[];
}

export interface UpdateAllOrdersByField_updateOrdersContent_orders_mediaOrder_preview_price_customer {
  /**
   * How much the product is pricing for the scope in the contract
   */
  priceContract: any | null;
  /**
   * How much the product is pricing for the order if it has been override the contract
   */
  priceContractOverride: any | null;
}

export interface UpdateAllOrdersByField_updateOrdersContent_orders_mediaOrder_preview_price_endCustomer {
  /**
   * Whats pay for the order and rounded
   */
  pricePayRounded: any | null;
}

export interface UpdateAllOrdersByField_updateOrdersContent_orders_mediaOrder_preview_price {
  /**
   * Currency
   */
  currency: string;
  /**
   * Customer view of the price
   */
  customer: UpdateAllOrdersByField_updateOrdersContent_orders_mediaOrder_preview_price_customer;
  /**
   * EndCustomer view of the price
   */
  endCustomer: UpdateAllOrdersByField_updateOrdersContent_orders_mediaOrder_preview_price_endCustomer;
}

export interface UpdateAllOrdersByField_updateOrdersContent_orders_mediaOrder_preview {
  /**
   * material
   */
  material: UpdateAllOrdersByField_updateOrdersContent_orders_mediaOrder_preview_material;
  /**
   * price of the order
   */
  price: UpdateAllOrdersByField_updateOrdersContent_orders_mediaOrder_preview_price | null;
}

export interface UpdateAllOrdersByField_updateOrdersContent_orders_mediaOrder_media {
  /**
   * The id of the media
   */
  id: string | null;
  /**
   * The friendlyName of the media
   */
  friendlyName: string;
}

export interface UpdateAllOrdersByField_updateOrdersContent_orders_mediaOrder_status {
  /**
   * Color for vieweing the customer
   */
  color: StatusColors;
  /**
   * ProductStatus enum for the status
   */
  status: OrderStatus;
}

export interface UpdateAllOrdersByField_updateOrdersContent_orders_mediaOrder {
  /**
   * Time when last sending the booking to media
   */
  timeBookingStop: any | null;
  /**
   * Locked
   */
  isLocked: boolean;
  /**
   * times when the order will be published
   */
  publishTimes: (UpdateAllOrdersByField_updateOrdersContent_orders_mediaOrder_publishTimes | null)[];
  /**
   * documentformat of the order
   */
  documentFormat: UpdateAllOrdersByField_updateOrdersContent_orders_mediaOrder_documentFormat;
  /**
   * Publish conversation settings for the media
   */
  publishConversation: UpdateAllOrdersByField_updateOrdersContent_orders_mediaOrder_publishConversation;
  /**
   * Parallell publish channels
   */
  publishChannels: UpdateAllOrdersByField_updateOrdersContent_orders_mediaOrder_publishChannels;
  /**
   * information for the editors
   */
  edit: UpdateAllOrdersByField_updateOrdersContent_orders_mediaOrder_edit;
  /**
   * Preview of the order
   */
  preview: UpdateAllOrdersByField_updateOrdersContent_orders_mediaOrder_preview;
  /**
   * media of the order
   */
  media: UpdateAllOrdersByField_updateOrdersContent_orders_mediaOrder_media;
  /**
   * Status of the order
   */
  status: UpdateAllOrdersByField_updateOrdersContent_orders_mediaOrder_status;
}

export interface UpdateAllOrdersByField_updateOrdersContent_orders {
  /**
   * The id of the mediaorder
   */
  id: string | null;
  /**
   * The Id shown for customer
   */
  customerFriendlyId: string;
  /**
   * Order is deleted
   */
  isDeleted: boolean;
  /**
   * document of the order
   */
  document: UpdateAllOrdersByField_updateOrdersContent_orders_document;
  client: UpdateAllOrdersByField_updateOrdersContent_orders_client | null;
  alerting: UpdateAllOrdersByField_updateOrdersContent_orders_alerting;
  /**
   * mediaOrder properties if that kind of order
   */
  mediaOrder: UpdateAllOrdersByField_updateOrdersContent_orders_mediaOrder | null;
}

export interface UpdateAllOrdersByField_updateOrdersContent {
  orders: UpdateAllOrdersByField_updateOrdersContent_orders[];
}

export interface UpdateAllOrdersByField {
  /**
   * Update contentModel in all orders belongings to the same document
   */
  updateOrdersContent: UpdateAllOrdersByField_updateOrdersContent;
}

export interface UpdateAllOrdersByFieldVariables {
  input: MutationUpdateOrdersContentInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateCaseFolder
// ====================================================

export interface UpdateCaseFolder_updateCaseFolder_caseFolder_customer {
  /**
   * The id of customer
   */
  id: string;
  /**
   * The name of customer
   */
  name: string;
}

export interface UpdateCaseFolder_updateCaseFolder_caseFolder_productPackages_productPackage {
  /**
   * Id of the ProductPackage
   */
  id: string;
}

export interface UpdateCaseFolder_updateCaseFolder_caseFolder_productPackages {
  /**
   * Product Package
   */
  productPackage: UpdateCaseFolder_updateCaseFolder_caseFolder_productPackages_productPackage;
}

export interface UpdateCaseFolder_updateCaseFolder_caseFolder_propertiesFuneralStandard {
  /**
   * Id from a another businesssystem (option)
   */
  externalId: string;
  /**
   * Born date for the person in the case
   */
  personBorn: any | null;
  /**
   * Died date for the person in the case
   */
  personDied: any | null;
  /**
   * Funeral date for the person in the case
   */
  personFuneral: any | null;
  /**
   * Lastname when the person in the case was born
   */
  personBornLastname: string | null;
  /**
   * First name of the person in the case
   */
  personFirstname: string;
  /**
   * Last name of the person in the case
   */
  personLastname: string;
  /**
   * Where did the person live
   */
  personCityLive: string;
  /**
   * Informationtext about the funeral
   */
  personFuneralInfoText: string;
  /**
   * Last day for booking the cermony for memory
   */
  memoryCeremonyLastBookingDay: any | null;
}

export interface UpdateCaseFolder_updateCaseFolder_caseFolder {
  /**
   * The id of the caseFolder
   */
  id: string | null;
  /**
   * The internal id of the caseFolder
   */
  internalId: number;
  /**
   * The name of the caseFolder
   */
  name: string;
  customer: UpdateCaseFolder_updateCaseFolder_caseFolder_customer;
  /**
   * ProductPackages of the caseFolder
   */
  productPackages: (UpdateCaseFolder_updateCaseFolder_caseFolder_productPackages | null)[];
  propertiesFuneralStandard: UpdateCaseFolder_updateCaseFolder_caseFolder_propertiesFuneralStandard;
}

export interface UpdateCaseFolder_updateCaseFolder {
  /**
   * Mutation is not successful
   */
  isError: boolean;
  /**
   * Reasons
   */
  errorReason: string | null;
  caseFolder: UpdateCaseFolder_updateCaseFolder_caseFolder;
}

export interface UpdateCaseFolder {
  /**
   * Update casefolder
   */
  updateCaseFolder: UpdateCaseFolder_updateCaseFolder;
}

export interface UpdateCaseFolderVariables {
  input: MutationUpdateCaseFolderInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateCurrentUserSession
// ====================================================

export interface UpdateCurrentUserSession_updateCurrentUserSession_userSession_customer {
  /**
   * The id of customer
   */
  id: string;
}

export interface UpdateCurrentUserSession_updateCurrentUserSession_userSession {
  id: string;
  /**
   * The authorization-token for putting in the Authorization header when calling for the api
   */
  authorizationToken: string;
  customer: UpdateCurrentUserSession_updateCurrentUserSession_userSession_customer | null;
}

export interface UpdateCurrentUserSession_updateCurrentUserSession {
  userSession: UpdateCurrentUserSession_updateCurrentUserSession_userSession;
}

export interface UpdateCurrentUserSession {
  /**
   * Change active customer or cultureCode
   */
  updateCurrentUserSession: UpdateCurrentUserSession_updateCurrentUserSession;
}

export interface UpdateCurrentUserSessionVariables {
  input: MutationUpdateCurrentUserSessionInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: MutationUpdateCustomerContract
// ====================================================

export interface MutationUpdateCustomerContract_updateCustomerContract_customerContract_customerProducts_product {
  /**
   * Name of the product
   */
  name: string;
}

export interface MutationUpdateCustomerContract_updateCustomerContract_customerContract_customerProducts_contractEndCustomer {
  /**
   * Price added on the proofs. The customer earnings
   */
  price: any;
}

export interface MutationUpdateCustomerContract_updateCustomerContract_customerContract_customerProducts {
  /**
   * Product
   */
  product: MutationUpdateCustomerContract_updateCustomerContract_customerContract_customerProducts_product;
  /**
   * EndCustomer contract
   */
  contractEndCustomer: MutationUpdateCustomerContract_updateCustomerContract_customerContract_customerProducts_contractEndCustomer;
}

export interface MutationUpdateCustomerContract_updateCustomerContract_customerContract {
  /**
   * Name of the contract
   */
  name: string;
  /**
   * Products in the contract
   */
  customerProducts: MutationUpdateCustomerContract_updateCustomerContract_customerContract_customerProducts[];
}

export interface MutationUpdateCustomerContract_updateCustomerContract {
  customerContract: MutationUpdateCustomerContract_updateCustomerContract_customerContract | null;
}

export interface MutationUpdateCustomerContract {
  /**
   * Update customer contract
   */
  updateCustomerContract: MutationUpdateCustomerContract_updateCustomerContract;
}

export interface MutationUpdateCustomerContractVariables {
  input: MutationUpdateCustomerContractInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateCustomerDocumentContentInitiator
// ====================================================

export interface UpdateCustomerDocumentContentInitiator_updateCustomerDocumentContentInitiator {
  /**
   * Reasons
   */
  errorReason: string | null;
  /**
   * Stack
   */
  errorStack: string | null;
  /**
   * Mutation is not successful
   */
  isError: boolean;
}

export interface UpdateCustomerDocumentContentInitiator {
  /**
   * Update customer document content initiator
   */
  updateCustomerDocumentContentInitiator: UpdateCustomerDocumentContentInitiator_updateCustomerDocumentContentInitiator;
}

export interface UpdateCustomerDocumentContentInitiatorVariables {
  input: MutationUpdateCustomerDocumentContentInitiatorInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateCustomerDocumentContentInitiatorFromOrder
// ====================================================

export interface UpdateCustomerDocumentContentInitiatorFromOrder {
  /**
   * Update customer document content initiator
   */
  updateCustomerDocumentContentInitiatorFromOrder: string;
}

export interface UpdateCustomerDocumentContentInitiatorFromOrderVariables {
  customerScopeForDocumentContent: CustomerScope;
  orderId: string;
  replaceLowerCustomerScopeInitiors: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateCustomerUser
// ====================================================

export interface UpdateCustomerUser_updateCustomerUser_customer_office {
  /**
   * The id of office
   */
  id: string;
  /**
   * The name of office
   */
  name: string;
}

export interface UpdateCustomerUser_updateCustomerUser_customer_primaryUser {
  /**
   * The id of user
   */
  id: string | null;
  /**
   * The username of user
   */
  username: string;
  /**
   * The account is enabled or not
   */
  enabled: boolean;
}

export interface UpdateCustomerUser_updateCustomerUser_customer {
  /**
   * The id of customer
   */
  id: string;
  /**
   * The name of customer
   */
  name: string;
  office: UpdateCustomerUser_updateCustomerUser_customer_office;
  /**
   * The primary user for the customer
   */
  primaryUser: UpdateCustomerUser_updateCustomerUser_customer_primaryUser | null;
}

export interface UpdateCustomerUser_updateCustomerUser {
  errorCode: CustomerInputErrors | null;
  /**
   * Reasons
   */
  errorReason: string | null;
  /**
   * Stack
   */
  errorStack: string | null;
  /**
   * Mutation is not successful
   */
  isError: boolean;
  customer: UpdateCustomerUser_updateCustomerUser_customer | null;
}

export interface UpdateCustomerUser {
  /**
   * Update a user and customer in the system
   */
  updateCustomerUser: UpdateCustomerUser_updateCustomerUser;
}

export interface UpdateCustomerUserVariables {
  input: MutationCRUDCustomerUserInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateDocumentFormat
// ====================================================

export interface UpdateDocumentFormat_updateOrderDocumentFormat_order_document {
  /**
   * Id of the document
   */
  id: string | null;
}

export interface UpdateDocumentFormat_updateOrderDocumentFormat_order_client {
  mainSystem: SystemFlags;
  isTaps5: boolean;
}

export interface UpdateDocumentFormat_updateOrderDocumentFormat_order_alerting {
  smallText: string | null;
  text: string | null;
  type: OrderAlertingType;
}

export interface UpdateDocumentFormat_updateOrderDocumentFormat_order_mediaOrder_publishTimes {
  /**
   * Date when the order will be published
   */
  time: any;
}

export interface UpdateDocumentFormat_updateOrderDocumentFormat_order_mediaOrder_documentFormat_documentFormatCollection {
  id: string;
  /**
   * The name of the collection
   */
  name: string;
}

export interface UpdateDocumentFormat_updateOrderDocumentFormat_order_mediaOrder_documentFormat_documentType {
  /**
   * Id of the documenttype
   */
  id: string;
  /**
   * The name of the documentType
   */
  name: string;
}

export interface UpdateDocumentFormat_updateOrderDocumentFormat_order_mediaOrder_documentFormat {
  /**
   * Id of the documentformat
   */
  id: string;
  documentFormatCollection: UpdateDocumentFormat_updateOrderDocumentFormat_order_mediaOrder_documentFormat_documentFormatCollection;
  mediaAdaptedName: string;
  documentType: UpdateDocumentFormat_updateOrderDocumentFormat_order_mediaOrder_documentFormat_documentType;
}

export interface UpdateDocumentFormat_updateOrderDocumentFormat_order_mediaOrder_publishConversation {
  /**
   * Validation error texts
   */
  validateErrorTexts: string[];
  /**
   * Message to media
   */
  messageFromMedia: string | null;
  /**
   * Code to verify late publish
   */
  codeLate: string;
  /**
   * Code to media. Used when the ordering is late. Passed bookingstop. This must be verfied before it can be published
   */
  isCodeLate: boolean;
  /**
   * Message to media allowed
   */
  isMessageToMedia: boolean;
  /**
   * Media-System Ordernumber has to be set
   */
  isMediaSystemOrderNumber: boolean;
  /**
   * EndCustomer subribenumber on media can be set
   */
  isMediaCustomerSubriberNumber: boolean;
  /**
   * Status for publish
   */
  publishReadyStatus: MediaOrderPublishhReadyStatus;
}

export interface UpdateDocumentFormat_updateOrderDocumentFormat_order_mediaOrder_publishChannels {
  /**
   * Publish in familjesidan
   */
  inFamiljesidan: boolean;
  /**
   * Publish in minnesrummet
   */
  inMinnesrummet: boolean;
  /**
   * Publish in agency homepage
   */
  inAgency: boolean;
}

export interface UpdateDocumentFormat_updateOrderDocumentFormat_order_mediaOrder_edit_userInterface {
  editStatusColor: EditUIEditStatusColor;
  editStatusText: string | null;
  publishButtonText: string | null;
  editStatusIcon: EditUIEditStatusIcon;
  isUnPublishButton: boolean;
  unPublishButtonText: string | null;
  isPublishButton: boolean;
  isLockButton: boolean;
  lockButtonText: string | null;
  isUnlockButton: boolean;
  unlockButtonText: string | null;
  isReactivateButton: boolean;
  reactivateButtonText: string | null;
  isSetExhibitOrder: boolean;
  setExhibitOrderText: string | null;
  isUpdateCustomerDocumentContentInitiator: boolean;
  updateCustomerDocumentContentInitiatorText: string | null;
  /**
   * Size limit
   */
  orderEntityNameSingular: string | null;
  /**
   * Size limit
   */
  orderEntityNamePlural: string | null;
  /**
   * Size limit
   */
  orderEntityNameSingularDetermined: string | null;
  /**
   * Size limit
   */
  orderEntityNamePluralDetermined: string | null;
  isCopyFuneralInfoToMemoryRoom: boolean;
  copyFuneralInfoToMemoryRoomText: string | null;
  isNetnoticeEnabled: boolean;
  isNetnoticeSupported: boolean;
}

export interface UpdateDocumentFormat_updateOrderDocumentFormat_order_mediaOrder_edit_resources {
  /**
   * Get style css for the specialCharacters
   */
  styleCssSpecialCharacters: string;
}

export interface UpdateDocumentFormat_updateOrderDocumentFormat_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_validateErrors {
  error: EditModelAdvertAreaContentValidateErrors;
  validationText: string | null;
}

export interface UpdateDocumentFormat_updateOrderDocumentFormat_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_common_marginLeftRightOffset {
  /**
   * Type
   */
  type: RelativeTypes;
  /**
   * Value
   */
  value: number;
}

export interface UpdateDocumentFormat_updateOrderDocumentFormat_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_common_margin {
  /**
   * Type of unit
   */
  type: UnitTypes;
  /**
   * Left
   */
  left: number | null;
  /**
   * Right
   */
  right: number | null;
  /**
   * Top
   */
  top: number | null;
  /**
   * Bottom
   */
  bottom: number | null;
}

export interface UpdateDocumentFormat_updateOrderDocumentFormat_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_common_size {
  /**
   * Type
   */
  type: UnitTypes;
  /**
   * Min Width
   */
  minWidth: number | null;
  /**
   * Min Height
   */
  minHeight: number | null;
  /**
   * Max Width
   */
  maxWidth: number | null;
  /**
   * Max Height
   */
  maxHeight: number | null;
  /**
   * Fix Width
   */
  fixedWidth: number | null;
  /**
   * Fix Height
   */
  fixedHeight: number | null;
}

export interface UpdateDocumentFormat_updateOrderDocumentFormat_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_common {
  /**
   * Alternative BlockStyle
   */
  blockStyleAreaNameId: string | null;
  marginLeftRightOffset: UpdateDocumentFormat_updateOrderDocumentFormat_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_common_marginLeftRightOffset | null;
  margin: UpdateDocumentFormat_updateOrderDocumentFormat_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_common_margin | null;
  size: UpdateDocumentFormat_updateOrderDocumentFormat_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_common_size | null;
}

export interface UpdateDocumentFormat_updateOrderDocumentFormat_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_image {
  /**
   * Repository of image
   */
  type: EditModelAdvertAreaContentRepository | null;
  /**
   * id for the image
   */
  imageId: string | null;
  /**
   * public id for the image
   */
  imagePublicId: string | null;
  /**
   * public id for the image
   */
  imageThumbnaill100x80Url: string | null;
  /**
   * Height for the image in mm
   */
  heightMM: number | null;
  /**
   * DefaultHeight for the image in mm
   */
  defaultHeightMM: number | null;
  /**
   * IsSizeFree
   */
  isSizeFree: boolean | null;
  /**
   * Upload image content
   */
  uploadData: string | null;
  /**
   * Upload filename
   */
  uploadFilename: string | null;
  /**
   * Bitmap resolution in dpi
   */
  resolution: number | null;
  /**
   * Upload prefered HeightMM of uploaded image
   */
  uploadPreferedHeightMM: number | null;
  /**
   * Repository of image
   */
  repository: EditModelAdvertAreaContentRepository | null;
  /**
   * Upload source image content
   */
  uploadDataSource: string | null;
  /**
   * Comment about the upload
   */
  uploadComment: string | null;
}

export interface UpdateDocumentFormat_updateOrderDocumentFormat_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_text_textScaleWidthOffset {
  /**
   * Type
   */
  type: RelativeTypes;
  /**
   * Value
   */
  value: number;
}

export interface UpdateDocumentFormat_updateOrderDocumentFormat_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_text_fontSize {
  /**
   * Type of unit
   */
  type: UnitTypes;
  /**
   * Value
   */
  value: number;
}

export interface UpdateDocumentFormat_updateOrderDocumentFormat_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_text_lineHeight {
  /**
   * Type of unit
   */
  type: UnitTypes;
  /**
   * Value
   */
  value: number;
}

export interface UpdateDocumentFormat_updateOrderDocumentFormat_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_text_lineHeightEmpty {
  /**
   * Type of unit
   */
  type: UnitTypes;
  /**
   * Value
   */
  value: number;
}

export interface UpdateDocumentFormat_updateOrderDocumentFormat_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_text_letterSpacing {
  /**
   * Type of unit
   */
  type: UnitTypes;
  /**
   * Value
   */
  value: number;
}

export interface UpdateDocumentFormat_updateOrderDocumentFormat_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_text_textScaleWidth {
  /**
   * Type
   */
  type: RelativeTypes;
  /**
   * Value
   */
  value: number;
}

export interface UpdateDocumentFormat_updateOrderDocumentFormat_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_text {
  /**
   * Font style
   */
  fontStyle: FontStyles | null;
  /**
   * Textscale width offset
   */
  textScaleWidthOffset: UpdateDocumentFormat_updateOrderDocumentFormat_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_text_textScaleWidthOffset | null;
  /**
   * Font weight
   */
  fontWeight: FontWeights | null;
  /**
   * Text alignment
   */
  textAlignment: TextAlignments | null;
  /**
   * Text formats in xml
   */
  xmlText: string | null;
  /**
   * Is htmleditor readonly
   */
  isXmlTextReadonly: boolean | null;
  /**
   * Fontfamily of the text
   */
  fontFamily: string | null;
  /**
   * Font
   */
  fontSize: UpdateDocumentFormat_updateOrderDocumentFormat_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_text_fontSize | null;
  /**
   * Line-height
   */
  lineHeight: UpdateDocumentFormat_updateOrderDocumentFormat_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_text_lineHeight | null;
  /**
   * Line-height for empty rows
   */
  lineHeightEmpty: UpdateDocumentFormat_updateOrderDocumentFormat_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_text_lineHeightEmpty | null;
  /**
   * Letter-Spacing
   */
  letterSpacing: UpdateDocumentFormat_updateOrderDocumentFormat_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_text_letterSpacing | null;
  /**
   * Textscale width
   */
  textScaleWidth: UpdateDocumentFormat_updateOrderDocumentFormat_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_text_textScaleWidth | null;
  /**
   * Text do not wrap when text reach its width
   */
  textNoWrap: boolean | null;
}

export interface UpdateDocumentFormat_updateOrderDocumentFormat_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_separator {
  /**
   * Type of image
   */
  type: EditModelAdvertAreaContentItemSeparatorTypes;
  imageId: string | null;
  /**
   * Repository of image
   */
  repository: EditModelAdvertAreaContentRepository | null;
  /**
   * Width for the image in mm
   */
  widthMM: number | null;
  /**
   * Height for the image in mm
   */
  heightMM: number | null;
}

export interface UpdateDocumentFormat_updateOrderDocumentFormat_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_fields {
  /**
   * Name
   */
  name: string;
  /**
   * Header
   */
  header: string | null;
  /**
   * Type
   */
  type: EditModelAdvertAreaContentItemFieldItemTypes | null;
  /**
   * Editor
   */
  editor: EditModelAdvertAreaContentItemFieldItemEditors | null;
  /**
   * Value
   */
  value: string | null;
  __typename: "EditModelAdvertAreaContentItemFieldItem";
}

export interface UpdateDocumentFormat_updateOrderDocumentFormat_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item {
  /**
   * Common
   */
  common: UpdateDocumentFormat_updateOrderDocumentFormat_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_common | null;
  /**
   * Value is only for demo purpose
   */
  isDemoValue: boolean | null;
  /**
   * ImageContent
   */
  image: UpdateDocumentFormat_updateOrderDocumentFormat_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_image | null;
  /**
   * TextContent
   */
  text: UpdateDocumentFormat_updateOrderDocumentFormat_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_text | null;
  /**
   * HorizontalLineContent
   */
  separator: UpdateDocumentFormat_updateOrderDocumentFormat_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_separator | null;
  /**
   * Fields
   */
  fields: (UpdateDocumentFormat_updateOrderDocumentFormat_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_fields | null)[] | null;
}

export interface UpdateDocumentFormat_updateOrderDocumentFormat_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns {
  item: UpdateDocumentFormat_updateOrderDocumentFormat_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item;
}

export interface UpdateDocumentFormat_updateOrderDocumentFormat_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows {
  columns: UpdateDocumentFormat_updateOrderDocumentFormat_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns[];
}

export interface UpdateDocumentFormat_updateOrderDocumentFormat_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_value {
  /**
   * Id for the content-value
   */
  id: string | null;
  /**
   * Is content visible
   */
  visible: boolean;
  /**
   * Is it content
   */
  hasContent: boolean;
  /**
   * Is editor visible
   */
  isEditorVisible: boolean;
  /**
   * Id to the template
   */
  templateAreaId: string | null;
  /**
   * Rows
   */
  rows: UpdateDocumentFormat_updateOrderDocumentFormat_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows[];
}

export interface UpdateDocumentFormat_updateOrderDocumentFormat_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_access {
  add: boolean;
  modify: boolean;
  statusAdd: EditModelAdvertAreaContentEditorAccessStatuses;
}

export interface UpdateDocumentFormat_updateOrderDocumentFormat_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_common_allowedMarginLeftRightOffsetInterval {
  /**
   * Type of unit
   */
  type: RelativeTypes;
  /**
   * Max-Value
   */
  maxValue: number;
  /**
   * Min-Value
   */
  minValue: number;
  /**
   * Interval-Value
   */
  intervalValue: number;
}

export interface UpdateDocumentFormat_updateOrderDocumentFormat_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_common_blockStyles {
  /**
   * Id
   */
  id: string;
  /**
   * Name
   */
  name: string;
}

export interface UpdateDocumentFormat_updateOrderDocumentFormat_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_common {
  /**
   * Margin can adjust margins
   */
  isEnabledAdjustMargins: boolean;
  /**
   * If UpdateOrdersContent is avaiable
   */
  isEnabledUpdateOrdersContent: boolean;
  /**
   * If it is possible to add columns
   */
  isEnabledMultiColumns: boolean;
  /**
   * Enable margin setting
   */
  isMarginLeftRightOffset: boolean;
  /**
   * Allowed margin settings
   */
  allowedMarginLeftRightOffsetInterval: UpdateDocumentFormat_updateOrderDocumentFormat_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_common_allowedMarginLeftRightOffsetInterval | null;
  /**
   * Block styles
   */
  blockStyles: UpdateDocumentFormat_updateOrderDocumentFormat_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_common_blockStyles[];
}

export interface UpdateDocumentFormat_updateOrderDocumentFormat_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_text_allowedFontSizes {
  /**
   * Values
   */
  values: number[];
}

export interface UpdateDocumentFormat_updateOrderDocumentFormat_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_text_allowedLineHeights {
  /**
   * Values
   */
  values: number[];
}

export interface UpdateDocumentFormat_updateOrderDocumentFormat_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_text_allowedFontSizeInterval {
  /**
   * Type of unit
   */
  type: UnitTypes;
  /**
   * Interval-Value
   */
  intervalValue: number;
}

export interface UpdateDocumentFormat_updateOrderDocumentFormat_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_text_allowedLineHeightInterval {
  /**
   * Type of unit
   */
  type: UnitTypes;
  /**
   * Interval-Value
   */
  intervalValue: number;
}

export interface UpdateDocumentFormat_updateOrderDocumentFormat_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_text_allowedTextScaleWidthOffsetInterval {
  /**
   * Interval-Value
   */
  intervalValue: number;
  /**
   * Min-Value
   */
  minValue: number;
  /**
   * Max-Value
   */
  maxValue: number;
}

export interface UpdateDocumentFormat_updateOrderDocumentFormat_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_text_allowedCharacterGalleries_items {
  /**
   * Id
   */
  id: string;
  /**
   * Name
   */
  name: string;
  /**
   * Title
   */
  title: string;
  /**
   * Preview
   */
  preview: string | null;
  /**
   * Html
   */
  html: string;
}

export interface UpdateDocumentFormat_updateOrderDocumentFormat_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_text_allowedCharacterGalleries {
  /**
   * Id
   */
  id: string;
  isAdvancedUser: boolean;
  /**
   * Items
   */
  items: UpdateDocumentFormat_updateOrderDocumentFormat_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_text_allowedCharacterGalleries_items[];
}

export interface UpdateDocumentFormat_updateOrderDocumentFormat_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_text_allowedParagraphClasses {
  /**
   * Id
   */
  id: string;
  /**
   * Name
   */
  name: string;
}

export interface UpdateDocumentFormat_updateOrderDocumentFormat_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_text {
  /**
   * Default textalignment from template
   */
  defaultTextAdjustment: TextAlignments;
  /**
   * Allowed font sizes
   */
  allowedFontSizes: UpdateDocumentFormat_updateOrderDocumentFormat_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_text_allowedFontSizes;
  /**
   * Allowed font styles
   */
  allowedFontStyles: FontStyles[];
  /**
   * Allowed font weights
   */
  allowedFontWeights: FontWeights[];
  /**
   * Allowed line height sizes
   */
  allowedLineHeights: UpdateDocumentFormat_updateOrderDocumentFormat_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_text_allowedLineHeights;
  /**
   * Allowed font families
   */
  allowedFontFamilies: string[];
  /**
   * Allowed text adjustments
   */
  allowedTextAdjustments: TextAlignments[];
  /**
   * Allowed font size interval
   */
  allowedFontSizeInterval: UpdateDocumentFormat_updateOrderDocumentFormat_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_text_allowedFontSizeInterval;
  /**
   * Allowed line height size interval
   */
  allowedLineHeightInterval: UpdateDocumentFormat_updateOrderDocumentFormat_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_text_allowedLineHeightInterval;
  /**
   * Enable text scale width offset
   */
  isTextScaleWidthOffset: boolean;
  /**
   * Allowed TextScale width offset interval
   */
  allowedTextScaleWidthOffsetInterval: UpdateDocumentFormat_updateOrderDocumentFormat_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_text_allowedTextScaleWidthOffsetInterval | null;
  /**
   * Allowed special characters galleries
   */
  allowedCharacterGalleries: UpdateDocumentFormat_updateOrderDocumentFormat_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_text_allowedCharacterGalleries[];
  /**
   * Allowed number of paragraphClass
   */
  allowedParagraphClasses: UpdateDocumentFormat_updateOrderDocumentFormat_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_text_allowedParagraphClasses[];
  /**
   * Enable to change font size
   */
  isEnabledFontSize: boolean;
  /**
   * Enable to change font style
   */
  isEnabledFontStyle: boolean;
  /**
   * Enable to change line height
   */
  isEnabledLineHeight: boolean;
  /**
   * Enable to change font family
   */
  isEnabledFontFamily: boolean;
  /**
   * Enable to change font weights
   */
  isEnabledFontWeight: boolean;
  /**
   * Enable to change letter spacing
   */
  isEnabledLetterSpacing: boolean;
  /**
   * Enable to advanced settings for the admin/support
   */
  isEnabledAdvancedSettings: boolean;
  /**
   * Enable to change letter spacing
   */
  isEnabledTextScaleWidthTolerance: boolean;
  /**
   * Enable gallery option
   */
  isEnabledGallery: boolean;
}

export interface UpdateDocumentFormat_updateOrderDocumentFormat_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_image_adjustment_brightness {
  /**
   * Interval-Value
   */
  intervalValue: number;
  /**
   * Max-Value
   */
  maxValue: number;
  /**
   * Min-Value
   */
  minValue: number;
  /**
   * Type of unit
   */
  type: RelativeTypes;
}

export interface UpdateDocumentFormat_updateOrderDocumentFormat_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_image_adjustment_contrast {
  /**
   * Interval-Value
   */
  intervalValue: number;
  /**
   * Max-Value
   */
  maxValue: number;
  /**
   * Min-Value
   */
  minValue: number;
  /**
   * Type of unit
   */
  type: RelativeTypes;
}

export interface UpdateDocumentFormat_updateOrderDocumentFormat_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_image_adjustment_rotate {
  /**
   * Interval-Value
   */
  intervalValue: number;
  /**
   * Max-Value
   */
  maxValue: number;
  /**
   * Min-Value
   */
  minValue: number;
  /**
   * Type of unit
   */
  type: RelativeTypes;
}

export interface UpdateDocumentFormat_updateOrderDocumentFormat_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_image_adjustment {
  brightness: UpdateDocumentFormat_updateOrderDocumentFormat_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_image_adjustment_brightness;
  contrast: UpdateDocumentFormat_updateOrderDocumentFormat_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_image_adjustment_contrast;
  rotate: UpdateDocumentFormat_updateOrderDocumentFormat_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_image_adjustment_rotate;
}

export interface UpdateDocumentFormat_updateOrderDocumentFormat_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_image_mask {
  imageUrl: string | null;
}

export interface UpdateDocumentFormat_updateOrderDocumentFormat_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_image {
  /**
   * Min image height
   */
  minImageHeight: number;
  /**
   * Max image height
   */
  maxImageHeight: number;
  /**
   * Filter by gallery image category
   */
  filterGalleryImageAttributeInternalIds: number[];
  /**
   * Filter by not gallery image category
   */
  filterGalleryImageAttributeNotInternalIds: number[];
  /**
   * Filter by not gallery image category
   */
  galleryImageOption: string;
  /**
   * Filter by not gallery image category
   */
  documentFormatId: string;
  /**
   * Is the user enabled to switch to free size
   */
  isEnabledFreeSize: boolean;
  /**
   * Is the user enabled to upload images
   */
  isEnabledUpload: boolean;
  /**
   * Gallery is enabled
   */
  isEnabledGallery: boolean;
  /**
   * Resize is enabled
   */
  isEnabledResize: boolean;
  adjustment: UpdateDocumentFormat_updateOrderDocumentFormat_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_image_adjustment | null;
  mask: UpdateDocumentFormat_updateOrderDocumentFormat_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_image_mask | null;
}

export interface UpdateDocumentFormat_updateOrderDocumentFormat_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_help {
  html: string | null;
  hoverHtml: string | null;
  supportHtml: string | null;
}

export interface UpdateDocumentFormat_updateOrderDocumentFormat_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor {
  /**
   * Type of editor (Image, Text, Separator)
   */
  type: EditModelAdvertAreaContentEditorTypes;
  /**
   * SubType of editor
   */
  behaviour: EditModelAdvertAreaContentEditorBehaviours;
  /**
   * Access properties
   */
  access: UpdateDocumentFormat_updateOrderDocumentFormat_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_access;
  /**
   * Common parameters
   */
  common: UpdateDocumentFormat_updateOrderDocumentFormat_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_common;
  /**
   * Text parameters
   */
  text: UpdateDocumentFormat_updateOrderDocumentFormat_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_text | null;
  /**
   * Image parameters
   */
  image: UpdateDocumentFormat_updateOrderDocumentFormat_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_image | null;
  /**
   * Help properties
   */
  help: UpdateDocumentFormat_updateOrderDocumentFormat_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_help | null;
}

export interface UpdateDocumentFormat_updateOrderDocumentFormat_order_mediaOrder_edit_editModelAdvert_basePage_areas_content {
  /**
   * Validate errors
   */
  validateErrors: UpdateDocumentFormat_updateOrderDocumentFormat_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_validateErrors[];
  value: UpdateDocumentFormat_updateOrderDocumentFormat_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_value;
  editor: UpdateDocumentFormat_updateOrderDocumentFormat_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor | null;
}

export interface UpdateDocumentFormat_updateOrderDocumentFormat_order_mediaOrder_edit_editModelAdvert_basePage_areas_style_margin {
  /**
   * Top
   */
  top: number | null;
  /**
   * Left
   */
  left: number | null;
  /**
   * Right
   */
  right: number | null;
  /**
   * Bottom
   */
  bottom: number | null;
}

export interface UpdateDocumentFormat_updateOrderDocumentFormat_order_mediaOrder_edit_editModelAdvert_basePage_areas_style_content_size {
  /**
   * Type
   */
  type: UnitTypes;
  /**
   * Min Width
   */
  minWidth: number | null;
  /**
   * Min Height
   */
  minHeight: number | null;
  /**
   * Max Width
   */
  maxWidth: number | null;
  /**
   * Max Height
   */
  maxHeight: number | null;
  /**
   * Fix Width
   */
  fixedWidth: number | null;
  /**
   * Fix Height
   */
  fixedHeight: number | null;
}

export interface UpdateDocumentFormat_updateOrderDocumentFormat_order_mediaOrder_edit_editModelAdvert_basePage_areas_style_content {
  /**
   * Size limit
   */
  size: UpdateDocumentFormat_updateOrderDocumentFormat_order_mediaOrder_edit_editModelAdvert_basePage_areas_style_content_size | null;
}

export interface UpdateDocumentFormat_updateOrderDocumentFormat_order_mediaOrder_edit_editModelAdvert_basePage_areas_style_text_fontSize {
  /**
   * Type of unit
   */
  type: UnitTypes;
  /**
   * Value
   */
  value: number;
}

export interface UpdateDocumentFormat_updateOrderDocumentFormat_order_mediaOrder_edit_editModelAdvert_basePage_areas_style_text_lineHeight {
  /**
   * Type of unit
   */
  type: UnitTypes;
  /**
   * Value
   */
  value: number;
}

export interface UpdateDocumentFormat_updateOrderDocumentFormat_order_mediaOrder_edit_editModelAdvert_basePage_areas_style_text_lineHeightEmpty {
  /**
   * Type of unit
   */
  type: UnitTypes;
  /**
   * Value
   */
  value: number;
}

export interface UpdateDocumentFormat_updateOrderDocumentFormat_order_mediaOrder_edit_editModelAdvert_basePage_areas_style_text_letterSpacing {
  /**
   * Type of unit
   */
  type: UnitTypes;
  /**
   * Value
   */
  value: number;
}

export interface UpdateDocumentFormat_updateOrderDocumentFormat_order_mediaOrder_edit_editModelAdvert_basePage_areas_style_text_textScaleWidthOffset {
  /**
   * Type
   */
  type: RelativeTypes;
  /**
   * Value
   */
  value: number;
}

export interface UpdateDocumentFormat_updateOrderDocumentFormat_order_mediaOrder_edit_editModelAdvert_basePage_areas_style_text_textScaleWidth {
  /**
   * Type
   */
  type: RelativeTypes;
  /**
   * Value
   */
  value: number;
}

export interface UpdateDocumentFormat_updateOrderDocumentFormat_order_mediaOrder_edit_editModelAdvert_basePage_areas_style_text {
  fontFamily: string;
  /**
   * Font-size
   */
  fontSize: UpdateDocumentFormat_updateOrderDocumentFormat_order_mediaOrder_edit_editModelAdvert_basePage_areas_style_text_fontSize | null;
  /**
   * Line height
   */
  lineHeight: UpdateDocumentFormat_updateOrderDocumentFormat_order_mediaOrder_edit_editModelAdvert_basePage_areas_style_text_lineHeight | null;
  /**
   * Line height for empty rows
   */
  lineHeightEmpty: UpdateDocumentFormat_updateOrderDocumentFormat_order_mediaOrder_edit_editModelAdvert_basePage_areas_style_text_lineHeightEmpty | null;
  /**
   * Letter spacing
   */
  letterSpacing: UpdateDocumentFormat_updateOrderDocumentFormat_order_mediaOrder_edit_editModelAdvert_basePage_areas_style_text_letterSpacing | null;
  textScaleWidthOffset: UpdateDocumentFormat_updateOrderDocumentFormat_order_mediaOrder_edit_editModelAdvert_basePage_areas_style_text_textScaleWidthOffset;
  textScaleWidth: UpdateDocumentFormat_updateOrderDocumentFormat_order_mediaOrder_edit_editModelAdvert_basePage_areas_style_text_textScaleWidth;
  fontStyle: FontStyles | null;
  fontWeight: FontWeights | null;
}

export interface UpdateDocumentFormat_updateOrderDocumentFormat_order_mediaOrder_edit_editModelAdvert_basePage_areas_style {
  /**
   * margins
   */
  margin: UpdateDocumentFormat_updateOrderDocumentFormat_order_mediaOrder_edit_editModelAdvert_basePage_areas_style_margin | null;
  content: UpdateDocumentFormat_updateOrderDocumentFormat_order_mediaOrder_edit_editModelAdvert_basePage_areas_style_content;
  /**
   * Text settings
   */
  text: UpdateDocumentFormat_updateOrderDocumentFormat_order_mediaOrder_edit_editModelAdvert_basePage_areas_style_text;
}

export interface UpdateDocumentFormat_updateOrderDocumentFormat_order_mediaOrder_edit_editModelAdvert_basePage_areas_rectangle {
  /**
   * X coordinate
   */
  x: number;
  /**
   * Y coordinate
   */
  y: number;
  /**
   * Width
   */
  w: number;
  /**
   * Width
   */
  h: number;
}

export interface UpdateDocumentFormat_updateOrderDocumentFormat_order_mediaOrder_edit_editModelAdvert_basePage_areas {
  /**
   * Id of the blockeditor
   */
  id: string;
  /**
   * Parameters of the blockeditor
   */
  header: string;
  content: UpdateDocumentFormat_updateOrderDocumentFormat_order_mediaOrder_edit_editModelAdvert_basePage_areas_content;
  style: UpdateDocumentFormat_updateOrderDocumentFormat_order_mediaOrder_edit_editModelAdvert_basePage_areas_style;
  rectangle: UpdateDocumentFormat_updateOrderDocumentFormat_order_mediaOrder_edit_editModelAdvert_basePage_areas_rectangle | null;
}

export interface UpdateDocumentFormat_updateOrderDocumentFormat_order_mediaOrder_edit_editModelAdvert_basePage {
  areas: (UpdateDocumentFormat_updateOrderDocumentFormat_order_mediaOrder_edit_editModelAdvert_basePage_areas | null)[] | null;
}

export interface UpdateDocumentFormat_updateOrderDocumentFormat_order_mediaOrder_edit_editModelAdvert {
  /**
   * If UpdateOrdersContent is avaiable
   */
  isEnabledUpdateOrdersContent: boolean;
  basePage: UpdateDocumentFormat_updateOrderDocumentFormat_order_mediaOrder_edit_editModelAdvert_basePage;
}

export interface UpdateDocumentFormat_updateOrderDocumentFormat_order_mediaOrder_edit_history {
  /**
   * How many steps it is possible to go back in history
   */
  countStepBack: number;
}

export interface UpdateDocumentFormat_updateOrderDocumentFormat_order_mediaOrder_edit {
  /**
   * Texts for editing the order
   */
  userInterface: UpdateDocumentFormat_updateOrderDocumentFormat_order_mediaOrder_edit_userInterface;
  resources: UpdateDocumentFormat_updateOrderDocumentFormat_order_mediaOrder_edit_resources | null;
  editModelAdvert: UpdateDocumentFormat_updateOrderDocumentFormat_order_mediaOrder_edit_editModelAdvert | null;
  history: UpdateDocumentFormat_updateOrderDocumentFormat_order_mediaOrder_edit_history;
}

export interface UpdateDocumentFormat_updateOrderDocumentFormat_order_mediaOrder_preview_material_basePage_size {
  /**
   * Width
   */
  width: number;
  /**
   * Height
   */
  height: number;
}

export interface UpdateDocumentFormat_updateOrderDocumentFormat_order_mediaOrder_preview_material_basePage {
  size: UpdateDocumentFormat_updateOrderDocumentFormat_order_mediaOrder_preview_material_basePage_size | null;
}

export interface UpdateDocumentFormat_updateOrderDocumentFormat_order_mediaOrder_preview_material_pages_png {
  /**
   * Url till pngfile
   */
  url: string;
}

export interface UpdateDocumentFormat_updateOrderDocumentFormat_order_mediaOrder_preview_material_pages {
  /**
   * Png material
   */
  png: UpdateDocumentFormat_updateOrderDocumentFormat_order_mediaOrder_preview_material_pages_png;
}

export interface UpdateDocumentFormat_updateOrderDocumentFormat_order_mediaOrder_preview_material {
  /**
   * base page
   */
  basePage: UpdateDocumentFormat_updateOrderDocumentFormat_order_mediaOrder_preview_material_basePage;
  /**
   * pages
   */
  pages: (UpdateDocumentFormat_updateOrderDocumentFormat_order_mediaOrder_preview_material_pages | null)[];
}

export interface UpdateDocumentFormat_updateOrderDocumentFormat_order_mediaOrder_preview_price_customer {
  /**
   * How much the product is pricing for the scope in the contract
   */
  priceContract: any | null;
  /**
   * How much the product is pricing for the order if it has been override the contract
   */
  priceContractOverride: any | null;
}

export interface UpdateDocumentFormat_updateOrderDocumentFormat_order_mediaOrder_preview_price_endCustomer {
  /**
   * Whats pay for the order and rounded
   */
  pricePayRounded: any | null;
}

export interface UpdateDocumentFormat_updateOrderDocumentFormat_order_mediaOrder_preview_price {
  /**
   * Currency
   */
  currency: string;
  /**
   * Customer view of the price
   */
  customer: UpdateDocumentFormat_updateOrderDocumentFormat_order_mediaOrder_preview_price_customer;
  /**
   * EndCustomer view of the price
   */
  endCustomer: UpdateDocumentFormat_updateOrderDocumentFormat_order_mediaOrder_preview_price_endCustomer;
}

export interface UpdateDocumentFormat_updateOrderDocumentFormat_order_mediaOrder_preview {
  /**
   * material
   */
  material: UpdateDocumentFormat_updateOrderDocumentFormat_order_mediaOrder_preview_material;
  /**
   * price of the order
   */
  price: UpdateDocumentFormat_updateOrderDocumentFormat_order_mediaOrder_preview_price | null;
}

export interface UpdateDocumentFormat_updateOrderDocumentFormat_order_mediaOrder_media {
  /**
   * The id of the media
   */
  id: string | null;
  /**
   * The friendlyName of the media
   */
  friendlyName: string;
}

export interface UpdateDocumentFormat_updateOrderDocumentFormat_order_mediaOrder_status {
  /**
   * Color for vieweing the customer
   */
  color: StatusColors;
  /**
   * ProductStatus enum for the status
   */
  status: OrderStatus;
}

export interface UpdateDocumentFormat_updateOrderDocumentFormat_order_mediaOrder {
  /**
   * Time when last sending the booking to media
   */
  timeBookingStop: any | null;
  /**
   * Locked
   */
  isLocked: boolean;
  /**
   * times when the order will be published
   */
  publishTimes: (UpdateDocumentFormat_updateOrderDocumentFormat_order_mediaOrder_publishTimes | null)[];
  /**
   * documentformat of the order
   */
  documentFormat: UpdateDocumentFormat_updateOrderDocumentFormat_order_mediaOrder_documentFormat;
  /**
   * Publish conversation settings for the media
   */
  publishConversation: UpdateDocumentFormat_updateOrderDocumentFormat_order_mediaOrder_publishConversation;
  /**
   * Parallell publish channels
   */
  publishChannels: UpdateDocumentFormat_updateOrderDocumentFormat_order_mediaOrder_publishChannels;
  /**
   * information for the editors
   */
  edit: UpdateDocumentFormat_updateOrderDocumentFormat_order_mediaOrder_edit;
  /**
   * Preview of the order
   */
  preview: UpdateDocumentFormat_updateOrderDocumentFormat_order_mediaOrder_preview;
  /**
   * media of the order
   */
  media: UpdateDocumentFormat_updateOrderDocumentFormat_order_mediaOrder_media;
  /**
   * Status of the order
   */
  status: UpdateDocumentFormat_updateOrderDocumentFormat_order_mediaOrder_status;
}

export interface UpdateDocumentFormat_updateOrderDocumentFormat_order {
  /**
   * The id of the mediaorder
   */
  id: string | null;
  /**
   * The Id shown for customer
   */
  customerFriendlyId: string;
  /**
   * Order is deleted
   */
  isDeleted: boolean;
  /**
   * document of the order
   */
  document: UpdateDocumentFormat_updateOrderDocumentFormat_order_document;
  client: UpdateDocumentFormat_updateOrderDocumentFormat_order_client | null;
  alerting: UpdateDocumentFormat_updateOrderDocumentFormat_order_alerting;
  /**
   * mediaOrder properties if that kind of order
   */
  mediaOrder: UpdateDocumentFormat_updateOrderDocumentFormat_order_mediaOrder | null;
}

export interface UpdateDocumentFormat_updateOrderDocumentFormat {
  order: UpdateDocumentFormat_updateOrderDocumentFormat_order;
}

export interface UpdateDocumentFormat {
  /**
   * Update order document format
   */
  updateOrderDocumentFormat: UpdateDocumentFormat_updateOrderDocumentFormat;
}

export interface UpdateDocumentFormatVariables {
  input: MutationUpdateOrderDocumentFormatInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateOrderContent
// ====================================================

export interface UpdateOrderContent_updateOrderContent_order_document {
  /**
   * Id of the document
   */
  id: string | null;
}

export interface UpdateOrderContent_updateOrderContent_order_client {
  mainSystem: SystemFlags;
  isTaps5: boolean;
}

export interface UpdateOrderContent_updateOrderContent_order_alerting {
  smallText: string | null;
  text: string | null;
  type: OrderAlertingType;
}

export interface UpdateOrderContent_updateOrderContent_order_mediaOrder_publishTimes {
  /**
   * Date when the order will be published
   */
  time: any;
}

export interface UpdateOrderContent_updateOrderContent_order_mediaOrder_documentFormat_documentFormatCollection {
  id: string;
  /**
   * The name of the collection
   */
  name: string;
}

export interface UpdateOrderContent_updateOrderContent_order_mediaOrder_documentFormat_documentType {
  /**
   * Id of the documenttype
   */
  id: string;
  /**
   * The name of the documentType
   */
  name: string;
}

export interface UpdateOrderContent_updateOrderContent_order_mediaOrder_documentFormat {
  /**
   * Id of the documentformat
   */
  id: string;
  documentFormatCollection: UpdateOrderContent_updateOrderContent_order_mediaOrder_documentFormat_documentFormatCollection;
  mediaAdaptedName: string;
  documentType: UpdateOrderContent_updateOrderContent_order_mediaOrder_documentFormat_documentType;
}

export interface UpdateOrderContent_updateOrderContent_order_mediaOrder_publishConversation {
  /**
   * Validation error texts
   */
  validateErrorTexts: string[];
  /**
   * Message to media
   */
  messageFromMedia: string | null;
  /**
   * Code to verify late publish
   */
  codeLate: string;
  /**
   * Code to media. Used when the ordering is late. Passed bookingstop. This must be verfied before it can be published
   */
  isCodeLate: boolean;
  /**
   * Message to media allowed
   */
  isMessageToMedia: boolean;
  /**
   * Media-System Ordernumber has to be set
   */
  isMediaSystemOrderNumber: boolean;
  /**
   * EndCustomer subribenumber on media can be set
   */
  isMediaCustomerSubriberNumber: boolean;
  /**
   * Status for publish
   */
  publishReadyStatus: MediaOrderPublishhReadyStatus;
}

export interface UpdateOrderContent_updateOrderContent_order_mediaOrder_publishChannels {
  /**
   * Publish in familjesidan
   */
  inFamiljesidan: boolean;
  /**
   * Publish in minnesrummet
   */
  inMinnesrummet: boolean;
  /**
   * Publish in agency homepage
   */
  inAgency: boolean;
}

export interface UpdateOrderContent_updateOrderContent_order_mediaOrder_edit_userInterface {
  editStatusColor: EditUIEditStatusColor;
  editStatusText: string | null;
  publishButtonText: string | null;
  editStatusIcon: EditUIEditStatusIcon;
  isUnPublishButton: boolean;
  unPublishButtonText: string | null;
  isPublishButton: boolean;
  isLockButton: boolean;
  lockButtonText: string | null;
  isUnlockButton: boolean;
  unlockButtonText: string | null;
  isReactivateButton: boolean;
  reactivateButtonText: string | null;
  isSetExhibitOrder: boolean;
  setExhibitOrderText: string | null;
  isUpdateCustomerDocumentContentInitiator: boolean;
  updateCustomerDocumentContentInitiatorText: string | null;
  /**
   * Size limit
   */
  orderEntityNameSingular: string | null;
  /**
   * Size limit
   */
  orderEntityNamePlural: string | null;
  /**
   * Size limit
   */
  orderEntityNameSingularDetermined: string | null;
  /**
   * Size limit
   */
  orderEntityNamePluralDetermined: string | null;
  isCopyFuneralInfoToMemoryRoom: boolean;
  copyFuneralInfoToMemoryRoomText: string | null;
  isNetnoticeEnabled: boolean;
  isNetnoticeSupported: boolean;
}

export interface UpdateOrderContent_updateOrderContent_order_mediaOrder_edit_resources {
  /**
   * Get style css for the specialCharacters
   */
  styleCssSpecialCharacters: string;
}

export interface UpdateOrderContent_updateOrderContent_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_validateErrors {
  error: EditModelAdvertAreaContentValidateErrors;
  validationText: string | null;
}

export interface UpdateOrderContent_updateOrderContent_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_common_marginLeftRightOffset {
  /**
   * Type
   */
  type: RelativeTypes;
  /**
   * Value
   */
  value: number;
}

export interface UpdateOrderContent_updateOrderContent_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_common_margin {
  /**
   * Type of unit
   */
  type: UnitTypes;
  /**
   * Left
   */
  left: number | null;
  /**
   * Right
   */
  right: number | null;
  /**
   * Top
   */
  top: number | null;
  /**
   * Bottom
   */
  bottom: number | null;
}

export interface UpdateOrderContent_updateOrderContent_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_common_size {
  /**
   * Type
   */
  type: UnitTypes;
  /**
   * Min Width
   */
  minWidth: number | null;
  /**
   * Min Height
   */
  minHeight: number | null;
  /**
   * Max Width
   */
  maxWidth: number | null;
  /**
   * Max Height
   */
  maxHeight: number | null;
  /**
   * Fix Width
   */
  fixedWidth: number | null;
  /**
   * Fix Height
   */
  fixedHeight: number | null;
}

export interface UpdateOrderContent_updateOrderContent_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_common {
  /**
   * Alternative BlockStyle
   */
  blockStyleAreaNameId: string | null;
  marginLeftRightOffset: UpdateOrderContent_updateOrderContent_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_common_marginLeftRightOffset | null;
  margin: UpdateOrderContent_updateOrderContent_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_common_margin | null;
  size: UpdateOrderContent_updateOrderContent_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_common_size | null;
}

export interface UpdateOrderContent_updateOrderContent_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_image {
  /**
   * Repository of image
   */
  type: EditModelAdvertAreaContentRepository | null;
  /**
   * id for the image
   */
  imageId: string | null;
  /**
   * public id for the image
   */
  imagePublicId: string | null;
  /**
   * public id for the image
   */
  imageThumbnaill100x80Url: string | null;
  /**
   * Height for the image in mm
   */
  heightMM: number | null;
  /**
   * DefaultHeight for the image in mm
   */
  defaultHeightMM: number | null;
  /**
   * IsSizeFree
   */
  isSizeFree: boolean | null;
  /**
   * Upload image content
   */
  uploadData: string | null;
  /**
   * Upload filename
   */
  uploadFilename: string | null;
  /**
   * Bitmap resolution in dpi
   */
  resolution: number | null;
  /**
   * Upload prefered HeightMM of uploaded image
   */
  uploadPreferedHeightMM: number | null;
  /**
   * Repository of image
   */
  repository: EditModelAdvertAreaContentRepository | null;
  /**
   * Upload source image content
   */
  uploadDataSource: string | null;
  /**
   * Comment about the upload
   */
  uploadComment: string | null;
}

export interface UpdateOrderContent_updateOrderContent_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_text_textScaleWidthOffset {
  /**
   * Type
   */
  type: RelativeTypes;
  /**
   * Value
   */
  value: number;
}

export interface UpdateOrderContent_updateOrderContent_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_text_fontSize {
  /**
   * Type of unit
   */
  type: UnitTypes;
  /**
   * Value
   */
  value: number;
}

export interface UpdateOrderContent_updateOrderContent_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_text_lineHeight {
  /**
   * Type of unit
   */
  type: UnitTypes;
  /**
   * Value
   */
  value: number;
}

export interface UpdateOrderContent_updateOrderContent_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_text_lineHeightEmpty {
  /**
   * Type of unit
   */
  type: UnitTypes;
  /**
   * Value
   */
  value: number;
}

export interface UpdateOrderContent_updateOrderContent_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_text_letterSpacing {
  /**
   * Type of unit
   */
  type: UnitTypes;
  /**
   * Value
   */
  value: number;
}

export interface UpdateOrderContent_updateOrderContent_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_text_textScaleWidth {
  /**
   * Type
   */
  type: RelativeTypes;
  /**
   * Value
   */
  value: number;
}

export interface UpdateOrderContent_updateOrderContent_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_text {
  /**
   * Font style
   */
  fontStyle: FontStyles | null;
  /**
   * Textscale width offset
   */
  textScaleWidthOffset: UpdateOrderContent_updateOrderContent_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_text_textScaleWidthOffset | null;
  /**
   * Font weight
   */
  fontWeight: FontWeights | null;
  /**
   * Text alignment
   */
  textAlignment: TextAlignments | null;
  /**
   * Text formats in xml
   */
  xmlText: string | null;
  /**
   * Is htmleditor readonly
   */
  isXmlTextReadonly: boolean | null;
  /**
   * Fontfamily of the text
   */
  fontFamily: string | null;
  /**
   * Font
   */
  fontSize: UpdateOrderContent_updateOrderContent_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_text_fontSize | null;
  /**
   * Line-height
   */
  lineHeight: UpdateOrderContent_updateOrderContent_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_text_lineHeight | null;
  /**
   * Line-height for empty rows
   */
  lineHeightEmpty: UpdateOrderContent_updateOrderContent_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_text_lineHeightEmpty | null;
  /**
   * Letter-Spacing
   */
  letterSpacing: UpdateOrderContent_updateOrderContent_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_text_letterSpacing | null;
  /**
   * Textscale width
   */
  textScaleWidth: UpdateOrderContent_updateOrderContent_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_text_textScaleWidth | null;
  /**
   * Text do not wrap when text reach its width
   */
  textNoWrap: boolean | null;
}

export interface UpdateOrderContent_updateOrderContent_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_separator {
  /**
   * Type of image
   */
  type: EditModelAdvertAreaContentItemSeparatorTypes;
  imageId: string | null;
  /**
   * Repository of image
   */
  repository: EditModelAdvertAreaContentRepository | null;
  /**
   * Width for the image in mm
   */
  widthMM: number | null;
  /**
   * Height for the image in mm
   */
  heightMM: number | null;
}

export interface UpdateOrderContent_updateOrderContent_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_fields {
  /**
   * Name
   */
  name: string;
  /**
   * Header
   */
  header: string | null;
  /**
   * Type
   */
  type: EditModelAdvertAreaContentItemFieldItemTypes | null;
  /**
   * Editor
   */
  editor: EditModelAdvertAreaContentItemFieldItemEditors | null;
  /**
   * Value
   */
  value: string | null;
  __typename: "EditModelAdvertAreaContentItemFieldItem";
}

export interface UpdateOrderContent_updateOrderContent_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item {
  /**
   * Common
   */
  common: UpdateOrderContent_updateOrderContent_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_common | null;
  /**
   * Value is only for demo purpose
   */
  isDemoValue: boolean | null;
  /**
   * ImageContent
   */
  image: UpdateOrderContent_updateOrderContent_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_image | null;
  /**
   * TextContent
   */
  text: UpdateOrderContent_updateOrderContent_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_text | null;
  /**
   * HorizontalLineContent
   */
  separator: UpdateOrderContent_updateOrderContent_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_separator | null;
  /**
   * Fields
   */
  fields: (UpdateOrderContent_updateOrderContent_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_fields | null)[] | null;
}

export interface UpdateOrderContent_updateOrderContent_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns {
  item: UpdateOrderContent_updateOrderContent_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item;
}

export interface UpdateOrderContent_updateOrderContent_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows {
  columns: UpdateOrderContent_updateOrderContent_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns[];
}

export interface UpdateOrderContent_updateOrderContent_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_value {
  /**
   * Id for the content-value
   */
  id: string | null;
  /**
   * Is content visible
   */
  visible: boolean;
  /**
   * Is it content
   */
  hasContent: boolean;
  /**
   * Is editor visible
   */
  isEditorVisible: boolean;
  /**
   * Id to the template
   */
  templateAreaId: string | null;
  /**
   * Rows
   */
  rows: UpdateOrderContent_updateOrderContent_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows[];
}

export interface UpdateOrderContent_updateOrderContent_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_access {
  add: boolean;
  modify: boolean;
  statusAdd: EditModelAdvertAreaContentEditorAccessStatuses;
}

export interface UpdateOrderContent_updateOrderContent_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_common_allowedMarginLeftRightOffsetInterval {
  /**
   * Type of unit
   */
  type: RelativeTypes;
  /**
   * Max-Value
   */
  maxValue: number;
  /**
   * Min-Value
   */
  minValue: number;
  /**
   * Interval-Value
   */
  intervalValue: number;
}

export interface UpdateOrderContent_updateOrderContent_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_common_blockStyles {
  /**
   * Id
   */
  id: string;
  /**
   * Name
   */
  name: string;
}

export interface UpdateOrderContent_updateOrderContent_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_common {
  /**
   * Margin can adjust margins
   */
  isEnabledAdjustMargins: boolean;
  /**
   * If UpdateOrdersContent is avaiable
   */
  isEnabledUpdateOrdersContent: boolean;
  /**
   * If it is possible to add columns
   */
  isEnabledMultiColumns: boolean;
  /**
   * Enable margin setting
   */
  isMarginLeftRightOffset: boolean;
  /**
   * Allowed margin settings
   */
  allowedMarginLeftRightOffsetInterval: UpdateOrderContent_updateOrderContent_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_common_allowedMarginLeftRightOffsetInterval | null;
  /**
   * Block styles
   */
  blockStyles: UpdateOrderContent_updateOrderContent_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_common_blockStyles[];
}

export interface UpdateOrderContent_updateOrderContent_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_text_allowedFontSizes {
  /**
   * Values
   */
  values: number[];
}

export interface UpdateOrderContent_updateOrderContent_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_text_allowedLineHeights {
  /**
   * Values
   */
  values: number[];
}

export interface UpdateOrderContent_updateOrderContent_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_text_allowedFontSizeInterval {
  /**
   * Type of unit
   */
  type: UnitTypes;
  /**
   * Interval-Value
   */
  intervalValue: number;
}

export interface UpdateOrderContent_updateOrderContent_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_text_allowedLineHeightInterval {
  /**
   * Type of unit
   */
  type: UnitTypes;
  /**
   * Interval-Value
   */
  intervalValue: number;
}

export interface UpdateOrderContent_updateOrderContent_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_text_allowedTextScaleWidthOffsetInterval {
  /**
   * Interval-Value
   */
  intervalValue: number;
  /**
   * Min-Value
   */
  minValue: number;
  /**
   * Max-Value
   */
  maxValue: number;
}

export interface UpdateOrderContent_updateOrderContent_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_text_allowedCharacterGalleries_items {
  /**
   * Id
   */
  id: string;
  /**
   * Name
   */
  name: string;
  /**
   * Title
   */
  title: string;
  /**
   * Preview
   */
  preview: string | null;
  /**
   * Html
   */
  html: string;
}

export interface UpdateOrderContent_updateOrderContent_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_text_allowedCharacterGalleries {
  /**
   * Id
   */
  id: string;
  isAdvancedUser: boolean;
  /**
   * Items
   */
  items: UpdateOrderContent_updateOrderContent_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_text_allowedCharacterGalleries_items[];
}

export interface UpdateOrderContent_updateOrderContent_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_text_allowedParagraphClasses {
  /**
   * Id
   */
  id: string;
  /**
   * Name
   */
  name: string;
}

export interface UpdateOrderContent_updateOrderContent_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_text {
  /**
   * Default textalignment from template
   */
  defaultTextAdjustment: TextAlignments;
  /**
   * Allowed font sizes
   */
  allowedFontSizes: UpdateOrderContent_updateOrderContent_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_text_allowedFontSizes;
  /**
   * Allowed font styles
   */
  allowedFontStyles: FontStyles[];
  /**
   * Allowed font weights
   */
  allowedFontWeights: FontWeights[];
  /**
   * Allowed line height sizes
   */
  allowedLineHeights: UpdateOrderContent_updateOrderContent_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_text_allowedLineHeights;
  /**
   * Allowed font families
   */
  allowedFontFamilies: string[];
  /**
   * Allowed text adjustments
   */
  allowedTextAdjustments: TextAlignments[];
  /**
   * Allowed font size interval
   */
  allowedFontSizeInterval: UpdateOrderContent_updateOrderContent_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_text_allowedFontSizeInterval;
  /**
   * Allowed line height size interval
   */
  allowedLineHeightInterval: UpdateOrderContent_updateOrderContent_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_text_allowedLineHeightInterval;
  /**
   * Enable text scale width offset
   */
  isTextScaleWidthOffset: boolean;
  /**
   * Allowed TextScale width offset interval
   */
  allowedTextScaleWidthOffsetInterval: UpdateOrderContent_updateOrderContent_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_text_allowedTextScaleWidthOffsetInterval | null;
  /**
   * Allowed special characters galleries
   */
  allowedCharacterGalleries: UpdateOrderContent_updateOrderContent_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_text_allowedCharacterGalleries[];
  /**
   * Allowed number of paragraphClass
   */
  allowedParagraphClasses: UpdateOrderContent_updateOrderContent_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_text_allowedParagraphClasses[];
  /**
   * Enable to change font size
   */
  isEnabledFontSize: boolean;
  /**
   * Enable to change font style
   */
  isEnabledFontStyle: boolean;
  /**
   * Enable to change line height
   */
  isEnabledLineHeight: boolean;
  /**
   * Enable to change font family
   */
  isEnabledFontFamily: boolean;
  /**
   * Enable to change font weights
   */
  isEnabledFontWeight: boolean;
  /**
   * Enable to change letter spacing
   */
  isEnabledLetterSpacing: boolean;
  /**
   * Enable to advanced settings for the admin/support
   */
  isEnabledAdvancedSettings: boolean;
  /**
   * Enable to change letter spacing
   */
  isEnabledTextScaleWidthTolerance: boolean;
  /**
   * Enable gallery option
   */
  isEnabledGallery: boolean;
}

export interface UpdateOrderContent_updateOrderContent_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_image_adjustment_brightness {
  /**
   * Interval-Value
   */
  intervalValue: number;
  /**
   * Max-Value
   */
  maxValue: number;
  /**
   * Min-Value
   */
  minValue: number;
  /**
   * Type of unit
   */
  type: RelativeTypes;
}

export interface UpdateOrderContent_updateOrderContent_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_image_adjustment_contrast {
  /**
   * Interval-Value
   */
  intervalValue: number;
  /**
   * Max-Value
   */
  maxValue: number;
  /**
   * Min-Value
   */
  minValue: number;
  /**
   * Type of unit
   */
  type: RelativeTypes;
}

export interface UpdateOrderContent_updateOrderContent_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_image_adjustment_rotate {
  /**
   * Interval-Value
   */
  intervalValue: number;
  /**
   * Max-Value
   */
  maxValue: number;
  /**
   * Min-Value
   */
  minValue: number;
  /**
   * Type of unit
   */
  type: RelativeTypes;
}

export interface UpdateOrderContent_updateOrderContent_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_image_adjustment {
  brightness: UpdateOrderContent_updateOrderContent_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_image_adjustment_brightness;
  contrast: UpdateOrderContent_updateOrderContent_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_image_adjustment_contrast;
  rotate: UpdateOrderContent_updateOrderContent_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_image_adjustment_rotate;
}

export interface UpdateOrderContent_updateOrderContent_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_image_mask {
  imageUrl: string | null;
}

export interface UpdateOrderContent_updateOrderContent_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_image {
  /**
   * Min image height
   */
  minImageHeight: number;
  /**
   * Max image height
   */
  maxImageHeight: number;
  /**
   * Filter by gallery image category
   */
  filterGalleryImageAttributeInternalIds: number[];
  /**
   * Filter by not gallery image category
   */
  filterGalleryImageAttributeNotInternalIds: number[];
  /**
   * Filter by not gallery image category
   */
  galleryImageOption: string;
  /**
   * Filter by not gallery image category
   */
  documentFormatId: string;
  /**
   * Is the user enabled to switch to free size
   */
  isEnabledFreeSize: boolean;
  /**
   * Is the user enabled to upload images
   */
  isEnabledUpload: boolean;
  /**
   * Gallery is enabled
   */
  isEnabledGallery: boolean;
  /**
   * Resize is enabled
   */
  isEnabledResize: boolean;
  adjustment: UpdateOrderContent_updateOrderContent_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_image_adjustment | null;
  mask: UpdateOrderContent_updateOrderContent_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_image_mask | null;
}

export interface UpdateOrderContent_updateOrderContent_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_help {
  html: string | null;
  hoverHtml: string | null;
  supportHtml: string | null;
}

export interface UpdateOrderContent_updateOrderContent_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor {
  /**
   * Type of editor (Image, Text, Separator)
   */
  type: EditModelAdvertAreaContentEditorTypes;
  /**
   * SubType of editor
   */
  behaviour: EditModelAdvertAreaContentEditorBehaviours;
  /**
   * Access properties
   */
  access: UpdateOrderContent_updateOrderContent_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_access;
  /**
   * Common parameters
   */
  common: UpdateOrderContent_updateOrderContent_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_common;
  /**
   * Text parameters
   */
  text: UpdateOrderContent_updateOrderContent_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_text | null;
  /**
   * Image parameters
   */
  image: UpdateOrderContent_updateOrderContent_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_image | null;
  /**
   * Help properties
   */
  help: UpdateOrderContent_updateOrderContent_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_help | null;
}

export interface UpdateOrderContent_updateOrderContent_order_mediaOrder_edit_editModelAdvert_basePage_areas_content {
  /**
   * Validate errors
   */
  validateErrors: UpdateOrderContent_updateOrderContent_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_validateErrors[];
  value: UpdateOrderContent_updateOrderContent_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_value;
  editor: UpdateOrderContent_updateOrderContent_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor | null;
}

export interface UpdateOrderContent_updateOrderContent_order_mediaOrder_edit_editModelAdvert_basePage_areas_style_margin {
  /**
   * Top
   */
  top: number | null;
  /**
   * Left
   */
  left: number | null;
  /**
   * Right
   */
  right: number | null;
  /**
   * Bottom
   */
  bottom: number | null;
}

export interface UpdateOrderContent_updateOrderContent_order_mediaOrder_edit_editModelAdvert_basePage_areas_style_content_size {
  /**
   * Type
   */
  type: UnitTypes;
  /**
   * Min Width
   */
  minWidth: number | null;
  /**
   * Min Height
   */
  minHeight: number | null;
  /**
   * Max Width
   */
  maxWidth: number | null;
  /**
   * Max Height
   */
  maxHeight: number | null;
  /**
   * Fix Width
   */
  fixedWidth: number | null;
  /**
   * Fix Height
   */
  fixedHeight: number | null;
}

export interface UpdateOrderContent_updateOrderContent_order_mediaOrder_edit_editModelAdvert_basePage_areas_style_content {
  /**
   * Size limit
   */
  size: UpdateOrderContent_updateOrderContent_order_mediaOrder_edit_editModelAdvert_basePage_areas_style_content_size | null;
}

export interface UpdateOrderContent_updateOrderContent_order_mediaOrder_edit_editModelAdvert_basePage_areas_style_text_fontSize {
  /**
   * Type of unit
   */
  type: UnitTypes;
  /**
   * Value
   */
  value: number;
}

export interface UpdateOrderContent_updateOrderContent_order_mediaOrder_edit_editModelAdvert_basePage_areas_style_text_lineHeight {
  /**
   * Type of unit
   */
  type: UnitTypes;
  /**
   * Value
   */
  value: number;
}

export interface UpdateOrderContent_updateOrderContent_order_mediaOrder_edit_editModelAdvert_basePage_areas_style_text_lineHeightEmpty {
  /**
   * Type of unit
   */
  type: UnitTypes;
  /**
   * Value
   */
  value: number;
}

export interface UpdateOrderContent_updateOrderContent_order_mediaOrder_edit_editModelAdvert_basePage_areas_style_text_letterSpacing {
  /**
   * Type of unit
   */
  type: UnitTypes;
  /**
   * Value
   */
  value: number;
}

export interface UpdateOrderContent_updateOrderContent_order_mediaOrder_edit_editModelAdvert_basePage_areas_style_text_textScaleWidthOffset {
  /**
   * Type
   */
  type: RelativeTypes;
  /**
   * Value
   */
  value: number;
}

export interface UpdateOrderContent_updateOrderContent_order_mediaOrder_edit_editModelAdvert_basePage_areas_style_text_textScaleWidth {
  /**
   * Type
   */
  type: RelativeTypes;
  /**
   * Value
   */
  value: number;
}

export interface UpdateOrderContent_updateOrderContent_order_mediaOrder_edit_editModelAdvert_basePage_areas_style_text {
  fontFamily: string;
  /**
   * Font-size
   */
  fontSize: UpdateOrderContent_updateOrderContent_order_mediaOrder_edit_editModelAdvert_basePage_areas_style_text_fontSize | null;
  /**
   * Line height
   */
  lineHeight: UpdateOrderContent_updateOrderContent_order_mediaOrder_edit_editModelAdvert_basePage_areas_style_text_lineHeight | null;
  /**
   * Line height for empty rows
   */
  lineHeightEmpty: UpdateOrderContent_updateOrderContent_order_mediaOrder_edit_editModelAdvert_basePage_areas_style_text_lineHeightEmpty | null;
  /**
   * Letter spacing
   */
  letterSpacing: UpdateOrderContent_updateOrderContent_order_mediaOrder_edit_editModelAdvert_basePage_areas_style_text_letterSpacing | null;
  textScaleWidthOffset: UpdateOrderContent_updateOrderContent_order_mediaOrder_edit_editModelAdvert_basePage_areas_style_text_textScaleWidthOffset;
  textScaleWidth: UpdateOrderContent_updateOrderContent_order_mediaOrder_edit_editModelAdvert_basePage_areas_style_text_textScaleWidth;
  fontStyle: FontStyles | null;
  fontWeight: FontWeights | null;
}

export interface UpdateOrderContent_updateOrderContent_order_mediaOrder_edit_editModelAdvert_basePage_areas_style {
  /**
   * margins
   */
  margin: UpdateOrderContent_updateOrderContent_order_mediaOrder_edit_editModelAdvert_basePage_areas_style_margin | null;
  content: UpdateOrderContent_updateOrderContent_order_mediaOrder_edit_editModelAdvert_basePage_areas_style_content;
  /**
   * Text settings
   */
  text: UpdateOrderContent_updateOrderContent_order_mediaOrder_edit_editModelAdvert_basePage_areas_style_text;
}

export interface UpdateOrderContent_updateOrderContent_order_mediaOrder_edit_editModelAdvert_basePage_areas_rectangle {
  /**
   * X coordinate
   */
  x: number;
  /**
   * Y coordinate
   */
  y: number;
  /**
   * Width
   */
  w: number;
  /**
   * Width
   */
  h: number;
}

export interface UpdateOrderContent_updateOrderContent_order_mediaOrder_edit_editModelAdvert_basePage_areas {
  /**
   * Id of the blockeditor
   */
  id: string;
  /**
   * Parameters of the blockeditor
   */
  header: string;
  content: UpdateOrderContent_updateOrderContent_order_mediaOrder_edit_editModelAdvert_basePage_areas_content;
  style: UpdateOrderContent_updateOrderContent_order_mediaOrder_edit_editModelAdvert_basePage_areas_style;
  rectangle: UpdateOrderContent_updateOrderContent_order_mediaOrder_edit_editModelAdvert_basePage_areas_rectangle | null;
}

export interface UpdateOrderContent_updateOrderContent_order_mediaOrder_edit_editModelAdvert_basePage {
  areas: (UpdateOrderContent_updateOrderContent_order_mediaOrder_edit_editModelAdvert_basePage_areas | null)[] | null;
}

export interface UpdateOrderContent_updateOrderContent_order_mediaOrder_edit_editModelAdvert {
  /**
   * If UpdateOrdersContent is avaiable
   */
  isEnabledUpdateOrdersContent: boolean;
  basePage: UpdateOrderContent_updateOrderContent_order_mediaOrder_edit_editModelAdvert_basePage;
}

export interface UpdateOrderContent_updateOrderContent_order_mediaOrder_edit_history {
  /**
   * How many steps it is possible to go back in history
   */
  countStepBack: number;
}

export interface UpdateOrderContent_updateOrderContent_order_mediaOrder_edit {
  /**
   * Texts for editing the order
   */
  userInterface: UpdateOrderContent_updateOrderContent_order_mediaOrder_edit_userInterface;
  resources: UpdateOrderContent_updateOrderContent_order_mediaOrder_edit_resources | null;
  editModelAdvert: UpdateOrderContent_updateOrderContent_order_mediaOrder_edit_editModelAdvert | null;
  history: UpdateOrderContent_updateOrderContent_order_mediaOrder_edit_history;
}

export interface UpdateOrderContent_updateOrderContent_order_mediaOrder_preview_material_basePage_size {
  /**
   * Width
   */
  width: number;
  /**
   * Height
   */
  height: number;
}

export interface UpdateOrderContent_updateOrderContent_order_mediaOrder_preview_material_basePage {
  size: UpdateOrderContent_updateOrderContent_order_mediaOrder_preview_material_basePage_size | null;
}

export interface UpdateOrderContent_updateOrderContent_order_mediaOrder_preview_material_pages_png {
  /**
   * Url till pngfile
   */
  url: string;
}

export interface UpdateOrderContent_updateOrderContent_order_mediaOrder_preview_material_pages {
  /**
   * Png material
   */
  png: UpdateOrderContent_updateOrderContent_order_mediaOrder_preview_material_pages_png;
}

export interface UpdateOrderContent_updateOrderContent_order_mediaOrder_preview_material {
  /**
   * base page
   */
  basePage: UpdateOrderContent_updateOrderContent_order_mediaOrder_preview_material_basePage;
  /**
   * pages
   */
  pages: (UpdateOrderContent_updateOrderContent_order_mediaOrder_preview_material_pages | null)[];
}

export interface UpdateOrderContent_updateOrderContent_order_mediaOrder_preview_price_customer {
  /**
   * How much the product is pricing for the scope in the contract
   */
  priceContract: any | null;
  /**
   * How much the product is pricing for the order if it has been override the contract
   */
  priceContractOverride: any | null;
}

export interface UpdateOrderContent_updateOrderContent_order_mediaOrder_preview_price_endCustomer {
  /**
   * Whats pay for the order and rounded
   */
  pricePayRounded: any | null;
}

export interface UpdateOrderContent_updateOrderContent_order_mediaOrder_preview_price {
  /**
   * Currency
   */
  currency: string;
  /**
   * Customer view of the price
   */
  customer: UpdateOrderContent_updateOrderContent_order_mediaOrder_preview_price_customer;
  /**
   * EndCustomer view of the price
   */
  endCustomer: UpdateOrderContent_updateOrderContent_order_mediaOrder_preview_price_endCustomer;
}

export interface UpdateOrderContent_updateOrderContent_order_mediaOrder_preview {
  /**
   * material
   */
  material: UpdateOrderContent_updateOrderContent_order_mediaOrder_preview_material;
  /**
   * price of the order
   */
  price: UpdateOrderContent_updateOrderContent_order_mediaOrder_preview_price | null;
}

export interface UpdateOrderContent_updateOrderContent_order_mediaOrder_media {
  /**
   * The id of the media
   */
  id: string | null;
  /**
   * The friendlyName of the media
   */
  friendlyName: string;
}

export interface UpdateOrderContent_updateOrderContent_order_mediaOrder_status {
  /**
   * Color for vieweing the customer
   */
  color: StatusColors;
  /**
   * ProductStatus enum for the status
   */
  status: OrderStatus;
}

export interface UpdateOrderContent_updateOrderContent_order_mediaOrder {
  /**
   * Time when last sending the booking to media
   */
  timeBookingStop: any | null;
  /**
   * Locked
   */
  isLocked: boolean;
  /**
   * times when the order will be published
   */
  publishTimes: (UpdateOrderContent_updateOrderContent_order_mediaOrder_publishTimes | null)[];
  /**
   * documentformat of the order
   */
  documentFormat: UpdateOrderContent_updateOrderContent_order_mediaOrder_documentFormat;
  /**
   * Publish conversation settings for the media
   */
  publishConversation: UpdateOrderContent_updateOrderContent_order_mediaOrder_publishConversation;
  /**
   * Parallell publish channels
   */
  publishChannels: UpdateOrderContent_updateOrderContent_order_mediaOrder_publishChannels;
  /**
   * information for the editors
   */
  edit: UpdateOrderContent_updateOrderContent_order_mediaOrder_edit;
  /**
   * Preview of the order
   */
  preview: UpdateOrderContent_updateOrderContent_order_mediaOrder_preview;
  /**
   * media of the order
   */
  media: UpdateOrderContent_updateOrderContent_order_mediaOrder_media;
  /**
   * Status of the order
   */
  status: UpdateOrderContent_updateOrderContent_order_mediaOrder_status;
}

export interface UpdateOrderContent_updateOrderContent_order {
  /**
   * The id of the mediaorder
   */
  id: string | null;
  /**
   * The Id shown for customer
   */
  customerFriendlyId: string;
  /**
   * Order is deleted
   */
  isDeleted: boolean;
  /**
   * document of the order
   */
  document: UpdateOrderContent_updateOrderContent_order_document;
  client: UpdateOrderContent_updateOrderContent_order_client | null;
  alerting: UpdateOrderContent_updateOrderContent_order_alerting;
  /**
   * mediaOrder properties if that kind of order
   */
  mediaOrder: UpdateOrderContent_updateOrderContent_order_mediaOrder | null;
}

export interface UpdateOrderContent_updateOrderContent {
  /**
   * Mutation is not successful
   */
  isError: boolean;
  /**
   * Reasons
   */
  errorReason: string | null;
  order: UpdateOrderContent_updateOrderContent_order;
}

export interface UpdateOrderContent {
  /**
   * Update contentModel in order
   */
  updateOrderContent: UpdateOrderContent_updateOrderContent;
}

export interface UpdateOrderContentVariables {
  input: MutationUpdateOrderContentInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateOrderContentNoValues
// ====================================================

export interface UpdateOrderContentNoValues_updateOrderContent_order_document {
  /**
   * Id of the document
   */
  id: string | null;
}

export interface UpdateOrderContentNoValues_updateOrderContent_order_mediaOrder_publishTimes {
  /**
   * Date when the order will be published
   */
  time: any;
}

export interface UpdateOrderContentNoValues_updateOrderContent_order_mediaOrder_documentFormat {
  /**
   * Id of the documentformat
   */
  id: string;
  /**
   * The name of the documentformat
   */
  name: string;
}

export interface UpdateOrderContentNoValues_updateOrderContent_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_value {
  /**
   * Id for the content-value
   */
  id: string | null;
  /**
   * Is it content
   */
  hasContent: boolean;
  /**
   * Is content visible
   */
  visible: boolean;
}

export interface UpdateOrderContentNoValues_updateOrderContent_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_text {
  /**
   * Allowed text adjustments
   */
  allowedTextAdjustments: TextAlignments[];
  /**
   * Default textalignment from template
   */
  defaultTextAdjustment: TextAlignments;
}

export interface UpdateOrderContentNoValues_updateOrderContent_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_image {
  /**
   * Min image height
   */
  minImageHeight: number;
  /**
   * Max image height
   */
  maxImageHeight: number;
}

export interface UpdateOrderContentNoValues_updateOrderContent_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor {
  /**
   * Type of editor (Image, Text, Separator)
   */
  type: EditModelAdvertAreaContentEditorTypes;
  /**
   * SubType of editor
   */
  behaviour: EditModelAdvertAreaContentEditorBehaviours;
  /**
   * Text parameters
   */
  text: UpdateOrderContentNoValues_updateOrderContent_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_text | null;
  /**
   * Image parameters
   */
  image: UpdateOrderContentNoValues_updateOrderContent_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_image | null;
}

export interface UpdateOrderContentNoValues_updateOrderContent_order_mediaOrder_edit_editModelAdvert_basePage_areas_content {
  value: UpdateOrderContentNoValues_updateOrderContent_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_value;
  editor: UpdateOrderContentNoValues_updateOrderContent_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor | null;
}

export interface UpdateOrderContentNoValues_updateOrderContent_order_mediaOrder_edit_editModelAdvert_basePage_areas_rectangle {
  /**
   * X coordinate
   */
  x: number;
  /**
   * Y coordinate
   */
  y: number;
  /**
   * Width
   */
  w: number;
  /**
   * Width
   */
  h: number;
}

export interface UpdateOrderContentNoValues_updateOrderContent_order_mediaOrder_edit_editModelAdvert_basePage_areas {
  /**
   * Id of the blockeditor
   */
  id: string;
  /**
   * Parameters of the blockeditor
   */
  header: string;
  content: UpdateOrderContentNoValues_updateOrderContent_order_mediaOrder_edit_editModelAdvert_basePage_areas_content;
  rectangle: UpdateOrderContentNoValues_updateOrderContent_order_mediaOrder_edit_editModelAdvert_basePage_areas_rectangle | null;
}

export interface UpdateOrderContentNoValues_updateOrderContent_order_mediaOrder_edit_editModelAdvert_basePage {
  areas: (UpdateOrderContentNoValues_updateOrderContent_order_mediaOrder_edit_editModelAdvert_basePage_areas | null)[] | null;
}

export interface UpdateOrderContentNoValues_updateOrderContent_order_mediaOrder_edit_editModelAdvert {
  basePage: UpdateOrderContentNoValues_updateOrderContent_order_mediaOrder_edit_editModelAdvert_basePage;
}

export interface UpdateOrderContentNoValues_updateOrderContent_order_mediaOrder_edit_history {
  /**
   * How many steps it is possible to go back in history
   */
  countStepBack: number;
}

export interface UpdateOrderContentNoValues_updateOrderContent_order_mediaOrder_edit {
  editModelAdvert: UpdateOrderContentNoValues_updateOrderContent_order_mediaOrder_edit_editModelAdvert | null;
  history: UpdateOrderContentNoValues_updateOrderContent_order_mediaOrder_edit_history;
}

export interface UpdateOrderContentNoValues_updateOrderContent_order_mediaOrder_preview_material_basePage_size {
  /**
   * Width
   */
  width: number;
  /**
   * Height
   */
  height: number;
}

export interface UpdateOrderContentNoValues_updateOrderContent_order_mediaOrder_preview_material_basePage {
  size: UpdateOrderContentNoValues_updateOrderContent_order_mediaOrder_preview_material_basePage_size | null;
}

export interface UpdateOrderContentNoValues_updateOrderContent_order_mediaOrder_preview_material_pages_png {
  /**
   * Url till pngfile
   */
  url: string;
}

export interface UpdateOrderContentNoValues_updateOrderContent_order_mediaOrder_preview_material_pages {
  /**
   * Png material
   */
  png: UpdateOrderContentNoValues_updateOrderContent_order_mediaOrder_preview_material_pages_png;
}

export interface UpdateOrderContentNoValues_updateOrderContent_order_mediaOrder_preview_material {
  /**
   * base page
   */
  basePage: UpdateOrderContentNoValues_updateOrderContent_order_mediaOrder_preview_material_basePage;
  /**
   * pages
   */
  pages: (UpdateOrderContentNoValues_updateOrderContent_order_mediaOrder_preview_material_pages | null)[];
}

export interface UpdateOrderContentNoValues_updateOrderContent_order_mediaOrder_preview {
  /**
   * material
   */
  material: UpdateOrderContentNoValues_updateOrderContent_order_mediaOrder_preview_material;
}

export interface UpdateOrderContentNoValues_updateOrderContent_order_mediaOrder_media {
  /**
   * The id of the media
   */
  id: string | null;
  /**
   * The name of the media
   */
  name: string;
}

export interface UpdateOrderContentNoValues_updateOrderContent_order_mediaOrder_status {
  /**
   * Color for vieweing the customer
   */
  color: StatusColors;
  /**
   * ProductStatus enum for the status
   */
  status: OrderStatus;
}

export interface UpdateOrderContentNoValues_updateOrderContent_order_mediaOrder {
  /**
   * Time when last sending the booking to media
   */
  timeBookingStop: any | null;
  /**
   * Locked
   */
  isLocked: boolean;
  /**
   * times when the order will be published
   */
  publishTimes: (UpdateOrderContentNoValues_updateOrderContent_order_mediaOrder_publishTimes | null)[];
  /**
   * documentformat of the order
   */
  documentFormat: UpdateOrderContentNoValues_updateOrderContent_order_mediaOrder_documentFormat;
  /**
   * information for the editors
   */
  edit: UpdateOrderContentNoValues_updateOrderContent_order_mediaOrder_edit;
  /**
   * Preview of the order
   */
  preview: UpdateOrderContentNoValues_updateOrderContent_order_mediaOrder_preview;
  /**
   * media of the order
   */
  media: UpdateOrderContentNoValues_updateOrderContent_order_mediaOrder_media;
  /**
   * Status of the order
   */
  status: UpdateOrderContentNoValues_updateOrderContent_order_mediaOrder_status;
}

export interface UpdateOrderContentNoValues_updateOrderContent_order {
  /**
   * The id of the mediaorder
   */
  id: string | null;
  /**
   * The Id shown for customer
   */
  customerFriendlyId: string;
  /**
   * document of the order
   */
  document: UpdateOrderContentNoValues_updateOrderContent_order_document;
  /**
   * mediaOrder properties if that kind of order
   */
  mediaOrder: UpdateOrderContentNoValues_updateOrderContent_order_mediaOrder | null;
}

export interface UpdateOrderContentNoValues_updateOrderContent {
  order: UpdateOrderContentNoValues_updateOrderContent_order;
}

export interface UpdateOrderContentNoValues {
  /**
   * Update contentModel in order
   */
  updateOrderContent: UpdateOrderContentNoValues_updateOrderContent;
}

export interface UpdateOrderContentNoValuesVariables {
  input: MutationUpdateOrderContentInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateOrderLock
// ====================================================

export interface UpdateOrderLock_lockOrder_order_mediaOrder_status {
  /**
   * Color for vieweing the customer
   */
  color: StatusColors;
  /**
   * ProductStatus enum for the status
   */
  status: OrderStatus;
}

export interface UpdateOrderLock_lockOrder_order_mediaOrder {
  /**
   * Locked
   */
  isLocked: boolean;
  /**
   * Status of the order
   */
  status: UpdateOrderLock_lockOrder_order_mediaOrder_status;
  __typename: "MediaOrder";
}

export interface UpdateOrderLock_lockOrder_order {
  /**
   * The id of the mediaorder
   */
  id: string | null;
  /**
   * mediaOrder properties if that kind of order
   */
  mediaOrder: UpdateOrderLock_lockOrder_order_mediaOrder | null;
}

export interface UpdateOrderLock_lockOrder {
  /**
   * Mutation is not successful
   */
  isError: boolean;
  /**
   * Reasons
   */
  errorReason: string | null;
  order: UpdateOrderLock_lockOrder_order;
}

export interface UpdateOrderLock {
  /**
   * Lock an order
   */
  lockOrder: UpdateOrderLock_lockOrder;
}

export interface UpdateOrderLockVariables {
  input: MutationLockOrderInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateOrderPrice
// ====================================================

export interface UpdateOrderPrice_updateOrderPrice_order_mediaOrder_preview_price_customer {
  /**
   * How much the product is pricing for the scope in the contract
   */
  priceContract: any | null;
  /**
   * How much the product is pricing for the order if it has been override the contract
   */
  priceContractOverride: any | null;
}

export interface UpdateOrderPrice_updateOrderPrice_order_mediaOrder_preview_price_endCustomer {
  /**
   * Whats pay for the order and rounded
   */
  pricePayRounded: any | null;
}

export interface UpdateOrderPrice_updateOrderPrice_order_mediaOrder_preview_price {
  /**
   * Currency
   */
  currency: string;
  /**
   * Customer view of the price
   */
  customer: UpdateOrderPrice_updateOrderPrice_order_mediaOrder_preview_price_customer;
  /**
   * EndCustomer view of the price
   */
  endCustomer: UpdateOrderPrice_updateOrderPrice_order_mediaOrder_preview_price_endCustomer;
}

export interface UpdateOrderPrice_updateOrderPrice_order_mediaOrder_preview {
  /**
   * price of the order
   */
  price: UpdateOrderPrice_updateOrderPrice_order_mediaOrder_preview_price | null;
}

export interface UpdateOrderPrice_updateOrderPrice_order_mediaOrder {
  /**
   * Preview of the order
   */
  preview: UpdateOrderPrice_updateOrderPrice_order_mediaOrder_preview;
}

export interface UpdateOrderPrice_updateOrderPrice_order {
  /**
   * The id of the mediaorder
   */
  id: string | null;
  /**
   * mediaOrder properties if that kind of order
   */
  mediaOrder: UpdateOrderPrice_updateOrderPrice_order_mediaOrder | null;
}

export interface UpdateOrderPrice_updateOrderPrice {
  order: UpdateOrderPrice_updateOrderPrice_order;
}

export interface UpdateOrderPrice {
  /**
   * Update order price
   */
  updateOrderPrice: UpdateOrderPrice_updateOrderPrice;
}

export interface UpdateOrderPriceVariables {
  input: MutationUpdateOrderPriceInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdatePackage
// ====================================================

export interface UpdatePackage_updateOrderInitiatorPackages_orderInitiatorPackages_items_media {
  /**
   * The id of the media
   */
  id: string | null;
  /**
   * The friendlyName of the media
   */
  friendlyName: string;
}

export interface UpdatePackage_updateOrderInitiatorPackages_orderInitiatorPackages_items_inputId {
  /**
   * Id to documentFormatInitiatorId
   */
  documentFormatInitiatorId: string | null;
  /**
   * Id to custmoerDocumentFormatInitiatorId
   */
  customerDocumentFormatInitiatorId: string | null;
  /**
   * Id to media
   */
  mediaId: string | null;
}

export interface UpdatePackage_updateOrderInitiatorPackages_orderInitiatorPackages_items {
  /**
   * media of productpackageitem
   */
  media: UpdatePackage_updateOrderInitiatorPackages_orderInitiatorPackages_items_media;
  /**
   * Id object
   */
  inputId: UpdatePackage_updateOrderInitiatorPackages_orderInitiatorPackages_items_inputId;
}

export interface UpdatePackage_updateOrderInitiatorPackages_orderInitiatorPackages {
  /**
   * Id of the ProductPackage
   */
  id: string;
  /**
   * Name of the ProductPackage
   */
  name: string;
  /**
   * Which customer has access to the package
   */
  accessCustomerScope: CustomerScope | null;
  /**
   * Common price for the packages
   */
  commonPrice: any | null;
  imageId: string | null;
  /**
   * items of productpackages
   */
  items: UpdatePackage_updateOrderInitiatorPackages_orderInitiatorPackages_items[] | null;
}

export interface UpdatePackage_updateOrderInitiatorPackages {
  /**
   * Mutation is not successful
   */
  isError: boolean;
  /**
   * Reasons
   */
  errorReason: string | null;
  orderInitiatorPackages: (UpdatePackage_updateOrderInitiatorPackages_orderInitiatorPackages | null)[];
}

export interface UpdatePackage {
  /**
   * Update order-initiator packages
   */
  updateOrderInitiatorPackages: UpdatePackage_updateOrderInitiatorPackages;
}

export interface UpdatePackageVariables {
  input: MutationUpdateOrderInitiatorPackagesInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdatePublishChannel
// ====================================================

export interface UpdatePublishChannel_updateOrderPublishChannels_order_caseFolder {
  /**
   * The id of the caseFolder
   */
  id: string | null;
}

export interface UpdatePublishChannel_updateOrderPublishChannels_order_mediaOrder_publishChannels {
  /**
   * Publish in familjesidan
   */
  inFamiljesidan: boolean;
  /**
   * Publish in minnesrummet
   */
  inMinnesrummet: boolean;
  /**
   * Publish in agency homepage
   */
  inAgency: boolean;
}

export interface UpdatePublishChannel_updateOrderPublishChannels_order_mediaOrder_preview_price_endCustomer {
  /**
   * Whats pay for the order and rounded
   */
  pricePayRounded: any | null;
}

export interface UpdatePublishChannel_updateOrderPublishChannels_order_mediaOrder_preview_price {
  /**
   * EndCustomer view of the price
   */
  endCustomer: UpdatePublishChannel_updateOrderPublishChannels_order_mediaOrder_preview_price_endCustomer;
}

export interface UpdatePublishChannel_updateOrderPublishChannels_order_mediaOrder_preview {
  /**
   * price of the order
   */
  price: UpdatePublishChannel_updateOrderPublishChannels_order_mediaOrder_preview_price | null;
}

export interface UpdatePublishChannel_updateOrderPublishChannels_order_mediaOrder {
  /**
   * Parallell publish channels
   */
  publishChannels: UpdatePublishChannel_updateOrderPublishChannels_order_mediaOrder_publishChannels;
  /**
   * Preview of the order
   */
  preview: UpdatePublishChannel_updateOrderPublishChannels_order_mediaOrder_preview;
}

export interface UpdatePublishChannel_updateOrderPublishChannels_order {
  /**
   * The id of the mediaorder
   */
  id: string | null;
  /**
   * caseFolder of the order
   */
  caseFolder: UpdatePublishChannel_updateOrderPublishChannels_order_caseFolder;
  /**
   * mediaOrder properties if that kind of order
   */
  mediaOrder: UpdatePublishChannel_updateOrderPublishChannels_order_mediaOrder | null;
}

export interface UpdatePublishChannel_updateOrderPublishChannels {
  /**
   * Mutation is not successful
   */
  isError: boolean;
  /**
   * Reasons
   */
  errorReason: string | null;
  order: UpdatePublishChannel_updateOrderPublishChannels_order;
}

export interface UpdatePublishChannel {
  /**
   * Update order publish channel flags
   */
  updateOrderPublishChannels: UpdatePublishChannel_updateOrderPublishChannels;
}

export interface UpdatePublishChannelVariables {
  input: MutationUpdateOrderPublishChannelsInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdatePublishTime
// ====================================================

export interface UpdatePublishTime_updateOrderPublishTimes_order_alerting {
  smallText: string | null;
  text: string | null;
  type: OrderAlertingType;
}

export interface UpdatePublishTime_updateOrderPublishTimes_order_mediaOrder_preview_material_basePage_size {
  /**
   * Width
   */
  width: number;
  /**
   * Height
   */
  height: number;
}

export interface UpdatePublishTime_updateOrderPublishTimes_order_mediaOrder_preview_material_basePage {
  size: UpdatePublishTime_updateOrderPublishTimes_order_mediaOrder_preview_material_basePage_size | null;
}

export interface UpdatePublishTime_updateOrderPublishTimes_order_mediaOrder_preview_material_pages_png {
  /**
   * Url till pngfile
   */
  url: string;
}

export interface UpdatePublishTime_updateOrderPublishTimes_order_mediaOrder_preview_material_pages {
  /**
   * Png material
   */
  png: UpdatePublishTime_updateOrderPublishTimes_order_mediaOrder_preview_material_pages_png;
}

export interface UpdatePublishTime_updateOrderPublishTimes_order_mediaOrder_preview_material {
  /**
   * base page
   */
  basePage: UpdatePublishTime_updateOrderPublishTimes_order_mediaOrder_preview_material_basePage;
  /**
   * pages
   */
  pages: (UpdatePublishTime_updateOrderPublishTimes_order_mediaOrder_preview_material_pages | null)[];
}

export interface UpdatePublishTime_updateOrderPublishTimes_order_mediaOrder_preview_price_endCustomer {
  /**
   * Whats pay for the order and rounded
   */
  pricePayRounded: any | null;
}

export interface UpdatePublishTime_updateOrderPublishTimes_order_mediaOrder_preview_price {
  /**
   * Currency
   */
  currency: string;
  /**
   * EndCustomer view of the price
   */
  endCustomer: UpdatePublishTime_updateOrderPublishTimes_order_mediaOrder_preview_price_endCustomer;
}

export interface UpdatePublishTime_updateOrderPublishTimes_order_mediaOrder_preview {
  /**
   * material
   */
  material: UpdatePublishTime_updateOrderPublishTimes_order_mediaOrder_preview_material;
  /**
   * price of the order
   */
  price: UpdatePublishTime_updateOrderPublishTimes_order_mediaOrder_preview_price | null;
}

export interface UpdatePublishTime_updateOrderPublishTimes_order_mediaOrder_publishConversation {
  /**
   * Message to media
   */
  messageFromMedia: string | null;
  /**
   * Code to verify late publish
   */
  codeLate: string;
  /**
   * Code to media. Used when the ordering is late. Passed bookingstop. This must be verfied before it can be published
   */
  isCodeLate: boolean;
  /**
   * Message to media allowed
   */
  isMessageToMedia: boolean;
  /**
   * Media-System Ordernumber has to be set
   */
  isMediaSystemOrderNumber: boolean;
  /**
   * EndCustomer subribenumber on media can be set
   */
  isMediaCustomerSubriberNumber: boolean;
  /**
   * Status for publish
   */
  publishReadyStatus: MediaOrderPublishhReadyStatus;
}

export interface UpdatePublishTime_updateOrderPublishTimes_order_mediaOrder_publishChannels {
  /**
   * Publish in familjesidan
   */
  inFamiljesidan: boolean;
  /**
   * Publish in minnesrummet
   */
  inMinnesrummet: boolean;
  /**
   * Publish in agency homepage
   */
  inAgency: boolean;
}

export interface UpdatePublishTime_updateOrderPublishTimes_order_mediaOrder_publishTimes {
  /**
   * Date when the order will be published
   */
  time: any;
}

export interface UpdatePublishTime_updateOrderPublishTimes_order_mediaOrder {
  /**
   * Preview of the order
   */
  preview: UpdatePublishTime_updateOrderPublishTimes_order_mediaOrder_preview;
  /**
   * Publish conversation settings for the media
   */
  publishConversation: UpdatePublishTime_updateOrderPublishTimes_order_mediaOrder_publishConversation;
  /**
   * Parallell publish channels
   */
  publishChannels: UpdatePublishTime_updateOrderPublishTimes_order_mediaOrder_publishChannels;
  /**
   * times when the order will be published
   */
  publishTimes: (UpdatePublishTime_updateOrderPublishTimes_order_mediaOrder_publishTimes | null)[];
}

export interface UpdatePublishTime_updateOrderPublishTimes_order {
  /**
   * The id of the mediaorder
   */
  id: string | null;
  alerting: UpdatePublishTime_updateOrderPublishTimes_order_alerting;
  /**
   * mediaOrder properties if that kind of order
   */
  mediaOrder: UpdatePublishTime_updateOrderPublishTimes_order_mediaOrder | null;
}

export interface UpdatePublishTime_updateOrderPublishTimes {
  /**
   * Mutation is not successful
   */
  isError: boolean;
  /**
   * Reasons
   */
  errorReason: string | null;
  order: UpdatePublishTime_updateOrderPublishTimes_order;
}

export interface UpdatePublishTime {
  /**
   * Update order publish times
   */
  updateOrderPublishTimes: UpdatePublishTime_updateOrderPublishTimes;
}

export interface UpdatePublishTimeVariables {
  input: MutationUpdateOrderPublishTimesInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: documentFormat
// ====================================================

export interface documentFormat_documentFormat_availableStyleTemplates2 {
  id: number;
  name: string | null;
  color: string | null;
}

export interface documentFormat_documentFormat {
  /**
   * Id of the documentformat
   */
  id: string;
  /**
   * The name of the documentformat
   */
  name: string;
  /**
   * documentformat of the order
   */
  availableStyleTemplates2: documentFormat_documentFormat_availableStyleTemplates2[];
}

export interface documentFormat {
  /**
   * Get documentFormat
   */
  documentFormat: documentFormat_documentFormat;
}

export interface documentFormatVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetAdminActions
// ====================================================

export interface GetAdminActions_adminActions_parameters {
  /**
   * Id of the parameter
   */
  id: string;
  /**
   * Description of the parameter
   */
  description: string;
  /**
   * Validateregex of the parameter
   */
  validateRegEx: string;
  /**
   * Value for the parameter
   */
  value: string;
}

export interface GetAdminActions_adminActions {
  /**
   * Id of the action
   */
  actionId: string;
  /**
   * Name of the action
   */
  name: string;
  /**
   * Menu it will be present in
   */
  menuPath: string;
  /**
   * DocumentId for action
   */
  documentId: string | null;
  /**
   * Parameters using in action
   */
  parameters: GetAdminActions_adminActions_parameters[] | null;
  /**
   * Confirm message if needed
   */
  confirmMessage: string | null;
}

export interface GetAdminActions {
  adminActions: GetAdminActions_adminActions[];
}

export interface GetAdminActionsVariables {
  orderId?: string | null;
  documentId?: string | null;
  customerId?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetAdminCaseFolders
// ====================================================

export interface GetAdminCaseFolders_findCaseFoldersByConditions_caseFolders_documents_documentFormat {
  mediaAdaptedName: string;
}

export interface GetAdminCaseFolders_findCaseFoldersByConditions_caseFolders_documents_orders_mediaOrder_media {
  /**
   * The id of the media
   */
  id: string | null;
  /**
   * The friendlyName of the media
   */
  friendlyName: string;
}

export interface GetAdminCaseFolders_findCaseFoldersByConditions_caseFolders_documents_orders_mediaOrder_status {
  /**
   * Color for vieweing the customer
   */
  color: StatusColors;
  /**
   * ProductStatus enum for the status
   */
  status: OrderStatus;
}

export interface GetAdminCaseFolders_findCaseFoldersByConditions_caseFolders_documents_orders_mediaOrder {
  /**
   * media of the order
   */
  media: GetAdminCaseFolders_findCaseFoldersByConditions_caseFolders_documents_orders_mediaOrder_media;
  /**
   * Status of the order
   */
  status: GetAdminCaseFolders_findCaseFoldersByConditions_caseFolders_documents_orders_mediaOrder_status;
}

export interface GetAdminCaseFolders_findCaseFoldersByConditions_caseFolders_documents_orders {
  /**
   * The Id shown for customer
   */
  customerFriendlyId: string;
  /**
   * The id of the mediaorder
   */
  idString: string | null;
  /**
   * mediaOrder properties if that kind of order
   */
  mediaOrder: GetAdminCaseFolders_findCaseFoldersByConditions_caseFolders_documents_orders_mediaOrder | null;
}

export interface GetAdminCaseFolders_findCaseFoldersByConditions_caseFolders_documents {
  /**
   * Id of the document
   */
  idString: string | null;
  /**
   * format of the document
   */
  documentFormat: GetAdminCaseFolders_findCaseFoldersByConditions_caseFolders_documents_documentFormat;
  orders: GetAdminCaseFolders_findCaseFoldersByConditions_caseFolders_documents_orders[];
}

export interface GetAdminCaseFolders_findCaseFoldersByConditions_caseFolders_customer_office {
  /**
   * The name of office
   */
  name: string;
}

export interface GetAdminCaseFolders_findCaseFoldersByConditions_caseFolders_customer {
  /**
   * The id of customer
   */
  idString: string;
  /**
   * Get tempSessionToken for login as your user but this customer
   */
  tempSessionToken: string;
  office: GetAdminCaseFolders_findCaseFoldersByConditions_caseFolders_customer_office;
}

export interface GetAdminCaseFolders_findCaseFoldersByConditions_caseFolders {
  /**
   * The id of the caseFolder
   */
  idString: string | null;
  /**
   * The name of the caseFolder
   */
  name: string;
  documents: (GetAdminCaseFolders_findCaseFoldersByConditions_caseFolders_documents | null)[];
  customer: GetAdminCaseFolders_findCaseFoldersByConditions_caseFolders_customer;
}

export interface GetAdminCaseFolders_findCaseFoldersByConditions {
  caseFolders: (GetAdminCaseFolders_findCaseFoldersByConditions_caseFolders | null)[] | null;
}

export interface GetAdminCaseFolders {
  /**
   * Get case Folders from conditions (admin)
   */
  findCaseFoldersByConditions: GetAdminCaseFolders_findCaseFoldersByConditions;
}

export interface GetAdminCaseFoldersVariables {
  timeFrom?: any | null;
  limit?: number | null;
  condition: SearchOrderConditionInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetAdminCustomers
// ====================================================

export interface GetAdminCustomers_customerTreesByFreeSearch_companies_offices_customers_primaryUser {
  /**
   * The username of user
   */
  username: string;
}

export interface GetAdminCustomers_customerTreesByFreeSearch_companies_offices_customers {
  /**
   * The id of customer
   */
  idString: string;
  /**
   * The internalid of customer
   */
  internalId: number;
  /**
   * The name of customer
   */
  name: string;
  /**
   * Get tempSessionToken for login as your user but this customer
   */
  tempSessionToken: string;
  /**
   * The primary user for the customer
   */
  primaryUser: GetAdminCustomers_customerTreesByFreeSearch_companies_offices_customers_primaryUser | null;
}

export interface GetAdminCustomers_customerTreesByFreeSearch_companies_offices {
  /**
   * The id of office
   */
  idString: string;
  /**
   * The name of office
   */
  name: string;
  customers: GetAdminCustomers_customerTreesByFreeSearch_companies_offices_customers[];
}

export interface GetAdminCustomers_customerTreesByFreeSearch_companies {
  /**
   * The id of company
   */
  idString: string;
  /**
   * The name of company
   */
  name: string;
  offices: GetAdminCustomers_customerTreesByFreeSearch_companies_offices[];
}

export interface GetAdminCustomers_customerTreesByFreeSearch {
  companies: GetAdminCustomers_customerTreesByFreeSearch_companies[];
}

export interface GetAdminCustomers {
  customerTreesByFreeSearch: GetAdminCustomers_customerTreesByFreeSearch;
}

export interface GetAdminCustomersVariables {
  name?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetAgencySettingsMedias
// ====================================================

export interface GetAgencySettingsMedias_medias {
  /**
   * The id of the media
   */
  id: string | null;
  /**
   * The name of the media
   */
  name: string;
}

export interface GetAgencySettingsMedias {
  /**
   * Get medias
   */
  medias: GetAgencySettingsMedias_medias[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetAllNews
// ====================================================

export interface GetAllNews_news {
  /**
   * Id for the newsitem
   */
  id: number;
  /**
   * The news is popuped when user comes to homepage
   */
  isPopup: boolean;
  /**
   * The news should be shown in the news list
   */
  isNewslist: boolean;
  /**
   * Header for the news
   */
  header: string;
  /**
   * Summary for the news
   */
  summary: string;
  /**
   * The whole text for the news
   */
  text: string;
  /**
   * Valid from date
   */
  timeFrom: any | null;
  /**
   * Valid to date
   */
  timeTo: any | null;
  /**
   * Show all content
   */
  isFullContent: boolean;
}

export interface GetAllNews {
  /**
   * Get news
   */
  news: GetAllNews_news[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetArchiveCaseFolders
// ====================================================

export interface GetArchiveCaseFolders_findCaseFoldersByCurrentCustomer_caseFolders_propertiesFuneralStandard {
  /**
   * Id from a another businesssystem (option)
   */
  externalId: string;
}

export interface GetArchiveCaseFolders_findCaseFoldersByCurrentCustomer_caseFolders_customer {
  /**
   * The id of customer
   */
  id: string;
  /**
   * The name of customer
   */
  name: string;
}

export interface GetArchiveCaseFolders_findCaseFoldersByCurrentCustomer_caseFolders_productPackages_productPackage {
  /**
   * Id of the ProductPackage
   */
  id: string;
  /**
   * Name of the ProductPackage
   */
  name: string;
}

export interface GetArchiveCaseFolders_findCaseFoldersByCurrentCustomer_caseFolders_productPackages_documents_documentFormat_documentType {
  /**
   * Id of the documenttype
   */
  id: string;
  friendlyName: string;
}

export interface GetArchiveCaseFolders_findCaseFoldersByCurrentCustomer_caseFolders_productPackages_documents_documentFormat {
  /**
   * Id of the documentformat
   */
  id: string;
  documentType: GetArchiveCaseFolders_findCaseFoldersByCurrentCustomer_caseFolders_productPackages_documents_documentFormat_documentType;
}

export interface GetArchiveCaseFolders_findCaseFoldersByCurrentCustomer_caseFolders_productPackages_documents_orders_alerting {
  smallText: string | null;
  text: string | null;
  type: OrderAlertingType;
}

export interface GetArchiveCaseFolders_findCaseFoldersByCurrentCustomer_caseFolders_productPackages_documents_orders_mediaOrder_media {
  /**
   * The id of the media
   */
  id: string | null;
  /**
   * The friendlyName of the media
   */
  friendlyName: string;
}

export interface GetArchiveCaseFolders_findCaseFoldersByCurrentCustomer_caseFolders_productPackages_documents_orders_mediaOrder_documentFormat {
  /**
   * Id of the documentformat
   */
  id: string;
  mediaAdaptedName: string;
}

export interface GetArchiveCaseFolders_findCaseFoldersByCurrentCustomer_caseFolders_productPackages_documents_orders_mediaOrder_publishTimes {
  /**
   * Date when the order will be published
   */
  time: any;
}

export interface GetArchiveCaseFolders_findCaseFoldersByCurrentCustomer_caseFolders_productPackages_documents_orders_mediaOrder_status {
  /**
   * Color for vieweing the customer
   */
  color: StatusColors;
  /**
   * ProductStatus enum for the status
   */
  status: OrderStatus;
}

export interface GetArchiveCaseFolders_findCaseFoldersByCurrentCustomer_caseFolders_productPackages_documents_orders_mediaOrder {
  /**
   * media of the order
   */
  media: GetArchiveCaseFolders_findCaseFoldersByCurrentCustomer_caseFolders_productPackages_documents_orders_mediaOrder_media;
  /**
   * documentformat of the order
   */
  documentFormat: GetArchiveCaseFolders_findCaseFoldersByCurrentCustomer_caseFolders_productPackages_documents_orders_mediaOrder_documentFormat;
  /**
   * times when the order will be published
   */
  publishTimes: (GetArchiveCaseFolders_findCaseFoldersByCurrentCustomer_caseFolders_productPackages_documents_orders_mediaOrder_publishTimes | null)[];
  /**
   * Status of the order
   */
  status: GetArchiveCaseFolders_findCaseFoldersByCurrentCustomer_caseFolders_productPackages_documents_orders_mediaOrder_status;
}

export interface GetArchiveCaseFolders_findCaseFoldersByCurrentCustomer_caseFolders_productPackages_documents_orders {
  /**
   * The id of the mediaorder
   */
  id: string | null;
  /**
   * The Id shown for customer
   */
  customerFriendlyId: string;
  alerting: GetArchiveCaseFolders_findCaseFoldersByCurrentCustomer_caseFolders_productPackages_documents_orders_alerting;
  /**
   * mediaOrder properties if that kind of order
   */
  mediaOrder: GetArchiveCaseFolders_findCaseFoldersByCurrentCustomer_caseFolders_productPackages_documents_orders_mediaOrder | null;
}

export interface GetArchiveCaseFolders_findCaseFoldersByCurrentCustomer_caseFolders_productPackages_documents {
  /**
   * Id of the document
   */
  id: string | null;
  /**
   * InternalId of the document
   */
  internalId: number;
  /**
   * format of the document
   */
  documentFormat: GetArchiveCaseFolders_findCaseFoldersByCurrentCustomer_caseFolders_productPackages_documents_documentFormat;
  orders: GetArchiveCaseFolders_findCaseFoldersByCurrentCustomer_caseFolders_productPackages_documents_orders[];
}

export interface GetArchiveCaseFolders_findCaseFoldersByCurrentCustomer_caseFolders_productPackages {
  /**
   * Product Package
   */
  productPackage: GetArchiveCaseFolders_findCaseFoldersByCurrentCustomer_caseFolders_productPackages_productPackage;
  /**
   * orders of the product package
   */
  documents: (GetArchiveCaseFolders_findCaseFoldersByCurrentCustomer_caseFolders_productPackages_documents | null)[];
}

export interface GetArchiveCaseFolders_findCaseFoldersByCurrentCustomer_caseFolders_summary {
  /**
   * Count number of orders)
   */
  countOrders: number;
  /**
   * Most critical Status in the orders
   */
  mostCriticalStatus: OrderStatus;
  /**
   * Most critical Status color in the orders
   */
  mostCriticalColor: StatusColors;
  /**
   * Flag when shows warning about the bookingstoptime
   */
  isMostCriticalTimeBookingWarning: boolean;
}

export interface GetArchiveCaseFolders_findCaseFoldersByCurrentCustomer_caseFolders {
  /**
   * The id of the caseFolder
   */
  id: string | null;
  /**
   * The name of the caseFolder
   */
  name: string;
  /**
   * The internal id of the caseFolder
   */
  internalId: number;
  propertiesFuneralStandard: GetArchiveCaseFolders_findCaseFoldersByCurrentCustomer_caseFolders_propertiesFuneralStandard;
  customer: GetArchiveCaseFolders_findCaseFoldersByCurrentCustomer_caseFolders_customer;
  /**
   * ProductPackages of the caseFolder
   */
  productPackages: (GetArchiveCaseFolders_findCaseFoldersByCurrentCustomer_caseFolders_productPackages | null)[];
  summary: GetArchiveCaseFolders_findCaseFoldersByCurrentCustomer_caseFolders_summary;
}

export interface GetArchiveCaseFolders_findCaseFoldersByCurrentCustomer {
  /**
   * The total number of documentFolders in the search
   */
  count: number;
  /**
   * The startindex for the search
   */
  startIndex: number;
  caseFolders: (GetArchiveCaseFolders_findCaseFoldersByCurrentCustomer_caseFolders | null)[] | null;
}

export interface GetArchiveCaseFolders {
  /**
   * Get case folders for customer
   */
  findCaseFoldersByCurrentCustomer: GetArchiveCaseFolders_findCaseFoldersByCurrentCustomer;
}

export interface GetArchiveCaseFoldersVariables {
  scope?: CaseFolderCustomerScope | null;
  filterByOrderStatus?: OrderStatus | null;
  textFilter?: string | null;
  startindex: number;
  pageLength: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetAvaiableDocumentFormatCollections
// ====================================================

export interface GetAvaiableDocumentFormatCollections_orders_mediaOrder_documentFormat_documentFormatCollection {
  id: string;
  /**
   * The name of the collection
   */
  name: string;
}

export interface GetAvaiableDocumentFormatCollections_orders_mediaOrder_documentFormat {
  /**
   * Id of the documentformat
   */
  id: string;
  documentFormatCollection: GetAvaiableDocumentFormatCollections_orders_mediaOrder_documentFormat_documentFormatCollection;
}

export interface GetAvaiableDocumentFormatCollections_orders_mediaOrder_availableDocumentFormatCollections {
  id: string;
  /**
   * The name of the collection
   */
  name: string;
  thumbnailUrl: string | null;
}

export interface GetAvaiableDocumentFormatCollections_orders_mediaOrder {
  /**
   * documentformat of the order
   */
  documentFormat: GetAvaiableDocumentFormatCollections_orders_mediaOrder_documentFormat;
  /**
   * documentformatcollection of the order
   */
  availableDocumentFormatCollections: GetAvaiableDocumentFormatCollections_orders_mediaOrder_availableDocumentFormatCollections[];
}

export interface GetAvaiableDocumentFormatCollections_orders {
  /**
   * The id of the mediaorder
   */
  id: string | null;
  /**
   * mediaOrder properties if that kind of order
   */
  mediaOrder: GetAvaiableDocumentFormatCollections_orders_mediaOrder | null;
}

export interface GetAvaiableDocumentFormatCollections {
  /**
   * Get orders
   */
  orders: GetAvaiableDocumentFormatCollections_orders[];
}

export interface GetAvaiableDocumentFormatCollectionsVariables {
  orderId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetAvaiableDocumentFormats
// ====================================================

export interface GetAvaiableDocumentFormats_orders_mediaOrder_documentFormat_availableStyleTemplates2 {
  id: number;
  name: string | null;
  color: string | null;
}

export interface GetAvaiableDocumentFormats_orders_mediaOrder_documentFormat_documentFormatCollection {
  id: string;
  /**
   * The name of the collection
   */
  name: string;
}

export interface GetAvaiableDocumentFormats_orders_mediaOrder_documentFormat {
  /**
   * Id of the documentformat
   */
  id: string;
  /**
   * documentformat of the order
   */
  availableStyleTemplates2: GetAvaiableDocumentFormats_orders_mediaOrder_documentFormat_availableStyleTemplates2[];
  documentFormatCollection: GetAvaiableDocumentFormats_orders_mediaOrder_documentFormat_documentFormatCollection;
}

export interface GetAvaiableDocumentFormats_orders_mediaOrder_availableDocumentFormats_availableStyleTemplates2 {
  id: number;
  color: string | null;
  name: string | null;
}

export interface GetAvaiableDocumentFormats_orders_mediaOrder_availableDocumentFormats {
  /**
   * Id of the documentformat
   */
  id: string;
  name: string;
  thumbnailUrl: string;
  thumbnailWidth: number | null;
  thumbnailHeight: number | null;
  /**
   * documentformat of the order
   */
  availableStyleTemplates2: GetAvaiableDocumentFormats_orders_mediaOrder_availableDocumentFormats_availableStyleTemplates2[];
}

export interface GetAvaiableDocumentFormats_orders_mediaOrder {
  /**
   * documentformat of the order
   */
  documentFormat: GetAvaiableDocumentFormats_orders_mediaOrder_documentFormat;
  /**
   * documentformat of the order
   */
  availableDocumentFormats: GetAvaiableDocumentFormats_orders_mediaOrder_availableDocumentFormats[];
}

export interface GetAvaiableDocumentFormats_orders {
  /**
   * The id of the mediaorder
   */
  id: string | null;
  /**
   * mediaOrder properties if that kind of order
   */
  mediaOrder: GetAvaiableDocumentFormats_orders_mediaOrder | null;
}

export interface GetAvaiableDocumentFormats {
  /**
   * Get orders
   */
  orders: GetAvaiableDocumentFormats_orders[];
}

export interface GetAvaiableDocumentFormatsVariables {
  orderId: string;
  documentFormatCollectionId?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetAvailablePublishTimes
// ====================================================

export interface GetAvailablePublishTimes_mediaPublishCalendarDays {
  /**
   * Day in the Calendar
   */
  timeDay: any;
  /**
   * Allowed to publish
   */
  isEnabled: boolean;
  /**
   * Time when last booking can be done
   */
  timeBooking: any | null;
  /**
   * Comment about the day
   */
  comment: string;
}

export interface GetAvailablePublishTimes {
  /**
   * Get booking calendar
   */
  mediaPublishCalendarDays: GetAvailablePublishTimes_mediaPublishCalendarDays[];
}

export interface GetAvailablePublishTimesVariables {
  orderId?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetAvailableTags
// ====================================================

export interface GetAvailableTags_galleryImagesTagsByTags {
  tags: string[];
}

export interface GetAvailableTags {
  /**
   * Get images-Tags filter by tags
   */
  galleryImagesTagsByTags: GetAvailableTags_galleryImagesTagsByTags;
}

export interface GetAvailableTagsVariables {
  input: GalleryImageAvailableTagsInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetCalendarOrders
// ====================================================

export interface GetCalendarOrders_calendarOrdersByCurrentCustomer_summary {
  /**
   * Most critical Status color in the orders
   */
  mostCriticalColor: StatusColors;
  /**
   * Most critical Status in the orders
   */
  mostCriticalStatus: OrderStatus;
}

export interface GetCalendarOrders_calendarOrdersByCurrentCustomer_orders_alerting {
  smallText: string | null;
  text: string | null;
  type: OrderAlertingType;
}

export interface GetCalendarOrders_calendarOrdersByCurrentCustomer_orders_mediaOrder_status {
  /**
   * ProductStatus enum for the status
   */
  status: OrderStatus;
  /**
   * Color for vieweing the customer
   */
  color: StatusColors;
}

export interface GetCalendarOrders_calendarOrdersByCurrentCustomer_orders_mediaOrder_media {
  /**
   * The id of the media
   */
  id: string | null;
  /**
   * The friendlyName of the media
   */
  friendlyName: string;
}

export interface GetCalendarOrders_calendarOrdersByCurrentCustomer_orders_mediaOrder {
  /**
   * Status of the order
   */
  status: GetCalendarOrders_calendarOrdersByCurrentCustomer_orders_mediaOrder_status;
  /**
   * media of the order
   */
  media: GetCalendarOrders_calendarOrdersByCurrentCustomer_orders_mediaOrder_media;
}

export interface GetCalendarOrders_calendarOrdersByCurrentCustomer_orders_document_caseFolder {
  /**
   * The id of the caseFolder
   */
  id: string | null;
  /**
   * The name of the caseFolder
   */
  name: string;
}

export interface GetCalendarOrders_calendarOrdersByCurrentCustomer_orders_document {
  /**
   * Id of the document
   */
  id: string | null;
  /**
   * caseFolder of the document
   */
  caseFolder: GetCalendarOrders_calendarOrdersByCurrentCustomer_orders_document_caseFolder;
}

export interface GetCalendarOrders_calendarOrdersByCurrentCustomer_orders {
  orderDate: any | null;
  /**
   * The id of the mediaorder
   */
  id: string | null;
  alerting: GetCalendarOrders_calendarOrdersByCurrentCustomer_orders_alerting;
  /**
   * mediaOrder properties if that kind of order
   */
  mediaOrder: GetCalendarOrders_calendarOrdersByCurrentCustomer_orders_mediaOrder | null;
  /**
   * document of the order
   */
  document: GetCalendarOrders_calendarOrdersByCurrentCustomer_orders_document;
}

export interface GetCalendarOrders_calendarOrdersByCurrentCustomer {
  /**
   * Calenderday
   */
  date: any;
  summary: GetCalendarOrders_calendarOrdersByCurrentCustomer_summary;
  /**
   * Orders for the calenderday to view
   */
  orders: GetCalendarOrders_calendarOrdersByCurrentCustomer_orders[];
}

export interface GetCalendarOrders {
  /**
   * Get order by calender days
   */
  calendarOrdersByCurrentCustomer: GetCalendarOrders_calendarOrdersByCurrentCustomer[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetCurrentCustomerAllProducts
// ====================================================

export interface GetCurrentCustomerAllProducts_currentCustomer_office_company_customerContract_customerProducts_product {
  /**
   * Id of the product
   */
  id: string;
  /**
   * Name of the product
   */
  name: string;
  isStandardProduct: boolean;
}

export interface GetCurrentCustomerAllProducts_currentCustomer_office_company_customerContract_customerProducts_contractCustomer {
  /**
   * Price added on the proofs. The customer earnings
   */
  price: any;
  /**
   * The setting is stored
   */
  isStored: boolean;
}

export interface GetCurrentCustomerAllProducts_currentCustomer_office_company_customerContract_customerProducts_contractEndCustomer {
  /**
   * Price added on the proofs. The customer earnings
   */
  price: any;
  /**
   * The setting is stored
   */
  isStored: boolean;
}

export interface GetCurrentCustomerAllProducts_currentCustomer_office_company_customerContract_customerProducts {
  /**
   * Product
   */
  product: GetCurrentCustomerAllProducts_currentCustomer_office_company_customerContract_customerProducts_product;
  /**
   * Customer contract
   */
  contractCustomer: GetCurrentCustomerAllProducts_currentCustomer_office_company_customerContract_customerProducts_contractCustomer;
  /**
   * EndCustomer contract
   */
  contractEndCustomer: GetCurrentCustomerAllProducts_currentCustomer_office_company_customerContract_customerProducts_contractEndCustomer;
}

export interface GetCurrentCustomerAllProducts_currentCustomer_office_company_customerContract {
  /**
   * Id of the contract
   */
  id: string;
  /**
   * Name of the contract
   */
  name: string;
  /**
   * Products in the contract
   */
  customerProducts: GetCurrentCustomerAllProducts_currentCustomer_office_company_customerContract_customerProducts[];
}

export interface GetCurrentCustomerAllProducts_currentCustomer_office_company {
  /**
   * The id of company
   */
  id: string;
  customerContract: GetCurrentCustomerAllProducts_currentCustomer_office_company_customerContract;
}

export interface GetCurrentCustomerAllProducts_currentCustomer_office {
  /**
   * The id of office
   */
  id: string;
  /**
   * The name of office
   */
  name: string;
  company: GetCurrentCustomerAllProducts_currentCustomer_office_company;
}

export interface GetCurrentCustomerAllProducts_currentCustomer {
  /**
   * The id of customer
   */
  id: string;
  office: GetCurrentCustomerAllProducts_currentCustomer_office;
}

export interface GetCurrentCustomerAllProducts {
  /**
   * Get current customer
   */
  currentCustomer: GetCurrentCustomerAllProducts_currentCustomer | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetCurrentCustomer
// ====================================================

export interface GetCurrentCustomer_currentCustomer_colleagues_inheritSettings {
  /**
   * The emailaddress used when sending info to customer
   */
  contactPhone: string | null;
}

export interface GetCurrentCustomer_currentCustomer_colleagues_office {
  /**
   * The id of office
   */
  id: string;
  /**
   * The name of office
   */
  name: string;
}

export interface GetCurrentCustomer_currentCustomer_colleagues_primaryUser {
  /**
   * The id of user
   */
  id: string | null;
  /**
   * The username of user
   */
  username: string;
  /**
   * The account is enabled or not
   */
  enabled: boolean;
}

export interface GetCurrentCustomer_currentCustomer_colleagues {
  /**
   * The id of customer
   */
  id: string;
  /**
   * The name of customer
   */
  name: string;
  /**
   * The firstname of customer
   */
  firstName: string;
  /**
   * The lastname of customer
   */
  lastName: string;
  inheritSettings: GetCurrentCustomer_currentCustomer_colleagues_inheritSettings;
  office: GetCurrentCustomer_currentCustomer_colleagues_office;
  /**
   * The primary user for the customer
   */
  primaryUser: GetCurrentCustomer_currentCustomer_colleagues_primaryUser | null;
}

export interface GetCurrentCustomer_currentCustomer_settings {
  /**
   * The emailaddress used when sending info to office
   */
  emailOffice: string | null;
  /**
   * The emailaddress used when sending info to customer
   */
  contactPhone: string | null;
  /**
   * The name of the local memory name
   */
  agencyHomepageName: string | null;
  /**
   * The emailaddress used when sending info to customer
   */
  emailCustomer: string | null;
  /**
   * When order is disapproved or approved a receipt is sending to this address
   */
  emailOrderReceipt: string | null;
  /**
   * The emailaddress send to Memoriz
   */
  emailOrderMemoriz: string | null;
  /**
   * Post city
   */
  postAddressCity: string | null;
  /**
   * Post postcode
   */
  postAddressPostcode: string | null;
  /**
   * Post address
   */
  postAddressStreet: string | null;
  orderPriceEndCustomerRule: CustomerSettingOrderPriceEndCustomerRules | null;
  /**
   * On the proof, we shows suggestion of an thanks advert
   */
  isDefaultProofSuggestionThanksAdvert: boolean | null;
  /**
   * On the proof, the casefee will be add on the other others
   */
  isDefaultProofCaseFeeAddOnOrders: boolean | null;
  /**
   * On the proof, the casefee will be hided (not visible)
   */
  isDefaultProofCaseFeeHide: boolean | null;
  /**
   * On the proof, the casefee will be add on the first order
   */
  isDefaultProofCaseFeeAddOnFirstOrder: boolean | null;
  /**
   * Default parallel publish in familjesidan for printadverts
   */
  isDefaultParallelPublishInFamiljesidan: boolean | null;
  /**
   * Default parallel publish in minnesrummet for printadverts
   */
  isDefaultParallelPublishInMinnesrummet: boolean | null;
  /**
   * Default parallel publish in the agency home page for printadverts
   */
  isDefaultParallelPublishInAgencyHomepage: boolean | null;
  /**
   * Default enable MyPage service
   */
  isDefaultMyPageEnabled: boolean | null;
  /**
   * External memoryroom is exposed
   */
  isMemoryRoomUrlEnabled: boolean | null;
  /**
   * Favorite medias
   */
  favoriteMedias: (string | null)[] | null;
  /**
   * Is allowed to change addon
   */
  isAllowUpdateAddOn: boolean | null;
}

export interface GetCurrentCustomer_currentCustomer_inheritSettings {
  /**
   * The emailaddress used when sending info to office
   */
  emailOffice: string | null;
  /**
   * The emailaddress used when sending info to customer
   */
  contactPhone: string | null;
  /**
   * The name of the local memory name
   */
  agencyHomepageName: string | null;
  /**
   * The emailaddress used when sending info to customer
   */
  emailCustomer: string | null;
  /**
   * When order is disapproved or approved a receipt is sending to this address
   */
  emailOrderReceipt: string | null;
  /**
   * The emailaddress send to Memoriz
   */
  emailOrderMemoriz: string | null;
  /**
   * Post city
   */
  postAddressCity: string | null;
  /**
   * Post postcode
   */
  postAddressPostcode: string | null;
  /**
   * Post address
   */
  postAddressStreet: string | null;
  orderPriceEndCustomerRule: CustomerSettingOrderPriceEndCustomerRules | null;
  /**
   * On the proof, we shows suggestion of an thanks advert
   */
  isDefaultProofSuggestionThanksAdvert: boolean | null;
  /**
   * On the proof, the casefee will be add on the other others
   */
  isDefaultProofCaseFeeAddOnOrders: boolean | null;
  /**
   * On the proof, the casefee will be hided (not visible)
   */
  isDefaultProofCaseFeeHide: boolean | null;
  /**
   * On the proof, the casefee will be add on the first order
   */
  isDefaultProofCaseFeeAddOnFirstOrder: boolean | null;
  /**
   * Default parallel publish in familjesidan for printadverts
   */
  isDefaultParallelPublishInFamiljesidan: boolean | null;
  /**
   * Default parallel publish in minnesrummet for printadverts
   */
  isDefaultParallelPublishInMinnesrummet: boolean | null;
  /**
   * Default parallel publish in the agency home page for printadverts
   */
  isDefaultParallelPublishInAgencyHomepage: boolean | null;
  /**
   * Default enable MyPage service
   */
  isDefaultMyPageEnabled: boolean | null;
  /**
   * External memoryroom is exposed
   */
  isMemoryRoomUrlEnabled: boolean | null;
  /**
   * Favorite medias
   */
  favoriteMedias: (string | null)[] | null;
  /**
   * Is allowed to change addon
   */
  isAllowUpdateAddOn: boolean | null;
}

export interface GetCurrentCustomer_currentCustomer_office_settings {
  /**
   * The emailaddress used when sending info to office
   */
  emailOffice: string | null;
  /**
   * The emailaddress used when sending info to customer
   */
  contactPhone: string | null;
  /**
   * The name of the local memory name
   */
  agencyHomepageName: string | null;
  /**
   * The emailaddress used when sending info to customer
   */
  emailCustomer: string | null;
  /**
   * When order is disapproved or approved a receipt is sending to this address
   */
  emailOrderReceipt: string | null;
  /**
   * The emailaddress send to Memoriz
   */
  emailOrderMemoriz: string | null;
  /**
   * Post city
   */
  postAddressCity: string | null;
  /**
   * Post postcode
   */
  postAddressPostcode: string | null;
  /**
   * Post address
   */
  postAddressStreet: string | null;
  orderPriceEndCustomerRule: CustomerSettingOrderPriceEndCustomerRules | null;
  /**
   * On the proof, we shows suggestion of an thanks advert
   */
  isDefaultProofSuggestionThanksAdvert: boolean | null;
  /**
   * On the proof, the casefee will be add on the other others
   */
  isDefaultProofCaseFeeAddOnOrders: boolean | null;
  /**
   * On the proof, the casefee will be hided (not visible)
   */
  isDefaultProofCaseFeeHide: boolean | null;
  /**
   * On the proof, the casefee will be add on the first order
   */
  isDefaultProofCaseFeeAddOnFirstOrder: boolean | null;
  /**
   * Default parallel publish in familjesidan for printadverts
   */
  isDefaultParallelPublishInFamiljesidan: boolean | null;
  /**
   * Default parallel publish in minnesrummet for printadverts
   */
  isDefaultParallelPublishInMinnesrummet: boolean | null;
  /**
   * Default parallel publish in the agency home page for printadverts
   */
  isDefaultParallelPublishInAgencyHomepage: boolean | null;
  /**
   * Default enable MyPage service
   */
  isDefaultMyPageEnabled: boolean | null;
  /**
   * External memoryroom is exposed
   */
  isMemoryRoomUrlEnabled: boolean | null;
  /**
   * Favorite medias
   */
  favoriteMedias: (string | null)[] | null;
  /**
   * Is allowed to change addon
   */
  isAllowUpdateAddOn: boolean | null;
}

export interface GetCurrentCustomer_currentCustomer_office_inheritSettings {
  /**
   * The emailaddress used when sending info to office
   */
  emailOffice: string | null;
  /**
   * The emailaddress used when sending info to customer
   */
  contactPhone: string | null;
  /**
   * The name of the local memory name
   */
  agencyHomepageName: string | null;
  /**
   * The emailaddress used when sending info to customer
   */
  emailCustomer: string | null;
  /**
   * When order is disapproved or approved a receipt is sending to this address
   */
  emailOrderReceipt: string | null;
  /**
   * The emailaddress send to Memoriz
   */
  emailOrderMemoriz: string | null;
  /**
   * Post city
   */
  postAddressCity: string | null;
  /**
   * Post postcode
   */
  postAddressPostcode: string | null;
  /**
   * Post address
   */
  postAddressStreet: string | null;
  orderPriceEndCustomerRule: CustomerSettingOrderPriceEndCustomerRules | null;
  /**
   * On the proof, we shows suggestion of an thanks advert
   */
  isDefaultProofSuggestionThanksAdvert: boolean | null;
  /**
   * On the proof, the casefee will be add on the other others
   */
  isDefaultProofCaseFeeAddOnOrders: boolean | null;
  /**
   * On the proof, the casefee will be hided (not visible)
   */
  isDefaultProofCaseFeeHide: boolean | null;
  /**
   * On the proof, the casefee will be add on the first order
   */
  isDefaultProofCaseFeeAddOnFirstOrder: boolean | null;
  /**
   * Default parallel publish in familjesidan for printadverts
   */
  isDefaultParallelPublishInFamiljesidan: boolean | null;
  /**
   * Default parallel publish in minnesrummet for printadverts
   */
  isDefaultParallelPublishInMinnesrummet: boolean | null;
  /**
   * Default parallel publish in the agency home page for printadverts
   */
  isDefaultParallelPublishInAgencyHomepage: boolean | null;
  /**
   * Default enable MyPage service
   */
  isDefaultMyPageEnabled: boolean | null;
  /**
   * External memoryroom is exposed
   */
  isMemoryRoomUrlEnabled: boolean | null;
  /**
   * Favorite medias
   */
  favoriteMedias: (string | null)[] | null;
  /**
   * Is allowed to change addon
   */
  isAllowUpdateAddOn: boolean | null;
}

export interface GetCurrentCustomer_currentCustomer_office_company_settings {
  /**
   * The emailaddress using for invoicing
   */
  emailInvoicing: string | null;
}

export interface GetCurrentCustomer_currentCustomer_office_company_inheritSettings {
  /**
   * The emailaddress using for invoicing
   */
  emailInvoicing: string | null;
  /**
   * The emailaddress used when sending info to office
   */
  emailOffice: string | null;
}

export interface GetCurrentCustomer_currentCustomer_office_company_customerContract_customerProducts_product {
  /**
   * Id of the product
   */
  id: string;
  /**
   * Name of the product
   */
  name: string;
}

export interface GetCurrentCustomer_currentCustomer_office_company_customerContract_customerProducts_contractCustomer {
  /**
   * Price added on the proofs. The customer earnings
   */
  price: any;
}

export interface GetCurrentCustomer_currentCustomer_office_company_customerContract_customerProducts_contractEndCustomer {
  /**
   * Price added on the proofs. The customer earnings
   */
  price: any;
}

export interface GetCurrentCustomer_currentCustomer_office_company_customerContract_customerProducts {
  /**
   * Product
   */
  product: GetCurrentCustomer_currentCustomer_office_company_customerContract_customerProducts_product;
  /**
   * Customer contract
   */
  contractCustomer: GetCurrentCustomer_currentCustomer_office_company_customerContract_customerProducts_contractCustomer;
  /**
   * EndCustomer contract
   */
  contractEndCustomer: GetCurrentCustomer_currentCustomer_office_company_customerContract_customerProducts_contractEndCustomer;
}

export interface GetCurrentCustomer_currentCustomer_office_company_customerContract {
  /**
   * Id of the contract
   */
  id: string;
  /**
   * Products in the contract
   */
  customerProducts: GetCurrentCustomer_currentCustomer_office_company_customerContract_customerProducts[];
}

export interface GetCurrentCustomer_currentCustomer_office_company_offices {
  /**
   * The id of office
   */
  id: string;
  /**
   * The name of office
   */
  name: string;
}

export interface GetCurrentCustomer_currentCustomer_office_company {
  /**
   * The id of company
   */
  id: string;
  settings: GetCurrentCustomer_currentCustomer_office_company_settings;
  inheritSettings: GetCurrentCustomer_currentCustomer_office_company_inheritSettings;
  customerContract: GetCurrentCustomer_currentCustomer_office_company_customerContract;
  offices: GetCurrentCustomer_currentCustomer_office_company_offices[];
}

export interface GetCurrentCustomer_currentCustomer_office {
  /**
   * The id of office
   */
  id: string;
  settings: GetCurrentCustomer_currentCustomer_office_settings;
  inheritSettings: GetCurrentCustomer_currentCustomer_office_inheritSettings;
  company: GetCurrentCustomer_currentCustomer_office_company;
}

export interface GetCurrentCustomer_currentCustomer {
  /**
   * The id of customer
   */
  id: string;
  colleagues: (GetCurrentCustomer_currentCustomer_colleagues | null)[];
  settings: GetCurrentCustomer_currentCustomer_settings;
  inheritSettings: GetCurrentCustomer_currentCustomer_inheritSettings;
  office: GetCurrentCustomer_currentCustomer_office;
}

export interface GetCurrentCustomer {
  /**
   * Get current customer
   */
  currentCustomer: GetCurrentCustomer_currentCustomer | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetCurrentCustomerColleagues
// ====================================================

export interface GetCurrentCustomerColleagues_currentCustomer_colleagues_office {
  /**
   * The id of office
   */
  id: string;
  /**
   * The name of office
   */
  name: string;
}

export interface GetCurrentCustomerColleagues_currentCustomer_colleagues {
  /**
   * The id of customer
   */
  id: string;
  /**
   * The name of customer
   */
  name: string;
  office: GetCurrentCustomerColleagues_currentCustomer_colleagues_office;
}

export interface GetCurrentCustomerColleagues_currentCustomer {
  /**
   * The id of customer
   */
  id: string;
  colleagues: (GetCurrentCustomerColleagues_currentCustomer_colleagues | null)[];
}

export interface GetCurrentCustomerColleagues {
  /**
   * Get current customer
   */
  currentCustomer: GetCurrentCustomerColleagues_currentCustomer | null;
}

export interface GetCurrentCustomerColleaguesVariables {
  input?: CustomerScope | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetCurrentUserSession
// ====================================================

export interface GetCurrentUserSession_currentUserSession_user_customers_office {
  /**
   * The id of office
   */
  id: string;
  /**
   * The name of office
   */
  name: string;
}

export interface GetCurrentUserSession_currentUserSession_user_customers {
  /**
   * The id of customer
   */
  id: string;
  office: GetCurrentUserSession_currentUserSession_user_customers_office;
}

export interface GetCurrentUserSession_currentUserSession_user {
  /**
   * The id of user
   */
  id: string | null;
  /**
   * FE User
   */
  isFEUser: boolean;
  /**
   * The username of user
   */
  username: string;
  /**
   * CultureCode for the user
   */
  cultureCode: string;
  /**
   * The user who cannot do any harm in the system
   */
  isDemoUser: boolean;
  /**
   * The user who has extra privilege in the system
   */
  isPowerUserOffice: boolean;
  /**
   * The owner of the customerCompany
   */
  isPowerUserCompany: boolean;
  /**
   * The user, helping others
   */
  isAssistentUser: boolean;
  /**
   * The timecut user helping the customer
   */
  isSupportUser: boolean;
  /**
   * The timecut user who has most privilege in the system
   */
  isSuperUser: boolean;
  /**
   * The mediahouse user
   */
  isMediaUser: boolean;
  /**
   * Bitnet User
   */
  isBitnetUser: boolean;
  customers: (GetCurrentUserSession_currentUserSession_user_customers | null)[] | null;
}

export interface GetCurrentUserSession_currentUserSession_customer_settings {
  /**
   * The emailaddress used when sending info to customer
   */
  emailCustomer: string | null;
  /**
   * The emailaddress used when sending info to customer
   */
  contactPhone: string | null;
}

export interface GetCurrentUserSession_currentUserSession_customer_colleagues_office {
  /**
   * The id of office
   */
  id: string;
  /**
   * The name of office
   */
  name: string;
}

export interface GetCurrentUserSession_currentUserSession_customer_colleagues {
  /**
   * The id of customer
   */
  id: string;
  /**
   * The firstname of customer
   */
  firstName: string;
  /**
   * The lastname of customer
   */
  lastName: string;
  office: GetCurrentUserSession_currentUserSession_customer_colleagues_office;
}

export interface GetCurrentUserSession_currentUserSession_customer_office_company {
  /**
   * The id of company
   */
  id: string;
}

export interface GetCurrentUserSession_currentUserSession_customer_office {
  /**
   * The id of office
   */
  id: string;
  /**
   * The name of office
   */
  name: string;
  company: GetCurrentUserSession_currentUserSession_customer_office_company;
}

export interface GetCurrentUserSession_currentUserSession_customer {
  /**
   * The id of customer
   */
  id: string;
  /**
   * The name of customer
   */
  name: string;
  settings: GetCurrentUserSession_currentUserSession_customer_settings;
  colleagues: (GetCurrentUserSession_currentUserSession_customer_colleagues | null)[];
  office: GetCurrentUserSession_currentUserSession_customer_office;
}

export interface GetCurrentUserSession_currentUserSession {
  id: string;
  isAuthenticated: boolean;
  cultureCode: string;
  user: GetCurrentUserSession_currentUserSession_user | null;
  customer: GetCurrentUserSession_currentUserSession_customer | null;
}

export interface GetCurrentUserSession {
  /**
   * Get current customer
   */
  currentUserSession: GetCurrentUserSession_currentUserSession;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetCustomerDocumentContentInitiators
// ====================================================

export interface GetCustomerDocumentContentInitiators_customerDocumentContentInitiators_owner {
  /**
   * The name of customer
   */
  name: string;
}

export interface GetCustomerDocumentContentInitiators_customerDocumentContentInitiators_areas_text {
  xmlText: string;
}

export interface GetCustomerDocumentContentInitiators_customerDocumentContentInitiators_areas_image {
  imageId: string | null;
  imageThumbnaill100x80Url: string | null;
}

export interface GetCustomerDocumentContentInitiators_customerDocumentContentInitiators_areas {
  name: string;
  nameId: string;
  templateAreaId: string;
  text: GetCustomerDocumentContentInitiators_customerDocumentContentInitiators_areas_text | null;
  image: GetCustomerDocumentContentInitiators_customerDocumentContentInitiators_areas_image | null;
}

export interface GetCustomerDocumentContentInitiators_customerDocumentContentInitiators_areaIngress_text {
  xmlText: string;
}

export interface GetCustomerDocumentContentInitiators_customerDocumentContentInitiators_areaIngress {
  text: GetCustomerDocumentContentInitiators_customerDocumentContentInitiators_areaIngress_text | null;
}

export interface GetCustomerDocumentContentInitiators_customerDocumentContentInitiators_footerIngress_text {
  xmlText: string;
}

export interface GetCustomerDocumentContentInitiators_customerDocumentContentInitiators_footerIngress {
  text: GetCustomerDocumentContentInitiators_customerDocumentContentInitiators_footerIngress_text | null;
}

export interface GetCustomerDocumentContentInitiators_customerDocumentContentInitiators {
  documentFormatInitiatorId: string | null;
  isDefault: boolean;
  owner: GetCustomerDocumentContentInitiators_customerDocumentContentInitiators_owner | null;
  areas: GetCustomerDocumentContentInitiators_customerDocumentContentInitiators_areas[];
  areaIngress: GetCustomerDocumentContentInitiators_customerDocumentContentInitiators_areaIngress | null;
  footerIngress: GetCustomerDocumentContentInitiators_customerDocumentContentInitiators_footerIngress | null;
}

export interface GetCustomerDocumentContentInitiators {
  customerDocumentContentInitiators: GetCustomerDocumentContentInitiators_customerDocumentContentInitiators[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetCaseFolders
// ====================================================

export interface GetCaseFolders_findCaseFoldersByCurrentCustomer_caseFolders_customer {
  /**
   * The id of customer
   */
  id: string;
  /**
   * The name of customer
   */
  name: string;
}

export interface GetCaseFolders_findCaseFoldersByCurrentCustomer_caseFolders_propertiesFuneralStandard {
  /**
   * Id from a another businesssystem (option)
   */
  externalId: string;
  /**
   * Born date for the person in the case
   */
  personBorn: any | null;
  /**
   * Died date for the person in the case
   */
  personDied: any | null;
  /**
   * Funeral date for the person in the case
   */
  personFuneral: any | null;
  /**
   * Lastname when the person in the case was born
   */
  personBornLastname: string | null;
  /**
   * First name of the person in the case
   */
  personFirstname: string;
  /**
   * Last name of the person in the case
   */
  personLastname: string;
  /**
   * Where did the person live
   */
  personCityLive: string;
  /**
   * Informationtext about the funeral
   */
  personFuneralInfoText: string;
  /**
   * Last day for booking the cermony for memory
   */
  memoryCeremonyLastBookingDay: any | null;
}

export interface GetCaseFolders_findCaseFoldersByCurrentCustomer_caseFolders_summary {
  /**
   * Count number of orders)
   */
  countOrders: number;
  /**
   * Most critical Status color in the orders
   */
  mostCriticalColor: StatusColors;
  /**
   * Most critical Status in the orders
   */
  mostCriticalStatus: OrderStatus;
}

export interface GetCaseFolders_findCaseFoldersByCurrentCustomer_caseFolders_productPackages_productPackage {
  /**
   * Id of the ProductPackage
   */
  id: string;
  /**
   * Name of the ProductPackage
   */
  name: string;
}

export interface GetCaseFolders_findCaseFoldersByCurrentCustomer_caseFolders_productPackages_summary {
  /**
   * Most critical Status color in the orders
   */
  mostCriticalColor: StatusColors;
  /**
   * Most critical Status in the orders
   */
  mostCriticalStatus: OrderStatus;
  /**
   * Flag when shows warning about the bookingstoptime
   */
  isMostCriticalTimeBookingWarning: boolean;
}

export interface GetCaseFolders_findCaseFoldersByCurrentCustomer_caseFolders_productPackages_documents_documentFormat_documentType {
  friendlyName: string;
  /**
   * Id of the documenttype
   */
  id: string;
  /**
   * The name of the documentType
   */
  name: string;
}

export interface GetCaseFolders_findCaseFoldersByCurrentCustomer_caseFolders_productPackages_documents_documentFormat {
  /**
   * Id of the documentformat
   */
  id: string;
  documentType: GetCaseFolders_findCaseFoldersByCurrentCustomer_caseFolders_productPackages_documents_documentFormat_documentType;
}

export interface GetCaseFolders_findCaseFoldersByCurrentCustomer_caseFolders_productPackages_documents_orders_document {
  /**
   * Id of the document
   */
  id: string | null;
}

export interface GetCaseFolders_findCaseFoldersByCurrentCustomer_caseFolders_productPackages_documents_orders_alerting {
  text: string | null;
  smallText: string | null;
}

export interface GetCaseFolders_findCaseFoldersByCurrentCustomer_caseFolders_productPackages_documents_orders_mediaOrder_media {
  /**
   * The id of the media
   */
  id: string | null;
  /**
   * The friendlyName of the media
   */
  friendlyName: string;
}

export interface GetCaseFolders_findCaseFoldersByCurrentCustomer_caseFolders_productPackages_documents_orders_mediaOrder_documentFormat {
  /**
   * Id of the documentformat
   */
  id: string;
  mediaAdaptedName: string;
}

export interface GetCaseFolders_findCaseFoldersByCurrentCustomer_caseFolders_productPackages_documents_orders_mediaOrder_publishTimes {
  /**
   * Date when the order will be published
   */
  time: any;
}

export interface GetCaseFolders_findCaseFoldersByCurrentCustomer_caseFolders_productPackages_documents_orders_mediaOrder_status {
  /**
   * ProductStatus enum for the status
   */
  status: OrderStatus;
  /**
   * Color for vieweing the customer
   */
  color: StatusColors;
}

export interface GetCaseFolders_findCaseFoldersByCurrentCustomer_caseFolders_productPackages_documents_orders_mediaOrder {
  /**
   * Flag when shows warning about the bookingstoptime
   */
  isCriticalTimeBookingWarning: boolean;
  /**
   * Locked
   */
  isLocked: boolean;
  /**
   * media of the order
   */
  media: GetCaseFolders_findCaseFoldersByCurrentCustomer_caseFolders_productPackages_documents_orders_mediaOrder_media;
  /**
   * documentformat of the order
   */
  documentFormat: GetCaseFolders_findCaseFoldersByCurrentCustomer_caseFolders_productPackages_documents_orders_mediaOrder_documentFormat;
  /**
   * times when the order will be published
   */
  publishTimes: (GetCaseFolders_findCaseFoldersByCurrentCustomer_caseFolders_productPackages_documents_orders_mediaOrder_publishTimes | null)[];
  /**
   * Status of the order
   */
  status: GetCaseFolders_findCaseFoldersByCurrentCustomer_caseFolders_productPackages_documents_orders_mediaOrder_status;
}

export interface GetCaseFolders_findCaseFoldersByCurrentCustomer_caseFolders_productPackages_documents_orders {
  /**
   * The id of the mediaorder
   */
  id: string | null;
  /**
   * The Id shown for customer
   */
  customerFriendlyId: string;
  /**
   * document of the order
   */
  document: GetCaseFolders_findCaseFoldersByCurrentCustomer_caseFolders_productPackages_documents_orders_document;
  alerting: GetCaseFolders_findCaseFoldersByCurrentCustomer_caseFolders_productPackages_documents_orders_alerting;
  /**
   * mediaOrder properties if that kind of order
   */
  mediaOrder: GetCaseFolders_findCaseFoldersByCurrentCustomer_caseFolders_productPackages_documents_orders_mediaOrder | null;
}

export interface GetCaseFolders_findCaseFoldersByCurrentCustomer_caseFolders_productPackages_documents {
  /**
   * Id of the document
   */
  id: string | null;
  /**
   * InternalId of the document
   */
  internalId: number;
  /**
   * format of the document
   */
  documentFormat: GetCaseFolders_findCaseFoldersByCurrentCustomer_caseFolders_productPackages_documents_documentFormat;
  orders: GetCaseFolders_findCaseFoldersByCurrentCustomer_caseFolders_productPackages_documents_orders[];
}

export interface GetCaseFolders_findCaseFoldersByCurrentCustomer_caseFolders_productPackages {
  /**
   * Product Package
   */
  productPackage: GetCaseFolders_findCaseFoldersByCurrentCustomer_caseFolders_productPackages_productPackage;
  /**
   * ordersummary of product package
   */
  summary: GetCaseFolders_findCaseFoldersByCurrentCustomer_caseFolders_productPackages_summary;
  /**
   * orders of the product package
   */
  documents: (GetCaseFolders_findCaseFoldersByCurrentCustomer_caseFolders_productPackages_documents | null)[];
}

export interface GetCaseFolders_findCaseFoldersByCurrentCustomer_caseFolders {
  /**
   * The id of the caseFolder
   */
  id: string | null;
  /**
   * The internal id of the caseFolder
   */
  internalId: number;
  /**
   * The name of the caseFolder
   */
  name: string;
  customer: GetCaseFolders_findCaseFoldersByCurrentCustomer_caseFolders_customer;
  propertiesFuneralStandard: GetCaseFolders_findCaseFoldersByCurrentCustomer_caseFolders_propertiesFuneralStandard;
  summary: GetCaseFolders_findCaseFoldersByCurrentCustomer_caseFolders_summary;
  /**
   * ProductPackages of the caseFolder
   */
  productPackages: (GetCaseFolders_findCaseFoldersByCurrentCustomer_caseFolders_productPackages | null)[];
}

export interface GetCaseFolders_findCaseFoldersByCurrentCustomer {
  caseFolders: (GetCaseFolders_findCaseFoldersByCurrentCustomer_caseFolders | null)[] | null;
}

export interface GetCaseFolders {
  /**
   * Get case folders for customer
   */
  findCaseFoldersByCurrentCustomer: GetCaseFolders_findCaseFoldersByCurrentCustomer;
}

export interface GetCaseFoldersVariables {
  touchCaseFolderIds?: string[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetDocumentTypes
// ====================================================

export interface GetDocumentTypes_documentTypes {
  /**
   * Id of the documenttype
   */
  id: string;
  /**
   * The name of the documentType
   */
  name: string;
}

export interface GetDocumentTypes {
  /**
   * Get documentTypes
   */
  documentTypes: GetDocumentTypes_documentTypes[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetEditOrderPageCaseFolder
// ====================================================

export interface GetEditOrderPageCaseFolder_caseFolder_customer {
  /**
   * The id of customer
   */
  id: string;
  /**
   * The name of customer
   */
  name: string;
}

export interface GetEditOrderPageCaseFolder_caseFolder_propertiesFuneralStandard {
  /**
   * Id from a another businesssystem (option)
   */
  externalId: string;
  /**
   * Born date for the person in the case
   */
  personBorn: any | null;
  /**
   * Died date for the person in the case
   */
  personDied: any | null;
  /**
   * Funeral date for the person in the case
   */
  personFuneral: any | null;
  /**
   * Lastname when the person in the case was born
   */
  personBornLastname: string | null;
  /**
   * First name of the person in the case
   */
  personFirstname: string;
  /**
   * Last name of the person in the case
   */
  personLastname: string;
  /**
   * Where did the person live
   */
  personCityLive: string;
  /**
   * Informationtext about the funeral
   */
  personFuneralInfoText: string;
  /**
   * Last day for booking the cermony for memory
   */
  memoryCeremonyLastBookingDay: any | null;
}

export interface GetEditOrderPageCaseFolder_caseFolder_summary {
  /**
   * Count number of orders)
   */
  countOrders: number;
  /**
   * Most critical Status color in the orders
   */
  mostCriticalColor: StatusColors;
  /**
   * Most critical Status in the orders
   */
  mostCriticalStatus: OrderStatus;
}

export interface GetEditOrderPageCaseFolder_caseFolder_productPackages_productPackage {
  /**
   * Id of the ProductPackage
   */
  id: string;
  /**
   * Name of the ProductPackage
   */
  name: string;
}

export interface GetEditOrderPageCaseFolder_caseFolder_productPackages_summary {
  /**
   * Most critical Status color in the orders
   */
  mostCriticalColor: StatusColors;
  /**
   * Most critical Status in the orders
   */
  mostCriticalStatus: OrderStatus;
  /**
   * Flag when shows warning about the bookingstoptime
   */
  isMostCriticalTimeBookingWarning: boolean;
}

export interface GetEditOrderPageCaseFolder_caseFolder_productPackages_documents_documentFormat_documentType {
  friendlyName: string;
  /**
   * Id of the documenttype
   */
  id: string;
  /**
   * The name of the documentType
   */
  name: string;
}

export interface GetEditOrderPageCaseFolder_caseFolder_productPackages_documents_documentFormat {
  /**
   * Id of the documentformat
   */
  id: string;
  documentType: GetEditOrderPageCaseFolder_caseFolder_productPackages_documents_documentFormat_documentType;
}

export interface GetEditOrderPageCaseFolder_caseFolder_productPackages_documents_orders_document {
  /**
   * Id of the document
   */
  id: string | null;
}

export interface GetEditOrderPageCaseFolder_caseFolder_productPackages_documents_orders_alerting {
  text: string | null;
  smallText: string | null;
}

export interface GetEditOrderPageCaseFolder_caseFolder_productPackages_documents_orders_mediaOrder_media {
  /**
   * The id of the media
   */
  id: string | null;
  /**
   * The friendlyName of the media
   */
  friendlyName: string;
}

export interface GetEditOrderPageCaseFolder_caseFolder_productPackages_documents_orders_mediaOrder_documentFormat {
  /**
   * Id of the documentformat
   */
  id: string;
  mediaAdaptedName: string;
}

export interface GetEditOrderPageCaseFolder_caseFolder_productPackages_documents_orders_mediaOrder_publishTimes {
  /**
   * Date when the order will be published
   */
  time: any;
}

export interface GetEditOrderPageCaseFolder_caseFolder_productPackages_documents_orders_mediaOrder_status {
  /**
   * ProductStatus enum for the status
   */
  status: OrderStatus;
  /**
   * Color for vieweing the customer
   */
  color: StatusColors;
}

export interface GetEditOrderPageCaseFolder_caseFolder_productPackages_documents_orders_mediaOrder {
  /**
   * Flag when shows warning about the bookingstoptime
   */
  isCriticalTimeBookingWarning: boolean;
  /**
   * Locked
   */
  isLocked: boolean;
  /**
   * media of the order
   */
  media: GetEditOrderPageCaseFolder_caseFolder_productPackages_documents_orders_mediaOrder_media;
  /**
   * documentformat of the order
   */
  documentFormat: GetEditOrderPageCaseFolder_caseFolder_productPackages_documents_orders_mediaOrder_documentFormat;
  /**
   * times when the order will be published
   */
  publishTimes: (GetEditOrderPageCaseFolder_caseFolder_productPackages_documents_orders_mediaOrder_publishTimes | null)[];
  /**
   * Status of the order
   */
  status: GetEditOrderPageCaseFolder_caseFolder_productPackages_documents_orders_mediaOrder_status;
}

export interface GetEditOrderPageCaseFolder_caseFolder_productPackages_documents_orders {
  /**
   * The id of the mediaorder
   */
  id: string | null;
  /**
   * The Id shown for customer
   */
  customerFriendlyId: string;
  /**
   * document of the order
   */
  document: GetEditOrderPageCaseFolder_caseFolder_productPackages_documents_orders_document;
  alerting: GetEditOrderPageCaseFolder_caseFolder_productPackages_documents_orders_alerting;
  /**
   * mediaOrder properties if that kind of order
   */
  mediaOrder: GetEditOrderPageCaseFolder_caseFolder_productPackages_documents_orders_mediaOrder | null;
}

export interface GetEditOrderPageCaseFolder_caseFolder_productPackages_documents {
  /**
   * Id of the document
   */
  id: string | null;
  /**
   * InternalId of the document
   */
  internalId: number;
  /**
   * format of the document
   */
  documentFormat: GetEditOrderPageCaseFolder_caseFolder_productPackages_documents_documentFormat;
  orders: GetEditOrderPageCaseFolder_caseFolder_productPackages_documents_orders[];
}

export interface GetEditOrderPageCaseFolder_caseFolder_productPackages {
  /**
   * Product Package
   */
  productPackage: GetEditOrderPageCaseFolder_caseFolder_productPackages_productPackage;
  /**
   * ordersummary of product package
   */
  summary: GetEditOrderPageCaseFolder_caseFolder_productPackages_summary;
  /**
   * orders of the product package
   */
  documents: (GetEditOrderPageCaseFolder_caseFolder_productPackages_documents | null)[];
}

export interface GetEditOrderPageCaseFolder_caseFolder_editTabs_orders_client_route_editPageRoute {
  clientApp: ApplicationClientApps;
  absUrl: string;
  relUrl: string;
  taps5IframeAbsUrl: string | null;
  taps5RelUrl: string;
}

export interface GetEditOrderPageCaseFolder_caseFolder_editTabs_orders_client_route {
  editPageRoute: GetEditOrderPageCaseFolder_caseFolder_editTabs_orders_client_route_editPageRoute;
}

export interface GetEditOrderPageCaseFolder_caseFolder_editTabs_orders_client {
  route: GetEditOrderPageCaseFolder_caseFolder_editTabs_orders_client_route;
}

export interface GetEditOrderPageCaseFolder_caseFolder_editTabs_orders_alerting {
  smallText: string | null;
  text: string | null;
  type: OrderAlertingType;
}

export interface GetEditOrderPageCaseFolder_caseFolder_editTabs_orders_mediaOrder_edit {
  /**
   * Kind of editor using for the order
   */
  editModelType: EditModelTypes;
}

export interface GetEditOrderPageCaseFolder_caseFolder_editTabs_orders_mediaOrder_documentFormat_documentType {
  /**
   * Id of the documenttype
   */
  id: string;
  /**
   * The name of the documentType
   */
  name: string;
}

export interface GetEditOrderPageCaseFolder_caseFolder_editTabs_orders_mediaOrder_documentFormat {
  /**
   * Id of the documentformat
   */
  id: string;
  mediaAdaptedName: string;
  documentType: GetEditOrderPageCaseFolder_caseFolder_editTabs_orders_mediaOrder_documentFormat_documentType;
}

export interface GetEditOrderPageCaseFolder_caseFolder_editTabs_orders_mediaOrder_media {
  /**
   * The id of the media
   */
  id: string | null;
  /**
   * The friendlyName of the media
   */
  friendlyName: string;
}

export interface GetEditOrderPageCaseFolder_caseFolder_editTabs_orders_mediaOrder_status {
  /**
   * Color for vieweing the customer
   */
  color: StatusColors;
  /**
   * ProductStatus enum for the status
   */
  status: OrderStatus;
}

export interface GetEditOrderPageCaseFolder_caseFolder_editTabs_orders_mediaOrder {
  /**
   * Locked
   */
  isLocked: boolean;
  /**
   * information for the editors
   */
  edit: GetEditOrderPageCaseFolder_caseFolder_editTabs_orders_mediaOrder_edit;
  /**
   * documentformat of the order
   */
  documentFormat: GetEditOrderPageCaseFolder_caseFolder_editTabs_orders_mediaOrder_documentFormat;
  /**
   * media of the order
   */
  media: GetEditOrderPageCaseFolder_caseFolder_editTabs_orders_mediaOrder_media;
  /**
   * Status of the order
   */
  status: GetEditOrderPageCaseFolder_caseFolder_editTabs_orders_mediaOrder_status;
}

export interface GetEditOrderPageCaseFolder_caseFolder_editTabs_orders_document {
  /**
   * Id of the document
   */
  id: string | null;
}

export interface GetEditOrderPageCaseFolder_caseFolder_editTabs_orders {
  /**
   * The id of the mediaorder
   */
  id: string | null;
  /**
   * Order is deleted
   */
  isDeleted: boolean;
  /**
   * The Id shown for customer
   */
  customerFriendlyId: string;
  client: GetEditOrderPageCaseFolder_caseFolder_editTabs_orders_client | null;
  alerting: GetEditOrderPageCaseFolder_caseFolder_editTabs_orders_alerting;
  /**
   * mediaOrder properties if that kind of order
   */
  mediaOrder: GetEditOrderPageCaseFolder_caseFolder_editTabs_orders_mediaOrder | null;
  /**
   * document of the order
   */
  document: GetEditOrderPageCaseFolder_caseFolder_editTabs_orders_document;
}

export interface GetEditOrderPageCaseFolder_caseFolder_editTabs {
  id: string;
  name: string;
  isSubTabs: boolean;
  orders: GetEditOrderPageCaseFolder_caseFolder_editTabs_orders[];
}

export interface GetEditOrderPageCaseFolder_caseFolder {
  /**
   * The id of the caseFolder
   */
  id: string | null;
  /**
   * The internal id of the caseFolder
   */
  internalId: number;
  /**
   * The name of the caseFolder
   */
  name: string;
  customer: GetEditOrderPageCaseFolder_caseFolder_customer;
  propertiesFuneralStandard: GetEditOrderPageCaseFolder_caseFolder_propertiesFuneralStandard;
  summary: GetEditOrderPageCaseFolder_caseFolder_summary;
  /**
   * ProductPackages of the caseFolder
   */
  productPackages: (GetEditOrderPageCaseFolder_caseFolder_productPackages | null)[];
  editTabs: GetEditOrderPageCaseFolder_caseFolder_editTabs[];
}

export interface GetEditOrderPageCaseFolder {
  /**
   * Get caseFolder
   */
  caseFolder: GetEditOrderPageCaseFolder_caseFolder;
}

export interface GetEditOrderPageCaseFolderVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetGalleryImageInformation
// ====================================================

export interface GetGalleryImageInformation_galleryImages_tagGroups {
  tags: string[];
}

export interface GetGalleryImageInformation_galleryImages {
  /**
   * Id of the galleryimage
   */
  id: string;
  /**
   * Description
   */
  description: string;
  /**
   * Group name
   */
  groupName: string;
  /**
   * Thumbnail image
   */
  thumbnail100x80Url: string;
  /**
   * Public id
   */
  publicId: number;
  /**
   * Repository for the image
   */
  repository: EditModelAdvertAreaContentRepository;
  /**
   * Tag groups
   */
  tagGroups: GetGalleryImageInformation_galleryImages_tagGroups[];
}

export interface GetGalleryImageInformation {
  galleryImages: GetGalleryImageInformation_galleryImages[];
}

export interface GetGalleryImageInformationVariables {
  ids: (string | null)[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetGalleryImages
// ====================================================

export interface GetGalleryImages_galleryImagesByTags_images_tagGroups {
  tags: string[];
}

export interface GetGalleryImages_galleryImagesByTags_images {
  /**
   * Id of the galleryimage
   */
  id: string;
  /**
   * Public id
   */
  publicId: number;
  /**
   * Description
   */
  description: string;
  /**
   * Thumbnail image
   */
  thumbnail100x80Url: string;
  /**
   * Repository for the image
   */
  repository: EditModelAdvertAreaContentRepository;
  /**
   * Tag groups
   */
  tagGroups: GetGalleryImages_galleryImagesByTags_images_tagGroups[];
}

export interface GetGalleryImages_galleryImagesByTags {
  isSupportTags: boolean;
  /**
   * Total av hits in search
   */
  totalImageCount: number;
  startIndex: number;
  actuallyPageLength: number;
  pageLength: number;
  /**
   * Image find
   */
  images: GetGalleryImages_galleryImagesByTags_images[];
}

export interface GetGalleryImages {
  /**
   * Get images filter by tags
   */
  galleryImagesByTags: GetGalleryImages_galleryImagesByTags;
}

export interface GetGalleryImagesVariables {
  input: GalleryImageAvailableImagesInput;
  filterTagGroupId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetGalleryImageTagsByTagGroup
// ====================================================

export interface GetGalleryImageTagsByTagGroup_galleryImagesTagsByTagGroups {
  tags: string[];
}

export interface GetGalleryImageTagsByTagGroup {
  galleryImagesTagsByTagGroups: GetGalleryImageTagsByTagGroup_galleryImagesTagsByTagGroups[];
}

export interface GetGalleryImageTagsByTagGroupVariables {
  input: GalleryImageTagsInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getGalleryTextGroupsByTags
// ====================================================

export interface getGalleryTextGroupsByTags_galleryTextGroupsByTags_texts {
  /**
   * Id of the gallerytext
   */
  id: string;
  /**
   * Name of the text
   */
  name: string;
  /**
   * Text as xml
   */
  textXml: string;
  /**
   * Author of the text
   */
  author: string;
  /**
   * Tags
   */
  tags: string[];
  /**
   * Internal id
   */
  internalId: number;
  /**
   * Text can be edit (update/saved) of user
   */
  isEditable: boolean;
  __typename: "GalleryTextItem";
}

export interface getGalleryTextGroupsByTags_galleryTextGroupsByTags {
  /**
   * The total number of texts in the search
   */
  count: number;
  /**
   * The startindex for the search
   */
  startIndex: number;
  /**
   * Text
   */
  texts: getGalleryTextGroupsByTags_galleryTextGroupsByTags_texts[];
  __typename: "GalleryTextItemGroupsResult";
}

export interface getGalleryTextGroupsByTags {
  /**
   * Get text from gallery
   */
  galleryTextGroupsByTags: getGalleryTextGroupsByTags_galleryTextGroupsByTags;
}

export interface getGalleryTextGroupsByTagsVariables {
  input: GalleryTextItemGroupInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetMedias
// ====================================================

export interface GetMedias_smedias_medias {
  /**
   * The id of the media
   */
  id: string | null;
  /**
   * The name of the media
   */
  name: string;
}

export interface GetMedias_smedias {
  /**
   * The id of the single-media
   */
  id: string | null;
  /**
   * The name of the single-media
   */
  name: string;
  medias: (GetMedias_smedias_medias | null)[] | null;
}

export interface GetMedias {
  /**
   * Get smedias
   */
  smedias: GetMedias_smedias[];
}

export interface GetMediasVariables {
  documentTypeId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetMediasByIds
// ====================================================

export interface GetMediasByIds_medias {
  /**
   * The id of the media
   */
  id: string | null;
  /**
   * The name of the media
   */
  name: string;
}

export interface GetMediasByIds {
  /**
   * Get medias
   */
  medias: GetMediasByIds_medias[];
}

export interface GetMediasByIdsVariables {
  ids?: string[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetNetNotice
// ====================================================

export interface GetNetNotice_orders_mediaOrder_preview_material_basePage_netnotice {
  /**
   * Html-representation of the advert. Root tag html
   */
  html: string | null;
  /**
   * Error when render
   */
  isError: boolean;
  /**
   * Error reason
   */
  errorReason: string;
}

export interface GetNetNotice_orders_mediaOrder_preview_material_basePage {
  /**
   * Html material
   */
  netnotice: GetNetNotice_orders_mediaOrder_preview_material_basePage_netnotice;
}

export interface GetNetNotice_orders_mediaOrder_preview_material {
  /**
   * base page
   */
  basePage: GetNetNotice_orders_mediaOrder_preview_material_basePage;
}

export interface GetNetNotice_orders_mediaOrder_preview {
  /**
   * material
   */
  material: GetNetNotice_orders_mediaOrder_preview_material;
}

export interface GetNetNotice_orders_mediaOrder {
  /**
   * Preview of the order
   */
  preview: GetNetNotice_orders_mediaOrder_preview;
}

export interface GetNetNotice_orders {
  /**
   * The id of the mediaorder
   */
  id: string | null;
  /**
   * mediaOrder properties if that kind of order
   */
  mediaOrder: GetNetNotice_orders_mediaOrder | null;
}

export interface GetNetNotice {
  /**
   * Get orders
   */
  orders: GetNetNotice_orders[];
}

export interface GetNetNoticeVariables {
  id: string;
  isNetNoticeVisible: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetNewCaseFolderPageCaseFolder
// ====================================================

export interface GetNewCaseFolderPageCaseFolder_caseFolder_customer {
  /**
   * The id of customer
   */
  id: string;
  /**
   * The name of customer
   */
  name: string;
}

export interface GetNewCaseFolderPageCaseFolder_caseFolder_productPackages_productPackage {
  /**
   * Id of the ProductPackage
   */
  id: string;
}

export interface GetNewCaseFolderPageCaseFolder_caseFolder_productPackages {
  /**
   * Product Package
   */
  productPackage: GetNewCaseFolderPageCaseFolder_caseFolder_productPackages_productPackage;
}

export interface GetNewCaseFolderPageCaseFolder_caseFolder_propertiesFuneralStandard {
  /**
   * Id from a another businesssystem (option)
   */
  externalId: string;
  /**
   * Born date for the person in the case
   */
  personBorn: any | null;
  /**
   * Died date for the person in the case
   */
  personDied: any | null;
  /**
   * Funeral date for the person in the case
   */
  personFuneral: any | null;
  /**
   * Lastname when the person in the case was born
   */
  personBornLastname: string | null;
  /**
   * First name of the person in the case
   */
  personFirstname: string;
  /**
   * Last name of the person in the case
   */
  personLastname: string;
  /**
   * Where did the person live
   */
  personCityLive: string;
  /**
   * Informationtext about the funeral
   */
  personFuneralInfoText: string;
  /**
   * Last day for booking the cermony for memory
   */
  memoryCeremonyLastBookingDay: any | null;
}

export interface GetNewCaseFolderPageCaseFolder_caseFolder {
  /**
   * The id of the caseFolder
   */
  id: string | null;
  /**
   * The internal id of the caseFolder
   */
  internalId: number;
  /**
   * The name of the caseFolder
   */
  name: string;
  customer: GetNewCaseFolderPageCaseFolder_caseFolder_customer;
  /**
   * ProductPackages of the caseFolder
   */
  productPackages: (GetNewCaseFolderPageCaseFolder_caseFolder_productPackages | null)[];
  propertiesFuneralStandard: GetNewCaseFolderPageCaseFolder_caseFolder_propertiesFuneralStandard;
}

export interface GetNewCaseFolderPageCaseFolder {
  /**
   * Get caseFolder
   */
  caseFolder: GetNewCaseFolderPageCaseFolder_caseFolder;
}

export interface GetNewCaseFolderPageCaseFolderVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getNewsSimple
// ====================================================

export interface getNewsSimple_news {
  /**
   * Id for the newsitem
   */
  id: number;
  /**
   * The whole text for the news
   */
  text: string;
}

export interface getNewsSimple {
  /**
   * Get news
   */
  news: getNewsSimple_news[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetOrderById
// ====================================================

export interface GetOrderById_orders_document {
  /**
   * Id of the document
   */
  id: string | null;
}

export interface GetOrderById_orders_client {
  mainSystem: SystemFlags;
  isTaps5: boolean;
}

export interface GetOrderById_orders_alerting {
  smallText: string | null;
  text: string | null;
  type: OrderAlertingType;
}

export interface GetOrderById_orders_mediaOrder_publishTimes {
  /**
   * Date when the order will be published
   */
  time: any;
}

export interface GetOrderById_orders_mediaOrder_documentFormat_documentFormatCollection {
  id: string;
  /**
   * The name of the collection
   */
  name: string;
}

export interface GetOrderById_orders_mediaOrder_documentFormat_documentType {
  /**
   * Id of the documenttype
   */
  id: string;
  /**
   * The name of the documentType
   */
  name: string;
}

export interface GetOrderById_orders_mediaOrder_documentFormat {
  /**
   * Id of the documentformat
   */
  id: string;
  documentFormatCollection: GetOrderById_orders_mediaOrder_documentFormat_documentFormatCollection;
  mediaAdaptedName: string;
  documentType: GetOrderById_orders_mediaOrder_documentFormat_documentType;
}

export interface GetOrderById_orders_mediaOrder_publishConversation {
  /**
   * Validation error texts
   */
  validateErrorTexts: string[];
  /**
   * Message to media
   */
  messageFromMedia: string | null;
  /**
   * Code to verify late publish
   */
  codeLate: string;
  /**
   * Code to media. Used when the ordering is late. Passed bookingstop. This must be verfied before it can be published
   */
  isCodeLate: boolean;
  /**
   * Message to media allowed
   */
  isMessageToMedia: boolean;
  /**
   * Media-System Ordernumber has to be set
   */
  isMediaSystemOrderNumber: boolean;
  /**
   * EndCustomer subribenumber on media can be set
   */
  isMediaCustomerSubriberNumber: boolean;
  /**
   * Status for publish
   */
  publishReadyStatus: MediaOrderPublishhReadyStatus;
}

export interface GetOrderById_orders_mediaOrder_publishChannels {
  /**
   * Publish in familjesidan
   */
  inFamiljesidan: boolean;
  /**
   * Publish in minnesrummet
   */
  inMinnesrummet: boolean;
  /**
   * Publish in agency homepage
   */
  inAgency: boolean;
}

export interface GetOrderById_orders_mediaOrder_edit_userInterface {
  editStatusColor: EditUIEditStatusColor;
  editStatusText: string | null;
  publishButtonText: string | null;
  editStatusIcon: EditUIEditStatusIcon;
  isUnPublishButton: boolean;
  unPublishButtonText: string | null;
  isPublishButton: boolean;
  isLockButton: boolean;
  lockButtonText: string | null;
  isUnlockButton: boolean;
  unlockButtonText: string | null;
  isReactivateButton: boolean;
  reactivateButtonText: string | null;
  isSetExhibitOrder: boolean;
  setExhibitOrderText: string | null;
  isUpdateCustomerDocumentContentInitiator: boolean;
  updateCustomerDocumentContentInitiatorText: string | null;
  /**
   * Size limit
   */
  orderEntityNameSingular: string | null;
  /**
   * Size limit
   */
  orderEntityNamePlural: string | null;
  /**
   * Size limit
   */
  orderEntityNameSingularDetermined: string | null;
  /**
   * Size limit
   */
  orderEntityNamePluralDetermined: string | null;
  isCopyFuneralInfoToMemoryRoom: boolean;
  copyFuneralInfoToMemoryRoomText: string | null;
  isNetnoticeEnabled: boolean;
  isNetnoticeSupported: boolean;
}

export interface GetOrderById_orders_mediaOrder_edit_resources {
  /**
   * Get style css for the specialCharacters
   */
  styleCssSpecialCharacters: string;
}

export interface GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_validateErrors {
  error: EditModelAdvertAreaContentValidateErrors;
  validationText: string | null;
}

export interface GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_common_marginLeftRightOffset {
  /**
   * Type
   */
  type: RelativeTypes;
  /**
   * Value
   */
  value: number;
}

export interface GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_common_margin {
  /**
   * Type of unit
   */
  type: UnitTypes;
  /**
   * Left
   */
  left: number | null;
  /**
   * Right
   */
  right: number | null;
  /**
   * Top
   */
  top: number | null;
  /**
   * Bottom
   */
  bottom: number | null;
}

export interface GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_common_size {
  /**
   * Type
   */
  type: UnitTypes;
  /**
   * Min Width
   */
  minWidth: number | null;
  /**
   * Min Height
   */
  minHeight: number | null;
  /**
   * Max Width
   */
  maxWidth: number | null;
  /**
   * Max Height
   */
  maxHeight: number | null;
  /**
   * Fix Width
   */
  fixedWidth: number | null;
  /**
   * Fix Height
   */
  fixedHeight: number | null;
}

export interface GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_common {
  /**
   * Alternative BlockStyle
   */
  blockStyleAreaNameId: string | null;
  marginLeftRightOffset: GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_common_marginLeftRightOffset | null;
  margin: GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_common_margin | null;
  size: GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_common_size | null;
}

export interface GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_image {
  /**
   * Repository of image
   */
  type: EditModelAdvertAreaContentRepository | null;
  /**
   * id for the image
   */
  imageId: string | null;
  /**
   * public id for the image
   */
  imagePublicId: string | null;
  /**
   * public id for the image
   */
  imageThumbnaill100x80Url: string | null;
  /**
   * Height for the image in mm
   */
  heightMM: number | null;
  /**
   * DefaultHeight for the image in mm
   */
  defaultHeightMM: number | null;
  /**
   * IsSizeFree
   */
  isSizeFree: boolean | null;
  /**
   * Upload image content
   */
  uploadData: string | null;
  /**
   * Upload filename
   */
  uploadFilename: string | null;
  /**
   * Bitmap resolution in dpi
   */
  resolution: number | null;
  /**
   * Upload prefered HeightMM of uploaded image
   */
  uploadPreferedHeightMM: number | null;
  /**
   * Repository of image
   */
  repository: EditModelAdvertAreaContentRepository | null;
  /**
   * Upload source image content
   */
  uploadDataSource: string | null;
  /**
   * Comment about the upload
   */
  uploadComment: string | null;
}

export interface GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_text_textScaleWidthOffset {
  /**
   * Type
   */
  type: RelativeTypes;
  /**
   * Value
   */
  value: number;
}

export interface GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_text_fontSize {
  /**
   * Type of unit
   */
  type: UnitTypes;
  /**
   * Value
   */
  value: number;
}

export interface GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_text_lineHeight {
  /**
   * Type of unit
   */
  type: UnitTypes;
  /**
   * Value
   */
  value: number;
}

export interface GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_text_lineHeightEmpty {
  /**
   * Type of unit
   */
  type: UnitTypes;
  /**
   * Value
   */
  value: number;
}

export interface GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_text_letterSpacing {
  /**
   * Type of unit
   */
  type: UnitTypes;
  /**
   * Value
   */
  value: number;
}

export interface GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_text_textScaleWidth {
  /**
   * Type
   */
  type: RelativeTypes;
  /**
   * Value
   */
  value: number;
}

export interface GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_text {
  /**
   * Font style
   */
  fontStyle: FontStyles | null;
  /**
   * Textscale width offset
   */
  textScaleWidthOffset: GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_text_textScaleWidthOffset | null;
  /**
   * Font weight
   */
  fontWeight: FontWeights | null;
  /**
   * Text alignment
   */
  textAlignment: TextAlignments | null;
  /**
   * Text formats in xml
   */
  xmlText: string | null;
  /**
   * Is htmleditor readonly
   */
  isXmlTextReadonly: boolean | null;
  /**
   * Fontfamily of the text
   */
  fontFamily: string | null;
  /**
   * Font
   */
  fontSize: GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_text_fontSize | null;
  /**
   * Line-height
   */
  lineHeight: GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_text_lineHeight | null;
  /**
   * Line-height for empty rows
   */
  lineHeightEmpty: GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_text_lineHeightEmpty | null;
  /**
   * Letter-Spacing
   */
  letterSpacing: GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_text_letterSpacing | null;
  /**
   * Textscale width
   */
  textScaleWidth: GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_text_textScaleWidth | null;
  /**
   * Text do not wrap when text reach its width
   */
  textNoWrap: boolean | null;
}

export interface GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_separator {
  /**
   * Type of image
   */
  type: EditModelAdvertAreaContentItemSeparatorTypes;
  imageId: string | null;
  /**
   * Repository of image
   */
  repository: EditModelAdvertAreaContentRepository | null;
  /**
   * Width for the image in mm
   */
  widthMM: number | null;
  /**
   * Height for the image in mm
   */
  heightMM: number | null;
}

export interface GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_fields {
  /**
   * Name
   */
  name: string;
  /**
   * Header
   */
  header: string | null;
  /**
   * Type
   */
  type: EditModelAdvertAreaContentItemFieldItemTypes | null;
  /**
   * Editor
   */
  editor: EditModelAdvertAreaContentItemFieldItemEditors | null;
  /**
   * Value
   */
  value: string | null;
  __typename: "EditModelAdvertAreaContentItemFieldItem";
}

export interface GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item {
  /**
   * Common
   */
  common: GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_common | null;
  /**
   * Value is only for demo purpose
   */
  isDemoValue: boolean | null;
  /**
   * ImageContent
   */
  image: GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_image | null;
  /**
   * TextContent
   */
  text: GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_text | null;
  /**
   * HorizontalLineContent
   */
  separator: GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_separator | null;
  /**
   * Fields
   */
  fields: (GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_fields | null)[] | null;
}

export interface GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns {
  item: GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item;
}

export interface GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows {
  columns: GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns[];
}

export interface GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_value {
  /**
   * Id for the content-value
   */
  id: string | null;
  /**
   * Is content visible
   */
  visible: boolean;
  /**
   * Is it content
   */
  hasContent: boolean;
  /**
   * Is editor visible
   */
  isEditorVisible: boolean;
  /**
   * Id to the template
   */
  templateAreaId: string | null;
  /**
   * Rows
   */
  rows: GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows[];
}

export interface GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_access {
  add: boolean;
  modify: boolean;
  statusAdd: EditModelAdvertAreaContentEditorAccessStatuses;
}

export interface GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_common_allowedMarginLeftRightOffsetInterval {
  /**
   * Type of unit
   */
  type: RelativeTypes;
  /**
   * Max-Value
   */
  maxValue: number;
  /**
   * Min-Value
   */
  minValue: number;
  /**
   * Interval-Value
   */
  intervalValue: number;
}

export interface GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_common_blockStyles {
  /**
   * Id
   */
  id: string;
  /**
   * Name
   */
  name: string;
}

export interface GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_common {
  /**
   * Margin can adjust margins
   */
  isEnabledAdjustMargins: boolean;
  /**
   * If UpdateOrdersContent is avaiable
   */
  isEnabledUpdateOrdersContent: boolean;
  /**
   * If it is possible to add columns
   */
  isEnabledMultiColumns: boolean;
  /**
   * Enable margin setting
   */
  isMarginLeftRightOffset: boolean;
  /**
   * Allowed margin settings
   */
  allowedMarginLeftRightOffsetInterval: GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_common_allowedMarginLeftRightOffsetInterval | null;
  /**
   * Block styles
   */
  blockStyles: GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_common_blockStyles[];
}

export interface GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_text_allowedFontSizes {
  /**
   * Values
   */
  values: number[];
}

export interface GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_text_allowedLineHeights {
  /**
   * Values
   */
  values: number[];
}

export interface GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_text_allowedFontSizeInterval {
  /**
   * Type of unit
   */
  type: UnitTypes;
  /**
   * Interval-Value
   */
  intervalValue: number;
}

export interface GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_text_allowedLineHeightInterval {
  /**
   * Type of unit
   */
  type: UnitTypes;
  /**
   * Interval-Value
   */
  intervalValue: number;
}

export interface GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_text_allowedTextScaleWidthOffsetInterval {
  /**
   * Interval-Value
   */
  intervalValue: number;
  /**
   * Min-Value
   */
  minValue: number;
  /**
   * Max-Value
   */
  maxValue: number;
}

export interface GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_text_allowedCharacterGalleries_items {
  /**
   * Id
   */
  id: string;
  /**
   * Name
   */
  name: string;
  /**
   * Title
   */
  title: string;
  /**
   * Preview
   */
  preview: string | null;
  /**
   * Html
   */
  html: string;
}

export interface GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_text_allowedCharacterGalleries {
  /**
   * Id
   */
  id: string;
  isAdvancedUser: boolean;
  /**
   * Items
   */
  items: GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_text_allowedCharacterGalleries_items[];
}

export interface GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_text_allowedParagraphClasses {
  /**
   * Id
   */
  id: string;
  /**
   * Name
   */
  name: string;
}

export interface GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_text {
  /**
   * Default textalignment from template
   */
  defaultTextAdjustment: TextAlignments;
  /**
   * Allowed font sizes
   */
  allowedFontSizes: GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_text_allowedFontSizes;
  /**
   * Allowed font styles
   */
  allowedFontStyles: FontStyles[];
  /**
   * Allowed font weights
   */
  allowedFontWeights: FontWeights[];
  /**
   * Allowed line height sizes
   */
  allowedLineHeights: GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_text_allowedLineHeights;
  /**
   * Allowed font families
   */
  allowedFontFamilies: string[];
  /**
   * Allowed text adjustments
   */
  allowedTextAdjustments: TextAlignments[];
  /**
   * Allowed font size interval
   */
  allowedFontSizeInterval: GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_text_allowedFontSizeInterval;
  /**
   * Allowed line height size interval
   */
  allowedLineHeightInterval: GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_text_allowedLineHeightInterval;
  /**
   * Enable text scale width offset
   */
  isTextScaleWidthOffset: boolean;
  /**
   * Allowed TextScale width offset interval
   */
  allowedTextScaleWidthOffsetInterval: GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_text_allowedTextScaleWidthOffsetInterval | null;
  /**
   * Allowed special characters galleries
   */
  allowedCharacterGalleries: GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_text_allowedCharacterGalleries[];
  /**
   * Allowed number of paragraphClass
   */
  allowedParagraphClasses: GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_text_allowedParagraphClasses[];
  /**
   * Enable to change font size
   */
  isEnabledFontSize: boolean;
  /**
   * Enable to change font style
   */
  isEnabledFontStyle: boolean;
  /**
   * Enable to change line height
   */
  isEnabledLineHeight: boolean;
  /**
   * Enable to change font family
   */
  isEnabledFontFamily: boolean;
  /**
   * Enable to change font weights
   */
  isEnabledFontWeight: boolean;
  /**
   * Enable to change letter spacing
   */
  isEnabledLetterSpacing: boolean;
  /**
   * Enable to advanced settings for the admin/support
   */
  isEnabledAdvancedSettings: boolean;
  /**
   * Enable to change letter spacing
   */
  isEnabledTextScaleWidthTolerance: boolean;
  /**
   * Enable gallery option
   */
  isEnabledGallery: boolean;
}

export interface GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_image_adjustment_brightness {
  /**
   * Interval-Value
   */
  intervalValue: number;
  /**
   * Max-Value
   */
  maxValue: number;
  /**
   * Min-Value
   */
  minValue: number;
  /**
   * Type of unit
   */
  type: RelativeTypes;
}

export interface GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_image_adjustment_contrast {
  /**
   * Interval-Value
   */
  intervalValue: number;
  /**
   * Max-Value
   */
  maxValue: number;
  /**
   * Min-Value
   */
  minValue: number;
  /**
   * Type of unit
   */
  type: RelativeTypes;
}

export interface GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_image_adjustment_rotate {
  /**
   * Interval-Value
   */
  intervalValue: number;
  /**
   * Max-Value
   */
  maxValue: number;
  /**
   * Min-Value
   */
  minValue: number;
  /**
   * Type of unit
   */
  type: RelativeTypes;
}

export interface GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_image_adjustment {
  brightness: GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_image_adjustment_brightness;
  contrast: GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_image_adjustment_contrast;
  rotate: GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_image_adjustment_rotate;
}

export interface GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_image_mask {
  imageUrl: string | null;
}

export interface GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_image {
  /**
   * Min image height
   */
  minImageHeight: number;
  /**
   * Max image height
   */
  maxImageHeight: number;
  /**
   * Filter by gallery image category
   */
  filterGalleryImageAttributeInternalIds: number[];
  /**
   * Filter by not gallery image category
   */
  filterGalleryImageAttributeNotInternalIds: number[];
  /**
   * Filter by not gallery image category
   */
  galleryImageOption: string;
  /**
   * Filter by not gallery image category
   */
  documentFormatId: string;
  /**
   * Is the user enabled to switch to free size
   */
  isEnabledFreeSize: boolean;
  /**
   * Is the user enabled to upload images
   */
  isEnabledUpload: boolean;
  /**
   * Gallery is enabled
   */
  isEnabledGallery: boolean;
  /**
   * Resize is enabled
   */
  isEnabledResize: boolean;
  adjustment: GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_image_adjustment | null;
  mask: GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_image_mask | null;
}

export interface GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_help {
  html: string | null;
  hoverHtml: string | null;
  supportHtml: string | null;
}

export interface GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor {
  /**
   * Type of editor (Image, Text, Separator)
   */
  type: EditModelAdvertAreaContentEditorTypes;
  /**
   * SubType of editor
   */
  behaviour: EditModelAdvertAreaContentEditorBehaviours;
  /**
   * Access properties
   */
  access: GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_access;
  /**
   * Common parameters
   */
  common: GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_common;
  /**
   * Text parameters
   */
  text: GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_text | null;
  /**
   * Image parameters
   */
  image: GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_image | null;
  /**
   * Help properties
   */
  help: GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_help | null;
}

export interface GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content {
  /**
   * Validate errors
   */
  validateErrors: GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_validateErrors[];
  value: GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_value;
  editor: GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor | null;
}

export interface GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_style_margin {
  /**
   * Top
   */
  top: number | null;
  /**
   * Left
   */
  left: number | null;
  /**
   * Right
   */
  right: number | null;
  /**
   * Bottom
   */
  bottom: number | null;
}

export interface GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_style_content_size {
  /**
   * Type
   */
  type: UnitTypes;
  /**
   * Min Width
   */
  minWidth: number | null;
  /**
   * Min Height
   */
  minHeight: number | null;
  /**
   * Max Width
   */
  maxWidth: number | null;
  /**
   * Max Height
   */
  maxHeight: number | null;
  /**
   * Fix Width
   */
  fixedWidth: number | null;
  /**
   * Fix Height
   */
  fixedHeight: number | null;
}

export interface GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_style_content {
  /**
   * Size limit
   */
  size: GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_style_content_size | null;
}

export interface GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_style_text_fontSize {
  /**
   * Type of unit
   */
  type: UnitTypes;
  /**
   * Value
   */
  value: number;
}

export interface GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_style_text_lineHeight {
  /**
   * Type of unit
   */
  type: UnitTypes;
  /**
   * Value
   */
  value: number;
}

export interface GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_style_text_lineHeightEmpty {
  /**
   * Type of unit
   */
  type: UnitTypes;
  /**
   * Value
   */
  value: number;
}

export interface GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_style_text_letterSpacing {
  /**
   * Type of unit
   */
  type: UnitTypes;
  /**
   * Value
   */
  value: number;
}

export interface GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_style_text_textScaleWidthOffset {
  /**
   * Type
   */
  type: RelativeTypes;
  /**
   * Value
   */
  value: number;
}

export interface GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_style_text_textScaleWidth {
  /**
   * Type
   */
  type: RelativeTypes;
  /**
   * Value
   */
  value: number;
}

export interface GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_style_text {
  fontFamily: string;
  /**
   * Font-size
   */
  fontSize: GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_style_text_fontSize | null;
  /**
   * Line height
   */
  lineHeight: GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_style_text_lineHeight | null;
  /**
   * Line height for empty rows
   */
  lineHeightEmpty: GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_style_text_lineHeightEmpty | null;
  /**
   * Letter spacing
   */
  letterSpacing: GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_style_text_letterSpacing | null;
  textScaleWidthOffset: GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_style_text_textScaleWidthOffset;
  textScaleWidth: GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_style_text_textScaleWidth;
  fontStyle: FontStyles | null;
  fontWeight: FontWeights | null;
}

export interface GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_style {
  /**
   * margins
   */
  margin: GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_style_margin | null;
  content: GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_style_content;
  /**
   * Text settings
   */
  text: GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_style_text;
}

export interface GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_rectangle {
  /**
   * X coordinate
   */
  x: number;
  /**
   * Y coordinate
   */
  y: number;
  /**
   * Width
   */
  w: number;
  /**
   * Width
   */
  h: number;
}

export interface GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas {
  /**
   * Id of the blockeditor
   */
  id: string;
  /**
   * Parameters of the blockeditor
   */
  header: string;
  content: GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content;
  style: GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_style;
  rectangle: GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_rectangle | null;
}

export interface GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage {
  areas: (GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas | null)[] | null;
}

export interface GetOrderById_orders_mediaOrder_edit_editModelAdvert {
  /**
   * If UpdateOrdersContent is avaiable
   */
  isEnabledUpdateOrdersContent: boolean;
  basePage: GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage;
}

export interface GetOrderById_orders_mediaOrder_edit_history {
  /**
   * How many steps it is possible to go back in history
   */
  countStepBack: number;
}

export interface GetOrderById_orders_mediaOrder_edit {
  /**
   * Texts for editing the order
   */
  userInterface: GetOrderById_orders_mediaOrder_edit_userInterface;
  resources: GetOrderById_orders_mediaOrder_edit_resources | null;
  editModelAdvert: GetOrderById_orders_mediaOrder_edit_editModelAdvert | null;
  history: GetOrderById_orders_mediaOrder_edit_history;
}

export interface GetOrderById_orders_mediaOrder_preview_material_basePage_size {
  /**
   * Width
   */
  width: number;
  /**
   * Height
   */
  height: number;
}

export interface GetOrderById_orders_mediaOrder_preview_material_basePage {
  size: GetOrderById_orders_mediaOrder_preview_material_basePage_size | null;
}

export interface GetOrderById_orders_mediaOrder_preview_material_pages_png {
  /**
   * Url till pngfile
   */
  url: string;
}

export interface GetOrderById_orders_mediaOrder_preview_material_pages {
  /**
   * Png material
   */
  png: GetOrderById_orders_mediaOrder_preview_material_pages_png;
}

export interface GetOrderById_orders_mediaOrder_preview_material {
  /**
   * base page
   */
  basePage: GetOrderById_orders_mediaOrder_preview_material_basePage;
  /**
   * pages
   */
  pages: (GetOrderById_orders_mediaOrder_preview_material_pages | null)[];
}

export interface GetOrderById_orders_mediaOrder_preview_price_customer {
  /**
   * How much the product is pricing for the scope in the contract
   */
  priceContract: any | null;
  /**
   * How much the product is pricing for the order if it has been override the contract
   */
  priceContractOverride: any | null;
}

export interface GetOrderById_orders_mediaOrder_preview_price_endCustomer {
  /**
   * Whats pay for the order and rounded
   */
  pricePayRounded: any | null;
}

export interface GetOrderById_orders_mediaOrder_preview_price {
  /**
   * Currency
   */
  currency: string;
  /**
   * Customer view of the price
   */
  customer: GetOrderById_orders_mediaOrder_preview_price_customer;
  /**
   * EndCustomer view of the price
   */
  endCustomer: GetOrderById_orders_mediaOrder_preview_price_endCustomer;
}

export interface GetOrderById_orders_mediaOrder_preview {
  /**
   * material
   */
  material: GetOrderById_orders_mediaOrder_preview_material;
  /**
   * price of the order
   */
  price: GetOrderById_orders_mediaOrder_preview_price | null;
}

export interface GetOrderById_orders_mediaOrder_media {
  /**
   * The id of the media
   */
  id: string | null;
  /**
   * The friendlyName of the media
   */
  friendlyName: string;
}

export interface GetOrderById_orders_mediaOrder_status {
  /**
   * Color for vieweing the customer
   */
  color: StatusColors;
  /**
   * ProductStatus enum for the status
   */
  status: OrderStatus;
}

export interface GetOrderById_orders_mediaOrder {
  /**
   * Time when last sending the booking to media
   */
  timeBookingStop: any | null;
  /**
   * Locked
   */
  isLocked: boolean;
  /**
   * times when the order will be published
   */
  publishTimes: (GetOrderById_orders_mediaOrder_publishTimes | null)[];
  /**
   * documentformat of the order
   */
  documentFormat: GetOrderById_orders_mediaOrder_documentFormat;
  /**
   * Publish conversation settings for the media
   */
  publishConversation: GetOrderById_orders_mediaOrder_publishConversation;
  /**
   * Parallell publish channels
   */
  publishChannels: GetOrderById_orders_mediaOrder_publishChannels;
  /**
   * information for the editors
   */
  edit: GetOrderById_orders_mediaOrder_edit;
  /**
   * Preview of the order
   */
  preview: GetOrderById_orders_mediaOrder_preview;
  /**
   * media of the order
   */
  media: GetOrderById_orders_mediaOrder_media;
  /**
   * Status of the order
   */
  status: GetOrderById_orders_mediaOrder_status;
}

export interface GetOrderById_orders {
  /**
   * The id of the mediaorder
   */
  id: string | null;
  /**
   * The Id shown for customer
   */
  customerFriendlyId: string;
  /**
   * Order is deleted
   */
  isDeleted: boolean;
  /**
   * document of the order
   */
  document: GetOrderById_orders_document;
  client: GetOrderById_orders_client | null;
  alerting: GetOrderById_orders_alerting;
  /**
   * mediaOrder properties if that kind of order
   */
  mediaOrder: GetOrderById_orders_mediaOrder | null;
}

export interface GetOrderById {
  /**
   * Get orders
   */
  orders: GetOrderById_orders[];
}

export interface GetOrderByIdVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetOrderByIdLiveStream
// ====================================================

export interface GetOrderByIdLiveStream_orders_mediaOrder_order_mediaOrder_preview_price_endCustomer {
  /**
   * Whats pay for the order and rounded
   */
  pricePayRounded: any | null;
}

export interface GetOrderByIdLiveStream_orders_mediaOrder_order_mediaOrder_preview_price {
  /**
   * Currency
   */
  currency: string;
  /**
   * EndCustomer view of the price
   */
  endCustomer: GetOrderByIdLiveStream_orders_mediaOrder_order_mediaOrder_preview_price_endCustomer;
}

export interface GetOrderByIdLiveStream_orders_mediaOrder_order_mediaOrder_preview {
  /**
   * price of the order
   */
  price: GetOrderByIdLiveStream_orders_mediaOrder_order_mediaOrder_preview_price | null;
}

export interface GetOrderByIdLiveStream_orders_mediaOrder_order_mediaOrder_status {
  /**
   * Color for vieweing the customer
   */
  color: StatusColors;
  /**
   * ProductStatus enum for the status
   */
  status: OrderStatus;
}

export interface GetOrderByIdLiveStream_orders_mediaOrder_order_mediaOrder {
  /**
   * Preview of the order
   */
  preview: GetOrderByIdLiveStream_orders_mediaOrder_order_mediaOrder_preview;
  /**
   * Status of the order
   */
  status: GetOrderByIdLiveStream_orders_mediaOrder_order_mediaOrder_status;
}

export interface GetOrderByIdLiveStream_orders_mediaOrder_order {
  /**
   * The Id shown for customer
   */
  customerFriendlyId: string;
  /**
   * mediaOrder properties if that kind of order
   */
  mediaOrder: GetOrderByIdLiveStream_orders_mediaOrder_order_mediaOrder | null;
}

export interface GetOrderByIdLiveStream_orders_mediaOrder_publishChannels {
  /**
   * Publish in familjesidan
   */
  inFamiljesidan: boolean;
  /**
   * Publish in minnesrummet
   */
  inMinnesrummet: boolean;
  /**
   * Publish in agency homepage
   */
  inAgency: boolean;
  __typename: "MediaOrderPublishChannels";
}

export interface GetOrderByIdLiveStream_orders_mediaOrder_publishTimes {
  /**
   * Date when the order will be published
   */
  time: any;
  __typename: "MediaOrderPublishDay";
}

export interface GetOrderByIdLiveStream_orders_mediaOrder_edit_userInterface {
  editStatusColor: EditUIEditStatusColor;
  editStatusText: string | null;
  publishButtonText: string | null;
  editStatusIcon: EditUIEditStatusIcon;
  isUnPublishButton: boolean;
  unPublishButtonText: string | null;
  isPublishButton: boolean;
  isLockButton: boolean;
  lockButtonText: string | null;
  isUnlockButton: boolean;
  unlockButtonText: string | null;
  isReactivateButton: boolean;
  reactivateButtonText: string | null;
  isSetExhibitOrder: boolean;
  setExhibitOrderText: string | null;
  isUpdateCustomerDocumentContentInitiator: boolean;
  updateCustomerDocumentContentInitiatorText: string | null;
  /**
   * Size limit
   */
  orderEntityNameSingular: string | null;
  /**
   * Size limit
   */
  orderEntityNamePlural: string | null;
  /**
   * Size limit
   */
  orderEntityNameSingularDetermined: string | null;
  /**
   * Size limit
   */
  orderEntityNamePluralDetermined: string | null;
  isCopyFuneralInfoToMemoryRoom: boolean;
  copyFuneralInfoToMemoryRoomText: string | null;
  isNetnoticeEnabled: boolean;
  isNetnoticeSupported: boolean;
}

export interface GetOrderByIdLiveStream_orders_mediaOrder_edit_editModelLiveStream_advanced {
  playerUrl: string | null;
  ingestionKey: string | null;
  ingestionUrl: string | null;
  streamName: string | null;
  RecorderLarix500: string | null;
  RecorderLarix1000: string | null;
  RecorderLarix1500: string | null;
  RecorderLarix2000: string | null;
  GoCoderIOS: string | null;
  GoCoderAndriod: string | null;
  qrRecorderLarix500: string | null;
  qrRecorderLarix1000: string | null;
  qrRecorderLarix1500: string | null;
  qrRecorderLarix2000: string | null;
  qrGoCoderIOS: string | null;
  qrGoCoderAndriod: string | null;
  GoCoderAndroid: string | null;
  __typename: "EditModelLiveStreamAdvanced";
}

export interface GetOrderByIdLiveStream_orders_mediaOrder_edit_editModelLiveStream {
  isStored: boolean;
  isActivated: boolean;
  timeLive: any | null;
  waitImageUrl: string | null;
  ceremonyAgendaUrl: string | null;
  advanced: GetOrderByIdLiveStream_orders_mediaOrder_edit_editModelLiveStream_advanced;
  __typename: "EditModelLiveStream";
}

export interface GetOrderByIdLiveStream_orders_mediaOrder_edit {
  /**
   * Texts for editing the order
   */
  userInterface: GetOrderByIdLiveStream_orders_mediaOrder_edit_userInterface;
  /**
   * Kind of editor using for the order
   */
  editModelType: EditModelTypes;
  editModelLiveStream: GetOrderByIdLiveStream_orders_mediaOrder_edit_editModelLiveStream | null;
  __typename: "EditInfo";
}

export interface GetOrderByIdLiveStream_orders_mediaOrder {
  /**
   * Order for this mediaorder
   */
  order: GetOrderByIdLiveStream_orders_mediaOrder_order;
  /**
   * Time when last sending the booking to media
   */
  timeBookingStop: any | null;
  /**
   * Locked
   */
  isLocked: boolean;
  /**
   * Parallell publish channels
   */
  publishChannels: GetOrderByIdLiveStream_orders_mediaOrder_publishChannels;
  /**
   * times when the order will be published
   */
  publishTimes: (GetOrderByIdLiveStream_orders_mediaOrder_publishTimes | null)[];
  /**
   * information for the editors
   */
  edit: GetOrderByIdLiveStream_orders_mediaOrder_edit;
  __typename: "MediaOrder";
}

export interface GetOrderByIdLiveStream_orders {
  /**
   * The id of the mediaorder
   */
  id: string | null;
  /**
   * The Id shown for customer
   */
  customerFriendlyId: string;
  /**
   * mediaOrder properties if that kind of order
   */
  mediaOrder: GetOrderByIdLiveStream_orders_mediaOrder | null;
  __typename: "Order";
}

export interface GetOrderByIdLiveStream {
  /**
   * Get orders
   */
  orders: GetOrderByIdLiveStream_orders[];
}

export interface GetOrderByIdLiveStreamVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetOrderByIdMemoryRoom
// ====================================================

export interface GetOrderByIdMemoryRoom_orders_mediaOrder_publishChannels {
  /**
   * Publish in familjesidan
   */
  inFamiljesidan: boolean;
  /**
   * Publish in minnesrummet
   */
  inMinnesrummet: boolean;
  /**
   * Publish in agency homepage
   */
  inAgency: boolean;
}

export interface GetOrderByIdMemoryRoom_orders_mediaOrder_publishTimes {
  /**
   * Date when the order will be published
   */
  time: any;
}

export interface GetOrderByIdMemoryRoom_orders_mediaOrder_status {
  /**
   * Color for vieweing the customer
   */
  color: StatusColors;
  /**
   * ProductStatus enum for the status
   */
  status: OrderStatus;
  /**
   * Order is alread
   */
  isBookingSent: boolean;
}

export interface GetOrderByIdMemoryRoom_orders_mediaOrder_preview_price_endCustomer {
  /**
   * Whats pay for the order and rounded
   */
  pricePayRounded: any | null;
}

export interface GetOrderByIdMemoryRoom_orders_mediaOrder_preview_price {
  /**
   * Currency
   */
  currency: string;
  /**
   * EndCustomer view of the price
   */
  endCustomer: GetOrderByIdMemoryRoom_orders_mediaOrder_preview_price_endCustomer;
}

export interface GetOrderByIdMemoryRoom_orders_mediaOrder_preview {
  /**
   * price of the order
   */
  price: GetOrderByIdMemoryRoom_orders_mediaOrder_preview_price | null;
}

export interface GetOrderByIdMemoryRoom_orders_mediaOrder_edit_userInterface {
  editStatusColor: EditUIEditStatusColor;
  editStatusText: string | null;
  publishButtonText: string | null;
  editStatusIcon: EditUIEditStatusIcon;
  isUnPublishButton: boolean;
  unPublishButtonText: string | null;
  isPublishButton: boolean;
  isLockButton: boolean;
  lockButtonText: string | null;
  isUnlockButton: boolean;
  unlockButtonText: string | null;
  isReactivateButton: boolean;
  reactivateButtonText: string | null;
  isSetExhibitOrder: boolean;
  setExhibitOrderText: string | null;
  isUpdateCustomerDocumentContentInitiator: boolean;
  updateCustomerDocumentContentInitiatorText: string | null;
  /**
   * Size limit
   */
  orderEntityNameSingular: string | null;
  /**
   * Size limit
   */
  orderEntityNamePlural: string | null;
  /**
   * Size limit
   */
  orderEntityNameSingularDetermined: string | null;
  /**
   * Size limit
   */
  orderEntityNamePluralDetermined: string | null;
  isCopyFuneralInfoToMemoryRoom: boolean;
  copyFuneralInfoToMemoryRoomText: string | null;
  isNetnoticeEnabled: boolean;
  isNetnoticeSupported: boolean;
}

export interface GetOrderByIdMemoryRoom_orders_mediaOrder_edit_editModelMemoryRoom_entries {
  memoryRoomUrl: string;
  voucherKey: string;
}

export interface GetOrderByIdMemoryRoom_orders_mediaOrder_edit_editModelMemoryRoom_case_personTimeBorn {
  value: any | null;
}

export interface GetOrderByIdMemoryRoom_orders_mediaOrder_edit_editModelMemoryRoom_case_personTimeDied {
  value: any | null;
}

export interface GetOrderByIdMemoryRoom_orders_mediaOrder_edit_editModelMemoryRoom_case {
  personFirstname: string;
  personLastname: string;
  personBornLastname: string;
  personCityDied: string;
  personTimeBorn: GetOrderByIdMemoryRoom_orders_mediaOrder_edit_editModelMemoryRoom_case_personTimeBorn;
  personTimeDied: GetOrderByIdMemoryRoom_orders_mediaOrder_edit_editModelMemoryRoom_case_personTimeDied;
}

export interface GetOrderByIdMemoryRoom_orders_mediaOrder_edit_editModelMemoryRoom_features {
  isLightningCandle: boolean;
}

export interface GetOrderByIdMemoryRoom_orders_mediaOrder_edit_editModelMemoryRoom_contents_avatarImage_value {
  id: string;
  imageUrl: string;
  name: string;
}

export interface GetOrderByIdMemoryRoom_orders_mediaOrder_edit_editModelMemoryRoom_contents_avatarImage {
  value: GetOrderByIdMemoryRoom_orders_mediaOrder_edit_editModelMemoryRoom_contents_avatarImage_value | null;
}

export interface GetOrderByIdMemoryRoom_orders_mediaOrder_edit_editModelMemoryRoom_contents_images {
  id: string;
  imageUrl: string;
  name: string;
}

export interface GetOrderByIdMemoryRoom_orders_mediaOrder_edit_editModelMemoryRoom_contents {
  welcomeText: string;
  avatarImage: GetOrderByIdMemoryRoom_orders_mediaOrder_edit_editModelMemoryRoom_contents_avatarImage | null;
  images: GetOrderByIdMemoryRoom_orders_mediaOrder_edit_editModelMemoryRoom_contents_images[];
}

export interface GetOrderByIdMemoryRoom_orders_mediaOrder_edit_editModelMemoryRoom_funeral_ceremonies_lastBookingTime {
  value: any | null;
}

export interface GetOrderByIdMemoryRoom_orders_mediaOrder_edit_editModelMemoryRoom_funeral_ceremonies_time {
  value: any | null;
}

export interface GetOrderByIdMemoryRoom_orders_mediaOrder_edit_editModelMemoryRoom_funeral_ceremonies {
  lastBookingTime: GetOrderByIdMemoryRoom_orders_mediaOrder_edit_editModelMemoryRoom_funeral_ceremonies_lastBookingTime;
  time: GetOrderByIdMemoryRoom_orders_mediaOrder_edit_editModelMemoryRoom_funeral_ceremonies_time;
  locationText: string;
  infoText: string;
}

export interface GetOrderByIdMemoryRoom_orders_mediaOrder_edit_editModelMemoryRoom_funeral {
  ceremonies: GetOrderByIdMemoryRoom_orders_mediaOrder_edit_editModelMemoryRoom_funeral_ceremonies[];
}

export interface GetOrderByIdMemoryRoom_orders_mediaOrder_edit_editModelMemoryRoom {
  id: string;
  entries: GetOrderByIdMemoryRoom_orders_mediaOrder_edit_editModelMemoryRoom_entries | null;
  case: GetOrderByIdMemoryRoom_orders_mediaOrder_edit_editModelMemoryRoom_case;
  features: GetOrderByIdMemoryRoom_orders_mediaOrder_edit_editModelMemoryRoom_features;
  contents: GetOrderByIdMemoryRoom_orders_mediaOrder_edit_editModelMemoryRoom_contents;
  funeral: GetOrderByIdMemoryRoom_orders_mediaOrder_edit_editModelMemoryRoom_funeral;
}

export interface GetOrderByIdMemoryRoom_orders_mediaOrder_edit {
  /**
   * Texts for editing the order
   */
  userInterface: GetOrderByIdMemoryRoom_orders_mediaOrder_edit_userInterface;
  /**
   * Kind of editor using for the order
   */
  editModelType: EditModelTypes;
  editModelMemoryRoom: GetOrderByIdMemoryRoom_orders_mediaOrder_edit_editModelMemoryRoom | null;
}

export interface GetOrderByIdMemoryRoom_orders_mediaOrder {
  /**
   * Locked
   */
  isLocked: boolean;
  /**
   * Parallell publish channels
   */
  publishChannels: GetOrderByIdMemoryRoom_orders_mediaOrder_publishChannels;
  /**
   * times when the order will be published
   */
  publishTimes: (GetOrderByIdMemoryRoom_orders_mediaOrder_publishTimes | null)[];
  /**
   * Status of the order
   */
  status: GetOrderByIdMemoryRoom_orders_mediaOrder_status;
  /**
   * Preview of the order
   */
  preview: GetOrderByIdMemoryRoom_orders_mediaOrder_preview;
  /**
   * information for the editors
   */
  edit: GetOrderByIdMemoryRoom_orders_mediaOrder_edit;
}

export interface GetOrderByIdMemoryRoom_orders {
  /**
   * The id of the mediaorder
   */
  id: string | null;
  /**
   * The Id shown for customer
   */
  customerFriendlyId: string;
  /**
   * mediaOrder properties if that kind of order
   */
  mediaOrder: GetOrderByIdMemoryRoom_orders_mediaOrder | null;
}

export interface GetOrderByIdMemoryRoom {
  /**
   * Get orders
   */
  orders: GetOrderByIdMemoryRoom_orders[];
}

export interface GetOrderByIdMemoryRoomVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetOrderProof
// ====================================================

export interface GetOrderProof_orderCollection_material_proof {
  /**
   * Url to the material
   */
  url: string | null;
}

export interface GetOrderProof_orderCollection_material {
  /**
   * Proof
   */
  proof: GetOrderProof_orderCollection_material_proof;
}

export interface GetOrderProof_orderCollection {
  /**
   * Materials for the collection
   */
  material: GetOrderProof_orderCollection_material;
}

export interface GetOrderProof {
  /**
   * Get orderCollection
   */
  orderCollection: GetOrderProof_orderCollection;
}

export interface GetOrderProofVariables {
  orderIds: string[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetPackages
// ====================================================

export interface GetPackages_orderInitiatorPackageImages {
  id: string;
  name: string;
  imageUrl: string;
}

export interface GetPackages_orderInitiatorPackagesByCurrentUser_items_media {
  /**
   * The id of the media
   */
  id: string | null;
  /**
   * The friendlyName of the media
   */
  friendlyName: string;
}

export interface GetPackages_orderInitiatorPackagesByCurrentUser_items_documentFormat_documentType {
  /**
   * Id of the documenttype
   */
  id: string;
  /**
   * The name of the documentType
   */
  name: string;
}

export interface GetPackages_orderInitiatorPackagesByCurrentUser_items_documentFormat {
  /**
   * Id of the documentformat
   */
  id: string;
  documentType: GetPackages_orderInitiatorPackagesByCurrentUser_items_documentFormat_documentType;
}

export interface GetPackages_orderInitiatorPackagesByCurrentUser_items_documentFormatInitiator {
  /**
   * Id of the documenttypeinit
   */
  id: string | null;
  /**
   * The name of the documentTypeinit
   */
  name: string;
}

export interface GetPackages_orderInitiatorPackagesByCurrentUser_items_customerDocumentFormatInitiator {
  id: string;
  name: string;
}

export interface GetPackages_orderInitiatorPackagesByCurrentUser_items_inputId {
  /**
   * Id to custmoerDocumentFormatInitiatorId
   */
  customerDocumentFormatInitiatorId: string | null;
  /**
   * Id to documentFormatInitiatorId
   */
  documentFormatInitiatorId: string | null;
  /**
   * Id to media
   */
  mediaId: string | null;
}

export interface GetPackages_orderInitiatorPackagesByCurrentUser_items {
  /**
   * media of productpackageitem
   */
  media: GetPackages_orderInitiatorPackagesByCurrentUser_items_media;
  /**
   * documentFormat of productpackageitem
   */
  documentFormat: GetPackages_orderInitiatorPackagesByCurrentUser_items_documentFormat;
  /**
   * documentFormatInit of productpackageitem
   */
  documentFormatInitiator: GetPackages_orderInitiatorPackagesByCurrentUser_items_documentFormatInitiator | null;
  /**
   * Customer documentFormatInit of productpackageitem
   */
  customerDocumentFormatInitiator: GetPackages_orderInitiatorPackagesByCurrentUser_items_customerDocumentFormatInitiator | null;
  /**
   * Id object
   */
  inputId: GetPackages_orderInitiatorPackagesByCurrentUser_items_inputId;
}

export interface GetPackages_orderInitiatorPackagesByCurrentUser {
  /**
   * Id of the ProductPackage
   */
  id: string;
  /**
   * Name of the ProductPackage
   */
  name: string;
  /**
   * Which customer has access to the package
   */
  accessCustomerScope: CustomerScope | null;
  /**
   * Common price for the packages
   */
  commonPrice: any | null;
  imageId: string | null;
  /**
   * Presentation Image
   */
  imageUrl: string | null;
  /**
   * items of productpackages
   */
  items: GetPackages_orderInitiatorPackagesByCurrentUser_items[] | null;
}

export interface GetPackages {
  /**
   * Get order initiator images
   */
  orderInitiatorPackageImages: GetPackages_orderInitiatorPackageImages[];
  /**
   * Get order initiator packages for current user
   */
  orderInitiatorPackagesByCurrentUser: GetPackages_orderInitiatorPackagesByCurrentUser[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getProducts
// ====================================================

export interface getProducts_currentCustomer_office_company_customerContract_customerProducts_product {
  /**
   * Id of the product
   */
  id: string;
  /**
   * Name of the product
   */
  name: string;
}

export interface getProducts_currentCustomer_office_company_customerContract_customerProducts {
  /**
   * Product
   */
  product: getProducts_currentCustomer_office_company_customerContract_customerProducts_product;
}

export interface getProducts_currentCustomer_office_company_customerContract {
  /**
   * Products in the contract
   */
  customerProducts: getProducts_currentCustomer_office_company_customerContract_customerProducts[];
}

export interface getProducts_currentCustomer_office_company {
  customerContract: getProducts_currentCustomer_office_company_customerContract;
}

export interface getProducts_currentCustomer_office {
  company: getProducts_currentCustomer_office_company;
}

export interface getProducts_currentCustomer {
  office: getProducts_currentCustomer_office;
}

export interface getProducts {
  /**
   * Get current customer
   */
  currentCustomer: getProducts_currentCustomer | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetPublishOrders
// ====================================================

export interface GetPublishOrders_caseFolder_documents_caseFolder {
  /**
   * The id of the caseFolder
   */
  id: string | null;
}

export interface GetPublishOrders_caseFolder_documents_summary_price_endCustomer {
  /**
   * Whats pay for the order
   */
  pricePay: any | null;
}

export interface GetPublishOrders_caseFolder_documents_summary_price {
  /**
   * EndCustomer view of the price
   */
  endCustomer: GetPublishOrders_caseFolder_documents_summary_price_endCustomer;
}

export interface GetPublishOrders_caseFolder_documents_summary {
  /**
   * pricesummary of the order
   */
  price: GetPublishOrders_caseFolder_documents_summary_price;
}

export interface GetPublishOrders_caseFolder_documents_orders_caseFolder {
  /**
   * The id of the caseFolder
   */
  id: string | null;
}

export interface GetPublishOrders_caseFolder_documents_orders_mediaOrder_media {
  /**
   * The id of the media
   */
  id: string | null;
  /**
   * The friendlyName of the media
   */
  friendlyName: string;
}

export interface GetPublishOrders_caseFolder_documents_orders_mediaOrder_publishConversation {
  /**
   * Message to media
   */
  messageFromMedia: string | null;
  /**
   * Code to verify late publish
   */
  codeLate: string;
  /**
   * Code to media. Used when the ordering is late. Passed bookingstop. This must be verfied before it can be published
   */
  isCodeLate: boolean;
  /**
   * Message to media allowed
   */
  isMessageToMedia: boolean;
  /**
   * Media-System Ordernumber has to be set
   */
  isMediaSystemOrderNumber: boolean;
  /**
   * EndCustomer subribenumber on media can be set
   */
  isMediaCustomerSubriberNumber: boolean;
  /**
   * Status for publish
   */
  publishReadyStatus: MediaOrderPublishhReadyStatus;
  /**
   * Order is alread
   */
  isBookingSent: boolean;
  /**
   * Validation error texts
   */
  validateErrorTexts: string[];
}

export interface GetPublishOrders_caseFolder_documents_orders_mediaOrder_publishChannels {
  /**
   * Publish in familjesidan
   */
  inFamiljesidan: boolean;
  /**
   * Publish in minnesrummet
   */
  inMinnesrummet: boolean;
  /**
   * Publish in agency homepage
   */
  inAgency: boolean;
}

export interface GetPublishOrders_caseFolder_documents_orders_mediaOrder_preview_material_basePage_size {
  /**
   * Width
   */
  width: number;
  /**
   * Height
   */
  height: number;
}

export interface GetPublishOrders_caseFolder_documents_orders_mediaOrder_preview_material_basePage {
  size: GetPublishOrders_caseFolder_documents_orders_mediaOrder_preview_material_basePage_size | null;
}

export interface GetPublishOrders_caseFolder_documents_orders_mediaOrder_preview_material_pages_png {
  /**
   * Url till pngfile
   */
  url: string;
}

export interface GetPublishOrders_caseFolder_documents_orders_mediaOrder_preview_material_pages {
  /**
   * Png material
   */
  png: GetPublishOrders_caseFolder_documents_orders_mediaOrder_preview_material_pages_png;
}

export interface GetPublishOrders_caseFolder_documents_orders_mediaOrder_preview_material {
  /**
   * base page
   */
  basePage: GetPublishOrders_caseFolder_documents_orders_mediaOrder_preview_material_basePage;
  /**
   * pages
   */
  pages: (GetPublishOrders_caseFolder_documents_orders_mediaOrder_preview_material_pages | null)[];
}

export interface GetPublishOrders_caseFolder_documents_orders_mediaOrder_preview_price_endCustomer {
  /**
   * Whats pay for the order and rounded
   */
  pricePayRounded: any | null;
}

export interface GetPublishOrders_caseFolder_documents_orders_mediaOrder_preview_price {
  /**
   * Currency
   */
  currency: string;
  /**
   * EndCustomer view of the price
   */
  endCustomer: GetPublishOrders_caseFolder_documents_orders_mediaOrder_preview_price_endCustomer;
}

export interface GetPublishOrders_caseFolder_documents_orders_mediaOrder_preview {
  /**
   * material
   */
  material: GetPublishOrders_caseFolder_documents_orders_mediaOrder_preview_material;
  /**
   * price of the order
   */
  price: GetPublishOrders_caseFolder_documents_orders_mediaOrder_preview_price | null;
}

export interface GetPublishOrders_caseFolder_documents_orders_mediaOrder_documentFormat {
  /**
   * Id of the documentformat
   */
  id: string;
  /**
   * Size in Columns
   */
  sizeColumns: number | null;
}

export interface GetPublishOrders_caseFolder_documents_orders_mediaOrder_publishTimes {
  /**
   * Date when the order will be published
   */
  time: any;
}

export interface GetPublishOrders_caseFolder_documents_orders_mediaOrder_status {
  /**
   * Color for vieweing the customer
   */
  color: StatusColors;
  /**
   * ProductStatus enum for the status
   */
  status: OrderStatus;
}

export interface GetPublishOrders_caseFolder_documents_orders_mediaOrder {
  /**
   * Locked
   */
  isLocked: boolean;
  /**
   * media of the order
   */
  media: GetPublishOrders_caseFolder_documents_orders_mediaOrder_media;
  /**
   * Publish conversation settings for the media
   */
  publishConversation: GetPublishOrders_caseFolder_documents_orders_mediaOrder_publishConversation;
  /**
   * Parallell publish channels
   */
  publishChannels: GetPublishOrders_caseFolder_documents_orders_mediaOrder_publishChannels;
  /**
   * Preview of the order
   */
  preview: GetPublishOrders_caseFolder_documents_orders_mediaOrder_preview;
  /**
   * documentformat of the order
   */
  documentFormat: GetPublishOrders_caseFolder_documents_orders_mediaOrder_documentFormat;
  /**
   * times when the order will be published
   */
  publishTimes: (GetPublishOrders_caseFolder_documents_orders_mediaOrder_publishTimes | null)[];
  /**
   * Status of the order
   */
  status: GetPublishOrders_caseFolder_documents_orders_mediaOrder_status;
}

export interface GetPublishOrders_caseFolder_documents_orders {
  /**
   * The id of the mediaorder
   */
  id: string | null;
  /**
   * The Id shown for customer
   */
  customerFriendlyId: string;
  /**
   * caseFolder of the order
   */
  caseFolder: GetPublishOrders_caseFolder_documents_orders_caseFolder;
  /**
   * mediaOrder properties if that kind of order
   */
  mediaOrder: GetPublishOrders_caseFolder_documents_orders_mediaOrder | null;
}

export interface GetPublishOrders_caseFolder_documents_documentFormat_documentType {
  /**
   * Id of the documenttype
   */
  id: string;
  /**
   * The name of the documentType
   */
  name: string;
}

export interface GetPublishOrders_caseFolder_documents_documentFormat {
  /**
   * Id of the documentformat
   */
  id: string;
  documentType: GetPublishOrders_caseFolder_documents_documentFormat_documentType;
}

export interface GetPublishOrders_caseFolder_documents {
  /**
   * caseFolder of the document
   */
  caseFolder: GetPublishOrders_caseFolder_documents_caseFolder;
  /**
   * Id of the document
   */
  id: string | null;
  /**
   * ordersummary of document
   */
  summary: GetPublishOrders_caseFolder_documents_summary;
  orders: GetPublishOrders_caseFolder_documents_orders[];
  /**
   * format of the document
   */
  documentFormat: GetPublishOrders_caseFolder_documents_documentFormat;
}

export interface GetPublishOrders_caseFolder {
  /**
   * The id of the caseFolder
   */
  id: string | null;
  documents: (GetPublishOrders_caseFolder_documents | null)[];
}

export interface GetPublishOrders {
  /**
   * Get caseFolder
   */
  caseFolder: GetPublishOrders_caseFolder;
}

export interface GetPublishOrdersVariables {
  caseFolderId: string;
  documentId?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetReviewCaseFolder
// ====================================================

export interface GetReviewCaseFolder_caseFolder_documents_orders_caseFolder {
  /**
   * The id of the caseFolder
   */
  id: string | null;
}

export interface GetReviewCaseFolder_caseFolder_documents_orders_alerting {
  smallText: string | null;
  text: string | null;
  type: OrderAlertingType;
}

export interface GetReviewCaseFolder_caseFolder_documents_orders_mediaOrder_media {
  /**
   * The id of the media
   */
  id: string | null;
  /**
   * The friendlyName of the media
   */
  friendlyName: string;
}

export interface GetReviewCaseFolder_caseFolder_documents_orders_mediaOrder_publishConversation {
  /**
   * Message to media
   */
  messageFromMedia: string | null;
  /**
   * Code to verify late publish
   */
  codeLate: string;
  /**
   * Code to media. Used when the ordering is late. Passed bookingstop. This must be verfied before it can be published
   */
  isCodeLate: boolean;
  /**
   * Message to media allowed
   */
  isMessageToMedia: boolean;
  /**
   * Media-System Ordernumber has to be set
   */
  isMediaSystemOrderNumber: boolean;
  /**
   * EndCustomer subribenumber on media can be set
   */
  isMediaCustomerSubriberNumber: boolean;
  /**
   * Status for publish
   */
  publishReadyStatus: MediaOrderPublishhReadyStatus;
}

export interface GetReviewCaseFolder_caseFolder_documents_orders_mediaOrder_publishChannels {
  /**
   * Publish in familjesidan
   */
  inFamiljesidan: boolean;
  /**
   * Publish in minnesrummet
   */
  inMinnesrummet: boolean;
  /**
   * Publish in agency homepage
   */
  inAgency: boolean;
}

export interface GetReviewCaseFolder_caseFolder_documents_orders_mediaOrder_preview_material_basePage_size {
  /**
   * Width
   */
  width: number;
  /**
   * Height
   */
  height: number;
}

export interface GetReviewCaseFolder_caseFolder_documents_orders_mediaOrder_preview_material_basePage {
  size: GetReviewCaseFolder_caseFolder_documents_orders_mediaOrder_preview_material_basePage_size | null;
}

export interface GetReviewCaseFolder_caseFolder_documents_orders_mediaOrder_preview_material_pages_png {
  /**
   * Url till pngfile
   */
  url: string;
}

export interface GetReviewCaseFolder_caseFolder_documents_orders_mediaOrder_preview_material_pages {
  /**
   * Png material
   */
  png: GetReviewCaseFolder_caseFolder_documents_orders_mediaOrder_preview_material_pages_png;
}

export interface GetReviewCaseFolder_caseFolder_documents_orders_mediaOrder_preview_material {
  /**
   * base page
   */
  basePage: GetReviewCaseFolder_caseFolder_documents_orders_mediaOrder_preview_material_basePage;
  /**
   * pages
   */
  pages: (GetReviewCaseFolder_caseFolder_documents_orders_mediaOrder_preview_material_pages | null)[];
}

export interface GetReviewCaseFolder_caseFolder_documents_orders_mediaOrder_preview_price_endCustomer {
  /**
   * Whats pay for the order and rounded
   */
  pricePayRounded: any | null;
}

export interface GetReviewCaseFolder_caseFolder_documents_orders_mediaOrder_preview_price {
  /**
   * Currency
   */
  currency: string;
  /**
   * EndCustomer view of the price
   */
  endCustomer: GetReviewCaseFolder_caseFolder_documents_orders_mediaOrder_preview_price_endCustomer;
}

export interface GetReviewCaseFolder_caseFolder_documents_orders_mediaOrder_preview {
  /**
   * material
   */
  material: GetReviewCaseFolder_caseFolder_documents_orders_mediaOrder_preview_material;
  /**
   * price of the order
   */
  price: GetReviewCaseFolder_caseFolder_documents_orders_mediaOrder_preview_price | null;
}

export interface GetReviewCaseFolder_caseFolder_documents_orders_mediaOrder_documentFormat {
  /**
   * Id of the documentformat
   */
  id: string;
  /**
   * Size in Columns
   */
  sizeColumns: number | null;
}

export interface GetReviewCaseFolder_caseFolder_documents_orders_mediaOrder_publishTimes {
  /**
   * Date when the order will be published
   */
  time: any;
}

export interface GetReviewCaseFolder_caseFolder_documents_orders_mediaOrder_status {
  /**
   * Color for vieweing the customer
   */
  color: StatusColors;
  /**
   * ProductStatus enum for the status
   */
  status: OrderStatus;
}

export interface GetReviewCaseFolder_caseFolder_documents_orders_mediaOrder {
  /**
   * Locked
   */
  isLocked: boolean;
  /**
   * media of the order
   */
  media: GetReviewCaseFolder_caseFolder_documents_orders_mediaOrder_media;
  /**
   * Publish conversation settings for the media
   */
  publishConversation: GetReviewCaseFolder_caseFolder_documents_orders_mediaOrder_publishConversation;
  /**
   * Parallell publish channels
   */
  publishChannels: GetReviewCaseFolder_caseFolder_documents_orders_mediaOrder_publishChannels;
  /**
   * Preview of the order
   */
  preview: GetReviewCaseFolder_caseFolder_documents_orders_mediaOrder_preview;
  /**
   * documentformat of the order
   */
  documentFormat: GetReviewCaseFolder_caseFolder_documents_orders_mediaOrder_documentFormat;
  /**
   * times when the order will be published
   */
  publishTimes: (GetReviewCaseFolder_caseFolder_documents_orders_mediaOrder_publishTimes | null)[];
  /**
   * Status of the order
   */
  status: GetReviewCaseFolder_caseFolder_documents_orders_mediaOrder_status;
}

export interface GetReviewCaseFolder_caseFolder_documents_orders {
  /**
   * The id of the mediaorder
   */
  id: string | null;
  /**
   * The Id shown for customer
   */
  customerFriendlyId: string;
  /**
   * caseFolder of the order
   */
  caseFolder: GetReviewCaseFolder_caseFolder_documents_orders_caseFolder;
  alerting: GetReviewCaseFolder_caseFolder_documents_orders_alerting;
  /**
   * mediaOrder properties if that kind of order
   */
  mediaOrder: GetReviewCaseFolder_caseFolder_documents_orders_mediaOrder | null;
}

export interface GetReviewCaseFolder_caseFolder_documents_documentFormat_documentType {
  /**
   * Id of the documenttype
   */
  id: string;
  /**
   * The name of the documentType
   */
  name: string;
}

export interface GetReviewCaseFolder_caseFolder_documents_documentFormat {
  /**
   * Id of the documentformat
   */
  id: string;
  documentType: GetReviewCaseFolder_caseFolder_documents_documentFormat_documentType;
}

export interface GetReviewCaseFolder_caseFolder_documents_summary_price_endCustomer {
  /**
   * Whats pay for the order and rounded
   */
  pricePayRounded: any | null;
}

export interface GetReviewCaseFolder_caseFolder_documents_summary_price {
  /**
   * EndCustomer view of the price
   */
  endCustomer: GetReviewCaseFolder_caseFolder_documents_summary_price_endCustomer;
}

export interface GetReviewCaseFolder_caseFolder_documents_summary {
  /**
   * pricesummary of the order
   */
  price: GetReviewCaseFolder_caseFolder_documents_summary_price;
}

export interface GetReviewCaseFolder_caseFolder_documents {
  /**
   * Id of the document
   */
  id: string | null;
  orders: GetReviewCaseFolder_caseFolder_documents_orders[];
  /**
   * format of the document
   */
  documentFormat: GetReviewCaseFolder_caseFolder_documents_documentFormat;
  /**
   * ordersummary of document
   */
  summary: GetReviewCaseFolder_caseFolder_documents_summary;
}

export interface GetReviewCaseFolder_caseFolder {
  /**
   * The id of the caseFolder
   */
  id: string | null;
  documents: (GetReviewCaseFolder_caseFolder_documents | null)[];
}

export interface GetReviewCaseFolder {
  /**
   * Get caseFolder
   */
  caseFolder: GetReviewCaseFolder_caseFolder;
}

export interface GetReviewCaseFolderVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetReviewCaseFolderOnlyOrder
// ====================================================

export interface GetReviewCaseFolderOnlyOrder_orders_caseFolder {
  /**
   * The id of the caseFolder
   */
  id: string | null;
}

export interface GetReviewCaseFolderOnlyOrder_orders_mediaOrder_media {
  /**
   * The id of the media
   */
  id: string | null;
  /**
   * The friendlyName of the media
   */
  friendlyName: string;
}

export interface GetReviewCaseFolderOnlyOrder_orders_mediaOrder_publishConversation {
  /**
   * Message to media
   */
  messageFromMedia: string | null;
  /**
   * Code to verify late publish
   */
  codeLate: string;
  /**
   * Code to media. Used when the ordering is late. Passed bookingstop. This must be verfied before it can be published
   */
  isCodeLate: boolean;
  /**
   * Message to media allowed
   */
  isMessageToMedia: boolean;
  /**
   * Media-System Ordernumber has to be set
   */
  isMediaSystemOrderNumber: boolean;
  /**
   * EndCustomer subribenumber on media can be set
   */
  isMediaCustomerSubriberNumber: boolean;
  /**
   * Status for publish
   */
  publishReadyStatus: MediaOrderPublishhReadyStatus;
}

export interface GetReviewCaseFolderOnlyOrder_orders_mediaOrder_publishChannels {
  /**
   * Publish in familjesidan
   */
  inFamiljesidan: boolean;
  /**
   * Publish in minnesrummet
   */
  inMinnesrummet: boolean;
  /**
   * Publish in agency homepage
   */
  inAgency: boolean;
}

export interface GetReviewCaseFolderOnlyOrder_orders_mediaOrder_preview_material_basePage_size {
  /**
   * Width
   */
  width: number;
  /**
   * Height
   */
  height: number;
}

export interface GetReviewCaseFolderOnlyOrder_orders_mediaOrder_preview_material_basePage {
  size: GetReviewCaseFolderOnlyOrder_orders_mediaOrder_preview_material_basePage_size | null;
}

export interface GetReviewCaseFolderOnlyOrder_orders_mediaOrder_preview_material_pages_png {
  /**
   * Url till pngfile
   */
  url: string;
}

export interface GetReviewCaseFolderOnlyOrder_orders_mediaOrder_preview_material_pages {
  /**
   * Png material
   */
  png: GetReviewCaseFolderOnlyOrder_orders_mediaOrder_preview_material_pages_png;
}

export interface GetReviewCaseFolderOnlyOrder_orders_mediaOrder_preview_material {
  /**
   * base page
   */
  basePage: GetReviewCaseFolderOnlyOrder_orders_mediaOrder_preview_material_basePage;
  /**
   * pages
   */
  pages: (GetReviewCaseFolderOnlyOrder_orders_mediaOrder_preview_material_pages | null)[];
}

export interface GetReviewCaseFolderOnlyOrder_orders_mediaOrder_preview_price_endCustomer {
  /**
   * Whats pay for the order and rounded
   */
  pricePayRounded: any | null;
}

export interface GetReviewCaseFolderOnlyOrder_orders_mediaOrder_preview_price {
  /**
   * Currency
   */
  currency: string;
  /**
   * EndCustomer view of the price
   */
  endCustomer: GetReviewCaseFolderOnlyOrder_orders_mediaOrder_preview_price_endCustomer;
}

export interface GetReviewCaseFolderOnlyOrder_orders_mediaOrder_preview {
  /**
   * material
   */
  material: GetReviewCaseFolderOnlyOrder_orders_mediaOrder_preview_material;
  /**
   * price of the order
   */
  price: GetReviewCaseFolderOnlyOrder_orders_mediaOrder_preview_price | null;
}

export interface GetReviewCaseFolderOnlyOrder_orders_mediaOrder_documentFormat {
  /**
   * Id of the documentformat
   */
  id: string;
  /**
   * Size in Columns
   */
  sizeColumns: number | null;
}

export interface GetReviewCaseFolderOnlyOrder_orders_mediaOrder_publishTimes {
  /**
   * Date when the order will be published
   */
  time: any;
}

export interface GetReviewCaseFolderOnlyOrder_orders_mediaOrder_status {
  /**
   * Color for vieweing the customer
   */
  color: StatusColors;
  /**
   * ProductStatus enum for the status
   */
  status: OrderStatus;
}

export interface GetReviewCaseFolderOnlyOrder_orders_mediaOrder {
  /**
   * Locked
   */
  isLocked: boolean;
  /**
   * media of the order
   */
  media: GetReviewCaseFolderOnlyOrder_orders_mediaOrder_media;
  /**
   * Publish conversation settings for the media
   */
  publishConversation: GetReviewCaseFolderOnlyOrder_orders_mediaOrder_publishConversation;
  /**
   * Parallell publish channels
   */
  publishChannels: GetReviewCaseFolderOnlyOrder_orders_mediaOrder_publishChannels;
  /**
   * Preview of the order
   */
  preview: GetReviewCaseFolderOnlyOrder_orders_mediaOrder_preview;
  /**
   * documentformat of the order
   */
  documentFormat: GetReviewCaseFolderOnlyOrder_orders_mediaOrder_documentFormat;
  /**
   * times when the order will be published
   */
  publishTimes: (GetReviewCaseFolderOnlyOrder_orders_mediaOrder_publishTimes | null)[];
  /**
   * Status of the order
   */
  status: GetReviewCaseFolderOnlyOrder_orders_mediaOrder_status;
}

export interface GetReviewCaseFolderOnlyOrder_orders {
  /**
   * The id of the mediaorder
   */
  id: string | null;
  /**
   * The Id shown for customer
   */
  customerFriendlyId: string;
  /**
   * caseFolder of the order
   */
  caseFolder: GetReviewCaseFolderOnlyOrder_orders_caseFolder;
  /**
   * mediaOrder properties if that kind of order
   */
  mediaOrder: GetReviewCaseFolderOnlyOrder_orders_mediaOrder | null;
}

export interface GetReviewCaseFolderOnlyOrder {
  /**
   * Get orders
   */
  orders: GetReviewCaseFolderOnlyOrder_orders[];
}

export interface GetReviewCaseFolderOnlyOrderVariables {
  ids: string[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetStatOrders
// ====================================================

export interface GetStatOrders_statsOrders_summery_price_endCustomer {
  /**
   * Whats pay for the order
   */
  pricePay: any | null;
}

export interface GetStatOrders_statsOrders_summery_price_customer {
  /**
   * How much have earned for the order
   */
  priceEarnings: any | null;
}

export interface GetStatOrders_statsOrders_summery_price {
  /**
   * EndCustomer view of the price
   */
  endCustomer: GetStatOrders_statsOrders_summery_price_endCustomer;
  /**
   * Customer view of the price
   */
  customer: GetStatOrders_statsOrders_summery_price_customer;
}

export interface GetStatOrders_statsOrders_summery {
  /**
   * Number of orders
   */
  countOrders: number;
  /**
   * Number of casefolders
   */
  countCaseFolders: number;
  /**
   * Numer of orders per casefolder in avarage
   */
  avarageOrdersPerCaseFolder: number;
  /**
   * price of the order
   */
  price: GetStatOrders_statsOrders_summery_price;
}

export interface GetStatOrders_statsOrders_caseFolders_orders_caseFolder {
  /**
   * The name of the caseFolder
   */
  name: string;
}

export interface GetStatOrders_statsOrders_caseFolders_orders_customer_office {
  /**
   * The name of office
   */
  name: string;
}

export interface GetStatOrders_statsOrders_caseFolders_orders_customer {
  /**
   * The name of customer
   */
  name: string;
  office: GetStatOrders_statsOrders_caseFolders_orders_customer_office;
}

export interface GetStatOrders_statsOrders_caseFolders_orders_mediaOrder_publishTimes {
  /**
   * Date when the order will be published
   */
  time: any;
  __typename: "MediaOrderPublishDay";
}

export interface GetStatOrders_statsOrders_caseFolders_orders_mediaOrder_media {
  /**
   * The name of the media
   */
  name: string;
  /**
   * Order is for a print-media
   */
  isPrintMedia: boolean;
  __typename: "Media";
}

export interface GetStatOrders_statsOrders_caseFolders_orders_mediaOrder_documentFormat {
  mediaAdaptedName: string;
}

export interface GetStatOrders_statsOrders_caseFolders_orders_mediaOrder {
  /**
   * times when the order will be published
   */
  publishTimes: (GetStatOrders_statsOrders_caseFolders_orders_mediaOrder_publishTimes | null)[];
  /**
   * media of the order
   */
  media: GetStatOrders_statsOrders_caseFolders_orders_mediaOrder_media;
  /**
   * documentformat of the order
   */
  documentFormat: GetStatOrders_statsOrders_caseFolders_orders_mediaOrder_documentFormat;
}

export interface GetStatOrders_statsOrders_caseFolders_orders_product {
  /**
   * Name of the product
   */
  name: string;
}

export interface GetStatOrders_statsOrders_caseFolders_orders_lockedPrice_endCustomer {
  /**
   * Whats pay for the order
   */
  pricePay: any | null;
}

export interface GetStatOrders_statsOrders_caseFolders_orders_lockedPrice_customer {
  /**
   * How much have earned for the order
   */
  priceEarnings: any | null;
}

export interface GetStatOrders_statsOrders_caseFolders_orders_lockedPrice {
  /**
   * EndCustomer view of the price
   */
  endCustomer: GetStatOrders_statsOrders_caseFolders_orders_lockedPrice_endCustomer;
  /**
   * Customer view of the price
   */
  customer: GetStatOrders_statsOrders_caseFolders_orders_lockedPrice_customer;
}

export interface GetStatOrders_statsOrders_caseFolders_orders {
  /**
   * The Id shown for customer
   */
  customerFriendlyId: string;
  orderDate: any | null;
  /**
   * caseFolder of the order
   */
  caseFolder: GetStatOrders_statsOrders_caseFolders_orders_caseFolder;
  /**
   * customer of the order
   */
  customer: GetStatOrders_statsOrders_caseFolders_orders_customer;
  /**
   * mediaOrder properties if that kind of order
   */
  mediaOrder: GetStatOrders_statsOrders_caseFolders_orders_mediaOrder | null;
  /**
   * Product for the order
   */
  product: GetStatOrders_statsOrders_caseFolders_orders_product;
  /**
   * price of the order
   */
  lockedPrice: GetStatOrders_statsOrders_caseFolders_orders_lockedPrice;
}

export interface GetStatOrders_statsOrders_caseFolders {
  /**
   * The name of the caseFolder
   */
  name: string;
  orders: GetStatOrders_statsOrders_caseFolders_orders[];
}

export interface GetStatOrders_statsOrders {
  /**
   * The total number of documentFolders in the search
   */
  count: number;
  /**
   * Summery
   */
  summery: GetStatOrders_statsOrders_summery;
  /**
   * CaseFolders
   */
  caseFolders: GetStatOrders_statsOrders_caseFolders[];
}

export interface GetStatOrders {
  /**
   * Get static of orders
   */
  statsOrders: GetStatOrders_statsOrders;
}

export interface GetStatOrdersVariables {
  input: StatsOrderInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetSuggestionDocumentFormats
// ====================================================

export interface GetSuggestionDocumentFormats_orders_mediaOrder_suggestionDocumentFormats {
  /**
   * Id of the documentformat
   */
  id: string;
  name: string;
  previewUrl: string;
  thumbnailWidth: number | null;
  thumbnailHeight: number | null;
}

export interface GetSuggestionDocumentFormats_orders_mediaOrder {
  /**
   * documentformat of the order
   */
  suggestionDocumentFormats: GetSuggestionDocumentFormats_orders_mediaOrder_suggestionDocumentFormats[];
}

export interface GetSuggestionDocumentFormats_orders {
  /**
   * The id of the mediaorder
   */
  id: string | null;
  /**
   * mediaOrder properties if that kind of order
   */
  mediaOrder: GetSuggestionDocumentFormats_orders_mediaOrder | null;
}

export interface GetSuggestionDocumentFormats {
  /**
   * Get orders
   */
  orders: GetSuggestionDocumentFormats_orders[];
}

export interface GetSuggestionDocumentFormatsVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getSystemInfo
// ====================================================

export interface getSystemInfo_systemInfo_build {
  branch: string;
  tag: string;
  time: string;
}

export interface getSystemInfo_systemInfo_configuration {
  websocketEnabled: boolean;
}

export interface getSystemInfo_systemInfo_environment {
  name: string;
}

export interface getSystemInfo_systemInfo {
  build: getSystemInfo_systemInfo_build;
  configuration: getSystemInfo_systemInfo_configuration;
  environment: getSystemInfo_systemInfo_environment;
}

export interface getSystemInfo {
  systemInfo: getSystemInfo_systemInfo;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: UserLogs
// ====================================================

export interface UserLogs_userLogs {
  time: string;
  logType: LogUserEvents;
  summeryText: string | null;
  userName: string | null;
  customerName: string | null;
  period: number;
  detailJson: string | null;
}

export interface UserLogs {
  /**
   * Get userlogs
   */
  userLogs: UserLogs_userLogs[];
}

export interface UserLogsVariables {
  orderId?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL subscription operation: OrderSubscription
// ====================================================

export interface OrderSubscription_order_document {
  /**
   * Id of the document
   */
  id: string | null;
}

export interface OrderSubscription_order_client {
  mainSystem: SystemFlags;
  isTaps5: boolean;
}

export interface OrderSubscription_order_alerting {
  smallText: string | null;
  text: string | null;
  type: OrderAlertingType;
}

export interface OrderSubscription_order_mediaOrder_publishTimes {
  /**
   * Date when the order will be published
   */
  time: any;
}

export interface OrderSubscription_order_mediaOrder_documentFormat_documentFormatCollection {
  id: string;
  /**
   * The name of the collection
   */
  name: string;
}

export interface OrderSubscription_order_mediaOrder_documentFormat_documentType {
  /**
   * Id of the documenttype
   */
  id: string;
  /**
   * The name of the documentType
   */
  name: string;
}

export interface OrderSubscription_order_mediaOrder_documentFormat {
  /**
   * Id of the documentformat
   */
  id: string;
  documentFormatCollection: OrderSubscription_order_mediaOrder_documentFormat_documentFormatCollection;
  mediaAdaptedName: string;
  documentType: OrderSubscription_order_mediaOrder_documentFormat_documentType;
}

export interface OrderSubscription_order_mediaOrder_publishConversation {
  /**
   * Validation error texts
   */
  validateErrorTexts: string[];
  /**
   * Message to media
   */
  messageFromMedia: string | null;
  /**
   * Code to verify late publish
   */
  codeLate: string;
  /**
   * Code to media. Used when the ordering is late. Passed bookingstop. This must be verfied before it can be published
   */
  isCodeLate: boolean;
  /**
   * Message to media allowed
   */
  isMessageToMedia: boolean;
  /**
   * Media-System Ordernumber has to be set
   */
  isMediaSystemOrderNumber: boolean;
  /**
   * EndCustomer subribenumber on media can be set
   */
  isMediaCustomerSubriberNumber: boolean;
  /**
   * Status for publish
   */
  publishReadyStatus: MediaOrderPublishhReadyStatus;
}

export interface OrderSubscription_order_mediaOrder_publishChannels {
  /**
   * Publish in familjesidan
   */
  inFamiljesidan: boolean;
  /**
   * Publish in minnesrummet
   */
  inMinnesrummet: boolean;
  /**
   * Publish in agency homepage
   */
  inAgency: boolean;
}

export interface OrderSubscription_order_mediaOrder_edit_userInterface {
  editStatusColor: EditUIEditStatusColor;
  editStatusText: string | null;
  publishButtonText: string | null;
  editStatusIcon: EditUIEditStatusIcon;
  isUnPublishButton: boolean;
  unPublishButtonText: string | null;
  isPublishButton: boolean;
  isLockButton: boolean;
  lockButtonText: string | null;
  isUnlockButton: boolean;
  unlockButtonText: string | null;
  isReactivateButton: boolean;
  reactivateButtonText: string | null;
  isSetExhibitOrder: boolean;
  setExhibitOrderText: string | null;
  isUpdateCustomerDocumentContentInitiator: boolean;
  updateCustomerDocumentContentInitiatorText: string | null;
  /**
   * Size limit
   */
  orderEntityNameSingular: string | null;
  /**
   * Size limit
   */
  orderEntityNamePlural: string | null;
  /**
   * Size limit
   */
  orderEntityNameSingularDetermined: string | null;
  /**
   * Size limit
   */
  orderEntityNamePluralDetermined: string | null;
  isCopyFuneralInfoToMemoryRoom: boolean;
  copyFuneralInfoToMemoryRoomText: string | null;
  isNetnoticeEnabled: boolean;
  isNetnoticeSupported: boolean;
}

export interface OrderSubscription_order_mediaOrder_edit_resources {
  /**
   * Get style css for the specialCharacters
   */
  styleCssSpecialCharacters: string;
}

export interface OrderSubscription_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_validateErrors {
  error: EditModelAdvertAreaContentValidateErrors;
  validationText: string | null;
}

export interface OrderSubscription_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_common_marginLeftRightOffset {
  /**
   * Type
   */
  type: RelativeTypes;
  /**
   * Value
   */
  value: number;
}

export interface OrderSubscription_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_common_margin {
  /**
   * Type of unit
   */
  type: UnitTypes;
  /**
   * Left
   */
  left: number | null;
  /**
   * Right
   */
  right: number | null;
  /**
   * Top
   */
  top: number | null;
  /**
   * Bottom
   */
  bottom: number | null;
}

export interface OrderSubscription_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_common_size {
  /**
   * Type
   */
  type: UnitTypes;
  /**
   * Min Width
   */
  minWidth: number | null;
  /**
   * Min Height
   */
  minHeight: number | null;
  /**
   * Max Width
   */
  maxWidth: number | null;
  /**
   * Max Height
   */
  maxHeight: number | null;
  /**
   * Fix Width
   */
  fixedWidth: number | null;
  /**
   * Fix Height
   */
  fixedHeight: number | null;
}

export interface OrderSubscription_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_common {
  /**
   * Alternative BlockStyle
   */
  blockStyleAreaNameId: string | null;
  marginLeftRightOffset: OrderSubscription_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_common_marginLeftRightOffset | null;
  margin: OrderSubscription_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_common_margin | null;
  size: OrderSubscription_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_common_size | null;
}

export interface OrderSubscription_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_image {
  /**
   * Repository of image
   */
  type: EditModelAdvertAreaContentRepository | null;
  /**
   * id for the image
   */
  imageId: string | null;
  /**
   * public id for the image
   */
  imagePublicId: string | null;
  /**
   * public id for the image
   */
  imageThumbnaill100x80Url: string | null;
  /**
   * Height for the image in mm
   */
  heightMM: number | null;
  /**
   * DefaultHeight for the image in mm
   */
  defaultHeightMM: number | null;
  /**
   * IsSizeFree
   */
  isSizeFree: boolean | null;
  /**
   * Upload image content
   */
  uploadData: string | null;
  /**
   * Upload filename
   */
  uploadFilename: string | null;
  /**
   * Bitmap resolution in dpi
   */
  resolution: number | null;
  /**
   * Upload prefered HeightMM of uploaded image
   */
  uploadPreferedHeightMM: number | null;
  /**
   * Repository of image
   */
  repository: EditModelAdvertAreaContentRepository | null;
  /**
   * Upload source image content
   */
  uploadDataSource: string | null;
  /**
   * Comment about the upload
   */
  uploadComment: string | null;
}

export interface OrderSubscription_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_text_textScaleWidthOffset {
  /**
   * Type
   */
  type: RelativeTypes;
  /**
   * Value
   */
  value: number;
}

export interface OrderSubscription_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_text_fontSize {
  /**
   * Type of unit
   */
  type: UnitTypes;
  /**
   * Value
   */
  value: number;
}

export interface OrderSubscription_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_text_lineHeight {
  /**
   * Type of unit
   */
  type: UnitTypes;
  /**
   * Value
   */
  value: number;
}

export interface OrderSubscription_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_text_lineHeightEmpty {
  /**
   * Type of unit
   */
  type: UnitTypes;
  /**
   * Value
   */
  value: number;
}

export interface OrderSubscription_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_text_letterSpacing {
  /**
   * Type of unit
   */
  type: UnitTypes;
  /**
   * Value
   */
  value: number;
}

export interface OrderSubscription_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_text_textScaleWidth {
  /**
   * Type
   */
  type: RelativeTypes;
  /**
   * Value
   */
  value: number;
}

export interface OrderSubscription_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_text {
  /**
   * Font style
   */
  fontStyle: FontStyles | null;
  /**
   * Textscale width offset
   */
  textScaleWidthOffset: OrderSubscription_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_text_textScaleWidthOffset | null;
  /**
   * Font weight
   */
  fontWeight: FontWeights | null;
  /**
   * Text alignment
   */
  textAlignment: TextAlignments | null;
  /**
   * Text formats in xml
   */
  xmlText: string | null;
  /**
   * Is htmleditor readonly
   */
  isXmlTextReadonly: boolean | null;
  /**
   * Fontfamily of the text
   */
  fontFamily: string | null;
  /**
   * Font
   */
  fontSize: OrderSubscription_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_text_fontSize | null;
  /**
   * Line-height
   */
  lineHeight: OrderSubscription_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_text_lineHeight | null;
  /**
   * Line-height for empty rows
   */
  lineHeightEmpty: OrderSubscription_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_text_lineHeightEmpty | null;
  /**
   * Letter-Spacing
   */
  letterSpacing: OrderSubscription_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_text_letterSpacing | null;
  /**
   * Textscale width
   */
  textScaleWidth: OrderSubscription_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_text_textScaleWidth | null;
  /**
   * Text do not wrap when text reach its width
   */
  textNoWrap: boolean | null;
}

export interface OrderSubscription_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_separator {
  /**
   * Type of image
   */
  type: EditModelAdvertAreaContentItemSeparatorTypes;
  imageId: string | null;
  /**
   * Repository of image
   */
  repository: EditModelAdvertAreaContentRepository | null;
  /**
   * Width for the image in mm
   */
  widthMM: number | null;
  /**
   * Height for the image in mm
   */
  heightMM: number | null;
}

export interface OrderSubscription_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_fields {
  /**
   * Name
   */
  name: string;
  /**
   * Header
   */
  header: string | null;
  /**
   * Type
   */
  type: EditModelAdvertAreaContentItemFieldItemTypes | null;
  /**
   * Editor
   */
  editor: EditModelAdvertAreaContentItemFieldItemEditors | null;
  /**
   * Value
   */
  value: string | null;
  __typename: "EditModelAdvertAreaContentItemFieldItem";
}

export interface OrderSubscription_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item {
  /**
   * Common
   */
  common: OrderSubscription_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_common | null;
  /**
   * Value is only for demo purpose
   */
  isDemoValue: boolean | null;
  /**
   * ImageContent
   */
  image: OrderSubscription_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_image | null;
  /**
   * TextContent
   */
  text: OrderSubscription_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_text | null;
  /**
   * HorizontalLineContent
   */
  separator: OrderSubscription_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_separator | null;
  /**
   * Fields
   */
  fields: (OrderSubscription_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_fields | null)[] | null;
}

export interface OrderSubscription_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns {
  item: OrderSubscription_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item;
}

export interface OrderSubscription_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows {
  columns: OrderSubscription_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns[];
}

export interface OrderSubscription_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_value {
  /**
   * Id for the content-value
   */
  id: string | null;
  /**
   * Is content visible
   */
  visible: boolean;
  /**
   * Is it content
   */
  hasContent: boolean;
  /**
   * Is editor visible
   */
  isEditorVisible: boolean;
  /**
   * Id to the template
   */
  templateAreaId: string | null;
  /**
   * Rows
   */
  rows: OrderSubscription_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows[];
}

export interface OrderSubscription_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_access {
  add: boolean;
  modify: boolean;
  statusAdd: EditModelAdvertAreaContentEditorAccessStatuses;
}

export interface OrderSubscription_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_common_allowedMarginLeftRightOffsetInterval {
  /**
   * Type of unit
   */
  type: RelativeTypes;
  /**
   * Max-Value
   */
  maxValue: number;
  /**
   * Min-Value
   */
  minValue: number;
  /**
   * Interval-Value
   */
  intervalValue: number;
}

export interface OrderSubscription_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_common_blockStyles {
  /**
   * Id
   */
  id: string;
  /**
   * Name
   */
  name: string;
}

export interface OrderSubscription_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_common {
  /**
   * Margin can adjust margins
   */
  isEnabledAdjustMargins: boolean;
  /**
   * If UpdateOrdersContent is avaiable
   */
  isEnabledUpdateOrdersContent: boolean;
  /**
   * If it is possible to add columns
   */
  isEnabledMultiColumns: boolean;
  /**
   * Enable margin setting
   */
  isMarginLeftRightOffset: boolean;
  /**
   * Allowed margin settings
   */
  allowedMarginLeftRightOffsetInterval: OrderSubscription_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_common_allowedMarginLeftRightOffsetInterval | null;
  /**
   * Block styles
   */
  blockStyles: OrderSubscription_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_common_blockStyles[];
}

export interface OrderSubscription_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_text_allowedFontSizes {
  /**
   * Values
   */
  values: number[];
}

export interface OrderSubscription_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_text_allowedLineHeights {
  /**
   * Values
   */
  values: number[];
}

export interface OrderSubscription_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_text_allowedFontSizeInterval {
  /**
   * Type of unit
   */
  type: UnitTypes;
  /**
   * Interval-Value
   */
  intervalValue: number;
}

export interface OrderSubscription_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_text_allowedLineHeightInterval {
  /**
   * Type of unit
   */
  type: UnitTypes;
  /**
   * Interval-Value
   */
  intervalValue: number;
}

export interface OrderSubscription_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_text_allowedTextScaleWidthOffsetInterval {
  /**
   * Interval-Value
   */
  intervalValue: number;
  /**
   * Min-Value
   */
  minValue: number;
  /**
   * Max-Value
   */
  maxValue: number;
}

export interface OrderSubscription_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_text_allowedCharacterGalleries_items {
  /**
   * Id
   */
  id: string;
  /**
   * Name
   */
  name: string;
  /**
   * Title
   */
  title: string;
  /**
   * Preview
   */
  preview: string | null;
  /**
   * Html
   */
  html: string;
}

export interface OrderSubscription_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_text_allowedCharacterGalleries {
  /**
   * Id
   */
  id: string;
  isAdvancedUser: boolean;
  /**
   * Items
   */
  items: OrderSubscription_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_text_allowedCharacterGalleries_items[];
}

export interface OrderSubscription_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_text_allowedParagraphClasses {
  /**
   * Id
   */
  id: string;
  /**
   * Name
   */
  name: string;
}

export interface OrderSubscription_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_text {
  /**
   * Default textalignment from template
   */
  defaultTextAdjustment: TextAlignments;
  /**
   * Allowed font sizes
   */
  allowedFontSizes: OrderSubscription_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_text_allowedFontSizes;
  /**
   * Allowed font styles
   */
  allowedFontStyles: FontStyles[];
  /**
   * Allowed font weights
   */
  allowedFontWeights: FontWeights[];
  /**
   * Allowed line height sizes
   */
  allowedLineHeights: OrderSubscription_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_text_allowedLineHeights;
  /**
   * Allowed font families
   */
  allowedFontFamilies: string[];
  /**
   * Allowed text adjustments
   */
  allowedTextAdjustments: TextAlignments[];
  /**
   * Allowed font size interval
   */
  allowedFontSizeInterval: OrderSubscription_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_text_allowedFontSizeInterval;
  /**
   * Allowed line height size interval
   */
  allowedLineHeightInterval: OrderSubscription_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_text_allowedLineHeightInterval;
  /**
   * Enable text scale width offset
   */
  isTextScaleWidthOffset: boolean;
  /**
   * Allowed TextScale width offset interval
   */
  allowedTextScaleWidthOffsetInterval: OrderSubscription_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_text_allowedTextScaleWidthOffsetInterval | null;
  /**
   * Allowed special characters galleries
   */
  allowedCharacterGalleries: OrderSubscription_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_text_allowedCharacterGalleries[];
  /**
   * Allowed number of paragraphClass
   */
  allowedParagraphClasses: OrderSubscription_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_text_allowedParagraphClasses[];
  /**
   * Enable to change font size
   */
  isEnabledFontSize: boolean;
  /**
   * Enable to change font style
   */
  isEnabledFontStyle: boolean;
  /**
   * Enable to change line height
   */
  isEnabledLineHeight: boolean;
  /**
   * Enable to change font family
   */
  isEnabledFontFamily: boolean;
  /**
   * Enable to change font weights
   */
  isEnabledFontWeight: boolean;
  /**
   * Enable to change letter spacing
   */
  isEnabledLetterSpacing: boolean;
  /**
   * Enable to advanced settings for the admin/support
   */
  isEnabledAdvancedSettings: boolean;
  /**
   * Enable to change letter spacing
   */
  isEnabledTextScaleWidthTolerance: boolean;
  /**
   * Enable gallery option
   */
  isEnabledGallery: boolean;
}

export interface OrderSubscription_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_image_adjustment_brightness {
  /**
   * Interval-Value
   */
  intervalValue: number;
  /**
   * Max-Value
   */
  maxValue: number;
  /**
   * Min-Value
   */
  minValue: number;
  /**
   * Type of unit
   */
  type: RelativeTypes;
}

export interface OrderSubscription_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_image_adjustment_contrast {
  /**
   * Interval-Value
   */
  intervalValue: number;
  /**
   * Max-Value
   */
  maxValue: number;
  /**
   * Min-Value
   */
  minValue: number;
  /**
   * Type of unit
   */
  type: RelativeTypes;
}

export interface OrderSubscription_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_image_adjustment_rotate {
  /**
   * Interval-Value
   */
  intervalValue: number;
  /**
   * Max-Value
   */
  maxValue: number;
  /**
   * Min-Value
   */
  minValue: number;
  /**
   * Type of unit
   */
  type: RelativeTypes;
}

export interface OrderSubscription_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_image_adjustment {
  brightness: OrderSubscription_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_image_adjustment_brightness;
  contrast: OrderSubscription_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_image_adjustment_contrast;
  rotate: OrderSubscription_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_image_adjustment_rotate;
}

export interface OrderSubscription_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_image_mask {
  imageUrl: string | null;
}

export interface OrderSubscription_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_image {
  /**
   * Min image height
   */
  minImageHeight: number;
  /**
   * Max image height
   */
  maxImageHeight: number;
  /**
   * Filter by gallery image category
   */
  filterGalleryImageAttributeInternalIds: number[];
  /**
   * Filter by not gallery image category
   */
  filterGalleryImageAttributeNotInternalIds: number[];
  /**
   * Filter by not gallery image category
   */
  galleryImageOption: string;
  /**
   * Filter by not gallery image category
   */
  documentFormatId: string;
  /**
   * Is the user enabled to switch to free size
   */
  isEnabledFreeSize: boolean;
  /**
   * Is the user enabled to upload images
   */
  isEnabledUpload: boolean;
  /**
   * Gallery is enabled
   */
  isEnabledGallery: boolean;
  /**
   * Resize is enabled
   */
  isEnabledResize: boolean;
  adjustment: OrderSubscription_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_image_adjustment | null;
  mask: OrderSubscription_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_image_mask | null;
}

export interface OrderSubscription_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_help {
  html: string | null;
  hoverHtml: string | null;
  supportHtml: string | null;
}

export interface OrderSubscription_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor {
  /**
   * Type of editor (Image, Text, Separator)
   */
  type: EditModelAdvertAreaContentEditorTypes;
  /**
   * SubType of editor
   */
  behaviour: EditModelAdvertAreaContentEditorBehaviours;
  /**
   * Access properties
   */
  access: OrderSubscription_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_access;
  /**
   * Common parameters
   */
  common: OrderSubscription_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_common;
  /**
   * Text parameters
   */
  text: OrderSubscription_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_text | null;
  /**
   * Image parameters
   */
  image: OrderSubscription_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_image | null;
  /**
   * Help properties
   */
  help: OrderSubscription_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_help | null;
}

export interface OrderSubscription_order_mediaOrder_edit_editModelAdvert_basePage_areas_content {
  /**
   * Validate errors
   */
  validateErrors: OrderSubscription_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_validateErrors[];
  value: OrderSubscription_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_value;
  editor: OrderSubscription_order_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor | null;
}

export interface OrderSubscription_order_mediaOrder_edit_editModelAdvert_basePage_areas_style_margin {
  /**
   * Top
   */
  top: number | null;
  /**
   * Left
   */
  left: number | null;
  /**
   * Right
   */
  right: number | null;
  /**
   * Bottom
   */
  bottom: number | null;
}

export interface OrderSubscription_order_mediaOrder_edit_editModelAdvert_basePage_areas_style_content_size {
  /**
   * Type
   */
  type: UnitTypes;
  /**
   * Min Width
   */
  minWidth: number | null;
  /**
   * Min Height
   */
  minHeight: number | null;
  /**
   * Max Width
   */
  maxWidth: number | null;
  /**
   * Max Height
   */
  maxHeight: number | null;
  /**
   * Fix Width
   */
  fixedWidth: number | null;
  /**
   * Fix Height
   */
  fixedHeight: number | null;
}

export interface OrderSubscription_order_mediaOrder_edit_editModelAdvert_basePage_areas_style_content {
  /**
   * Size limit
   */
  size: OrderSubscription_order_mediaOrder_edit_editModelAdvert_basePage_areas_style_content_size | null;
}

export interface OrderSubscription_order_mediaOrder_edit_editModelAdvert_basePage_areas_style_text_fontSize {
  /**
   * Type of unit
   */
  type: UnitTypes;
  /**
   * Value
   */
  value: number;
}

export interface OrderSubscription_order_mediaOrder_edit_editModelAdvert_basePage_areas_style_text_lineHeight {
  /**
   * Type of unit
   */
  type: UnitTypes;
  /**
   * Value
   */
  value: number;
}

export interface OrderSubscription_order_mediaOrder_edit_editModelAdvert_basePage_areas_style_text_lineHeightEmpty {
  /**
   * Type of unit
   */
  type: UnitTypes;
  /**
   * Value
   */
  value: number;
}

export interface OrderSubscription_order_mediaOrder_edit_editModelAdvert_basePage_areas_style_text_letterSpacing {
  /**
   * Type of unit
   */
  type: UnitTypes;
  /**
   * Value
   */
  value: number;
}

export interface OrderSubscription_order_mediaOrder_edit_editModelAdvert_basePage_areas_style_text_textScaleWidthOffset {
  /**
   * Type
   */
  type: RelativeTypes;
  /**
   * Value
   */
  value: number;
}

export interface OrderSubscription_order_mediaOrder_edit_editModelAdvert_basePage_areas_style_text_textScaleWidth {
  /**
   * Type
   */
  type: RelativeTypes;
  /**
   * Value
   */
  value: number;
}

export interface OrderSubscription_order_mediaOrder_edit_editModelAdvert_basePage_areas_style_text {
  fontFamily: string;
  /**
   * Font-size
   */
  fontSize: OrderSubscription_order_mediaOrder_edit_editModelAdvert_basePage_areas_style_text_fontSize | null;
  /**
   * Line height
   */
  lineHeight: OrderSubscription_order_mediaOrder_edit_editModelAdvert_basePage_areas_style_text_lineHeight | null;
  /**
   * Line height for empty rows
   */
  lineHeightEmpty: OrderSubscription_order_mediaOrder_edit_editModelAdvert_basePage_areas_style_text_lineHeightEmpty | null;
  /**
   * Letter spacing
   */
  letterSpacing: OrderSubscription_order_mediaOrder_edit_editModelAdvert_basePage_areas_style_text_letterSpacing | null;
  textScaleWidthOffset: OrderSubscription_order_mediaOrder_edit_editModelAdvert_basePage_areas_style_text_textScaleWidthOffset;
  textScaleWidth: OrderSubscription_order_mediaOrder_edit_editModelAdvert_basePage_areas_style_text_textScaleWidth;
  fontStyle: FontStyles | null;
  fontWeight: FontWeights | null;
}

export interface OrderSubscription_order_mediaOrder_edit_editModelAdvert_basePage_areas_style {
  /**
   * margins
   */
  margin: OrderSubscription_order_mediaOrder_edit_editModelAdvert_basePage_areas_style_margin | null;
  content: OrderSubscription_order_mediaOrder_edit_editModelAdvert_basePage_areas_style_content;
  /**
   * Text settings
   */
  text: OrderSubscription_order_mediaOrder_edit_editModelAdvert_basePage_areas_style_text;
}

export interface OrderSubscription_order_mediaOrder_edit_editModelAdvert_basePage_areas_rectangle {
  /**
   * X coordinate
   */
  x: number;
  /**
   * Y coordinate
   */
  y: number;
  /**
   * Width
   */
  w: number;
  /**
   * Width
   */
  h: number;
}

export interface OrderSubscription_order_mediaOrder_edit_editModelAdvert_basePage_areas {
  /**
   * Id of the blockeditor
   */
  id: string;
  /**
   * Parameters of the blockeditor
   */
  header: string;
  content: OrderSubscription_order_mediaOrder_edit_editModelAdvert_basePage_areas_content;
  style: OrderSubscription_order_mediaOrder_edit_editModelAdvert_basePage_areas_style;
  rectangle: OrderSubscription_order_mediaOrder_edit_editModelAdvert_basePage_areas_rectangle | null;
}

export interface OrderSubscription_order_mediaOrder_edit_editModelAdvert_basePage {
  areas: (OrderSubscription_order_mediaOrder_edit_editModelAdvert_basePage_areas | null)[] | null;
}

export interface OrderSubscription_order_mediaOrder_edit_editModelAdvert {
  /**
   * If UpdateOrdersContent is avaiable
   */
  isEnabledUpdateOrdersContent: boolean;
  basePage: OrderSubscription_order_mediaOrder_edit_editModelAdvert_basePage;
}

export interface OrderSubscription_order_mediaOrder_edit_history {
  /**
   * How many steps it is possible to go back in history
   */
  countStepBack: number;
}

export interface OrderSubscription_order_mediaOrder_edit {
  /**
   * Texts for editing the order
   */
  userInterface: OrderSubscription_order_mediaOrder_edit_userInterface;
  resources: OrderSubscription_order_mediaOrder_edit_resources | null;
  editModelAdvert: OrderSubscription_order_mediaOrder_edit_editModelAdvert | null;
  history: OrderSubscription_order_mediaOrder_edit_history;
}

export interface OrderSubscription_order_mediaOrder_preview_material_basePage_size {
  /**
   * Width
   */
  width: number;
  /**
   * Height
   */
  height: number;
}

export interface OrderSubscription_order_mediaOrder_preview_material_basePage {
  size: OrderSubscription_order_mediaOrder_preview_material_basePage_size | null;
}

export interface OrderSubscription_order_mediaOrder_preview_material_pages_png {
  /**
   * Url till pngfile
   */
  url: string;
}

export interface OrderSubscription_order_mediaOrder_preview_material_pages {
  /**
   * Png material
   */
  png: OrderSubscription_order_mediaOrder_preview_material_pages_png;
}

export interface OrderSubscription_order_mediaOrder_preview_material {
  /**
   * base page
   */
  basePage: OrderSubscription_order_mediaOrder_preview_material_basePage;
  /**
   * pages
   */
  pages: (OrderSubscription_order_mediaOrder_preview_material_pages | null)[];
}

export interface OrderSubscription_order_mediaOrder_preview_price_customer {
  /**
   * How much the product is pricing for the scope in the contract
   */
  priceContract: any | null;
  /**
   * How much the product is pricing for the order if it has been override the contract
   */
  priceContractOverride: any | null;
}

export interface OrderSubscription_order_mediaOrder_preview_price_endCustomer {
  /**
   * Whats pay for the order and rounded
   */
  pricePayRounded: any | null;
}

export interface OrderSubscription_order_mediaOrder_preview_price {
  /**
   * Currency
   */
  currency: string;
  /**
   * Customer view of the price
   */
  customer: OrderSubscription_order_mediaOrder_preview_price_customer;
  /**
   * EndCustomer view of the price
   */
  endCustomer: OrderSubscription_order_mediaOrder_preview_price_endCustomer;
}

export interface OrderSubscription_order_mediaOrder_preview {
  /**
   * material
   */
  material: OrderSubscription_order_mediaOrder_preview_material;
  /**
   * price of the order
   */
  price: OrderSubscription_order_mediaOrder_preview_price | null;
}

export interface OrderSubscription_order_mediaOrder_media {
  /**
   * The id of the media
   */
  id: string | null;
  /**
   * The friendlyName of the media
   */
  friendlyName: string;
}

export interface OrderSubscription_order_mediaOrder_status {
  /**
   * Color for vieweing the customer
   */
  color: StatusColors;
  /**
   * ProductStatus enum for the status
   */
  status: OrderStatus;
}

export interface OrderSubscription_order_mediaOrder {
  /**
   * Time when last sending the booking to media
   */
  timeBookingStop: any | null;
  /**
   * Locked
   */
  isLocked: boolean;
  /**
   * times when the order will be published
   */
  publishTimes: (OrderSubscription_order_mediaOrder_publishTimes | null)[];
  /**
   * documentformat of the order
   */
  documentFormat: OrderSubscription_order_mediaOrder_documentFormat;
  /**
   * Publish conversation settings for the media
   */
  publishConversation: OrderSubscription_order_mediaOrder_publishConversation;
  /**
   * Parallell publish channels
   */
  publishChannels: OrderSubscription_order_mediaOrder_publishChannels;
  /**
   * information for the editors
   */
  edit: OrderSubscription_order_mediaOrder_edit;
  /**
   * Preview of the order
   */
  preview: OrderSubscription_order_mediaOrder_preview;
  /**
   * media of the order
   */
  media: OrderSubscription_order_mediaOrder_media;
  /**
   * Status of the order
   */
  status: OrderSubscription_order_mediaOrder_status;
}

export interface OrderSubscription_order {
  /**
   * The id of the mediaorder
   */
  id: string | null;
  /**
   * The Id shown for customer
   */
  customerFriendlyId: string;
  /**
   * Order is deleted
   */
  isDeleted: boolean;
  /**
   * document of the order
   */
  document: OrderSubscription_order_document;
  client: OrderSubscription_order_client | null;
  alerting: OrderSubscription_order_alerting;
  /**
   * mediaOrder properties if that kind of order
   */
  mediaOrder: OrderSubscription_order_mediaOrder | null;
}

export interface OrderSubscription {
  order: OrderSubscription_order | null;
}

export interface OrderSubscriptionVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: AreaFragment
// ====================================================

export interface AreaFragment_content_validateErrors {
  error: EditModelAdvertAreaContentValidateErrors;
  validationText: string | null;
}

export interface AreaFragment_content_value_rows_columns_item_common_marginLeftRightOffset {
  /**
   * Type
   */
  type: RelativeTypes;
  /**
   * Value
   */
  value: number;
}

export interface AreaFragment_content_value_rows_columns_item_common_margin {
  /**
   * Type of unit
   */
  type: UnitTypes;
  /**
   * Left
   */
  left: number | null;
  /**
   * Right
   */
  right: number | null;
  /**
   * Top
   */
  top: number | null;
  /**
   * Bottom
   */
  bottom: number | null;
}

export interface AreaFragment_content_value_rows_columns_item_common_size {
  /**
   * Type
   */
  type: UnitTypes;
  /**
   * Min Width
   */
  minWidth: number | null;
  /**
   * Min Height
   */
  minHeight: number | null;
  /**
   * Max Width
   */
  maxWidth: number | null;
  /**
   * Max Height
   */
  maxHeight: number | null;
  /**
   * Fix Width
   */
  fixedWidth: number | null;
  /**
   * Fix Height
   */
  fixedHeight: number | null;
}

export interface AreaFragment_content_value_rows_columns_item_common {
  /**
   * Alternative BlockStyle
   */
  blockStyleAreaNameId: string | null;
  marginLeftRightOffset: AreaFragment_content_value_rows_columns_item_common_marginLeftRightOffset | null;
  margin: AreaFragment_content_value_rows_columns_item_common_margin | null;
  size: AreaFragment_content_value_rows_columns_item_common_size | null;
}

export interface AreaFragment_content_value_rows_columns_item_image {
  /**
   * Repository of image
   */
  type: EditModelAdvertAreaContentRepository | null;
  /**
   * id for the image
   */
  imageId: string | null;
  /**
   * public id for the image
   */
  imagePublicId: string | null;
  /**
   * public id for the image
   */
  imageThumbnaill100x80Url: string | null;
  /**
   * Height for the image in mm
   */
  heightMM: number | null;
  /**
   * DefaultHeight for the image in mm
   */
  defaultHeightMM: number | null;
  /**
   * IsSizeFree
   */
  isSizeFree: boolean | null;
  /**
   * Upload image content
   */
  uploadData: string | null;
  /**
   * Upload filename
   */
  uploadFilename: string | null;
  /**
   * Bitmap resolution in dpi
   */
  resolution: number | null;
  /**
   * Upload prefered HeightMM of uploaded image
   */
  uploadPreferedHeightMM: number | null;
  /**
   * Repository of image
   */
  repository: EditModelAdvertAreaContentRepository | null;
  /**
   * Upload source image content
   */
  uploadDataSource: string | null;
  /**
   * Comment about the upload
   */
  uploadComment: string | null;
}

export interface AreaFragment_content_value_rows_columns_item_text_textScaleWidthOffset {
  /**
   * Type
   */
  type: RelativeTypes;
  /**
   * Value
   */
  value: number;
}

export interface AreaFragment_content_value_rows_columns_item_text_fontSize {
  /**
   * Type of unit
   */
  type: UnitTypes;
  /**
   * Value
   */
  value: number;
}

export interface AreaFragment_content_value_rows_columns_item_text_lineHeight {
  /**
   * Type of unit
   */
  type: UnitTypes;
  /**
   * Value
   */
  value: number;
}

export interface AreaFragment_content_value_rows_columns_item_text_lineHeightEmpty {
  /**
   * Type of unit
   */
  type: UnitTypes;
  /**
   * Value
   */
  value: number;
}

export interface AreaFragment_content_value_rows_columns_item_text_letterSpacing {
  /**
   * Type of unit
   */
  type: UnitTypes;
  /**
   * Value
   */
  value: number;
}

export interface AreaFragment_content_value_rows_columns_item_text_textScaleWidth {
  /**
   * Type
   */
  type: RelativeTypes;
  /**
   * Value
   */
  value: number;
}

export interface AreaFragment_content_value_rows_columns_item_text {
  /**
   * Font style
   */
  fontStyle: FontStyles | null;
  /**
   * Textscale width offset
   */
  textScaleWidthOffset: AreaFragment_content_value_rows_columns_item_text_textScaleWidthOffset | null;
  /**
   * Font weight
   */
  fontWeight: FontWeights | null;
  /**
   * Text alignment
   */
  textAlignment: TextAlignments | null;
  /**
   * Text formats in xml
   */
  xmlText: string | null;
  /**
   * Is htmleditor readonly
   */
  isXmlTextReadonly: boolean | null;
  /**
   * Fontfamily of the text
   */
  fontFamily: string | null;
  /**
   * Font
   */
  fontSize: AreaFragment_content_value_rows_columns_item_text_fontSize | null;
  /**
   * Line-height
   */
  lineHeight: AreaFragment_content_value_rows_columns_item_text_lineHeight | null;
  /**
   * Line-height for empty rows
   */
  lineHeightEmpty: AreaFragment_content_value_rows_columns_item_text_lineHeightEmpty | null;
  /**
   * Letter-Spacing
   */
  letterSpacing: AreaFragment_content_value_rows_columns_item_text_letterSpacing | null;
  /**
   * Textscale width
   */
  textScaleWidth: AreaFragment_content_value_rows_columns_item_text_textScaleWidth | null;
  /**
   * Text do not wrap when text reach its width
   */
  textNoWrap: boolean | null;
}

export interface AreaFragment_content_value_rows_columns_item_separator {
  /**
   * Type of image
   */
  type: EditModelAdvertAreaContentItemSeparatorTypes;
  imageId: string | null;
  /**
   * Repository of image
   */
  repository: EditModelAdvertAreaContentRepository | null;
  /**
   * Width for the image in mm
   */
  widthMM: number | null;
  /**
   * Height for the image in mm
   */
  heightMM: number | null;
}

export interface AreaFragment_content_value_rows_columns_item_fields {
  /**
   * Name
   */
  name: string;
  /**
   * Header
   */
  header: string | null;
  /**
   * Type
   */
  type: EditModelAdvertAreaContentItemFieldItemTypes | null;
  /**
   * Editor
   */
  editor: EditModelAdvertAreaContentItemFieldItemEditors | null;
  /**
   * Value
   */
  value: string | null;
  __typename: "EditModelAdvertAreaContentItemFieldItem";
}

export interface AreaFragment_content_value_rows_columns_item {
  /**
   * Common
   */
  common: AreaFragment_content_value_rows_columns_item_common | null;
  /**
   * Value is only for demo purpose
   */
  isDemoValue: boolean | null;
  /**
   * ImageContent
   */
  image: AreaFragment_content_value_rows_columns_item_image | null;
  /**
   * TextContent
   */
  text: AreaFragment_content_value_rows_columns_item_text | null;
  /**
   * HorizontalLineContent
   */
  separator: AreaFragment_content_value_rows_columns_item_separator | null;
  /**
   * Fields
   */
  fields: (AreaFragment_content_value_rows_columns_item_fields | null)[] | null;
}

export interface AreaFragment_content_value_rows_columns {
  item: AreaFragment_content_value_rows_columns_item;
}

export interface AreaFragment_content_value_rows {
  columns: AreaFragment_content_value_rows_columns[];
}

export interface AreaFragment_content_value {
  /**
   * Id for the content-value
   */
  id: string | null;
  /**
   * Is content visible
   */
  visible: boolean;
  /**
   * Is it content
   */
  hasContent: boolean;
  /**
   * Is editor visible
   */
  isEditorVisible: boolean;
  /**
   * Id to the template
   */
  templateAreaId: string | null;
  /**
   * Rows
   */
  rows: AreaFragment_content_value_rows[];
}

export interface AreaFragment_content_editor_access {
  add: boolean;
  modify: boolean;
  statusAdd: EditModelAdvertAreaContentEditorAccessStatuses;
}

export interface AreaFragment_content_editor_common_allowedMarginLeftRightOffsetInterval {
  /**
   * Type of unit
   */
  type: RelativeTypes;
  /**
   * Max-Value
   */
  maxValue: number;
  /**
   * Min-Value
   */
  minValue: number;
  /**
   * Interval-Value
   */
  intervalValue: number;
}

export interface AreaFragment_content_editor_common_blockStyles {
  /**
   * Id
   */
  id: string;
  /**
   * Name
   */
  name: string;
}

export interface AreaFragment_content_editor_common {
  /**
   * Margin can adjust margins
   */
  isEnabledAdjustMargins: boolean;
  /**
   * If UpdateOrdersContent is avaiable
   */
  isEnabledUpdateOrdersContent: boolean;
  /**
   * If it is possible to add columns
   */
  isEnabledMultiColumns: boolean;
  /**
   * Enable margin setting
   */
  isMarginLeftRightOffset: boolean;
  /**
   * Allowed margin settings
   */
  allowedMarginLeftRightOffsetInterval: AreaFragment_content_editor_common_allowedMarginLeftRightOffsetInterval | null;
  /**
   * Block styles
   */
  blockStyles: AreaFragment_content_editor_common_blockStyles[];
}

export interface AreaFragment_content_editor_text_allowedFontSizes {
  /**
   * Values
   */
  values: number[];
}

export interface AreaFragment_content_editor_text_allowedLineHeights {
  /**
   * Values
   */
  values: number[];
}

export interface AreaFragment_content_editor_text_allowedFontSizeInterval {
  /**
   * Type of unit
   */
  type: UnitTypes;
  /**
   * Interval-Value
   */
  intervalValue: number;
}

export interface AreaFragment_content_editor_text_allowedLineHeightInterval {
  /**
   * Type of unit
   */
  type: UnitTypes;
  /**
   * Interval-Value
   */
  intervalValue: number;
}

export interface AreaFragment_content_editor_text_allowedTextScaleWidthOffsetInterval {
  /**
   * Interval-Value
   */
  intervalValue: number;
  /**
   * Min-Value
   */
  minValue: number;
  /**
   * Max-Value
   */
  maxValue: number;
}

export interface AreaFragment_content_editor_text_allowedCharacterGalleries_items {
  /**
   * Id
   */
  id: string;
  /**
   * Name
   */
  name: string;
  /**
   * Title
   */
  title: string;
  /**
   * Preview
   */
  preview: string | null;
  /**
   * Html
   */
  html: string;
}

export interface AreaFragment_content_editor_text_allowedCharacterGalleries {
  /**
   * Id
   */
  id: string;
  isAdvancedUser: boolean;
  /**
   * Items
   */
  items: AreaFragment_content_editor_text_allowedCharacterGalleries_items[];
}

export interface AreaFragment_content_editor_text_allowedParagraphClasses {
  /**
   * Id
   */
  id: string;
  /**
   * Name
   */
  name: string;
}

export interface AreaFragment_content_editor_text {
  /**
   * Default textalignment from template
   */
  defaultTextAdjustment: TextAlignments;
  /**
   * Allowed font sizes
   */
  allowedFontSizes: AreaFragment_content_editor_text_allowedFontSizes;
  /**
   * Allowed font styles
   */
  allowedFontStyles: FontStyles[];
  /**
   * Allowed font weights
   */
  allowedFontWeights: FontWeights[];
  /**
   * Allowed line height sizes
   */
  allowedLineHeights: AreaFragment_content_editor_text_allowedLineHeights;
  /**
   * Allowed font families
   */
  allowedFontFamilies: string[];
  /**
   * Allowed text adjustments
   */
  allowedTextAdjustments: TextAlignments[];
  /**
   * Allowed font size interval
   */
  allowedFontSizeInterval: AreaFragment_content_editor_text_allowedFontSizeInterval;
  /**
   * Allowed line height size interval
   */
  allowedLineHeightInterval: AreaFragment_content_editor_text_allowedLineHeightInterval;
  /**
   * Enable text scale width offset
   */
  isTextScaleWidthOffset: boolean;
  /**
   * Allowed TextScale width offset interval
   */
  allowedTextScaleWidthOffsetInterval: AreaFragment_content_editor_text_allowedTextScaleWidthOffsetInterval | null;
  /**
   * Allowed special characters galleries
   */
  allowedCharacterGalleries: AreaFragment_content_editor_text_allowedCharacterGalleries[];
  /**
   * Allowed number of paragraphClass
   */
  allowedParagraphClasses: AreaFragment_content_editor_text_allowedParagraphClasses[];
  /**
   * Enable to change font size
   */
  isEnabledFontSize: boolean;
  /**
   * Enable to change font style
   */
  isEnabledFontStyle: boolean;
  /**
   * Enable to change line height
   */
  isEnabledLineHeight: boolean;
  /**
   * Enable to change font family
   */
  isEnabledFontFamily: boolean;
  /**
   * Enable to change font weights
   */
  isEnabledFontWeight: boolean;
  /**
   * Enable to change letter spacing
   */
  isEnabledLetterSpacing: boolean;
  /**
   * Enable to advanced settings for the admin/support
   */
  isEnabledAdvancedSettings: boolean;
  /**
   * Enable to change letter spacing
   */
  isEnabledTextScaleWidthTolerance: boolean;
  /**
   * Enable gallery option
   */
  isEnabledGallery: boolean;
}

export interface AreaFragment_content_editor_image_adjustment_brightness {
  /**
   * Interval-Value
   */
  intervalValue: number;
  /**
   * Max-Value
   */
  maxValue: number;
  /**
   * Min-Value
   */
  minValue: number;
  /**
   * Type of unit
   */
  type: RelativeTypes;
}

export interface AreaFragment_content_editor_image_adjustment_contrast {
  /**
   * Interval-Value
   */
  intervalValue: number;
  /**
   * Max-Value
   */
  maxValue: number;
  /**
   * Min-Value
   */
  minValue: number;
  /**
   * Type of unit
   */
  type: RelativeTypes;
}

export interface AreaFragment_content_editor_image_adjustment_rotate {
  /**
   * Interval-Value
   */
  intervalValue: number;
  /**
   * Max-Value
   */
  maxValue: number;
  /**
   * Min-Value
   */
  minValue: number;
  /**
   * Type of unit
   */
  type: RelativeTypes;
}

export interface AreaFragment_content_editor_image_adjustment {
  brightness: AreaFragment_content_editor_image_adjustment_brightness;
  contrast: AreaFragment_content_editor_image_adjustment_contrast;
  rotate: AreaFragment_content_editor_image_adjustment_rotate;
}

export interface AreaFragment_content_editor_image_mask {
  imageUrl: string | null;
}

export interface AreaFragment_content_editor_image {
  /**
   * Min image height
   */
  minImageHeight: number;
  /**
   * Max image height
   */
  maxImageHeight: number;
  /**
   * Filter by gallery image category
   */
  filterGalleryImageAttributeInternalIds: number[];
  /**
   * Filter by not gallery image category
   */
  filterGalleryImageAttributeNotInternalIds: number[];
  /**
   * Filter by not gallery image category
   */
  galleryImageOption: string;
  /**
   * Filter by not gallery image category
   */
  documentFormatId: string;
  /**
   * Is the user enabled to switch to free size
   */
  isEnabledFreeSize: boolean;
  /**
   * Is the user enabled to upload images
   */
  isEnabledUpload: boolean;
  /**
   * Gallery is enabled
   */
  isEnabledGallery: boolean;
  /**
   * Resize is enabled
   */
  isEnabledResize: boolean;
  adjustment: AreaFragment_content_editor_image_adjustment | null;
  mask: AreaFragment_content_editor_image_mask | null;
}

export interface AreaFragment_content_editor_help {
  html: string | null;
  hoverHtml: string | null;
  supportHtml: string | null;
}

export interface AreaFragment_content_editor {
  /**
   * Type of editor (Image, Text, Separator)
   */
  type: EditModelAdvertAreaContentEditorTypes;
  /**
   * SubType of editor
   */
  behaviour: EditModelAdvertAreaContentEditorBehaviours;
  /**
   * Access properties
   */
  access: AreaFragment_content_editor_access;
  /**
   * Common parameters
   */
  common: AreaFragment_content_editor_common;
  /**
   * Text parameters
   */
  text: AreaFragment_content_editor_text | null;
  /**
   * Image parameters
   */
  image: AreaFragment_content_editor_image | null;
  /**
   * Help properties
   */
  help: AreaFragment_content_editor_help | null;
}

export interface AreaFragment_content {
  /**
   * Validate errors
   */
  validateErrors: AreaFragment_content_validateErrors[];
  value: AreaFragment_content_value;
  editor: AreaFragment_content_editor | null;
}

export interface AreaFragment_style_margin {
  /**
   * Top
   */
  top: number | null;
  /**
   * Left
   */
  left: number | null;
  /**
   * Right
   */
  right: number | null;
  /**
   * Bottom
   */
  bottom: number | null;
}

export interface AreaFragment_style_content_size {
  /**
   * Type
   */
  type: UnitTypes;
  /**
   * Min Width
   */
  minWidth: number | null;
  /**
   * Min Height
   */
  minHeight: number | null;
  /**
   * Max Width
   */
  maxWidth: number | null;
  /**
   * Max Height
   */
  maxHeight: number | null;
  /**
   * Fix Width
   */
  fixedWidth: number | null;
  /**
   * Fix Height
   */
  fixedHeight: number | null;
}

export interface AreaFragment_style_content {
  /**
   * Size limit
   */
  size: AreaFragment_style_content_size | null;
}

export interface AreaFragment_style_text_fontSize {
  /**
   * Type of unit
   */
  type: UnitTypes;
  /**
   * Value
   */
  value: number;
}

export interface AreaFragment_style_text_lineHeight {
  /**
   * Type of unit
   */
  type: UnitTypes;
  /**
   * Value
   */
  value: number;
}

export interface AreaFragment_style_text_lineHeightEmpty {
  /**
   * Type of unit
   */
  type: UnitTypes;
  /**
   * Value
   */
  value: number;
}

export interface AreaFragment_style_text_letterSpacing {
  /**
   * Type of unit
   */
  type: UnitTypes;
  /**
   * Value
   */
  value: number;
}

export interface AreaFragment_style_text_textScaleWidthOffset {
  /**
   * Type
   */
  type: RelativeTypes;
  /**
   * Value
   */
  value: number;
}

export interface AreaFragment_style_text_textScaleWidth {
  /**
   * Type
   */
  type: RelativeTypes;
  /**
   * Value
   */
  value: number;
}

export interface AreaFragment_style_text {
  fontFamily: string;
  /**
   * Font-size
   */
  fontSize: AreaFragment_style_text_fontSize | null;
  /**
   * Line height
   */
  lineHeight: AreaFragment_style_text_lineHeight | null;
  /**
   * Line height for empty rows
   */
  lineHeightEmpty: AreaFragment_style_text_lineHeightEmpty | null;
  /**
   * Letter spacing
   */
  letterSpacing: AreaFragment_style_text_letterSpacing | null;
  textScaleWidthOffset: AreaFragment_style_text_textScaleWidthOffset;
  textScaleWidth: AreaFragment_style_text_textScaleWidth;
  fontStyle: FontStyles | null;
  fontWeight: FontWeights | null;
}

export interface AreaFragment_style {
  /**
   * margins
   */
  margin: AreaFragment_style_margin | null;
  content: AreaFragment_style_content;
  /**
   * Text settings
   */
  text: AreaFragment_style_text;
}

export interface AreaFragment_rectangle {
  /**
   * X coordinate
   */
  x: number;
  /**
   * Y coordinate
   */
  y: number;
  /**
   * Width
   */
  w: number;
  /**
   * Width
   */
  h: number;
}

export interface AreaFragment {
  /**
   * Id of the blockeditor
   */
  id: string;
  /**
   * Parameters of the blockeditor
   */
  header: string;
  content: AreaFragment_content;
  style: AreaFragment_style;
  rectangle: AreaFragment_rectangle | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: CustomerUserFragment
// ====================================================

export interface CustomerUserFragment_inheritSettings {
  /**
   * The emailaddress used when sending info to customer
   */
  contactPhone: string | null;
}

export interface CustomerUserFragment_office {
  /**
   * The id of office
   */
  id: string;
  /**
   * The name of office
   */
  name: string;
}

export interface CustomerUserFragment_primaryUser {
  /**
   * The id of user
   */
  id: string | null;
  /**
   * The username of user
   */
  username: string;
  /**
   * The account is enabled or not
   */
  enabled: boolean;
}

export interface CustomerUserFragment {
  /**
   * The id of customer
   */
  id: string;
  /**
   * The name of customer
   */
  name: string;
  /**
   * The firstname of customer
   */
  firstName: string;
  /**
   * The lastname of customer
   */
  lastName: string;
  inheritSettings: CustomerUserFragment_inheritSettings;
  office: CustomerUserFragment_office;
  /**
   * The primary user for the customer
   */
  primaryUser: CustomerUserFragment_primaryUser | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: DashboardCaseFolderFragment
// ====================================================

export interface DashboardCaseFolderFragment_customer {
  /**
   * The id of customer
   */
  id: string;
  /**
   * The name of customer
   */
  name: string;
}

export interface DashboardCaseFolderFragment_propertiesFuneralStandard {
  /**
   * Id from a another businesssystem (option)
   */
  externalId: string;
  /**
   * Born date for the person in the case
   */
  personBorn: any | null;
  /**
   * Died date for the person in the case
   */
  personDied: any | null;
  /**
   * Funeral date for the person in the case
   */
  personFuneral: any | null;
  /**
   * Lastname when the person in the case was born
   */
  personBornLastname: string | null;
  /**
   * First name of the person in the case
   */
  personFirstname: string;
  /**
   * Last name of the person in the case
   */
  personLastname: string;
  /**
   * Where did the person live
   */
  personCityLive: string;
  /**
   * Informationtext about the funeral
   */
  personFuneralInfoText: string;
  /**
   * Last day for booking the cermony for memory
   */
  memoryCeremonyLastBookingDay: any | null;
}

export interface DashboardCaseFolderFragment_summary {
  /**
   * Count number of orders)
   */
  countOrders: number;
  /**
   * Most critical Status color in the orders
   */
  mostCriticalColor: StatusColors;
  /**
   * Most critical Status in the orders
   */
  mostCriticalStatus: OrderStatus;
}

export interface DashboardCaseFolderFragment_productPackages_productPackage {
  /**
   * Id of the ProductPackage
   */
  id: string;
  /**
   * Name of the ProductPackage
   */
  name: string;
}

export interface DashboardCaseFolderFragment_productPackages_summary {
  /**
   * Most critical Status color in the orders
   */
  mostCriticalColor: StatusColors;
  /**
   * Most critical Status in the orders
   */
  mostCriticalStatus: OrderStatus;
  /**
   * Flag when shows warning about the bookingstoptime
   */
  isMostCriticalTimeBookingWarning: boolean;
}

export interface DashboardCaseFolderFragment_productPackages_documents_documentFormat_documentType {
  friendlyName: string;
  /**
   * Id of the documenttype
   */
  id: string;
  /**
   * The name of the documentType
   */
  name: string;
}

export interface DashboardCaseFolderFragment_productPackages_documents_documentFormat {
  /**
   * Id of the documentformat
   */
  id: string;
  documentType: DashboardCaseFolderFragment_productPackages_documents_documentFormat_documentType;
}

export interface DashboardCaseFolderFragment_productPackages_documents_orders_document {
  /**
   * Id of the document
   */
  id: string | null;
}

export interface DashboardCaseFolderFragment_productPackages_documents_orders_alerting {
  text: string | null;
  smallText: string | null;
}

export interface DashboardCaseFolderFragment_productPackages_documents_orders_mediaOrder_media {
  /**
   * The id of the media
   */
  id: string | null;
  /**
   * The friendlyName of the media
   */
  friendlyName: string;
}

export interface DashboardCaseFolderFragment_productPackages_documents_orders_mediaOrder_documentFormat {
  /**
   * Id of the documentformat
   */
  id: string;
  mediaAdaptedName: string;
}

export interface DashboardCaseFolderFragment_productPackages_documents_orders_mediaOrder_publishTimes {
  /**
   * Date when the order will be published
   */
  time: any;
}

export interface DashboardCaseFolderFragment_productPackages_documents_orders_mediaOrder_status {
  /**
   * ProductStatus enum for the status
   */
  status: OrderStatus;
  /**
   * Color for vieweing the customer
   */
  color: StatusColors;
}

export interface DashboardCaseFolderFragment_productPackages_documents_orders_mediaOrder {
  /**
   * Flag when shows warning about the bookingstoptime
   */
  isCriticalTimeBookingWarning: boolean;
  /**
   * Locked
   */
  isLocked: boolean;
  /**
   * media of the order
   */
  media: DashboardCaseFolderFragment_productPackages_documents_orders_mediaOrder_media;
  /**
   * documentformat of the order
   */
  documentFormat: DashboardCaseFolderFragment_productPackages_documents_orders_mediaOrder_documentFormat;
  /**
   * times when the order will be published
   */
  publishTimes: (DashboardCaseFolderFragment_productPackages_documents_orders_mediaOrder_publishTimes | null)[];
  /**
   * Status of the order
   */
  status: DashboardCaseFolderFragment_productPackages_documents_orders_mediaOrder_status;
}

export interface DashboardCaseFolderFragment_productPackages_documents_orders {
  /**
   * The id of the mediaorder
   */
  id: string | null;
  /**
   * The Id shown for customer
   */
  customerFriendlyId: string;
  /**
   * document of the order
   */
  document: DashboardCaseFolderFragment_productPackages_documents_orders_document;
  alerting: DashboardCaseFolderFragment_productPackages_documents_orders_alerting;
  /**
   * mediaOrder properties if that kind of order
   */
  mediaOrder: DashboardCaseFolderFragment_productPackages_documents_orders_mediaOrder | null;
}

export interface DashboardCaseFolderFragment_productPackages_documents {
  /**
   * Id of the document
   */
  id: string | null;
  /**
   * InternalId of the document
   */
  internalId: number;
  /**
   * format of the document
   */
  documentFormat: DashboardCaseFolderFragment_productPackages_documents_documentFormat;
  orders: DashboardCaseFolderFragment_productPackages_documents_orders[];
}

export interface DashboardCaseFolderFragment_productPackages {
  /**
   * Product Package
   */
  productPackage: DashboardCaseFolderFragment_productPackages_productPackage;
  /**
   * ordersummary of product package
   */
  summary: DashboardCaseFolderFragment_productPackages_summary;
  /**
   * orders of the product package
   */
  documents: (DashboardCaseFolderFragment_productPackages_documents | null)[];
}

export interface DashboardCaseFolderFragment {
  /**
   * The id of the caseFolder
   */
  id: string | null;
  /**
   * The internal id of the caseFolder
   */
  internalId: number;
  /**
   * The name of the caseFolder
   */
  name: string;
  customer: DashboardCaseFolderFragment_customer;
  propertiesFuneralStandard: DashboardCaseFolderFragment_propertiesFuneralStandard;
  summary: DashboardCaseFolderFragment_summary;
  /**
   * ProductPackages of the caseFolder
   */
  productPackages: (DashboardCaseFolderFragment_productPackages | null)[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: EditOrderPageCaseFolderFragment
// ====================================================

export interface EditOrderPageCaseFolderFragment_editTabs_orders_client_route_editPageRoute {
  clientApp: ApplicationClientApps;
  absUrl: string;
  relUrl: string;
  taps5IframeAbsUrl: string | null;
  taps5RelUrl: string;
}

export interface EditOrderPageCaseFolderFragment_editTabs_orders_client_route {
  editPageRoute: EditOrderPageCaseFolderFragment_editTabs_orders_client_route_editPageRoute;
}

export interface EditOrderPageCaseFolderFragment_editTabs_orders_client {
  route: EditOrderPageCaseFolderFragment_editTabs_orders_client_route;
}

export interface EditOrderPageCaseFolderFragment_editTabs_orders_alerting {
  smallText: string | null;
  text: string | null;
  type: OrderAlertingType;
}

export interface EditOrderPageCaseFolderFragment_editTabs_orders_mediaOrder_edit {
  /**
   * Kind of editor using for the order
   */
  editModelType: EditModelTypes;
}

export interface EditOrderPageCaseFolderFragment_editTabs_orders_mediaOrder_documentFormat_documentType {
  /**
   * Id of the documenttype
   */
  id: string;
  /**
   * The name of the documentType
   */
  name: string;
}

export interface EditOrderPageCaseFolderFragment_editTabs_orders_mediaOrder_documentFormat {
  /**
   * Id of the documentformat
   */
  id: string;
  mediaAdaptedName: string;
  documentType: EditOrderPageCaseFolderFragment_editTabs_orders_mediaOrder_documentFormat_documentType;
}

export interface EditOrderPageCaseFolderFragment_editTabs_orders_mediaOrder_media {
  /**
   * The id of the media
   */
  id: string | null;
  /**
   * The friendlyName of the media
   */
  friendlyName: string;
}

export interface EditOrderPageCaseFolderFragment_editTabs_orders_mediaOrder_status {
  /**
   * Color for vieweing the customer
   */
  color: StatusColors;
  /**
   * ProductStatus enum for the status
   */
  status: OrderStatus;
}

export interface EditOrderPageCaseFolderFragment_editTabs_orders_mediaOrder {
  /**
   * Locked
   */
  isLocked: boolean;
  /**
   * information for the editors
   */
  edit: EditOrderPageCaseFolderFragment_editTabs_orders_mediaOrder_edit;
  /**
   * documentformat of the order
   */
  documentFormat: EditOrderPageCaseFolderFragment_editTabs_orders_mediaOrder_documentFormat;
  /**
   * media of the order
   */
  media: EditOrderPageCaseFolderFragment_editTabs_orders_mediaOrder_media;
  /**
   * Status of the order
   */
  status: EditOrderPageCaseFolderFragment_editTabs_orders_mediaOrder_status;
}

export interface EditOrderPageCaseFolderFragment_editTabs_orders_document {
  /**
   * Id of the document
   */
  id: string | null;
}

export interface EditOrderPageCaseFolderFragment_editTabs_orders {
  /**
   * The id of the mediaorder
   */
  id: string | null;
  /**
   * Order is deleted
   */
  isDeleted: boolean;
  /**
   * The Id shown for customer
   */
  customerFriendlyId: string;
  client: EditOrderPageCaseFolderFragment_editTabs_orders_client | null;
  alerting: EditOrderPageCaseFolderFragment_editTabs_orders_alerting;
  /**
   * mediaOrder properties if that kind of order
   */
  mediaOrder: EditOrderPageCaseFolderFragment_editTabs_orders_mediaOrder | null;
  /**
   * document of the order
   */
  document: EditOrderPageCaseFolderFragment_editTabs_orders_document;
}

export interface EditOrderPageCaseFolderFragment_editTabs {
  id: string;
  name: string;
  isSubTabs: boolean;
  orders: EditOrderPageCaseFolderFragment_editTabs_orders[];
}

export interface EditOrderPageCaseFolderFragment {
  /**
   * The id of the caseFolder
   */
  id: string | null;
  editTabs: EditOrderPageCaseFolderFragment_editTabs[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: OrderEditorContentValueFragment
// ====================================================

export interface OrderEditorContentValueFragment_rows_columns_item_common_marginLeftRightOffset {
  /**
   * Type
   */
  type: RelativeTypes;
  /**
   * Value
   */
  value: number;
}

export interface OrderEditorContentValueFragment_rows_columns_item_common_margin {
  /**
   * Type of unit
   */
  type: UnitTypes;
  /**
   * Left
   */
  left: number | null;
  /**
   * Right
   */
  right: number | null;
  /**
   * Top
   */
  top: number | null;
  /**
   * Bottom
   */
  bottom: number | null;
}

export interface OrderEditorContentValueFragment_rows_columns_item_common_size {
  /**
   * Type
   */
  type: UnitTypes;
  /**
   * Min Width
   */
  minWidth: number | null;
  /**
   * Min Height
   */
  minHeight: number | null;
  /**
   * Max Width
   */
  maxWidth: number | null;
  /**
   * Max Height
   */
  maxHeight: number | null;
  /**
   * Fix Width
   */
  fixedWidth: number | null;
  /**
   * Fix Height
   */
  fixedHeight: number | null;
}

export interface OrderEditorContentValueFragment_rows_columns_item_common {
  /**
   * Alternative BlockStyle
   */
  blockStyleAreaNameId: string | null;
  marginLeftRightOffset: OrderEditorContentValueFragment_rows_columns_item_common_marginLeftRightOffset | null;
  margin: OrderEditorContentValueFragment_rows_columns_item_common_margin | null;
  size: OrderEditorContentValueFragment_rows_columns_item_common_size | null;
}

export interface OrderEditorContentValueFragment_rows_columns_item_image {
  /**
   * Repository of image
   */
  type: EditModelAdvertAreaContentRepository | null;
  /**
   * id for the image
   */
  imageId: string | null;
  /**
   * public id for the image
   */
  imagePublicId: string | null;
  /**
   * public id for the image
   */
  imageThumbnaill100x80Url: string | null;
  /**
   * Height for the image in mm
   */
  heightMM: number | null;
  /**
   * DefaultHeight for the image in mm
   */
  defaultHeightMM: number | null;
  /**
   * IsSizeFree
   */
  isSizeFree: boolean | null;
  /**
   * Upload image content
   */
  uploadData: string | null;
  /**
   * Upload filename
   */
  uploadFilename: string | null;
  /**
   * Bitmap resolution in dpi
   */
  resolution: number | null;
  /**
   * Upload prefered HeightMM of uploaded image
   */
  uploadPreferedHeightMM: number | null;
  /**
   * Repository of image
   */
  repository: EditModelAdvertAreaContentRepository | null;
  /**
   * Upload source image content
   */
  uploadDataSource: string | null;
  /**
   * Comment about the upload
   */
  uploadComment: string | null;
}

export interface OrderEditorContentValueFragment_rows_columns_item_text_textScaleWidthOffset {
  /**
   * Type
   */
  type: RelativeTypes;
  /**
   * Value
   */
  value: number;
}

export interface OrderEditorContentValueFragment_rows_columns_item_text_fontSize {
  /**
   * Type of unit
   */
  type: UnitTypes;
  /**
   * Value
   */
  value: number;
}

export interface OrderEditorContentValueFragment_rows_columns_item_text_lineHeight {
  /**
   * Type of unit
   */
  type: UnitTypes;
  /**
   * Value
   */
  value: number;
}

export interface OrderEditorContentValueFragment_rows_columns_item_text_lineHeightEmpty {
  /**
   * Type of unit
   */
  type: UnitTypes;
  /**
   * Value
   */
  value: number;
}

export interface OrderEditorContentValueFragment_rows_columns_item_text_letterSpacing {
  /**
   * Type of unit
   */
  type: UnitTypes;
  /**
   * Value
   */
  value: number;
}

export interface OrderEditorContentValueFragment_rows_columns_item_text_textScaleWidth {
  /**
   * Type
   */
  type: RelativeTypes;
  /**
   * Value
   */
  value: number;
}

export interface OrderEditorContentValueFragment_rows_columns_item_text {
  /**
   * Font style
   */
  fontStyle: FontStyles | null;
  /**
   * Textscale width offset
   */
  textScaleWidthOffset: OrderEditorContentValueFragment_rows_columns_item_text_textScaleWidthOffset | null;
  /**
   * Font weight
   */
  fontWeight: FontWeights | null;
  /**
   * Text alignment
   */
  textAlignment: TextAlignments | null;
  /**
   * Text formats in xml
   */
  xmlText: string | null;
  /**
   * Is htmleditor readonly
   */
  isXmlTextReadonly: boolean | null;
  /**
   * Fontfamily of the text
   */
  fontFamily: string | null;
  /**
   * Font
   */
  fontSize: OrderEditorContentValueFragment_rows_columns_item_text_fontSize | null;
  /**
   * Line-height
   */
  lineHeight: OrderEditorContentValueFragment_rows_columns_item_text_lineHeight | null;
  /**
   * Line-height for empty rows
   */
  lineHeightEmpty: OrderEditorContentValueFragment_rows_columns_item_text_lineHeightEmpty | null;
  /**
   * Letter-Spacing
   */
  letterSpacing: OrderEditorContentValueFragment_rows_columns_item_text_letterSpacing | null;
  /**
   * Textscale width
   */
  textScaleWidth: OrderEditorContentValueFragment_rows_columns_item_text_textScaleWidth | null;
  /**
   * Text do not wrap when text reach its width
   */
  textNoWrap: boolean | null;
}

export interface OrderEditorContentValueFragment_rows_columns_item_separator {
  /**
   * Type of image
   */
  type: EditModelAdvertAreaContentItemSeparatorTypes;
  imageId: string | null;
  /**
   * Repository of image
   */
  repository: EditModelAdvertAreaContentRepository | null;
  /**
   * Width for the image in mm
   */
  widthMM: number | null;
  /**
   * Height for the image in mm
   */
  heightMM: number | null;
}

export interface OrderEditorContentValueFragment_rows_columns_item_fields {
  /**
   * Name
   */
  name: string;
  /**
   * Header
   */
  header: string | null;
  /**
   * Type
   */
  type: EditModelAdvertAreaContentItemFieldItemTypes | null;
  /**
   * Editor
   */
  editor: EditModelAdvertAreaContentItemFieldItemEditors | null;
  /**
   * Value
   */
  value: string | null;
  __typename: "EditModelAdvertAreaContentItemFieldItem";
}

export interface OrderEditorContentValueFragment_rows_columns_item {
  /**
   * Common
   */
  common: OrderEditorContentValueFragment_rows_columns_item_common | null;
  /**
   * Value is only for demo purpose
   */
  isDemoValue: boolean | null;
  /**
   * ImageContent
   */
  image: OrderEditorContentValueFragment_rows_columns_item_image | null;
  /**
   * TextContent
   */
  text: OrderEditorContentValueFragment_rows_columns_item_text | null;
  /**
   * HorizontalLineContent
   */
  separator: OrderEditorContentValueFragment_rows_columns_item_separator | null;
  /**
   * Fields
   */
  fields: (OrderEditorContentValueFragment_rows_columns_item_fields | null)[] | null;
}

export interface OrderEditorContentValueFragment_rows_columns {
  item: OrderEditorContentValueFragment_rows_columns_item;
}

export interface OrderEditorContentValueFragment_rows {
  columns: OrderEditorContentValueFragment_rows_columns[];
}

export interface OrderEditorContentValueFragment {
  /**
   * Id for the content-value
   */
  id: string | null;
  /**
   * Is content visible
   */
  visible: boolean;
  /**
   * Is it content
   */
  hasContent: boolean;
  /**
   * Is editor visible
   */
  isEditorVisible: boolean;
  /**
   * Id to the template
   */
  templateAreaId: string | null;
  /**
   * Rows
   */
  rows: OrderEditorContentValueFragment_rows[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: OrderFragment
// ====================================================

export interface OrderFragment_document {
  /**
   * Id of the document
   */
  id: string | null;
}

export interface OrderFragment_client {
  mainSystem: SystemFlags;
  isTaps5: boolean;
}

export interface OrderFragment_alerting {
  smallText: string | null;
  text: string | null;
  type: OrderAlertingType;
}

export interface OrderFragment_mediaOrder_publishTimes {
  /**
   * Date when the order will be published
   */
  time: any;
}

export interface OrderFragment_mediaOrder_documentFormat_documentFormatCollection {
  id: string;
  /**
   * The name of the collection
   */
  name: string;
}

export interface OrderFragment_mediaOrder_documentFormat_documentType {
  /**
   * Id of the documenttype
   */
  id: string;
  /**
   * The name of the documentType
   */
  name: string;
}

export interface OrderFragment_mediaOrder_documentFormat {
  /**
   * Id of the documentformat
   */
  id: string;
  documentFormatCollection: OrderFragment_mediaOrder_documentFormat_documentFormatCollection;
  mediaAdaptedName: string;
  documentType: OrderFragment_mediaOrder_documentFormat_documentType;
}

export interface OrderFragment_mediaOrder_publishConversation {
  /**
   * Validation error texts
   */
  validateErrorTexts: string[];
  /**
   * Message to media
   */
  messageFromMedia: string | null;
  /**
   * Code to verify late publish
   */
  codeLate: string;
  /**
   * Code to media. Used when the ordering is late. Passed bookingstop. This must be verfied before it can be published
   */
  isCodeLate: boolean;
  /**
   * Message to media allowed
   */
  isMessageToMedia: boolean;
  /**
   * Media-System Ordernumber has to be set
   */
  isMediaSystemOrderNumber: boolean;
  /**
   * EndCustomer subribenumber on media can be set
   */
  isMediaCustomerSubriberNumber: boolean;
  /**
   * Status for publish
   */
  publishReadyStatus: MediaOrderPublishhReadyStatus;
}

export interface OrderFragment_mediaOrder_publishChannels {
  /**
   * Publish in familjesidan
   */
  inFamiljesidan: boolean;
  /**
   * Publish in minnesrummet
   */
  inMinnesrummet: boolean;
  /**
   * Publish in agency homepage
   */
  inAgency: boolean;
}

export interface OrderFragment_mediaOrder_edit_userInterface {
  editStatusColor: EditUIEditStatusColor;
  editStatusText: string | null;
  publishButtonText: string | null;
  editStatusIcon: EditUIEditStatusIcon;
  isUnPublishButton: boolean;
  unPublishButtonText: string | null;
  isPublishButton: boolean;
  isLockButton: boolean;
  lockButtonText: string | null;
  isUnlockButton: boolean;
  unlockButtonText: string | null;
  isReactivateButton: boolean;
  reactivateButtonText: string | null;
  isSetExhibitOrder: boolean;
  setExhibitOrderText: string | null;
  isUpdateCustomerDocumentContentInitiator: boolean;
  updateCustomerDocumentContentInitiatorText: string | null;
  /**
   * Size limit
   */
  orderEntityNameSingular: string | null;
  /**
   * Size limit
   */
  orderEntityNamePlural: string | null;
  /**
   * Size limit
   */
  orderEntityNameSingularDetermined: string | null;
  /**
   * Size limit
   */
  orderEntityNamePluralDetermined: string | null;
  isCopyFuneralInfoToMemoryRoom: boolean;
  copyFuneralInfoToMemoryRoomText: string | null;
  isNetnoticeEnabled: boolean;
  isNetnoticeSupported: boolean;
}

export interface OrderFragment_mediaOrder_edit_resources {
  /**
   * Get style css for the specialCharacters
   */
  styleCssSpecialCharacters: string;
}

export interface OrderFragment_mediaOrder_edit_editModelAdvert_basePage_areas_content_validateErrors {
  error: EditModelAdvertAreaContentValidateErrors;
  validationText: string | null;
}

export interface OrderFragment_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_common_marginLeftRightOffset {
  /**
   * Type
   */
  type: RelativeTypes;
  /**
   * Value
   */
  value: number;
}

export interface OrderFragment_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_common_margin {
  /**
   * Type of unit
   */
  type: UnitTypes;
  /**
   * Left
   */
  left: number | null;
  /**
   * Right
   */
  right: number | null;
  /**
   * Top
   */
  top: number | null;
  /**
   * Bottom
   */
  bottom: number | null;
}

export interface OrderFragment_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_common_size {
  /**
   * Type
   */
  type: UnitTypes;
  /**
   * Min Width
   */
  minWidth: number | null;
  /**
   * Min Height
   */
  minHeight: number | null;
  /**
   * Max Width
   */
  maxWidth: number | null;
  /**
   * Max Height
   */
  maxHeight: number | null;
  /**
   * Fix Width
   */
  fixedWidth: number | null;
  /**
   * Fix Height
   */
  fixedHeight: number | null;
}

export interface OrderFragment_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_common {
  /**
   * Alternative BlockStyle
   */
  blockStyleAreaNameId: string | null;
  marginLeftRightOffset: OrderFragment_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_common_marginLeftRightOffset | null;
  margin: OrderFragment_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_common_margin | null;
  size: OrderFragment_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_common_size | null;
}

export interface OrderFragment_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_image {
  /**
   * Repository of image
   */
  type: EditModelAdvertAreaContentRepository | null;
  /**
   * id for the image
   */
  imageId: string | null;
  /**
   * public id for the image
   */
  imagePublicId: string | null;
  /**
   * public id for the image
   */
  imageThumbnaill100x80Url: string | null;
  /**
   * Height for the image in mm
   */
  heightMM: number | null;
  /**
   * DefaultHeight for the image in mm
   */
  defaultHeightMM: number | null;
  /**
   * IsSizeFree
   */
  isSizeFree: boolean | null;
  /**
   * Upload image content
   */
  uploadData: string | null;
  /**
   * Upload filename
   */
  uploadFilename: string | null;
  /**
   * Bitmap resolution in dpi
   */
  resolution: number | null;
  /**
   * Upload prefered HeightMM of uploaded image
   */
  uploadPreferedHeightMM: number | null;
  /**
   * Repository of image
   */
  repository: EditModelAdvertAreaContentRepository | null;
  /**
   * Upload source image content
   */
  uploadDataSource: string | null;
  /**
   * Comment about the upload
   */
  uploadComment: string | null;
}

export interface OrderFragment_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_text_textScaleWidthOffset {
  /**
   * Type
   */
  type: RelativeTypes;
  /**
   * Value
   */
  value: number;
}

export interface OrderFragment_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_text_fontSize {
  /**
   * Type of unit
   */
  type: UnitTypes;
  /**
   * Value
   */
  value: number;
}

export interface OrderFragment_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_text_lineHeight {
  /**
   * Type of unit
   */
  type: UnitTypes;
  /**
   * Value
   */
  value: number;
}

export interface OrderFragment_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_text_lineHeightEmpty {
  /**
   * Type of unit
   */
  type: UnitTypes;
  /**
   * Value
   */
  value: number;
}

export interface OrderFragment_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_text_letterSpacing {
  /**
   * Type of unit
   */
  type: UnitTypes;
  /**
   * Value
   */
  value: number;
}

export interface OrderFragment_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_text_textScaleWidth {
  /**
   * Type
   */
  type: RelativeTypes;
  /**
   * Value
   */
  value: number;
}

export interface OrderFragment_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_text {
  /**
   * Font style
   */
  fontStyle: FontStyles | null;
  /**
   * Textscale width offset
   */
  textScaleWidthOffset: OrderFragment_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_text_textScaleWidthOffset | null;
  /**
   * Font weight
   */
  fontWeight: FontWeights | null;
  /**
   * Text alignment
   */
  textAlignment: TextAlignments | null;
  /**
   * Text formats in xml
   */
  xmlText: string | null;
  /**
   * Is htmleditor readonly
   */
  isXmlTextReadonly: boolean | null;
  /**
   * Fontfamily of the text
   */
  fontFamily: string | null;
  /**
   * Font
   */
  fontSize: OrderFragment_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_text_fontSize | null;
  /**
   * Line-height
   */
  lineHeight: OrderFragment_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_text_lineHeight | null;
  /**
   * Line-height for empty rows
   */
  lineHeightEmpty: OrderFragment_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_text_lineHeightEmpty | null;
  /**
   * Letter-Spacing
   */
  letterSpacing: OrderFragment_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_text_letterSpacing | null;
  /**
   * Textscale width
   */
  textScaleWidth: OrderFragment_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_text_textScaleWidth | null;
  /**
   * Text do not wrap when text reach its width
   */
  textNoWrap: boolean | null;
}

export interface OrderFragment_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_separator {
  /**
   * Type of image
   */
  type: EditModelAdvertAreaContentItemSeparatorTypes;
  imageId: string | null;
  /**
   * Repository of image
   */
  repository: EditModelAdvertAreaContentRepository | null;
  /**
   * Width for the image in mm
   */
  widthMM: number | null;
  /**
   * Height for the image in mm
   */
  heightMM: number | null;
}

export interface OrderFragment_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_fields {
  /**
   * Name
   */
  name: string;
  /**
   * Header
   */
  header: string | null;
  /**
   * Type
   */
  type: EditModelAdvertAreaContentItemFieldItemTypes | null;
  /**
   * Editor
   */
  editor: EditModelAdvertAreaContentItemFieldItemEditors | null;
  /**
   * Value
   */
  value: string | null;
  __typename: "EditModelAdvertAreaContentItemFieldItem";
}

export interface OrderFragment_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item {
  /**
   * Common
   */
  common: OrderFragment_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_common | null;
  /**
   * Value is only for demo purpose
   */
  isDemoValue: boolean | null;
  /**
   * ImageContent
   */
  image: OrderFragment_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_image | null;
  /**
   * TextContent
   */
  text: OrderFragment_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_text | null;
  /**
   * HorizontalLineContent
   */
  separator: OrderFragment_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_separator | null;
  /**
   * Fields
   */
  fields: (OrderFragment_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_fields | null)[] | null;
}

export interface OrderFragment_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns {
  item: OrderFragment_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item;
}

export interface OrderFragment_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows {
  columns: OrderFragment_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns[];
}

export interface OrderFragment_mediaOrder_edit_editModelAdvert_basePage_areas_content_value {
  /**
   * Id for the content-value
   */
  id: string | null;
  /**
   * Is content visible
   */
  visible: boolean;
  /**
   * Is it content
   */
  hasContent: boolean;
  /**
   * Is editor visible
   */
  isEditorVisible: boolean;
  /**
   * Id to the template
   */
  templateAreaId: string | null;
  /**
   * Rows
   */
  rows: OrderFragment_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows[];
}

export interface OrderFragment_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_access {
  add: boolean;
  modify: boolean;
  statusAdd: EditModelAdvertAreaContentEditorAccessStatuses;
}

export interface OrderFragment_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_common_allowedMarginLeftRightOffsetInterval {
  /**
   * Type of unit
   */
  type: RelativeTypes;
  /**
   * Max-Value
   */
  maxValue: number;
  /**
   * Min-Value
   */
  minValue: number;
  /**
   * Interval-Value
   */
  intervalValue: number;
}

export interface OrderFragment_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_common_blockStyles {
  /**
   * Id
   */
  id: string;
  /**
   * Name
   */
  name: string;
}

export interface OrderFragment_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_common {
  /**
   * Margin can adjust margins
   */
  isEnabledAdjustMargins: boolean;
  /**
   * If UpdateOrdersContent is avaiable
   */
  isEnabledUpdateOrdersContent: boolean;
  /**
   * If it is possible to add columns
   */
  isEnabledMultiColumns: boolean;
  /**
   * Enable margin setting
   */
  isMarginLeftRightOffset: boolean;
  /**
   * Allowed margin settings
   */
  allowedMarginLeftRightOffsetInterval: OrderFragment_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_common_allowedMarginLeftRightOffsetInterval | null;
  /**
   * Block styles
   */
  blockStyles: OrderFragment_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_common_blockStyles[];
}

export interface OrderFragment_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_text_allowedFontSizes {
  /**
   * Values
   */
  values: number[];
}

export interface OrderFragment_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_text_allowedLineHeights {
  /**
   * Values
   */
  values: number[];
}

export interface OrderFragment_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_text_allowedFontSizeInterval {
  /**
   * Type of unit
   */
  type: UnitTypes;
  /**
   * Interval-Value
   */
  intervalValue: number;
}

export interface OrderFragment_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_text_allowedLineHeightInterval {
  /**
   * Type of unit
   */
  type: UnitTypes;
  /**
   * Interval-Value
   */
  intervalValue: number;
}

export interface OrderFragment_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_text_allowedTextScaleWidthOffsetInterval {
  /**
   * Interval-Value
   */
  intervalValue: number;
  /**
   * Min-Value
   */
  minValue: number;
  /**
   * Max-Value
   */
  maxValue: number;
}

export interface OrderFragment_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_text_allowedCharacterGalleries_items {
  /**
   * Id
   */
  id: string;
  /**
   * Name
   */
  name: string;
  /**
   * Title
   */
  title: string;
  /**
   * Preview
   */
  preview: string | null;
  /**
   * Html
   */
  html: string;
}

export interface OrderFragment_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_text_allowedCharacterGalleries {
  /**
   * Id
   */
  id: string;
  isAdvancedUser: boolean;
  /**
   * Items
   */
  items: OrderFragment_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_text_allowedCharacterGalleries_items[];
}

export interface OrderFragment_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_text_allowedParagraphClasses {
  /**
   * Id
   */
  id: string;
  /**
   * Name
   */
  name: string;
}

export interface OrderFragment_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_text {
  /**
   * Default textalignment from template
   */
  defaultTextAdjustment: TextAlignments;
  /**
   * Allowed font sizes
   */
  allowedFontSizes: OrderFragment_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_text_allowedFontSizes;
  /**
   * Allowed font styles
   */
  allowedFontStyles: FontStyles[];
  /**
   * Allowed font weights
   */
  allowedFontWeights: FontWeights[];
  /**
   * Allowed line height sizes
   */
  allowedLineHeights: OrderFragment_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_text_allowedLineHeights;
  /**
   * Allowed font families
   */
  allowedFontFamilies: string[];
  /**
   * Allowed text adjustments
   */
  allowedTextAdjustments: TextAlignments[];
  /**
   * Allowed font size interval
   */
  allowedFontSizeInterval: OrderFragment_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_text_allowedFontSizeInterval;
  /**
   * Allowed line height size interval
   */
  allowedLineHeightInterval: OrderFragment_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_text_allowedLineHeightInterval;
  /**
   * Enable text scale width offset
   */
  isTextScaleWidthOffset: boolean;
  /**
   * Allowed TextScale width offset interval
   */
  allowedTextScaleWidthOffsetInterval: OrderFragment_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_text_allowedTextScaleWidthOffsetInterval | null;
  /**
   * Allowed special characters galleries
   */
  allowedCharacterGalleries: OrderFragment_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_text_allowedCharacterGalleries[];
  /**
   * Allowed number of paragraphClass
   */
  allowedParagraphClasses: OrderFragment_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_text_allowedParagraphClasses[];
  /**
   * Enable to change font size
   */
  isEnabledFontSize: boolean;
  /**
   * Enable to change font style
   */
  isEnabledFontStyle: boolean;
  /**
   * Enable to change line height
   */
  isEnabledLineHeight: boolean;
  /**
   * Enable to change font family
   */
  isEnabledFontFamily: boolean;
  /**
   * Enable to change font weights
   */
  isEnabledFontWeight: boolean;
  /**
   * Enable to change letter spacing
   */
  isEnabledLetterSpacing: boolean;
  /**
   * Enable to advanced settings for the admin/support
   */
  isEnabledAdvancedSettings: boolean;
  /**
   * Enable to change letter spacing
   */
  isEnabledTextScaleWidthTolerance: boolean;
  /**
   * Enable gallery option
   */
  isEnabledGallery: boolean;
}

export interface OrderFragment_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_image_adjustment_brightness {
  /**
   * Interval-Value
   */
  intervalValue: number;
  /**
   * Max-Value
   */
  maxValue: number;
  /**
   * Min-Value
   */
  minValue: number;
  /**
   * Type of unit
   */
  type: RelativeTypes;
}

export interface OrderFragment_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_image_adjustment_contrast {
  /**
   * Interval-Value
   */
  intervalValue: number;
  /**
   * Max-Value
   */
  maxValue: number;
  /**
   * Min-Value
   */
  minValue: number;
  /**
   * Type of unit
   */
  type: RelativeTypes;
}

export interface OrderFragment_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_image_adjustment_rotate {
  /**
   * Interval-Value
   */
  intervalValue: number;
  /**
   * Max-Value
   */
  maxValue: number;
  /**
   * Min-Value
   */
  minValue: number;
  /**
   * Type of unit
   */
  type: RelativeTypes;
}

export interface OrderFragment_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_image_adjustment {
  brightness: OrderFragment_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_image_adjustment_brightness;
  contrast: OrderFragment_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_image_adjustment_contrast;
  rotate: OrderFragment_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_image_adjustment_rotate;
}

export interface OrderFragment_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_image_mask {
  imageUrl: string | null;
}

export interface OrderFragment_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_image {
  /**
   * Min image height
   */
  minImageHeight: number;
  /**
   * Max image height
   */
  maxImageHeight: number;
  /**
   * Filter by gallery image category
   */
  filterGalleryImageAttributeInternalIds: number[];
  /**
   * Filter by not gallery image category
   */
  filterGalleryImageAttributeNotInternalIds: number[];
  /**
   * Filter by not gallery image category
   */
  galleryImageOption: string;
  /**
   * Filter by not gallery image category
   */
  documentFormatId: string;
  /**
   * Is the user enabled to switch to free size
   */
  isEnabledFreeSize: boolean;
  /**
   * Is the user enabled to upload images
   */
  isEnabledUpload: boolean;
  /**
   * Gallery is enabled
   */
  isEnabledGallery: boolean;
  /**
   * Resize is enabled
   */
  isEnabledResize: boolean;
  adjustment: OrderFragment_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_image_adjustment | null;
  mask: OrderFragment_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_image_mask | null;
}

export interface OrderFragment_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_help {
  html: string | null;
  hoverHtml: string | null;
  supportHtml: string | null;
}

export interface OrderFragment_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor {
  /**
   * Type of editor (Image, Text, Separator)
   */
  type: EditModelAdvertAreaContentEditorTypes;
  /**
   * SubType of editor
   */
  behaviour: EditModelAdvertAreaContentEditorBehaviours;
  /**
   * Access properties
   */
  access: OrderFragment_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_access;
  /**
   * Common parameters
   */
  common: OrderFragment_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_common;
  /**
   * Text parameters
   */
  text: OrderFragment_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_text | null;
  /**
   * Image parameters
   */
  image: OrderFragment_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_image | null;
  /**
   * Help properties
   */
  help: OrderFragment_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_help | null;
}

export interface OrderFragment_mediaOrder_edit_editModelAdvert_basePage_areas_content {
  /**
   * Validate errors
   */
  validateErrors: OrderFragment_mediaOrder_edit_editModelAdvert_basePage_areas_content_validateErrors[];
  value: OrderFragment_mediaOrder_edit_editModelAdvert_basePage_areas_content_value;
  editor: OrderFragment_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor | null;
}

export interface OrderFragment_mediaOrder_edit_editModelAdvert_basePage_areas_style_margin {
  /**
   * Top
   */
  top: number | null;
  /**
   * Left
   */
  left: number | null;
  /**
   * Right
   */
  right: number | null;
  /**
   * Bottom
   */
  bottom: number | null;
}

export interface OrderFragment_mediaOrder_edit_editModelAdvert_basePage_areas_style_content_size {
  /**
   * Type
   */
  type: UnitTypes;
  /**
   * Min Width
   */
  minWidth: number | null;
  /**
   * Min Height
   */
  minHeight: number | null;
  /**
   * Max Width
   */
  maxWidth: number | null;
  /**
   * Max Height
   */
  maxHeight: number | null;
  /**
   * Fix Width
   */
  fixedWidth: number | null;
  /**
   * Fix Height
   */
  fixedHeight: number | null;
}

export interface OrderFragment_mediaOrder_edit_editModelAdvert_basePage_areas_style_content {
  /**
   * Size limit
   */
  size: OrderFragment_mediaOrder_edit_editModelAdvert_basePage_areas_style_content_size | null;
}

export interface OrderFragment_mediaOrder_edit_editModelAdvert_basePage_areas_style_text_fontSize {
  /**
   * Type of unit
   */
  type: UnitTypes;
  /**
   * Value
   */
  value: number;
}

export interface OrderFragment_mediaOrder_edit_editModelAdvert_basePage_areas_style_text_lineHeight {
  /**
   * Type of unit
   */
  type: UnitTypes;
  /**
   * Value
   */
  value: number;
}

export interface OrderFragment_mediaOrder_edit_editModelAdvert_basePage_areas_style_text_lineHeightEmpty {
  /**
   * Type of unit
   */
  type: UnitTypes;
  /**
   * Value
   */
  value: number;
}

export interface OrderFragment_mediaOrder_edit_editModelAdvert_basePage_areas_style_text_letterSpacing {
  /**
   * Type of unit
   */
  type: UnitTypes;
  /**
   * Value
   */
  value: number;
}

export interface OrderFragment_mediaOrder_edit_editModelAdvert_basePage_areas_style_text_textScaleWidthOffset {
  /**
   * Type
   */
  type: RelativeTypes;
  /**
   * Value
   */
  value: number;
}

export interface OrderFragment_mediaOrder_edit_editModelAdvert_basePage_areas_style_text_textScaleWidth {
  /**
   * Type
   */
  type: RelativeTypes;
  /**
   * Value
   */
  value: number;
}

export interface OrderFragment_mediaOrder_edit_editModelAdvert_basePage_areas_style_text {
  fontFamily: string;
  /**
   * Font-size
   */
  fontSize: OrderFragment_mediaOrder_edit_editModelAdvert_basePage_areas_style_text_fontSize | null;
  /**
   * Line height
   */
  lineHeight: OrderFragment_mediaOrder_edit_editModelAdvert_basePage_areas_style_text_lineHeight | null;
  /**
   * Line height for empty rows
   */
  lineHeightEmpty: OrderFragment_mediaOrder_edit_editModelAdvert_basePage_areas_style_text_lineHeightEmpty | null;
  /**
   * Letter spacing
   */
  letterSpacing: OrderFragment_mediaOrder_edit_editModelAdvert_basePage_areas_style_text_letterSpacing | null;
  textScaleWidthOffset: OrderFragment_mediaOrder_edit_editModelAdvert_basePage_areas_style_text_textScaleWidthOffset;
  textScaleWidth: OrderFragment_mediaOrder_edit_editModelAdvert_basePage_areas_style_text_textScaleWidth;
  fontStyle: FontStyles | null;
  fontWeight: FontWeights | null;
}

export interface OrderFragment_mediaOrder_edit_editModelAdvert_basePage_areas_style {
  /**
   * margins
   */
  margin: OrderFragment_mediaOrder_edit_editModelAdvert_basePage_areas_style_margin | null;
  content: OrderFragment_mediaOrder_edit_editModelAdvert_basePage_areas_style_content;
  /**
   * Text settings
   */
  text: OrderFragment_mediaOrder_edit_editModelAdvert_basePage_areas_style_text;
}

export interface OrderFragment_mediaOrder_edit_editModelAdvert_basePage_areas_rectangle {
  /**
   * X coordinate
   */
  x: number;
  /**
   * Y coordinate
   */
  y: number;
  /**
   * Width
   */
  w: number;
  /**
   * Width
   */
  h: number;
}

export interface OrderFragment_mediaOrder_edit_editModelAdvert_basePage_areas {
  /**
   * Id of the blockeditor
   */
  id: string;
  /**
   * Parameters of the blockeditor
   */
  header: string;
  content: OrderFragment_mediaOrder_edit_editModelAdvert_basePage_areas_content;
  style: OrderFragment_mediaOrder_edit_editModelAdvert_basePage_areas_style;
  rectangle: OrderFragment_mediaOrder_edit_editModelAdvert_basePage_areas_rectangle | null;
}

export interface OrderFragment_mediaOrder_edit_editModelAdvert_basePage {
  areas: (OrderFragment_mediaOrder_edit_editModelAdvert_basePage_areas | null)[] | null;
}

export interface OrderFragment_mediaOrder_edit_editModelAdvert {
  /**
   * If UpdateOrdersContent is avaiable
   */
  isEnabledUpdateOrdersContent: boolean;
  basePage: OrderFragment_mediaOrder_edit_editModelAdvert_basePage;
}

export interface OrderFragment_mediaOrder_edit_history {
  /**
   * How many steps it is possible to go back in history
   */
  countStepBack: number;
}

export interface OrderFragment_mediaOrder_edit {
  /**
   * Texts for editing the order
   */
  userInterface: OrderFragment_mediaOrder_edit_userInterface;
  resources: OrderFragment_mediaOrder_edit_resources | null;
  editModelAdvert: OrderFragment_mediaOrder_edit_editModelAdvert | null;
  history: OrderFragment_mediaOrder_edit_history;
}

export interface OrderFragment_mediaOrder_preview_material_basePage_size {
  /**
   * Width
   */
  width: number;
  /**
   * Height
   */
  height: number;
}

export interface OrderFragment_mediaOrder_preview_material_basePage {
  size: OrderFragment_mediaOrder_preview_material_basePage_size | null;
}

export interface OrderFragment_mediaOrder_preview_material_pages_png {
  /**
   * Url till pngfile
   */
  url: string;
}

export interface OrderFragment_mediaOrder_preview_material_pages {
  /**
   * Png material
   */
  png: OrderFragment_mediaOrder_preview_material_pages_png;
}

export interface OrderFragment_mediaOrder_preview_material {
  /**
   * base page
   */
  basePage: OrderFragment_mediaOrder_preview_material_basePage;
  /**
   * pages
   */
  pages: (OrderFragment_mediaOrder_preview_material_pages | null)[];
}

export interface OrderFragment_mediaOrder_preview_price_customer {
  /**
   * How much the product is pricing for the scope in the contract
   */
  priceContract: any | null;
  /**
   * How much the product is pricing for the order if it has been override the contract
   */
  priceContractOverride: any | null;
}

export interface OrderFragment_mediaOrder_preview_price_endCustomer {
  /**
   * Whats pay for the order and rounded
   */
  pricePayRounded: any | null;
}

export interface OrderFragment_mediaOrder_preview_price {
  /**
   * Currency
   */
  currency: string;
  /**
   * Customer view of the price
   */
  customer: OrderFragment_mediaOrder_preview_price_customer;
  /**
   * EndCustomer view of the price
   */
  endCustomer: OrderFragment_mediaOrder_preview_price_endCustomer;
}

export interface OrderFragment_mediaOrder_preview {
  /**
   * material
   */
  material: OrderFragment_mediaOrder_preview_material;
  /**
   * price of the order
   */
  price: OrderFragment_mediaOrder_preview_price | null;
}

export interface OrderFragment_mediaOrder_media {
  /**
   * The id of the media
   */
  id: string | null;
  /**
   * The friendlyName of the media
   */
  friendlyName: string;
}

export interface OrderFragment_mediaOrder_status {
  /**
   * Color for vieweing the customer
   */
  color: StatusColors;
  /**
   * ProductStatus enum for the status
   */
  status: OrderStatus;
}

export interface OrderFragment_mediaOrder {
  /**
   * Time when last sending the booking to media
   */
  timeBookingStop: any | null;
  /**
   * Locked
   */
  isLocked: boolean;
  /**
   * times when the order will be published
   */
  publishTimes: (OrderFragment_mediaOrder_publishTimes | null)[];
  /**
   * documentformat of the order
   */
  documentFormat: OrderFragment_mediaOrder_documentFormat;
  /**
   * Publish conversation settings for the media
   */
  publishConversation: OrderFragment_mediaOrder_publishConversation;
  /**
   * Parallell publish channels
   */
  publishChannels: OrderFragment_mediaOrder_publishChannels;
  /**
   * information for the editors
   */
  edit: OrderFragment_mediaOrder_edit;
  /**
   * Preview of the order
   */
  preview: OrderFragment_mediaOrder_preview;
  /**
   * media of the order
   */
  media: OrderFragment_mediaOrder_media;
  /**
   * Status of the order
   */
  status: OrderFragment_mediaOrder_status;
}

export interface OrderFragment {
  /**
   * The id of the mediaorder
   */
  id: string | null;
  /**
   * The Id shown for customer
   */
  customerFriendlyId: string;
  /**
   * Order is deleted
   */
  isDeleted: boolean;
  /**
   * document of the order
   */
  document: OrderFragment_document;
  client: OrderFragment_client | null;
  alerting: OrderFragment_alerting;
  /**
   * mediaOrder properties if that kind of order
   */
  mediaOrder: OrderFragment_mediaOrder | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: OrderFragmentNoValue
// ====================================================

export interface OrderFragmentNoValue_document {
  /**
   * Id of the document
   */
  id: string | null;
}

export interface OrderFragmentNoValue_mediaOrder_publishTimes {
  /**
   * Date when the order will be published
   */
  time: any;
}

export interface OrderFragmentNoValue_mediaOrder_documentFormat {
  /**
   * Id of the documentformat
   */
  id: string;
  /**
   * The name of the documentformat
   */
  name: string;
}

export interface OrderFragmentNoValue_mediaOrder_edit_editModelAdvert_basePage_areas_content_value {
  /**
   * Id for the content-value
   */
  id: string | null;
  /**
   * Is it content
   */
  hasContent: boolean;
  /**
   * Is content visible
   */
  visible: boolean;
}

export interface OrderFragmentNoValue_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_text {
  /**
   * Allowed text adjustments
   */
  allowedTextAdjustments: TextAlignments[];
  /**
   * Default textalignment from template
   */
  defaultTextAdjustment: TextAlignments;
}

export interface OrderFragmentNoValue_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_image {
  /**
   * Min image height
   */
  minImageHeight: number;
  /**
   * Max image height
   */
  maxImageHeight: number;
}

export interface OrderFragmentNoValue_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor {
  /**
   * Type of editor (Image, Text, Separator)
   */
  type: EditModelAdvertAreaContentEditorTypes;
  /**
   * SubType of editor
   */
  behaviour: EditModelAdvertAreaContentEditorBehaviours;
  /**
   * Text parameters
   */
  text: OrderFragmentNoValue_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_text | null;
  /**
   * Image parameters
   */
  image: OrderFragmentNoValue_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_image | null;
}

export interface OrderFragmentNoValue_mediaOrder_edit_editModelAdvert_basePage_areas_content {
  value: OrderFragmentNoValue_mediaOrder_edit_editModelAdvert_basePage_areas_content_value;
  editor: OrderFragmentNoValue_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor | null;
}

export interface OrderFragmentNoValue_mediaOrder_edit_editModelAdvert_basePage_areas_rectangle {
  /**
   * X coordinate
   */
  x: number;
  /**
   * Y coordinate
   */
  y: number;
  /**
   * Width
   */
  w: number;
  /**
   * Width
   */
  h: number;
}

export interface OrderFragmentNoValue_mediaOrder_edit_editModelAdvert_basePage_areas {
  /**
   * Id of the blockeditor
   */
  id: string;
  /**
   * Parameters of the blockeditor
   */
  header: string;
  content: OrderFragmentNoValue_mediaOrder_edit_editModelAdvert_basePage_areas_content;
  rectangle: OrderFragmentNoValue_mediaOrder_edit_editModelAdvert_basePage_areas_rectangle | null;
}

export interface OrderFragmentNoValue_mediaOrder_edit_editModelAdvert_basePage {
  areas: (OrderFragmentNoValue_mediaOrder_edit_editModelAdvert_basePage_areas | null)[] | null;
}

export interface OrderFragmentNoValue_mediaOrder_edit_editModelAdvert {
  basePage: OrderFragmentNoValue_mediaOrder_edit_editModelAdvert_basePage;
}

export interface OrderFragmentNoValue_mediaOrder_edit_history {
  /**
   * How many steps it is possible to go back in history
   */
  countStepBack: number;
}

export interface OrderFragmentNoValue_mediaOrder_edit {
  editModelAdvert: OrderFragmentNoValue_mediaOrder_edit_editModelAdvert | null;
  history: OrderFragmentNoValue_mediaOrder_edit_history;
}

export interface OrderFragmentNoValue_mediaOrder_preview_material_basePage_size {
  /**
   * Width
   */
  width: number;
  /**
   * Height
   */
  height: number;
}

export interface OrderFragmentNoValue_mediaOrder_preview_material_basePage {
  size: OrderFragmentNoValue_mediaOrder_preview_material_basePage_size | null;
}

export interface OrderFragmentNoValue_mediaOrder_preview_material_pages_png {
  /**
   * Url till pngfile
   */
  url: string;
}

export interface OrderFragmentNoValue_mediaOrder_preview_material_pages {
  /**
   * Png material
   */
  png: OrderFragmentNoValue_mediaOrder_preview_material_pages_png;
}

export interface OrderFragmentNoValue_mediaOrder_preview_material {
  /**
   * base page
   */
  basePage: OrderFragmentNoValue_mediaOrder_preview_material_basePage;
  /**
   * pages
   */
  pages: (OrderFragmentNoValue_mediaOrder_preview_material_pages | null)[];
}

export interface OrderFragmentNoValue_mediaOrder_preview {
  /**
   * material
   */
  material: OrderFragmentNoValue_mediaOrder_preview_material;
}

export interface OrderFragmentNoValue_mediaOrder_media {
  /**
   * The id of the media
   */
  id: string | null;
  /**
   * The name of the media
   */
  name: string;
}

export interface OrderFragmentNoValue_mediaOrder_status {
  /**
   * Color for vieweing the customer
   */
  color: StatusColors;
  /**
   * ProductStatus enum for the status
   */
  status: OrderStatus;
}

export interface OrderFragmentNoValue_mediaOrder {
  /**
   * Time when last sending the booking to media
   */
  timeBookingStop: any | null;
  /**
   * Locked
   */
  isLocked: boolean;
  /**
   * times when the order will be published
   */
  publishTimes: (OrderFragmentNoValue_mediaOrder_publishTimes | null)[];
  /**
   * documentformat of the order
   */
  documentFormat: OrderFragmentNoValue_mediaOrder_documentFormat;
  /**
   * information for the editors
   */
  edit: OrderFragmentNoValue_mediaOrder_edit;
  /**
   * Preview of the order
   */
  preview: OrderFragmentNoValue_mediaOrder_preview;
  /**
   * media of the order
   */
  media: OrderFragmentNoValue_mediaOrder_media;
  /**
   * Status of the order
   */
  status: OrderFragmentNoValue_mediaOrder_status;
}

export interface OrderFragmentNoValue {
  /**
   * The id of the mediaorder
   */
  id: string | null;
  /**
   * The Id shown for customer
   */
  customerFriendlyId: string;
  /**
   * document of the order
   */
  document: OrderFragmentNoValue_document;
  /**
   * mediaOrder properties if that kind of order
   */
  mediaOrder: OrderFragmentNoValue_mediaOrder | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: PreviewImageFragment
// ====================================================

export interface PreviewImageFragment_material_basePage_size {
  /**
   * Width
   */
  width: number;
  /**
   * Height
   */
  height: number;
}

export interface PreviewImageFragment_material_basePage {
  size: PreviewImageFragment_material_basePage_size | null;
}

export interface PreviewImageFragment_material_pages_png {
  /**
   * Url till pngfile
   */
  url: string;
}

export interface PreviewImageFragment_material_pages {
  /**
   * Png material
   */
  png: PreviewImageFragment_material_pages_png;
}

export interface PreviewImageFragment_material {
  /**
   * base page
   */
  basePage: PreviewImageFragment_material_basePage;
  /**
   * pages
   */
  pages: (PreviewImageFragment_material_pages | null)[];
}

export interface PreviewImageFragment {
  /**
   * material
   */
  material: PreviewImageFragment_material;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: PropertiesFuneralStandardFragment
// ====================================================

export interface PropertiesFuneralStandardFragment {
  /**
   * Id from a another businesssystem (option)
   */
  externalId: string;
  /**
   * Born date for the person in the case
   */
  personBorn: any | null;
  /**
   * Died date for the person in the case
   */
  personDied: any | null;
  /**
   * Funeral date for the person in the case
   */
  personFuneral: any | null;
  /**
   * Lastname when the person in the case was born
   */
  personBornLastname: string | null;
  /**
   * First name of the person in the case
   */
  personFirstname: string;
  /**
   * Last name of the person in the case
   */
  personLastname: string;
  /**
   * Where did the person live
   */
  personCityLive: string;
  /**
   * Informationtext about the funeral
   */
  personFuneralInfoText: string;
  /**
   * Last day for booking the cermony for memory
   */
  memoryCeremonyLastBookingDay: any | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: RectangleFragment
// ====================================================

export interface RectangleFragment_rectangle {
  /**
   * X coordinate
   */
  x: number;
  /**
   * Y coordinate
   */
  y: number;
  /**
   * Width
   */
  w: number;
  /**
   * Width
   */
  h: number;
}

export interface RectangleFragment {
  rectangle: RectangleFragment_rectangle | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: SettingsFragment
// ====================================================

export interface SettingsFragment {
  /**
   * The emailaddress used when sending info to office
   */
  emailOffice: string | null;
  /**
   * The emailaddress used when sending info to customer
   */
  contactPhone: string | null;
  /**
   * The name of the local memory name
   */
  agencyHomepageName: string | null;
  /**
   * The emailaddress used when sending info to customer
   */
  emailCustomer: string | null;
  /**
   * When order is disapproved or approved a receipt is sending to this address
   */
  emailOrderReceipt: string | null;
  /**
   * The emailaddress send to Memoriz
   */
  emailOrderMemoriz: string | null;
  /**
   * Post city
   */
  postAddressCity: string | null;
  /**
   * Post postcode
   */
  postAddressPostcode: string | null;
  /**
   * Post address
   */
  postAddressStreet: string | null;
  orderPriceEndCustomerRule: CustomerSettingOrderPriceEndCustomerRules | null;
  /**
   * On the proof, we shows suggestion of an thanks advert
   */
  isDefaultProofSuggestionThanksAdvert: boolean | null;
  /**
   * On the proof, the casefee will be add on the other others
   */
  isDefaultProofCaseFeeAddOnOrders: boolean | null;
  /**
   * On the proof, the casefee will be hided (not visible)
   */
  isDefaultProofCaseFeeHide: boolean | null;
  /**
   * On the proof, the casefee will be add on the first order
   */
  isDefaultProofCaseFeeAddOnFirstOrder: boolean | null;
  /**
   * Default parallel publish in familjesidan for printadverts
   */
  isDefaultParallelPublishInFamiljesidan: boolean | null;
  /**
   * Default parallel publish in minnesrummet for printadverts
   */
  isDefaultParallelPublishInMinnesrummet: boolean | null;
  /**
   * Default parallel publish in the agency home page for printadverts
   */
  isDefaultParallelPublishInAgencyHomepage: boolean | null;
  /**
   * Default enable MyPage service
   */
  isDefaultMyPageEnabled: boolean | null;
  /**
   * External memoryroom is exposed
   */
  isMemoryRoomUrlEnabled: boolean | null;
  /**
   * Favorite medias
   */
  favoriteMedias: (string | null)[] | null;
  /**
   * Is allowed to change addon
   */
  isAllowUpdateAddOn: boolean | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: UpdateCaseFolderResponseFragment
// ====================================================

export interface UpdateCaseFolderResponseFragment_customer {
  /**
   * The id of customer
   */
  id: string;
  /**
   * The name of customer
   */
  name: string;
}

export interface UpdateCaseFolderResponseFragment_productPackages_productPackage {
  /**
   * Id of the ProductPackage
   */
  id: string;
}

export interface UpdateCaseFolderResponseFragment_productPackages {
  /**
   * Product Package
   */
  productPackage: UpdateCaseFolderResponseFragment_productPackages_productPackage;
}

export interface UpdateCaseFolderResponseFragment_propertiesFuneralStandard {
  /**
   * Id from a another businesssystem (option)
   */
  externalId: string;
  /**
   * Born date for the person in the case
   */
  personBorn: any | null;
  /**
   * Died date for the person in the case
   */
  personDied: any | null;
  /**
   * Funeral date for the person in the case
   */
  personFuneral: any | null;
  /**
   * Lastname when the person in the case was born
   */
  personBornLastname: string | null;
  /**
   * First name of the person in the case
   */
  personFirstname: string;
  /**
   * Last name of the person in the case
   */
  personLastname: string;
  /**
   * Where did the person live
   */
  personCityLive: string;
  /**
   * Informationtext about the funeral
   */
  personFuneralInfoText: string;
  /**
   * Last day for booking the cermony for memory
   */
  memoryCeremonyLastBookingDay: any | null;
}

export interface UpdateCaseFolderResponseFragment {
  /**
   * The id of the caseFolder
   */
  id: string | null;
  /**
   * The internal id of the caseFolder
   */
  internalId: number;
  /**
   * The name of the caseFolder
   */
  name: string;
  customer: UpdateCaseFolderResponseFragment_customer;
  /**
   * ProductPackages of the caseFolder
   */
  productPackages: (UpdateCaseFolderResponseFragment_productPackages | null)[];
  propertiesFuneralStandard: UpdateCaseFolderResponseFragment_propertiesFuneralStandard;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: userInterfaceFragment
// ====================================================

export interface userInterfaceFragment {
  editStatusColor: EditUIEditStatusColor;
  editStatusText: string | null;
  publishButtonText: string | null;
  editStatusIcon: EditUIEditStatusIcon;
  isUnPublishButton: boolean;
  unPublishButtonText: string | null;
  isPublishButton: boolean;
  isLockButton: boolean;
  lockButtonText: string | null;
  isUnlockButton: boolean;
  unlockButtonText: string | null;
  isReactivateButton: boolean;
  reactivateButtonText: string | null;
  isSetExhibitOrder: boolean;
  setExhibitOrderText: string | null;
  isUpdateCustomerDocumentContentInitiator: boolean;
  updateCustomerDocumentContentInitiatorText: string | null;
  /**
   * Size limit
   */
  orderEntityNameSingular: string | null;
  /**
   * Size limit
   */
  orderEntityNamePlural: string | null;
  /**
   * Size limit
   */
  orderEntityNameSingularDetermined: string | null;
  /**
   * Size limit
   */
  orderEntityNamePluralDetermined: string | null;
  isCopyFuneralInfoToMemoryRoom: boolean;
  copyFuneralInfoToMemoryRoomText: string | null;
  isNetnoticeEnabled: boolean;
  isNetnoticeSupported: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: OrderInitiatorOptionFragment
// ====================================================

export interface OrderInitiatorOptionFragment_orderInitiatorId {
  /**
   * Id to media
   */
  mediaId: string | null;
  /**
   * Id to documentFormatInitiatorId
   */
  documentFormatInitiatorId: string | null;
}

export interface OrderInitiatorOptionFragment_columns {
  text: string;
}

export interface OrderInitiatorOptionFragment {
  selector: string;
  name: string | null;
  imageUrl: string | null;
  isSelected: boolean;
  isEnabled: boolean;
  hoverText: string | null;
  orderInitiatorId: OrderInitiatorOptionFragment_orderInitiatorId | null;
  columns: OrderInitiatorOptionFragment_columns[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: OrderInitiatorViewFragment
// ====================================================

export interface OrderInitiatorViewFragment_orderInitiatorId {
  /**
   * Id to media
   */
  mediaId: string | null;
  /**
   * Id to documentFormatInitiatorId
   */
  documentFormatInitiatorId: string | null;
  /**
   * Id to custmoerDocumentFormatInitiatorId
   */
  customerDocumentFormatInitiatorId: string | null;
}

export interface OrderInitiatorViewFragment_columns {
  name: string;
}

export interface OrderInitiatorViewFragment {
  orderInitiatorId: OrderInitiatorViewFragment_orderInitiatorId | null;
  isFilterEnabled: boolean;
  selectType: OrderInitiatorSelectTypes;
  layout: OrderInitiatorSelectLayout;
  view: OrderInitiatorSelectViews;
  header: string;
  isNavigateToEdit: boolean;
  navigateRoute: string | null;
  jsInvokeStatement: string | null;
  columns: OrderInitiatorViewFragment_columns[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum ApplicationClientApps {
  NONE = "NONE",
  TAPS_4 = "TAPS_4",
  TAPS_4_MEDIA_BOOKING = "TAPS_4_MEDIA_BOOKING",
  TAPS_5 = "TAPS_5",
  TAPS_PK_4 = "TAPS_PK_4",
  UNKNOWN = "UNKNOWN",
}

/**
 * What kind of documentfolders will be
 */
export enum CaseFolderCustomerScope {
  Company = "Company",
  Customer = "Customer",
  Default = "Default",
  Office = "Office",
}

/**
 * Error when adding customer
 */
export enum CustomerInputErrors {
  CUSTOMER_ID_IS_MISSING = "CUSTOMER_ID_IS_MISSING",
  CUSTOMER_ID_IS_USED_IN_CREATE = "CUSTOMER_ID_IS_USED_IN_CREATE",
  NONE = "NONE",
  USERNAME_ALREADY_TAKEN = "USERNAME_ALREADY_TAKEN",
  USERNAME_NOT_VALID = "USERNAME_NOT_VALID",
  USER_ID_IS_MISSING = "USER_ID_IS_MISSING",
  USER_ID_IS_USED_IN_CREATE = "USER_ID_IS_USED_IN_CREATE",
  USER_IS_MISSING = "USER_IS_MISSING",
}

/**
 * Customer scope
 */
export enum CustomerScope {
  ALL = "ALL",
  COMPANY = "COMPANY",
  COMPANY_GROUP = "COMPANY_GROUP",
  CUSTOMER = "CUSTOMER",
  DEFAULT = "DEFAULT",
  OFFICE = "OFFICE",
}

/**
 * Rules for merging price
 */
export enum CustomerSettingOrderPriceEndCustomerRules {
  ORDER = "ORDER",
  ORDER_AND_SERVICES = "ORDER_AND_SERVICES",
  ORDER_AND_SERVICES_IF_MAIN = "ORDER_AND_SERVICES_IF_MAIN",
}

export enum EditModelAdvertAreaContentEditorAccessStatuses {
  EXCLUSIVE = "EXCLUSIVE",
  NONE = "NONE",
  NORMAL = "NORMAL",
}

export enum EditModelAdvertAreaContentEditorBehaviours {
  GALLERY_IMAGE = "GALLERY_IMAGE",
  GALLERY_SEPARATOR = "GALLERY_SEPARATOR",
  IMAGE = "IMAGE",
  NONE = "NONE",
  ORDER_IMAGE = "ORDER_IMAGE",
  SEPARATOR = "SEPARATOR",
  TEXT_HTML = "TEXT_HTML",
  TEXT_HTML_FOOTER = "TEXT_HTML_FOOTER",
  TEXT_HTML_INGRESS = "TEXT_HTML_INGRESS",
  TEXT_HTML_PERSON = "TEXT_HTML_PERSON",
  TEXT_HTML_PERSON_BORN_DATE = "TEXT_HTML_PERSON_BORN_DATE",
  TEXT_HTML_PERSON_BORN_NAME = "TEXT_HTML_PERSON_BORN_NAME",
  TEXT_HTML_PERSON_DIED_DATE = "TEXT_HTML_PERSON_DIED_DATE",
  TEXT_HTML_RELATIVES = "TEXT_HTML_RELATIVES",
  TEXT_HTML_VERSE = "TEXT_HTML_VERSE",
  TEXT_PERSON_BORN = "TEXT_PERSON_BORN",
  TEXT_PERSON_DIED = "TEXT_PERSON_DIED",
  TEXT_PERSON_NAME = "TEXT_PERSON_NAME",
}

export enum EditModelAdvertAreaContentEditorTypes {
  CUSTOM = "CUSTOM",
  GALLERY_IMAGE = "GALLERY_IMAGE",
  NONE = "NONE",
  PROPS = "PROPS",
  SEPARATOR = "SEPARATOR",
  TEXT_HTML = "TEXT_HTML",
}

export enum EditModelAdvertAreaContentItemFieldItemEditors {
  CALENDER = "CALENDER",
  NONE = "NONE",
  TEXT_SINGLE_LINE = "TEXT_SINGLE_LINE",
}

export enum EditModelAdvertAreaContentItemFieldItemTypes {
  DATE_TIME = "DATE_TIME",
  NONE = "NONE",
  STRING = "STRING",
}

export enum EditModelAdvertAreaContentItemSeparatorTypes {
  HORIZONTAL_LINE = "HORIZONTAL_LINE",
  IMAGE = "IMAGE",
  NONE = "NONE",
}

export enum EditModelAdvertAreaContentRepository {
  GALLERY = "GALLERY",
  NONE = "NONE",
  ORDER = "ORDER",
  TEMPLATE = "TEMPLATE",
  USER = "USER",
}

/**
 * Type of text
 */
export enum EditModelAdvertAreaContentTextItemTypes {
  NONE = "NONE",
  TEXT = "TEXT",
}

export enum EditModelAdvertAreaContentValidateErrors {
  FAMILY_FE_BLOCKS_OVERFLOW = "FAMILY_FE_BLOCKS_OVERFLOW",
  FORMATTING_ERROR = "FORMATTING_ERROR",
  IMAGE_DENIED = "IMAGE_DENIED",
  IMAGE_VERIFYING = "IMAGE_VERIFYING",
  IS_DEMO_IMAGE = "IS_DEMO_IMAGE",
  IS_DEMO_TEXT = "IS_DEMO_TEXT",
  TEXT_HASHTAG_WARNING = "TEXT_HASHTAG_WARNING",
}

export enum EditModelMemoryRoomFuneralCermonyTypes {
  FUNERAL = "FUNERAL",
  MEMORIAL = "MEMORIAL",
  NONE = "NONE",
}

export enum EditModelMemoryRoomPublicationAccess {
  NONE = "NONE",
  PRIVATE = "PRIVATE",
  PUBLIC = "PUBLIC",
}

export enum EditModelMemoryRoomPublicationStatus {
  NONE = "NONE",
  PRODUCTION = "PRODUCTION",
}

export enum EditModelMemoryRoomPublicationTargets {
  DEFAULT = "DEFAULT",
  NONE = "NONE",
}

/**
 * Type of editor
 */
export enum EditModelTypes {
  ADVERT = "ADVERT",
  DEFAULT = "DEFAULT",
  LIVE_STREAM = "LIVE_STREAM",
  MEMORY_ROOM = "MEMORY_ROOM",
  PRINT = "PRINT",
  UNKNOW = "UNKNOW",
}

export enum EditUIEditStatusColor {
  BLACK = "BLACK",
  BLUE = "BLUE",
  GREEN = "GREEN",
  RED = "RED",
  YELLOW = "YELLOW",
}

export enum EditUIEditStatusIcon {
  LOCKED = "LOCKED",
  NONE = "NONE",
  UNLOCKED = "UNLOCKED",
}

export enum FontStyles {
  BACKSLANT = "BACKSLANT",
  ITALIC = "ITALIC",
  NORMAL = "NORMAL",
  OBELIC = "OBELIC",
}

export enum FontWeights {
  BLACK = "BLACK",
  BOLD = "BOLD",
  EXTRA_BOLD = "EXTRA_BOLD",
  LIGHT = "LIGHT",
  NORMAL = "NORMAL",
  SEMI_BOLD = "SEMI_BOLD",
  THIN = "THIN",
}

export enum LogUserEvents {
  CASE_FOLDER_CREATE = "CASE_FOLDER_CREATE",
  CASE_FOLDER_DELETE = "CASE_FOLDER_DELETE",
  CASE_FOLDER_EXCEPTION = "CASE_FOLDER_EXCEPTION",
  CASE_FOLDER_SERVICE_ORDER_SYNC_EXCEPTION = "CASE_FOLDER_SERVICE_ORDER_SYNC_EXCEPTION",
  CASE_FOLDER_UPDATE = "CASE_FOLDER_UPDATE",
  DOCUMENT_CREATE = "DOCUMENT_CREATE",
  DOCUMENT_DELETE = "DOCUMENT_DELETE",
  DOCUMENT_UPDATE = "DOCUMENT_UPDATE",
  LIVE_STREAM_CREATE = "LIVE_STREAM_CREATE",
  LIVE_STREAM_CREATE_UNSPECTED_ERROR = "LIVE_STREAM_CREATE_UNSPECTED_ERROR",
  LIVE_STREAM_DELETE = "LIVE_STREAM_DELETE",
  MEDIA_ORDER_CHANGE_PRICE_MANUALLY = "MEDIA_ORDER_CHANGE_PRICE_MANUALLY",
  MEDIA_ORDER_CHANGE_STATUS = "MEDIA_ORDER_CHANGE_STATUS",
  MEDIA_ORDER_CHANGE_STATUS_FORCE = "MEDIA_ORDER_CHANGE_STATUS_FORCE",
  MEDIA_ORDER_CONTENT_UPDATE = "MEDIA_ORDER_CONTENT_UPDATE",
  MEDIA_ORDER_CREATE = "MEDIA_ORDER_CREATE",
  MEDIA_ORDER_DELETE = "MEDIA_ORDER_DELETE",
  MEDIA_ORDER_EXPORT_UNSPECTED_ERROR = "MEDIA_ORDER_EXPORT_UNSPECTED_ERROR",
  MEDIA_ORDER_LIVE_STREAM_UPDATE = "MEDIA_ORDER_LIVE_STREAM_UPDATE",
  MEDIA_ORDER_LOCK = "MEDIA_ORDER_LOCK",
  MEDIA_ORDER_LOCK_EXCEPTION = "MEDIA_ORDER_LOCK_EXCEPTION",
  MEDIA_ORDER_MEMORIZ_EXPORT_SUCCESS = "MEDIA_ORDER_MEMORIZ_EXPORT_SUCCESS",
  MEDIA_ORDER_PUBLISH = "MEDIA_ORDER_PUBLISH",
  MEDIA_ORDER_PUBLISH_CHANNEL_UPDATE = "MEDIA_ORDER_PUBLISH_CHANNEL_UPDATE",
  MEDIA_ORDER_PUBLISH_MEMORIZ_UNSPECTED_ERROR = "MEDIA_ORDER_PUBLISH_MEMORIZ_UNSPECTED_ERROR",
  MEDIA_ORDER_PUBLISH_TIME_UPDATE = "MEDIA_ORDER_PUBLISH_TIME_UPDATE",
  MEDIA_ORDER_REACTIVATE_UN_PUBLISH = "MEDIA_ORDER_REACTIVATE_UN_PUBLISH",
  MEDIA_ORDER_SENT_AND_WAIT_MEDIA_AUTOMATICALLY_APPROVE = "MEDIA_ORDER_SENT_AND_WAIT_MEDIA_AUTOMATICALLY_APPROVE",
  MEDIA_ORDER_SENT_AND_WAIT_MEDIA_MANUALLY_APPROVE = "MEDIA_ORDER_SENT_AND_WAIT_MEDIA_MANUALLY_APPROVE",
  MEDIA_ORDER_SENT_IN_PRODUCTION = "MEDIA_ORDER_SENT_IN_PRODUCTION",
  MEDIA_ORDER_SENT_MEDIA_REPLY_DENIED = "MEDIA_ORDER_SENT_MEDIA_REPLY_DENIED",
  MEDIA_ORDER_UNLOCK = "MEDIA_ORDER_UNLOCK",
  MEDIA_ORDER_UNLOCK_EXCEPTION = "MEDIA_ORDER_UNLOCK_EXCEPTION",
  MEDIA_ORDER_UNSPECTED_ERROR = "MEDIA_ORDER_UNSPECTED_ERROR",
  MEDIA_ORDER_UN_PUBLISH = "MEDIA_ORDER_UN_PUBLISH",
  MEDIA_ORDER_UPDATE = "MEDIA_ORDER_UPDATE",
  NONE = "NONE",
  UNKNOWN = "UNKNOWN",
  USER_LOGIN = "USER_LOGIN",
  USER_LOGOUT = "USER_LOGOUT",
}

export enum MediaOrderPublishhReadyStatus {
  MISSING_PUBLISH_DAYS = "MISSING_PUBLISH_DAYS",
  NONE = "NONE",
  READY = "READY",
  SENDING = "SENDING",
  TO_LATE = "TO_LATE",
  VALIDATION_ERRORS = "VALIDATION_ERRORS",
  WRONG_STATE = "WRONG_STATE",
}

export enum MessageBoxIcons {
  ERROR = "ERROR",
  INFORMATION = "INFORMATION",
  NONE = "NONE",
  WARNING = "WARNING",
}

export enum OrderAlertingType {
  CRITICAL = "CRITICAL",
  NONE = "NONE",
  WARNING = "WARNING",
}

export enum OrderInitiatorSelectLayout {
  DROP_DOWN_LINE = "DROP_DOWN_LINE",
  IMAGE_TEXT_THUMBNAIL = "IMAGE_TEXT_THUMBNAIL",
  IMAGE_TEXT_THUMBNAIL_WITH_SLIDER = "IMAGE_TEXT_THUMBNAIL_WITH_SLIDER",
  IMAGE_TEXT_WITH_DROPDOWN_THUMBNAIL = "IMAGE_TEXT_WITH_DROPDOWN_THUMBNAIL",
  MULTI_DROPDOWN_LINE = "MULTI_DROPDOWN_LINE",
  SUB_DROPDOWN_LINE = "SUB_DROPDOWN_LINE",
  SUB_VIEWS = "SUB_VIEWS",
  TABLE = "TABLE",
  TEXTBOX_LINE = "TEXTBOX_LINE",
}

export enum OrderInitiatorSelectRoutes {
  NORMAL = "NORMAL",
  TEMPLATE = "TEMPLATE",
  THANK_NOTICE_ORDER_PREVIEW = "THANK_NOTICE_ORDER_PREVIEW",
}

export enum OrderInitiatorSelectTypes {
  ASK_CONTINUE = "ASK_CONTINUE",
  ASK_CONTINUE_CREATE = "ASK_CONTINUE_CREATE",
  COMPLETE = "COMPLETE",
  CUSTOMER_DOCUMENT_FORMAT_INITIATOR = "CUSTOMER_DOCUMENT_FORMAT_INITIATOR",
  DOCUMENTFORMAT = "DOCUMENTFORMAT",
  DOCUMENT_FORMAT_ADVERT_CATEGORY = "DOCUMENT_FORMAT_ADVERT_CATEGORY",
  DOCUMENT_FORMAT_COLLECTION = "DOCUMENT_FORMAT_COLLECTION",
  DOCUMENT_FORMAT_INITIATOR = "DOCUMENT_FORMAT_INITIATOR",
  DOCUMENT_TYPE = "DOCUMENT_TYPE",
  FROM_ORDER = "FROM_ORDER",
  IMPORT_PAG_ADVERT = "IMPORT_PAG_ADVERT",
  IMPORT_PAG_ADVERT_LIST = "IMPORT_PAG_ADVERT_LIST",
  MEDIA = "MEDIA",
  PB_CATEGORY = "PB_CATEGORY",
  PB_DOCUMENT_FORMAT = "PB_DOCUMENT_FORMAT",
  PB_FORMAT = "PB_FORMAT",
  PB_THEME = "PB_THEME",
  PRODUCT_CATEGORY = "PRODUCT_CATEGORY",
  ROOT = "ROOT",
  SMEDIA = "SMEDIA",
  TEST = "TEST",
}

export enum OrderInitiatorSelectViews {
  FORMAT = "FORMAT",
  FORMAT_GROUP = "FORMAT_GROUP",
  MEDIA = "MEDIA",
  MEDIA_NEXT = "MEDIA_NEXT",
  NONE = "NONE",
  PRODUCT = "PRODUCT",
  THEME = "THEME",
}

/**
 * Summary status of caseFolder
 */
export enum OrderStatus {
  CANCELED = "CANCELED",
  DELETED = "DELETED",
  EDIT = "EDIT",
  EDIT_CHANGE = "EDIT_CHANGE",
  FAILED = "FAILED",
  NONE = "NONE",
  PRODUCTION = "PRODUCTION",
  PUBLISHED = "PUBLISHED",
  UNSPECIFIED = "UNSPECIFIED",
  WAIT_APPROVAL = "WAIT_APPROVAL",
}

export enum RelativeTypes {
  INTERVAL = "INTERVAL",
  NONE = "NONE",
  PERCENT = "PERCENT",
  VALUE = "VALUE",
}

/**
 * Response Actions
 */
export enum ResponseActions {
  ERROR_MODAL = "ERROR_MODAL",
  NONE = "NONE",
  OPEN_URL = "OPEN_URL",
  TEXT_MODAL = "TEXT_MODAL",
}

export enum SearchOrderConditionTypes {
  AND = "AND",
  AUTO = "AUTO",
  CASE_FOLDER_PERSON_NAME = "CASE_FOLDER_PERSON_NAME",
  CUSTOMER_SCOPES_NAME = "CUSTOMER_SCOPES_NAME",
  MEDIA_NAME = "MEDIA_NAME",
  NONE = "NONE",
  NOT = "NOT",
  OR = "OR",
  ORDER_FRIENDLY_NAME = "ORDER_FRIENDLY_NAME",
}

export enum StatsOrderPeriods {
  ALL = "ALL",
  CUSTOM = "CUSTOM",
  LAST_14 = "LAST_14",
  LAST_MONTH = "LAST_MONTH",
}

export enum StatsOrderSort {
  CASE_FOLDER = "CASE_FOLDER",
  CUSTOMER = "CUSTOMER",
  ORDER_DATE = "ORDER_DATE",
  PRODUCT = "PRODUCT",
}

export enum StatusColors {
  BLUE = "BLUE",
  GRAY = "GRAY",
  GREEN = "GREEN",
  RED = "RED",
  YELLOW = "YELLOW",
}

export enum SystemFlags {
  TAPS_4 = "TAPS_4",
  TAPS_5 = "TAPS_5",
  TAPS_5_EXTERN = "TAPS_5_EXTERN",
}

export enum TextAlignments {
  CENTER = "CENTER",
  CENTER_LEFT = "CENTER_LEFT",
  DEFAULT = "DEFAULT",
  JUSTIFY = "JUSTIFY",
  JUSTIFY_LAST_CENTER = "JUSTIFY_LAST_CENTER",
  JUSTIFY_LAST_LEFT = "JUSTIFY_LAST_LEFT",
  LEFT = "LEFT",
  LEFT_LEFT_RIGHT_ALTERNATE = "LEFT_LEFT_RIGHT_ALTERNATE",
  LEFT_RIGHT_ALTERNATE = "LEFT_RIGHT_ALTERNATE",
  RIGHT = "RIGHT",
}

export enum UnitTypes {
  MILLIMETER = "MILLIMETER",
  NONE = "NONE",
  PIXEL = "PIXEL",
  POINT = "POINT",
}

export interface AEditModelAdvertAreaContentRowInput {
  extra?: string | null;
  columns: EditModelAdvertAreaContentColumnInput[];
}

export interface ActionParameterInput {
  id: string;
  description: string;
  validateRegEx: string;
  value: string;
}

export interface ActionRequestInput {
  actionId: string;
  name?: string | null;
  description?: string | null;
  menuPath?: string | null;
  confirmMessage?: string | null;
  orderId?: string | null;
  documentId?: string | null;
  customerId?: string | null;
  parameters?: ActionParameterInput[] | null;
}

export interface CaseFolderFuneralStandardInput {
  personFirstname: string;
  personLastname: string;
  personBornLastname?: string | null;
  personBorn: string;
  personDied: string;
  personFuneral?: any | null;
  personCityBorn?: string | null;
  personCityLive?: string | null;
  personCityDied?: string | null;
  externalId?: string | null;
  memoryCeremonyLastBookingDay?: string | null;
  personFuneralInfoText?: string | null;
  personFuneralLocationText?: string | null;
}

export interface CaseFolderInput {
  id?: string | null;
  customerId?: string | null;
  propertiesFuneralStandard?: CaseFolderFuneralStandardInput | null;
}

export interface CustomerContractProductEndCustomerInput {
  productId: string;
  price: any;
}

export interface CustomerDocumentContentInitiatorAreaImageInput {
  imageId?: string | null;
  imageThumbnaill100x80Url?: string | null;
}

export interface CustomerDocumentContentInitiatorAreaInput {
  templateAreaId: string;
  text?: CustomerDocumentContentInitiatorAreaTextInput | null;
  image?: CustomerDocumentContentInitiatorAreaImageInput | null;
}

export interface CustomerDocumentContentInitiatorAreaTextInput {
  xmlText: string;
}

export interface CustomerInput {
  id?: string | null;
  officeId?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  email?: string | null;
  primaryUser?: CustomerUserInput | null;
}

export interface CustomerScopeSettingsInput {
  settings: CustomerSettingsInput;
  customerScope: CustomerScope;
}

export interface CustomerSettingsInput {
  agencyHomepageName?: string | null;
  emailOrderReceipt?: string | null;
  emailOrderMemoriz?: string | null;
  emailCustomer?: string | null;
  emailOffice?: string | null;
  emailCompany?: string | null;
  emailInvoicing?: string | null;
  visitingAddressStreet?: string | null;
  visitingAddressCity?: string | null;
  postAddressStreet?: string | null;
  postAddressPostcode?: string | null;
  postAddressCity?: string | null;
  postAddressCountry?: string | null;
  contactPhone?: string | null;
  isDefaultProofSuggestionThanksAdvert?: boolean | null;
  isDefaultProofCaseFeeAddOnOrders?: boolean | null;
  isDefaultProofCaseFeeAddOnFirstOrder?: boolean | null;
  isDefaultProofCaseFeeHide?: boolean | null;
  isDefaultParallelPublishInMinnesrummet?: boolean | null;
  isDefaultParallelPublishInFamiljesidan?: boolean | null;
  isDefaultParallelPublishInAgencyHomepage?: boolean | null;
  isDefaultMyPageEnabled?: boolean | null;
  isDefaultAlisEnabled?: boolean | null;
  partnerSbfId?: number | null;
  isAllowUpdateAddOn?: boolean | null;
  isMemoryRoomUrlEnabled?: boolean | null;
  newsForCustomerGroups?: (string | null)[] | null;
  orderPriceEndCustomerRule?: CustomerSettingOrderPriceEndCustomerRules | null;
  favoriteMedias?: (string | null)[] | null;
}

export interface CustomerUserInput {
  id?: string | null;
  username?: string | null;
  password?: string | null;
  isEnabled?: boolean | null;
}

export interface EditModelAdvertAreaContentColumnInput {
  item: EditModelAdvertAreaContentItemInput;
}

export interface EditModelAdvertAreaContentItemCommonInput {
  blockStyleAreaNameId?: string | null;
  marginLeftRightOffset?: RelativeUnitInput | null;
  margin?: MarginNullableInput | null;
  size?: SizeLimitNullableInput | null;
}

export interface EditModelAdvertAreaContentItemFieldItemInput {
  name: string;
  header?: string | null;
  value?: string | null;
  type?: EditModelAdvertAreaContentItemFieldItemTypes | null;
  editor?: EditModelAdvertAreaContentItemFieldItemEditors | null;
}

export interface EditModelAdvertAreaContentItemImageInput {
  type?: EditModelAdvertAreaContentRepository | null;
  repository?: EditModelAdvertAreaContentRepository | null;
  imageGalleryId?: string | null;
  imagePublicId?: string | null;
  imageThumbnaill100x80Url?: string | null;
  imageId?: string | null;
  defaultHeightMM?: number | null;
  heightMM?: number | null;
  isSizeFree?: boolean | null;
  uploadData?: string | null;
  uploadFilename?: string | null;
  uploadDataSource?: string | null;
  uploadComment?: string | null;
  uploadPreferedHeightMM?: number | null;
  resolution?: number | null;
}

export interface EditModelAdvertAreaContentItemInput {
  common?: EditModelAdvertAreaContentItemCommonInput | null;
  text?: EditModelAdvertAreaContentItemTextInput | null;
  image?: EditModelAdvertAreaContentItemImageInput | null;
  separator?: EditModelAdvertAreaContentItemSeparatorInput | null;
  fields?: (EditModelAdvertAreaContentItemFieldItemInput | null)[] | null;
  isDemoValue?: boolean | null;
}

export interface EditModelAdvertAreaContentItemSeparatorInput {
  type: EditModelAdvertAreaContentItemSeparatorTypes;
  repository?: EditModelAdvertAreaContentRepository | null;
  imageId?: string | null;
  widthMM?: number | null;
  heightMM?: number | null;
}

export interface EditModelAdvertAreaContentItemTextInput {
  xmlText?: string | null;
  isXmlTextReadonly?: boolean | null;
  plainText?: string | null;
  lines?: (EditModelAdvertAreaContentItemTextParagraphInput | null)[] | null;
  textNoWrap?: boolean | null;
  fontFamily?: string | null;
  type?: EditModelAdvertAreaContentTextItemTypes | null;
  textAlignment?: TextAlignments | null;
  fontSize?: ValueUnitInput | null;
  lineHeight?: ValueUnitInput | null;
  lineHeightEmpty?: ValueUnitInput | null;
  letterSpacing?: ValueUnitInput | null;
  fontStyle?: FontStyles | null;
  fontWeight?: FontWeights | null;
  textScaleWidth?: RelativeUnitInput | null;
  textScaleHeight?: RelativeUnitInput | null;
  textScaleWidthOffset?: RelativeUnitInput | null;
  textScaleWidthTolerance?: MinMaxOptRelativeUnitInput | null;
}

export interface EditModelAdvertAreaContentItemTextParagraphInput {
  text: string;
  class?: string | null;
}

export interface EditModelAdvertAreaContentValueInput {
  id?: string | null;
  templateAreaId?: string | null;
  visible: boolean;
  hasContent: boolean;
  isEditorVisible?: boolean | null;
  rows: AEditModelAdvertAreaContentRowInput[];
}

export interface EditModelInput {
  editModelAdvertAreaContentValues?: (EditModelAdvertAreaContentValueInput | null)[] | null;
  editModelMemoryRoom?: EditModelMemoryRoomInput | null;
  editModelLiveStream?: EditModelLiveStreamInput | null;
}

export interface EditModelLiveStreamInput {
  waitImageBase64?: string | null;
  waitImageFilename?: string | null;
  ceremonyAgendaBase64?: string | null;
  ceremonyAgendaFilename?: string | null;
}

export interface EditModelMemoryRoomCaseInput {
  personFirstname?: string | null;
  personLastname?: string | null;
  personTimeBorn?: NullableDateInput | null;
  personTimeDied?: NullableDateInput | null;
  personBornLastname?: string | null;
  personCityDied?: string | null;
}

export interface EditModelMemoryRoomContentFileInput {
  id: string;
  name: string;
  purpose: string;
  combineKey: string;
  dataLength: number;
}

export interface EditModelMemoryRoomContentFundInput {
  id: string;
  name?: string | null;
}

export interface EditModelMemoryRoomContentImageInput {
  id?: string | null;
  name?: string | null;
  inputContentBase64Encoded?: string | null;
}

export interface EditModelMemoryRoomContentsInput {
  welcomeText?: string | null;
  avatarImage?: NullableEditModelMemoryRoomContentImageInput | null;
  avatarImageFilename?: string | null;
  backgroundImageId?: string | null;
  fundId?: string | null;
  images?: (EditModelMemoryRoomContentImageInput | null)[] | null;
  funds?: (EditModelMemoryRoomContentFundInput | null)[] | null;
  files?: (EditModelMemoryRoomContentFileInput | null)[] | null;
}

export interface EditModelMemoryRoomEntriesInput {
  password?: string | null;
  voucherKey?: string | null;
  memoryRoomUrl?: string | null;
}

export interface EditModelMemoryRoomFeaturesInput {
  isLightningCandle?: boolean | null;
  isMemorialGift?: boolean | null;
  isDeathAnnouncementAdvert?: boolean | null;
  isFuneralInfo?: boolean | null;
  isFlowerShop?: boolean | null;
}

export interface EditModelMemoryRoomFuneralCeremonyInput {
  id?: string | null;
  delete?: boolean | null;
  type?: EditModelMemoryRoomFuneralCermonyTypes | null;
  infoText?: string | null;
  locationText?: string | null;
  position?: string | null;
  time?: NullableDateTimeInput | null;
  lastBookingTime?: NullableDateTimeInput | null;
  fileCeremonyAgendaId?: string | null;
  fileCeremonyAgendaFilename?: string | null;
  fileCeremonyAgendaBase64Encoded?: string | null;
}

export interface EditModelMemoryRoomFuneralInput {
  ceremonies?: (EditModelMemoryRoomFuneralCeremonyInput | null)[] | null;
}

export interface EditModelMemoryRoomInput {
  id: string;
  isDeleted?: boolean | null;
  isHidden?: boolean | null;
  entries?: EditModelMemoryRoomEntriesInput | null;
  publications?: (EditModelMemoryRoomPublicationInput | null)[] | null;
  contents?: EditModelMemoryRoomContentsInput | null;
  features?: EditModelMemoryRoomFeaturesInput | null;
  funeral?: EditModelMemoryRoomFuneralInput | null;
  case?: EditModelMemoryRoomCaseInput | null;
  invitations?: EditModelMemoryRoomInvitationsInput | null;
}

export interface EditModelMemoryRoomInvitationGuestInput {
  id?: string | null;
  name?: string | null;
  postAddress?: string | null;
  postCity?: string | null;
  postCountry?: string | null;
  email?: string | null;
}

export interface EditModelMemoryRoomInvitationsInput {
  guests?: (EditModelMemoryRoomInvitationGuestInput | null)[] | null;
}

export interface EditModelMemoryRoomPublicationInput {
  id?: string | null;
  delete?: boolean | null;
  state?: EditModelMemoryRoomPublicationStatus | null;
  target?: EditModelMemoryRoomPublicationTargets | null;
  access?: EditModelMemoryRoomPublicationAccess | null;
  publicationStart?: NullableDateInput | null;
  publicationEnd?: NullableDateInput | null;
}

export interface GalleryImageAvailableImagesInput {
  tags: string[];
  startIndex?: number | null;
  pageLength?: number | null;
  filterGalleryImageAttributeInternalIds?: (number | null)[] | null;
  filterGalleryImageAttributeNotInternalIds?: (number | null)[] | null;
  galleryImageOption?: string | null;
  isPreview?: boolean | null;
  galleryImageDocumentFormatId?: string | null;
  documentFormatId?: string | null;
  mediaId?: string | null;
  templateAreaId?: string | null;
  tagGroupIds: string[];
}

export interface GalleryImageAvailableTagsInput {
  tags: string[];
  newTag: string;
  documentFormatId?: string | null;
  templateAreaId?: string | null;
  mediaId?: string | null;
  tagGroupIds: string[];
}

export interface GalleryImageTagsInput {
  documentFormatId?: string | null;
  templateAreaId?: string | null;
  tagGroupIds: string[];
}

export interface GalleryTextItemGroupInput {
  tags: string[];
  filterText: string;
  count?: number | null;
  startIndex?: number | null;
  documentFormatId?: string | null;
  mediaId?: string | null;
  templateAreaId?: string | null;
  filterGalleryPublic?: boolean | null;
  filterGalleryPrivate?: boolean | null;
}

export interface MarginNullableInput {
  left?: number | null;
  top?: number | null;
  right?: number | null;
  bottom?: number | null;
  type: UnitTypes;
}

export interface MaterialProofSetting {
  isSuggestionMediaOrders?: boolean | null;
  isVoucherKey?: boolean | null;
  isMemorizInfoMinnesrummet?: boolean | null;
  isSingleAdverts?: boolean | null;
  isMemorizInfoMinsida?: boolean | null;
  isCaseFeeIncludedInOrders?: boolean | null;
  isCaseFeeIncludedInFirstOrder?: boolean | null;
  isCaseFeeHide?: boolean | null;
}

export interface MediaOrderPublishChannelsInput {
  inPrintMedia?: boolean | null;
  inFamiljesidan?: boolean | null;
  inMinnesrummet?: boolean | null;
  inAgency?: boolean | null;
  inAgencyMyPage?: boolean | null;
  inWebbOnly?: boolean | null;
}

export interface MediaOrderPublishExecutionInput {
  orderId: string;
  messageToMedia?: string | null;
  codeLate?: string | null;
  mediaSystemOrderNumber?: string | null;
  mediaCustomerSubriberNumber?: string | null;
}

export interface MediaOrderReactivateExecutionInput {
  orderId: string;
}

export interface MediaOrderUnPublishExecutionInput {
  orderId: string;
  messageToMedia?: string | null;
  codeLate?: string | null;
}

export interface MinMaxOptRelativeUnitInput {
  minValue: number;
  maxValue: number;
  optValue: number;
  type: RelativeTypes;
}

export interface MutationCRUDCustomerUserInput {
  customer: CustomerInput;
}

export interface MutationCreateCaseFolderInput {
  caseFolder: CaseFolderInput;
}

export interface MutationCreateGalleryImageTagsInput {
  galleryImageTagGroupId: string;
  galleryImageTagGroupName?: string | null;
  tags: string[];
}

export interface MutationDeleteCaseFolderInput {
  caseFolderId: string;
}

export interface MutationDeleteGalleryImageTagGroupTagsOnAllImageItemsInput {
  tagGroupId: string;
  tags: string[];
}

export interface MutationDeleteOrderInitiatorPackagesInput {
  orderInitiatorPackageIds: string[];
}

export interface MutationDeleteOrderInput {
  orderIds: string[];
}

export interface MutationDeleteUserInput {
  userId: string;
}

export interface MutationInitiateCaseFolderOrdersInput {
  caseFolderId: string;
  documentId?: string | null;
  orderId?: string | null;
  orderInitiatorPackageId?: string | null;
  orderInitiator?: OrderInitiatorIdInput | null;
  orderInitiators?: OrderInitiatorIdInput[] | null;
}

export interface MutationInvokeActionInput {
  action: ActionRequestInput;
}

export interface MutationLockOrderInput {
  orderId: string;
  isLockOrUnlock?: boolean | null;
}

export interface MutationMessagingOrdersMaterialsInput {
  isOrdersProof: boolean;
  isOrderGalleryImages: boolean;
  isOrderPreviewPng: boolean;
  emailToAddress?: string | null;
  emailReplyAddress?: string | null;
  isSendEmailReplyCopy: boolean;
  message?: string | null;
  proofSetting?: MaterialProofSetting | null;
  orderIds: string[];
}

export interface MutationPublishOrdersInput {
  orders: MediaOrderPublishExecutionInput[];
}

export interface MutationReactivateUnPublishOrdersInput {
  orders: MediaOrderReactivateExecutionInput[];
}

export interface MutationSelectGalleryImageTagOnImageItemsInput {
  tagGroupId: string;
  tagGroupName?: string | null;
  tags: string[];
  isRemove?: boolean | null;
  imageIds: string[];
}

export interface MutationUnPublishOrdersInput {
  orders: MediaOrderUnPublishExecutionInput[];
}

export interface MutationUpdateCaseFolderInput {
  caseFolder: CaseFolderInput;
}

export interface MutationUpdateCurrentCustomerSettingsInput {
  customerScopeSettings: CustomerScopeSettingsInput[];
}

export interface MutationUpdateCurrentUserSessionInput {
  customerId?: string | null;
  cultureCode?: string | null;
}

export interface MutationUpdateCustomerContractInput {
  customerContractId: string;
  endCustomerProducts?: CustomerContractProductEndCustomerInput[] | null;
}

export interface MutationUpdateCustomerDocumentContentInitiatorInput {
  keepDefaultAreas?: boolean | null;
  replaceLowerCustomerScope: boolean;
  customerScope: CustomerScope;
  documentFormatInitiatorId: string;
  areas: CustomerDocumentContentInitiatorAreaInput[];
}

export interface MutationUpdateOrderContentInput {
  orderId: string;
  editModel: EditModelInput;
}

export interface MutationUpdateOrderDocumentFormatInput {
  orderId: string;
  documentFormatId?: string | null;
  styleTemplate2Id?: number | null;
}

export interface MutationUpdateOrderInitiatorPackagesInput {
  orderInitiatorPackages: OrderInitiatorPackageInput[];
}

export interface MutationUpdateOrderPriceInput {
  orderId: string;
  price: PriceUpdateInput;
}

export interface MutationUpdateOrderPublishChannelsInput {
  orderId: string;
  publishChannels: MediaOrderPublishChannelsInput;
}

export interface MutationUpdateOrderPublishTimesInput {
  orderId: string;
  publishTimes: any[];
}

export interface MutationUpdateOrdersContentFromOrderInput {
  orderId: string;
}

export interface MutationUpdateOrdersContentInput {
  orderId: string;
  editModel: EditModelInput;
}

export interface MutatonUpdateOrdersContentFromHistoryInput {
  orderId: string;
  offset: number;
}

export interface NullableDateInput {
  value?: any | null;
}

export interface NullableDateTimeInput {
  value?: any | null;
}

export interface NullableDecimalInput {
  value?: any | null;
}

export interface NullableEditModelMemoryRoomContentImageInput {
  value?: EditModelMemoryRoomContentImageInput | null;
}

export interface OrderCollectionMessagingInfoInput {
  orderIds: string[];
  email: string;
}

export interface OrderInitiatorIdInput {
  mediaId?: string | null;
  documentFormatInitiatorId?: string | null;
  customerDocumentFormatInitiatorId?: string | null;
}

export interface OrderInitiatorPackageInput {
  id?: string | null;
  name: string;
  commonPrice?: any | null;
  imageUrl?: string | null;
  imageId?: string | null;
  accessCustomerScope: CustomerScope;
  items: OrderInitiatorIdInput[];
}

export interface PriceUpdateInput {
  priceContractOverrideCustomer?: NullableDecimalInput | null;
  priceContractOverrideTimecut?: NullableDecimalInput | null;
}

export interface RelativeUnitInput {
  type: RelativeTypes;
  value: number;
}

export interface SaveGalleryTextItemInput {
  galleryTextItemId?: string | null;
  documentFormatId?: string | null;
  mediaId?: string | null;
  templateAreaId?: string | null;
  content: string;
}

export interface SearchOrderConditionInput {
  compareValue?: string | null;
  type: SearchOrderConditionTypes;
  childConditions?: (SearchOrderConditionInput | null)[] | null;
}

export interface SizeLimitNullableInput {
  type: UnitTypes;
  minWidth?: number | null;
  minHeight?: number | null;
  maxWidth?: number | null;
  maxHeight?: number | null;
  fixedWidth?: number | null;
  fixedHeight?: number | null;
}

export interface StatsOrderInput {
  customerScope: CustomerScope;
  periodScope: StatsOrderPeriods;
  startIndex: number;
  pageLength: number;
  customPeriodStart?: any | null;
  customPeriodDayLength?: number | null;
  customerId?: string | null;
  productIds: string[];
  documentTypeId?: string | null;
  sortField?: StatsOrderSort | null;
  sortDescending?: boolean | null;
}

export interface UserCredentialInput {
  username?: string | null;
  password?: string | null;
  tempKey?: string | null;
  tempSessionToken?: string | null;
}

export interface ValueUnitInput {
  value: number;
  type: UnitTypes;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
