import React from "react"
import styles from "../styles.scss"
import Button from "components/Button"
import { useTranslation } from "react-i18next"
import cx from "classnames"
import { ExpandIcon, ShrinkIcon } from "asset/svg/SvgComponents"

const SymbolModeToggleButton: React.FC<{
  isFEUser: boolean
  isSymboleViewMode: boolean
  handleToggleSymboleView: () => void
}> = ({ isFEUser, isSymboleViewMode, handleToggleSymboleView }) => {
  const { t } = useTranslation()
  return (
    <Button
      className={cx(styles.toggleSymboleButton, {
        [styles.expanded]: isSymboleViewMode,
      })}
      variant={isFEUser ? "feView" : "primary"}
      onClick={handleToggleSymboleView}
    >
      {/* <div>
        {isSymboleViewMode
          ? t("editOrder:showNormalMode")
          : t("editOrder:showSymbolMode")}
      </div> */}
      <div>
        {isSymboleViewMode
          ? React.cloneElement(<ShrinkIcon />, {
              size: "25px",
              color: isFEUser ? "#3C5E25" : "#fff",
            })
          : React.cloneElement(<ExpandIcon />, {
              size: "25px",
              color: isFEUser ? "#3C5E25" : "#fff",
            })}
      </div>
    </Button>
  )
}

export default SymbolModeToggleButton
