import * as React from "react"
import * as styles from "./styles.scss"
import * as cx from "classnames"
// const cx = require("classnames")
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
// import posed from "react-pose1"
import { motion } from "framer-motion"
import { IconProp } from "@fortawesome/fontawesome-svg-core"

// const PosedDiv = motion.div({
//   enter: { scale: 1 },
//   exit: { scale: 0 },
// })

const variants = {
  enter: { scale: 1 },
  exit: { scale: 0 },
}

class Button extends React.PureComponent<ButtonProps> {
  static defaultProps = {
    type: "button",
    isWorking: false,
    variant: "secondary",
    color: "secondary",
    isDiv: false,
  }

  getClassName = () =>
    cx(
      styles.button,
      styles[`variant-${this.props.variant}`],
      styles[`color-${this.props.color}`],
      this.props.className,
      {
        [styles.isWorking]: this.props.isWorking,
        [styles.disabled]: this.props.disabled,
        [styles.narrow]: this.props.narrow,
      }
    )

  handleClick = () => {
    if (this.props.disabled || !this.props.onClick) return
    this.props.onClick()
  }

  getContent = () => (
    <React.Fragment>
      <motion.div
        key="spinner-icon"
        initial={"enter"}
        // pose="enter"
        // animate="visible"
        animate={this.props.isWorking ? "enter" : "exit"}
        // withParent={false}
        className={styles.spinnerContainer}
        variants={variants}
      >
        <FontAwesomeIcon icon="spinner" className={styles.spinner} />
      </motion.div>

      <motion.div
        // withParent={false}
        initial={"enter"}
        // pose={this.props.isWorking ? "exit" : "enter"}
        animate={this.props.isWorking ? "exit" : "enter"}
        className={styles.buttonContent + " " + "global-button-content"}
        variants={variants}
      >
        {this.props.icon && (
          <FontAwesomeIcon icon={this.props.icon} className={styles.icon} />
        )}
        {this.props.children}
      </motion.div>
    </React.Fragment>
  )

  render() {
    const { isWorking, isDiv, type, disabled, id, dataTestId } = this.props

    return isDiv ? (
      <div onClick={this.handleClick} className={this.getClassName()} id={id}>
        {this.getContent()}
      </div>
    ) : (
      <button
        id={id}
        onClick={this.handleClick}
        className={this.getClassName()}
        type={type}
        disabled={isWorking || disabled}
        data-testid={dataTestId}
        onBlur={this.props.onBlur}
      >
        {this.getContent()}
      </button>
    )
  }
}

interface ButtonProps {
  type: "button" | "reset" | "submit" | undefined
  isWorking: boolean
  variant: ButtonVariants
  color: ButtonColors
  className?: string
  onClick?: () => void
  disabled?: boolean
  narrow?: boolean
  icon?: IconProp
  isDiv?: boolean
  id?: string
  dataTestId?: string
  onBlur?: () => void
}

export default Button
