import React from "react"

export const SvgStarsIcon: React.FC<{
  size?: number | string
}> = ({ size = "18" }) => (
  <svg
    baseProfile="basic"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 22.0004883 22"
    xmlSpace="preserve"
    width={size}
    height={size}
  >
    <g>
      <path
        fill="#1289A7"
        d="M19.347168,11.0620117l-6.1376953-2.2714844L10.9379883,2.652832C10.7924805,2.2607422,10.418457,2,10,2 S9.2075195,2.2607422,9.0620117,2.652832L6.7905273,8.7905273L0.652832,11.0620117C0.2602539,11.2075195,0,11.581543,0,12 c0,0.4189453,0.2607422,0.7929688,0.652832,0.9375l6.1376953,2.2714844l2.2714844,6.1376953 C9.2075195,21.7392578,9.581543,22,10,22s0.7924805-0.2607422,0.9379883-0.6533203l2.2714844-6.1376953L19.347168,12.9375 c0.3925781-0.1445312,0.6533203-0.5185547,0.6533203-0.9375C20.0004883,11.581543,19.7397461,11.2075195,19.347168,11.0620117z  M12.0839844,13.4931641c-0.2734375,0.1005859-0.4892578,0.3164062-0.5908203,0.5908203L10,18.1191406l-1.4931641-4.0351562 c-0.1015625-0.2744141-0.3173828-0.4902344-0.5908203-0.5908203L3.8813477,12l4.034668-1.4931641 c0.2734375-0.1015625,0.4892578-0.3173828,0.5908203-0.5908203L10,5.8808594l1.4931641,4.0351562 c0.1015625,0.2734375,0.3173828,0.4892578,0.5908203,0.5908203L16.1186523,12L12.0839844,13.4931641z"
      />
      <path
        fill="#1289A7"
        d="M13.6538086,3.4379883l0.6630859,0.2451172l0.2460938,0.6640625 C14.7075195,4.7392578,15.0825195,5,15.5004883,5c0.4189453,0,0.7929688-0.2612305,0.9384766-0.6538086l0.2441406-0.6630859 l0.6640625-0.2451172C17.7397461,3.2929688,18.0004883,2.9189453,18.0004883,2.5 c0-0.418457-0.2607422-0.7924805-0.6533203-0.9379883l-0.6640625-0.2456055l-0.2441406-0.6630859 C16.293457,0.2607422,15.9194336,0,15.5004883,0c-0.4179688,0-0.7929688,0.2602539-0.9375,0.652832l-0.2460938,0.6635742 L13.652832,1.5620117C13.2612305,1.7075195,13.0004883,2.081543,13.0004883,2.5S13.2612305,3.2929688,13.6538086,3.4379883z"
      />
      <path
        fill="#1289A7"
        d="M21.347168,6.0620117l-0.2988281-0.1103516l-0.109375-0.2978516 C20.793457,5.2612305,20.4194336,5.0004883,20.0014648,5c-0.0009766,0-0.0009766,0-0.0009766,0 c-0.4179688,0-0.7919922,0.2602539-0.9375,0.6523438l-0.1113281,0.2993164l-0.2978516,0.1103516 C18.2612305,6.2075195,18.0004883,6.581543,18.0004883,7s0.2607422,0.7924805,0.6533203,0.9379883l0.2978516,0.1103516 l0.1113281,0.2993164C19.2084961,8.7397461,19.5825195,9,20.0004883,9c0,0,0,0,0.0009766,0 c0.4179688-0.0004883,0.7919922-0.2612305,0.9375-0.6538086l0.109375-0.2978516l0.2988281-0.1103516 C21.7397461,7.7929688,22.0004883,7.418457,22.0004883,7S21.7397461,6.2070312,21.347168,6.0620117z"
      />
    </g>
  </svg>
)


export const SvgQuestionIcon:React.FC<{
  size?: number | string
}> = ({size= "18"}) => (
  <svg
  height={size}
  width={size}
  id="Capa_1"
  xmlns="http://www.w3.org/2000/svg"
  xmlnsXlink="http://www.w3.org/1999/xlink"
  viewBox="0 0 302.967 302.967"
  xmlSpace="preserve"
>
  <g>
    <g>
      <g>
        <path
          style={{
            fill: "#010002",
          }}
          d="M151.483,302.967C67.956,302.967,0,235.017,0,151.483S67.956,0,151.483,0 s151.483,67.956,151.483,151.483S235.017,302.967,151.483,302.967z M151.483,24.416c-70.066,0-127.067,57.001-127.067,127.067 s57.001,127.067,127.067,127.067s127.067-57.001,127.067-127.067S221.555,24.416,151.483,24.416z"
        />
      </g>
      <g>
        <g>
          <path
            style={{
              fill: "#010002",
            }}
            d="M116.586,118.12c1.795-4.607,4.297-8.588,7.511-11.961c3.225-3.389,7.114-6.016,11.667-7.898 c4.547-1.904,9.633-2.845,15.262-2.845c7.261,0,13.32,0.995,18.183,2.997c4.857,1.996,8.768,4.482,11.738,7.441 c2.964,2.97,5.091,6.168,6.369,9.584c1.273,3.432,1.915,6.636,1.915,9.595c0,4.901-0.642,8.947-1.915,12.118 c-1.278,3.171-2.866,5.88-4.759,8.131c-1.898,2.252-3.987,4.172-6.293,5.755c-2.295,1.588-4.471,3.171-6.516,4.759 c-2.045,1.583-3.862,3.394-5.445,5.439c-1.588,2.04-2.589,4.601-2.991,7.664v5.831H140.6v-6.908 c0.305-4.395,1.153-8.072,2.529-11.036c1.382-2.964,2.991-5.499,4.83-7.598c1.844-2.089,3.786-3.911,5.836-5.445 c2.04-1.539,3.927-3.073,5.673-4.591c1.73-1.545,3.144-3.225,4.221-5.069c1.071-1.833,1.556-4.15,1.452-6.908 c0-4.705-1.148-8.18-3.454-10.427c-2.295-2.257-5.493-3.378-9.589-3.378c-2.758,0-5.134,0.533-7.131,1.605 s-3.628,2.513-4.911,4.302c-1.278,1.795-2.225,3.894-2.834,6.288c-0.615,2.415-0.919,4.982-0.919,7.756h-22.55 C113.85,127.785,114.791,122.732,116.586,118.12z M162.536,183.938v23.616h-24.09v-23.616H162.536z"
          />
        </g>
      </g>
    </g>
  </g>
</svg>
)

export const SvgTextScaleIcon: React.FC<{
  size?: number | string
  color?: string
}> = ({ size = "18px", color = "#000" }) => (
  <svg
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 22 22"
    xmlSpace="preserve"
    width={size}
    height={size}
    fill={color}
  >
    <g>
      <g className="st0">
        <rect x={2.5} y={5} className="st1" width={17} height={1} />
      </g>
      <g className="st0">
        <rect x={2.5} y={16} className="st1" width={17} height={1} />
      </g>
      <g>
        <path
          className="st1"
          d="M14.8,8.6l2,2c0.2,0.2,0.2,0.6,0,0.8l-2,2c-0.4,0.4-1,0.1-1-0.4v-1.1H8.1V13c0,0.5-0.6,0.8-1,0.4l-2-2 c-0.2-0.2-0.2-0.6,0-0.8l2-2c0.4-0.4,1-0.1,1,0.4v1.1h5.7V9C13.9,8.5,14.5,8.2,14.8,8.6z"
        />
      </g>
    </g>
  </svg>
)

export const SvgMarginLeftRightIcon: React.FC<{
  size?: number | string
  color?: string
}> = ({ size = "18px", color = "#000" }) => (
  <svg
    viewBox="0 0 22 22"
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    fill={color}
  >
    <g>
      <path
        d="M20 15.0397L19.8974 6L18 6.00598L18.1026 15.0457L20 15.0397Z"
      />
    </g>
    <g>
      <path
        d="M3.9934 15.0071L4.00738 6.00078L2.01397 6L2 15.0063L3.9934 15.0071Z"
      />
    </g>
    <path
      d="M14.8 8.6L16.8 10.6C17 10.8 17 11.2 16.8 11.4L14.8 13.4C14.4 13.8 13.8 13.5 13.8 13V11.9H8.10001V13C8.10001 13.5 7.50001 13.8 7.10001 13.4L5.10001 11.4C4.90001 11.2 4.90001 10.8 5.10001 10.6L7.10001 8.6C7.50001 8.2 8.10001 8.5 8.10001 9V10.1H13.8V9C13.9 8.5 14.5 8.2 14.8 8.6Z"
    />
  </svg>
)


export const MagnifyingGlassWithBookmark: React.FC<{
  size?: number | string
  color?: string
}> = ({ size = "18px", color = "#000" }) => (
  <svg
  baseProfile="basic"
  id="Layer_1"
  xmlns="http://www.w3.org/2000/svg"
  xmlnsXlink="http://www.w3.org/1999/xlink"
  viewBox="0 0 19.1533203 21.7128906"
  xmlSpace="preserve"
  width={size}
  height={size}
  fill={color}
>
  <path d="M17.3017578,2.6601562c-0.0009766,0-0.0009766,0-0.0019531,0h-5.2460938 c0.2246704,0.6321411,0.3640747,1.3024292,0.4071655,2h4.6924438V18.512207l-4.3105469-3.7626953 c-0.1875-0.1645508-0.4228516-0.246582-0.6572266-0.246582s-0.46875,0.0820312-0.6572266,0.246582l-4.3110352,3.7607422v-6.3404541 c-0.6473389,0.1939087-1.3200684,0.3009644-2,0.3099365v8.2302246c0,0.3920898,0.2290039,0.7480469,0.5859375,0.9101562 c0.3574219,0.1625977,0.7758789,0.1010742,1.0712891-0.1567383l5.3110352-4.6333008l5.3105469,4.6362305 c0.1855469,0.1621094,0.4199219,0.246582,0.6572266,0.246582c0.140625,0,0.28125-0.0292969,0.4140625-0.0893555 c0.3564453-0.1625977,0.5859375-0.5185547,0.5859375-0.9106445V4.5092773 C19.1513672,3.4892578,18.3203125,2.6601562,17.3017578,2.6601562z" />
  <path d="M11.2226562,10.0063477L9.2861328,8.0742188c0.6137695-0.8579102,0.9492188-1.8994141,0.9482422-2.9570312 C10.234375,2.2954102,7.9389648,0,5.1171875,0S0,2.2954102,0,5.1171875c0,2.8212891,2.2954102,5.1166992,5.1166992,5.1166992 c0.0024414,0,0.0048828,0,0.0068359,0c1.0556641,0,2.0947266-0.3349609,2.9506836-0.9477539l1.90625,1.9121094 c0.1689453,0.1733398,0.3935547,0.2602539,0.6181641,0.2602539c0.2158203,0,0.4326172-0.0810547,0.6005859-0.2436523 C11.5390625,10.8837891,11.5458984,10.3374023,11.2226562,10.0063477z M11.024292,10.1602783L11.024292,10.1602783 l0.0001221-0.0001221L11.024292,10.1602783z M5.1171875,8.5170898c-1.8745117,0-3.3999023-1.5253906-3.3999023-3.3999023 s1.5253906-3.3999023,3.3999023-3.3999023s3.3999023,1.5253906,3.3999023,3.3999023S6.9916992,8.5170898,5.1171875,8.5170898z" />
</svg>
)

export const UploadIcon = ({ size = "18px", color = "#000" }) => (
  <svg
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 59 59"
    enableBackground="new 0 0 59 59"
    xmlSpace="preserve"
    width={size}
    height={size}
    fill={color}
  >
    <g id="Group_2" transform="translate(-1067 -500)">
      <circle
        id="Ellipse_1"
        opacity={0.08}
        enableBackground="new    "
        cx={1096.5}
        cy={529.5}
        r={29.5}
      />
      <g id="Group_1" transform="translate(1082.285 514.811)">
        <path
          id="Path_1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.2,19.9c0.5,0,1,0.4,1,1v2.6 c0,0.2,0.1,0.3,0.3,0.3h0H22c0.2,0,0.3-0.1,0.3-0.3c0,0,0,0,0,0v-2.6c0-0.5,0.4-1,1-1c0.5,0,1,0.4,1,1v2.6c0,1.2-1,2.3-2.3,2.3 H6.5c-1.2,0-2.3-1-2.3-2.3v-2.6C4.2,20.3,4.7,19.9,5.2,19.9C5.2,19.9,5.2,19.9,5.2,19.9z"
        />
        <path
          id="Path_2"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.6,19.7c-0.7,0-1.2-0.5-1.3-1.2 c-0.2-2.3-0.2-4.5-0.1-6.8c-0.3,0-0.6,0-1-0.1l-1.9-0.1c-0.5,0-0.9-0.5-0.9-1.1c0-0.2,0.1-0.3,0.2-0.5c1.4-2.1,3.1-4,5.2-5.5 L13.6,4c0.4-0.3,0.9-0.3,1.3,0l0.8,0.6c2.1,1.5,3.8,3.3,5.2,5.5c0.3,0.5,0.2,1.1-0.3,1.4c-0.1,0.1-0.3,0.1-0.5,0.2l-1.9,0.1 c-0.3,0-0.6,0-1,0.1c0.1,2.3,0.1,4.5-0.1,6.8c-0.1,0.7-0.6,1.2-1.3,1.2H12.6z"
        />
      </g>
    </g>
  </svg>
)

export const ExpandIcon = ({ size = "18px", color = "#fff" }) => (
  <svg
    viewBox="-2.4 -2.4 28.80 28.80"
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    fill={color}
  >
    <g id="SVGRepo_bgCarrier" strokeWidth={0} />
    <g
      id="SVGRepo_tracerCarrier"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <g id="SVGRepo_iconCarrier">
      {'\n      {" "}\n      '}
      <path
        d="M16 8L21 3M21 3H16M21 3V8M8 8L3 3M3 3L3 8M3 3L8 3M8 16L3 21M3 21H8M3 21L3 16M16 16L21 21M21 21V16M21 21H16"
        stroke={color}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      {'{" "}\n    '}
    </g>
  </svg>
)

export const ShrinkIcon = ({ size = "18px", color = "#fff" }) => (
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    id="minimize-size"
    className="icon glyph"
    width={size}
    height={size}
    fill={color}
  >
    <g id="SVGRepo_bgCarrier" strokeWidth={0} />
    <g
      id="SVGRepo_tracerCarrier"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <g id="SVGRepo_iconCarrier">
      <path d="M9,3A1,1,0,0,0,8,4V6.59L3.71,2.29A1,1,0,0,0,2.29,3.71L6.59,8H4a1,1,0,0,0,0,2H8a2,2,0,0,0,2-2V4A1,1,0,0,0,9,3Z" />
      <path d="M16,10h4a1,1,0,0,0,0-2H17.41l4.3-4.29a1,1,0,1,0-1.42-1.42L16,6.59V4a1,1,0,0,0-2,0V8A2,2,0,0,0,16,10Z" />
      <path d="M8,14H4a1,1,0,0,0,0,2H6.59l-4.3,4.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0L8,17.41V20a1,1,0,0,0,2,0V16A2,2,0,0,0,8,14Z" />
      <path d="M17.41,16H20a1,1,0,0,0,0-2H16a2,2,0,0,0-2,2v4a1,1,0,0,0,2,0V17.41l4.29,4.3a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42Z" />
    </g>
  </svg>
)
