import gql from "graphql-tag"

const USER_INTERFACE_FRAGMENT = gql`
fragment userInterfaceFragment on EditUI {
  editStatusColor
  editStatusText
  publishButtonText
  editStatusIcon
  isUnPublishButton
  unPublishButtonText
  isPublishButton
  isLockButton
  lockButtonText
  isUnlockButton
  unlockButtonText
  isReactivateButton
  reactivateButtonText
  isSetExhibitOrder
  setExhibitOrderText
  isUpdateCustomerDocumentContentInitiator
  updateCustomerDocumentContentInitiatorText
  orderEntityNameSingular: orderEntityName 
  orderEntityNamePlural: orderEntityName(plural:true)
  orderEntityNameSingularDetermined: orderEntityName(plural:true, determined:true)
  orderEntityNamePluralDetermined: orderEntityName(plural:true, determined:true)
	isCopyFuneralInfoToMemoryRoom
  copyFuneralInfoToMemoryRoomText
  isNetnoticeEnabled
  isNetnoticeSupported
}
`
export default USER_INTERFACE_FRAGMENT
