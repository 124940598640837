import * as React from "react"
import { FieldWrapperFormik } from "components/FieldWrapper"
import * as styles from "./styles.scss"
import LOGIN from "graphql/mutations/login"
import { Login, LoginVariables } from "schema"
import TextInput from "components/TextInput"
import { useTranslation } from "react-i18next"
import SubmitButton from "./SubmitButton"
import Logo from "./Logo"
import { useApolloClient, useMutation } from "react-apollo-hooks"
import { SessionStorageKeys } from "environmentUtils"
import { useFormik } from "formik"
import { object, string } from "yup"

const LoginForm: React.FC<{}> = () => {
  const [t] = useTranslation(["login", "errorMessages"])
  const [loading, setLoading] = React.useState(false)
  const client = useApolloClient()
  const loginFn = useMutation<Login, LoginVariables>(LOGIN, { client })

  interface FormData {
    username: string
    password: string
  }

  const validationSchema = object().shape({
    username: string().required().min(2).max(500),
    password: string().required().min(2).max(500),
  })

  const formik = useFormik<FormData>({
    initialValues: {
      username: "",
      password: "",
    },
    validationSchema,
    onSubmit: (values) => {
      setLoading(true)
      loginFn({
        variables: {
          userCredentialInput: {
            password: values.password,
            username: values.username,
          },
        },
        context: {
          skipErrorModal: true,
        },
      })
        .then((data) => {
          setLoading(false)
          if (
            data &&
            data.data &&
            data.data.loginByCredential &&
            data.data.loginByCredential.authorizationToken
          ) {
            sessionStorage.setItem(
              SessionStorageKeys.AUTH_TOKEN,
              data.data.loginByCredential.authorizationToken
            )
          }

          // tslint:disable-next-line: prefer-conditional-expression
          if (
            data &&
            data.data &&
            data.data.loginByCredential &&
            data.data.loginByCredential.user &&
            data.data.loginByCredential.user.customers &&
            data.data.loginByCredential.user.customers.length > 1
          ) {
            window.location.href = "/selectOffice"
          } else {
            window.location.href = "/dashboard"
          }
        })
        .catch((err) => {
          setLoading(false)
          formik.setStatus(t(`errorMessages:loginFormError`))
        })
    },
  })

  return (
    <form onSubmit={formik.handleSubmit} className={styles.loginForm}>
      <Logo />
      <FieldWrapperFormik
        value={formik.values.username}
        onChange={(e: any) => {
          formik.setFieldValue("username", e.target.value)
        }}
        errors={formik.touched.username && formik.errors.username}
        name="username"
        label={t("username")}
        placeholder={t("username")}
        component={TextInput}
        autoFocus={true}
        isAutoComplete={true}
      />

      <FieldWrapperFormik
        value={formik.values.password}
        onChange={(e: any) => {
          formik.setFieldValue("password", e.target.value)
        }}
        errors={formik.touched.password && formik.errors.password}
        name="password"
        label={t("password")}
        placeholder={t("password")}
        type="password"
        component={TextInput}
        className={styles.passwordField}
        isAutoComplete={true}
      />

      <SubmitButton isWorking={loading} id={"submit"} />

      {formik.status && (
        <div className={styles.submitError}>{formik.status}</div>
      )}
    </form>
  )
}

export default LoginForm
