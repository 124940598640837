import * as React from "react"
import * as styles from "./styles.scss"
import {
  GetOrderById_orders,
  GetEditOrderPageCaseFolder_caseFolder_editTabs_orders,
  GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas,
  GetOrderById,
  GetOrderByIdVariables,
  GetOrderById_orders_mediaOrder_edit_userInterface,
  GetOrderById_orders_mediaOrder_documentFormat_documentFormatCollection,
} from "schema"
import OrderPreview, { OrderPreviewRectangle } from "../OrderPreview"
import AreasContainer from "./AreasContainer"
import { VerseEditingContextProvider } from "./VerseEditingModal/VerseModalContext"
import { BottomPublishOrderBlock } from "../PublishOrderBlock"
import { useApolloClient, useQuery } from "react-apollo-hooks"
import GET_ORDER_BY_ID from "graphql/queries/getOrderById"
import ErrorMessage from "components/ErrorMessage"
import Loading from "../Loading"
import { TopSidebarCaseInfoBlock } from "./TopSidebarCaseInfoBlock"
import produce from "immer"
import { createContext } from "use-context-selector"
import { SubscriptionHandler } from "./SubscriptionHandler"
import { AppContext } from "App"
import cx from "classnames"
import { is } from "immer/dist/internal"

export const OrderEditorContext = createContext<OrderEditorContextValue>(
  {} as any
)

export const OrderEditoryAdvert: React.FC<{
  orderId: string
  orders: GetEditOrderPageCaseFolder_caseFolder_editTabs_orders[]
  editTabs: any[]
  documentId: string
  caseFolderId: string
  caseFolderName: string
}> = (props) => {
  const client = useApolloClient()
  const [isNetNoticeVisible, setNetNoticeVisible] = React.useState<boolean>(false)
  const { featureFlags } = React.useContext(AppContext)
  const { loading, data, error, refetch } = useQuery<
    GetOrderById,
    GetOrderByIdVariables
  >(GET_ORDER_BY_ID, {
    client,
    variables: {
      id: props.orderId,
    },
    context: {},
    fetchPolicy: "network-only",
  })


  if (error) {
    return null
  }

  if (loading) {
    return <Loading />
  }

  if (!(data && data.orders && data.orders.length > 0)) {
    return <ErrorMessage message={"no data"} />
  }

  const handleNetNoticeOpen = () => {
    setNetNoticeVisible(true)
  }

  const handleNetNoticeClose = () => {
    setNetNoticeVisible(false)
  }

  return (
    <>
      <AdvertOrderEditor
        formatCollection={
          data.orders[0].mediaOrder?.documentFormat.documentFormatCollection!
        }
        orderId={props.orderId}
        orders={props.orders}
        caseFolderId={props.caseFolderId}
        caseFolderName={props.caseFolderName}
        order={data.orders?.[0] ?? []}
        documentId={props.documentId}
        editTabs={props.editTabs}
        previewImageUrl={
          data.orders?.[0]?.mediaOrder?.preview?.material?.pages?.[0]?.png
            ?.url ?? ""
        }
        refetch={refetch}
        isFEUser={featureFlags?.isFEUser!}
        handleNetNoticeOpen={handleNetNoticeOpen}
        handleNetNoticeClose={handleNetNoticeClose}
        isNetNoticeVisible={isNetNoticeVisible}
      />
      {/* <ModalTap5Info
        isTaps5={data.orders?.[0]?.client?.isTaps5}
        taps4Url={data.orders?.[0]?.client?.route?.taps4Url}
      /> */}
    </>
  )
}

const AdvertOrderEditor: React.FC<{
  order: GetOrderById_orders
  orders: GetEditOrderPageCaseFolder_caseFolder_editTabs_orders[]
  caseFolderId: string
  orderId: string
  caseFolderName: string
  previewImageUrl: string
  editTabs: any[]
  documentId: string
  refetch: () => void
  formatCollection: GetOrderById_orders_mediaOrder_documentFormat_documentFormatCollection
  isFEUser: boolean
  handleNetNoticeOpen: () => void
  handleNetNoticeClose: () => void
  isNetNoticeVisible: boolean
}> = (props) => {
  const mainRef = React.useRef<HTMLDivElement | null>(null)
  const defaultState = {
    expandedAreaId: undefined,
    hoveredAreaId: undefined,
    previewIsUpdating: false,
  }
  const [state, setState] = React.useState<OrderEditorState>(defaultState)

  const togglePreviewIsUpdatingOn = () => {
    setState(
      produce(state, (draft) => {
        draft.previewIsUpdating = true
      })
    )
  }

  const togglePreviewIsUpdatingOff = () => {
    setState(
      produce(state, (draft) => {
        draft.previewIsUpdating = false
      })
    )
  }

  const setExpandedAreaId = (id: string) => {
    setState(
      produce(state, (draft) => {
        draft.expandedAreaId = id === state.expandedAreaId ? undefined : id
      })
    )
  }

  const setHoveredAreaId = (id: string) => {
    setState(
      produce(state, (draft) => {
        draft.hoveredAreaId = id === state.hoveredAreaId ? undefined : id
      })
    )
  }

  const areas =
    (props.order.mediaOrder?.edit?.editModelAdvert?.basePage
      ?.areas as GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas[]) ??
    []

  const styleCssSpecialCharacters =
    props.order.mediaOrder?.edit?.resources?.styleCssSpecialCharacters ?? ""

  React.useEffect(() => {
    setExpandedAreaId(areas[0].id)
  }, [])

  return (
    <div ref={mainRef}>
      <SubscriptionHandler orderId={props.orderId} />

      <div
        className={cx(styles.orderEditor, {
          [styles.adstatView_orderEditor]: props.isFEUser,
        })}
      >
        <style>{styleCssSpecialCharacters}</style>

        <VerseEditingContextProvider>
          <OrderEditorContext.Provider
            value={{
              expandedAreaId: state.expandedAreaId,
              hoveredAreaId: state.hoveredAreaId,
              previewIsUpdating: state.previewIsUpdating,
              setExpandedAreaId,
              setHoveredAreaId,
              togglePreviewIsUpdatingOn,
              togglePreviewIsUpdatingOff,
              selectedCaseFolderId: props.caseFolderId,
              previewImageUrl: `${props.previewImageUrl}`,
              previewImageRectangles: areas
                .filter((area) => area.rectangle)
                .map((area) => ({ ...area.rectangle!, id: area.id! })),
              selectedOrder: props.order,
              isLocked: props.order.mediaOrder?.isLocked ?? false,
              orders: props.orders,
              userInterface: props.order.mediaOrder?.edit.userInterface,
              isDeleted: props.order.isDeleted,
              handleNetNoticeOpen: props.handleNetNoticeOpen,
              handleNetNoticeClose: props.handleNetNoticeClose,
              isNetNoticeVisible: props.isNetNoticeVisible
            }}
          >
            <div className={styles.leftBlock}>
              <OrderPreview
                refetch={props.refetch}
                formatCollection={props.formatCollection}
              />
              {!props.isFEUser && (
                <BottomPublishOrderBlock
                  caseFolderId={props.caseFolderId}
                  documentId={props.documentId}
                  editTabs={props.editTabs}
                  order={props.order}
                  orderId={props.orderId}
                />
              )}
            </div>

            <div className={styles.sidebarBlock}>
              {!props.isFEUser && (
                <TopSidebarCaseInfoBlock
                  name={props.caseFolderName}
                  caseId={props.caseFolderId}
                />
              )}

              <AreasContainer
                areas={areas}
                selectedOrderId={props.order.id!}
                editTabs={props.editTabs}
                order={props.order}
                isFEUser={props.isFEUser}
              />
            </div>
          </OrderEditorContext.Provider>
        </VerseEditingContextProvider>
      </div>
    </div>
  )
}

interface OrderEditorState {
  expandedAreaId?: string
  hoveredAreaId?: string
  previewIsUpdating: boolean
}

interface OrderEditorContextValue extends OrderEditorState {
  previewImageUrl: string
  previewImageRectangles: OrderPreviewRectangle[]
  selectedOrder: GetOrderById_orders
  orders: GetEditOrderPageCaseFolder_caseFolder_editTabs_orders[]
  isLocked: boolean
  selectedCaseFolderId: string
  setHoveredAreaId: (id: string | undefined) => void
  setExpandedAreaId: (id: string) => void
  togglePreviewIsUpdatingOn: () => void
  togglePreviewIsUpdatingOff: () => void
  userInterface: GetOrderById_orders_mediaOrder_edit_userInterface | undefined
  isDeleted: boolean
  handleNetNoticeOpen: () => void
  handleNetNoticeClose: () => void
  isNetNoticeVisible: boolean
}

export default AdvertOrderEditor
